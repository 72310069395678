package studio.goodegg.capsule.downloads

import com.slack.circuit.runtime.CircuitUiEvent
import studio.goodegg.capsule.DownloadedEpisode

sealed class DownloadsUiEvent : CircuitUiEvent {
    data class Delete(val slug: String) : DownloadsUiEvent()
    data class PlayDownload(val episode: DownloadedEpisode) : DownloadsUiEvent()
    data class OpenPodcastDetails(val slug: String) : DownloadsUiEvent()
    data class OpenEpisodeDetails(val episode: DownloadedEpisode) : DownloadsUiEvent()
    data object Back : DownloadsUiEvent()
}
