package io.daio.pancake.components.search

import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.interaction.collectIsFocusedAsState
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.text.BasicTextField
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import io.daio.pancake.components.text.TextAreaColors
import io.daio.pancake.components.text.TextAreaDefaults
import io.daio.pancake.components.text.containerColor
import io.daio.pancake.components.text.cursorColor
import io.daio.pancake.components.text.labelColor
import io.daio.pancake.components.text.placeholderColor
import io.daio.pancake.components.text.textColor
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.layout.Tier
import io.daio.wild.container.Container
import io.daio.wild.content.ProvidesContentColor
import io.daio.wild.style.Border
import io.daio.wild.style.BorderDefaults

@Composable
fun SearchBar(
    value: String,
    onValueChange: (String) -> Unit,
    modifier: Modifier = Modifier,
    textStyle: TextStyle = Theme.typography.bodyLarge,
    onEnter: (() -> Unit)? = null,
    trailing: (@Composable () -> Unit)? = null,
    keyboardOptions: KeyboardOptions = KeyboardOptions.Default,
    keyboardActions: KeyboardActions = KeyboardActions {
        onEnter?.invoke()
    },
    enabled: Boolean = true,
    error: Boolean = false,
    border: Border = BorderDefaults.None,
    shape: Shape = Theme.shapes.large,
    contentPadding: PaddingValues = SearchBarDefaults.contentPadding,
    colors: TextAreaColors = TextAreaDefaults.colors(),
    placeholder: @Composable (() -> Unit)? = null,
    interactionSource: MutableInteractionSource = remember { MutableInteractionSource() },
) {
    val focused by interactionSource.collectIsFocusedAsState()

    Container(
        color = colors.containerColor(focused = focused, error = error, enabled = enabled),
        contentColor = colors.textColor(focused = focused, error = error, enabled = enabled),
        shape = shape,
        border = border,
        modifier = modifier.height(SearchBarDefaults.minHeight),
    ) {
        BasicTextField(
            value = value,
            interactionSource = interactionSource,
            onValueChange = onValueChange,
            maxLines = 1,
            keyboardActions = keyboardActions,
            keyboardOptions = keyboardOptions.copy(imeAction = ImeAction.Search),
            modifier = Modifier.fillMaxWidth(),
            cursorBrush = SolidColor(colors.cursorColor(error = error)),
            decorationBox = { textField ->
                Tier(
                    horizontalAlignment = Alignment.Start,
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier.padding(contentPadding),
                ) {
                    Box(modifier = Modifier.weight(1f), contentAlignment = Alignment.CenterStart) {
                        if (value.isEmpty()) {
                            placeholder?.invoke() ?: BodyText(
                                "Search",
                                textColor = colors.placeholderColor(
                                    focused = focused,
                                    error = error,
                                    enabled = enabled,
                                ),
                            )
                        }

                        textField()
                    }
                    trailing?.let {
                        ProvidesContentColor(
                            colors.labelColor(
                                focused = focused,
                                error = error,
                                enabled = enabled,
                            ),
                        ) {
                            trailing()
                        }
                    }
                }
            },
            textStyle = textStyle.merge(
                color = colors.textColor(
                    focused = focused,
                    error = error,
                    enabled = enabled,
                ),
            ),
            singleLine = true,
        )
    }
}

object SearchBarDefaults {
    val contentPadding: PaddingValues = PaddingValues(horizontal = 16.dp)
    val minHeight: Dp = 42.dp
}
