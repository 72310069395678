package io.daio.pancake.components.progress

import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.runtime.NonRestartableComposable
import androidx.compose.runtime.ReadOnlyComposable
import androidx.compose.runtime.Stable
import androidx.compose.runtime.State
import androidx.compose.runtime.rememberUpdatedState
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.color.disabled

@Composable
@NonRestartableComposable
fun SeekBar(
    value: Float,
    onValueChange: (Float) -> Unit,
    modifier: Modifier = Modifier,
    enabled: Boolean = true,
    valueRange: ClosedFloatingPointRange<Float> = 0f..1f,
    steps: Int = 0,
    colors: SeekBarColors = SeekBarDefaults.colors(),
    onValueChangeFinished: (() -> Unit)? = null,
    interactionSource: MutableInteractionSource? = null,
) {
    androidx.compose.material.Slider(
        value = value,
        onValueChange = onValueChange,
        modifier = modifier,
        enabled = enabled,
        valueRange = valueRange,
        steps = steps,
        colors = colors.toSliderColors(),
        onValueChangeFinished = onValueChangeFinished,
        interactionSource = interactionSource,
    )
}

@Immutable
data class SeekBarColors(
    val thumbColor: Color,
    val activeTrackColor: Color,
    val activeTickColor: Color,
    val inactiveTrackColor: Color,
    val inactiveTickColor: Color,
    val disabledThumbColor: Color,
    val disabledActiveTrackColor: Color,
    val disabledActiveTickColor: Color,
    val disabledInactiveTrackColor: Color,
    val disabledInactiveTickColor: Color,
)

@Immutable
private data class MaterialSliderColors(private val seekBarColors: SeekBarColors) :
    androidx.compose.material.SliderColors {

    @Composable
    override fun thumbColor(enabled: Boolean): State<Color> {
        return rememberUpdatedState(if (enabled) seekBarColors.thumbColor else seekBarColors.disabledThumbColor)
    }

    @Composable
    override fun trackColor(enabled: Boolean, active: Boolean): State<Color> {
        return rememberUpdatedState(
            if (enabled) {
                if (active) seekBarColors.activeTrackColor else seekBarColors.inactiveTrackColor
            } else {
                if (active) seekBarColors.disabledActiveTrackColor else seekBarColors.disabledInactiveTrackColor
            },
        )
    }

    @Composable
    override fun tickColor(enabled: Boolean, active: Boolean): State<Color> {
        return rememberUpdatedState(
            if (enabled) {
                if (active) seekBarColors.activeTickColor else seekBarColors.inactiveTickColor
            } else {
                if (active) seekBarColors.disabledActiveTickColor else seekBarColors.disabledInactiveTickColor
            },
        )
    }

}

@Stable
object SeekBarDefaults {

    @Composable
    @ReadOnlyComposable
    fun colors(
        thumbColor: Color = Theme.colors.accent,
        activeTrackColor: Color = Theme.colors.accent,
        activeTickColor: Color = Theme.colors.accent,
        inactiveTrackColor: Color = Theme.colors.surfaceInteractive.copy(alpha = .2f),
        inactiveTickColor: Color = Theme.colors.surfaceInteractive.copy(alpha = .2f),
        disabledThumbColor: Color = thumbColor.disabled(),
        disabledActiveTrackColor: Color = activeTrackColor.disabled(),
        disabledActiveTickColor: Color = activeTickColor.disabled(),
        disabledInactiveTrackColor: Color = inactiveTrackColor.disabled(),
        disabledInactiveTickColor: Color = inactiveTickColor.disabled(),
    ) = SeekBarColors(
        thumbColor = thumbColor,
        activeTrackColor = activeTrackColor,
        activeTickColor = activeTickColor,
        inactiveTrackColor = inactiveTrackColor,
        inactiveTickColor = inactiveTickColor,
        disabledThumbColor = disabledThumbColor,
        disabledActiveTrackColor = disabledActiveTrackColor,
        disabledActiveTickColor = disabledActiveTickColor,
        disabledInactiveTrackColor = disabledInactiveTrackColor,
        disabledInactiveTickColor = disabledInactiveTickColor,
    )
}

@Stable
private fun SeekBarColors.toSliderColors(): androidx.compose.material.SliderColors =
    MaterialSliderColors(this)
