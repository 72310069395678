package studio.goodegg.capsule.player

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import com.slack.circuit.retained.collectAsRetainedState
import com.slack.circuit.retained.rememberRetained
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.Navigator
import com.slack.circuit.runtime.presenter.Presenter
import com.slack.circuit.runtime.screen.Screen
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.PlayState
import studio.goodegg.capsule.core.playback.AudioBoostGain
import studio.goodegg.capsule.core.playback.PlaybackProperties
import studio.goodegg.capsule.core.playback.PlaybackSpeed
import studio.goodegg.capsule.core.playback.SleepTimerState
import studio.goodegg.capsule.domain.playback.DecrementSleepTimerInteractor
import studio.goodegg.capsule.domain.playback.GetLastPlayedInteractor
import studio.goodegg.capsule.domain.playback.IncrementSleepTimerInteractor
import studio.goodegg.capsule.domain.playback.NowPlayingItem
import studio.goodegg.capsule.domain.playback.ObserveNowPlayingItemInteractor
import studio.goodegg.capsule.domain.playback.ObservePlayStateInteractor
import studio.goodegg.capsule.domain.playback.ObservePlaybackPropertiesInteractor
import studio.goodegg.capsule.domain.playback.ObservePlayerNameInteractor
import studio.goodegg.capsule.domain.playback.ObserveSleepTimerInteractor
import studio.goodegg.capsule.domain.playback.PauseInteractor
import studio.goodegg.capsule.domain.playback.PlayInteractor
import studio.goodegg.capsule.domain.playback.ResumePlaybackInteractor
import studio.goodegg.capsule.domain.playback.SeekInteractor
import studio.goodegg.capsule.domain.playback.SetAudioBoostInteractor
import studio.goodegg.capsule.domain.playback.SetPlaybackSpeedInteractor
import studio.goodegg.capsule.domain.playback.SetTrimSilenceInteractor
import studio.goodegg.capsule.domain.playback.StartSleepTimerInteractor
import studio.goodegg.capsule.domain.playback.StopInteractor
import studio.goodegg.capsule.domain.playback.StopSleepTimerInteractor
import studio.goodegg.capsule.domain.playback.TuneInteractor
import studio.goodegg.capsule.domain.playback.playbackState
import studio.goodegg.capsule.navigation.PlayQueueScreen
import studio.goodegg.capsule.navigation.DockPlayerScreen
import studio.goodegg.capsule.navigation.FullPlayerScreen

@Inject
class PlayerPresenterFactory(
    private val playInteractor: PlayInteractor,
    private val tuneInteractor: TuneInteractor,
    private val pauseInteractor: PauseInteractor,
    private val seekInteractor: SeekInteractor,
    private val stopInteractor: StopInteractor,
    private val resumeInteractor: ResumePlaybackInteractor,
    private val nowPlayingInteractor: ObserveNowPlayingItemInteractor,
    private val observePlayStateInteractor: ObservePlayStateInteractor,
    private val lastPlayedInteractor: GetLastPlayedInteractor,
    private val setPlaybackSpeedInteractor: SetPlaybackSpeedInteractor,
    private val setAudioBoostInteractor: SetAudioBoostInteractor,
    private val setTrimSilenceInteractor: SetTrimSilenceInteractor,
    private val observePlaybackPropertiesInteractor: ObservePlaybackPropertiesInteractor,
    private val observePlayerNameInteractor: ObservePlayerNameInteractor,
    private val observeSleepTimerInteractor: ObserveSleepTimerInteractor,
    private val incrementSleepTimerInteractor: IncrementSleepTimerInteractor,
    private val decrementSleepTimerInteractor: DecrementSleepTimerInteractor,
    private val startSleepTimerInteractor: StartSleepTimerInteractor,
    private val stopSleepTimerInteractor: StopSleepTimerInteractor,
) :
    Presenter.Factory {
    override fun create(
        screen: Screen,
        navigator: Navigator,
        context: CircuitContext,
    ): Presenter<*>? {
        return when (screen) {
            is DockPlayerScreen, FullPlayerScreen ->
                PlayerPresenter(
                    navigator,
                    playInteractor,
                    tuneInteractor,
                    pauseInteractor,
                    seekInteractor,
                    stopInteractor,
                    resumeInteractor,
                    nowPlayingInteractor,
                    observePlayStateInteractor,
                    lastPlayedInteractor,
                    setPlaybackSpeedInteractor,
                    setAudioBoostInteractor,
                    setTrimSilenceInteractor,
                    observePlaybackPropertiesInteractor,
                    observePlayerNameInteractor,
                    observeSleepTimerInteractor,
                    incrementSleepTimerInteractor,
                    decrementSleepTimerInteractor,
                    startSleepTimerInteractor,
                    stopSleepTimerInteractor,
                )

            else -> null
        }
    }
}

class PlayerPresenter(
    private val navigator: Navigator,
    private val playInteractor: PlayInteractor,
    private val tuneInteractor: TuneInteractor,
    private val pauseInteractor: PauseInteractor,
    private val seekInteractor: SeekInteractor,
    private val stopInteractor: StopInteractor,
    private val resumeInteractor: ResumePlaybackInteractor,
    private val nowPlayingInteractor: ObserveNowPlayingItemInteractor,
    private val observePlayStateInteractor: ObservePlayStateInteractor,
    private val lastPlayedInteractor: GetLastPlayedInteractor,
    private val setPlaybackSpeedInteractor: SetPlaybackSpeedInteractor,
    private val setAudioBoostInteractor: SetAudioBoostInteractor,
    private val setTrimSilenceInteractor: SetTrimSilenceInteractor,
    private val observePlaybackPropertiesInteractor: ObservePlaybackPropertiesInteractor,
    private val observePlayerNameInteractor: ObservePlayerNameInteractor,
    private val observeSleepTimerInteractor: ObserveSleepTimerInteractor,
    private val incrementSleepTimerInteractor: IncrementSleepTimerInteractor,
    private val decrementSleepTimerInteractor: DecrementSleepTimerInteractor,
    private val startSleepTimerInteractor: StartSleepTimerInteractor,
    private val stopSleepTimerInteractor: StopSleepTimerInteractor,
) : Presenter<PlayerUiState> {
    @Composable
    override fun present(): PlayerUiState {
        val scope = rememberCoroutineScope()

        val playbackProperties by rememberRetained { observePlaybackPropertiesInteractor() }
            .collectAsRetainedState(
                PlaybackProperties(
                    trimSilence = false,
                    audioBoostGain = AudioBoostGain.Normal,
                    playbackSpeed = PlaybackSpeed.Default,
                ),
            )

        val sleepTimer by remember { observeSleepTimerInteractor() }
            .collectAsRetainedState(SleepTimerState.Stopped(0L))

        val playState by rememberRetained { observePlayStateInteractor() }
            .collectAsRetainedState(PlayState.Idle)

        val playerName by rememberRetained { observePlayerNameInteractor() }
            .collectAsRetainedState("")

        val nowPlaying by rememberRetained {
            nowPlayingInteractor().map { it ?: lastPlayedInteractor() }
        }.collectAsRetainedState(null)

        fun eventSink(event: PlayerUiEvent) {
            when (event) {
                PlayerUiEvent.Pause -> pauseInteractor()
                PlayerUiEvent.Play ->
                    if (playState == PlayState.Paused) {
                        resumeInteractor()
                    } else {
                        scope.launch {
                            nowPlaying?.let {
                                when (it) {
                                    is NowPlayingItem.Podcast -> {
                                        playInteractor(
                                            it.episode.episode,
                                            it.playbackState?.currentPosition ?: 0L,
                                        )
                                    }

                                    is NowPlayingItem.Radio -> {
                                        tuneInteractor.invoke(it.station.station)
                                    }
                                }
                            }
                        }
                    }

                PlayerUiEvent.Stop -> stopInteractor()
                is PlayerUiEvent.Seek -> seekInteractor(event.deltaMs)
                is PlayerUiEvent.SeekTo -> {
                    when (val playing = nowPlaying) {
                        is NowPlayingItem.Podcast -> {
                            val newPosition =
                                (playing.episode.episode.duration * event.percent).toLong()
                            val seekAmount =
                                newPosition - (playing.playbackState?.currentPosition ?: 0L)
                            seekInteractor.invoke(seekAmount)
                        }

                        else -> Unit
                    }
                }

                is PlayerUiEvent.SetSpeed -> {
                    scope.launch {
                        setPlaybackSpeedInteractor.invoke(event.speed)
                    }
                }

                is PlayerUiEvent.SetAudioBootGain -> {
                    scope.launch {
                        setAudioBoostInteractor.invoke(event.audioBoostGain)
                    }
                }

                is PlayerUiEvent.SetTrimSilence -> {
                    scope.launch {
                        setTrimSilenceInteractor.invoke(event.enabled)
                    }
                }

                PlayerUiEvent.OpenPlayQueue -> {
                    navigator.goTo(PlayQueueScreen)
                }

                is PlayerUiEvent.DecrementSleepTimer -> {
                    scope.launch {
                        decrementSleepTimerInteractor.invoke(event.decrement)
                    }
                }

                is PlayerUiEvent.IncrementSleepTimer -> {
                    scope.launch {
                        incrementSleepTimerInteractor.invoke(event.increment)
                    }
                }

                PlayerUiEvent.StartSleepTimer -> {
                    scope.launch {
                        startSleepTimerInteractor.invoke()
                    }
                }

                PlayerUiEvent.StopSleepTimer -> {
                    scope.launch {
                        stopSleepTimerInteractor.invoke()
                    }
                }

                PlayerUiEvent.OpenFullScreen -> {
                    navigator.goTo(FullPlayerScreen)
                }
            }
        }

        return PlayerUiState(
            playbackSpeed = playbackProperties.playbackSpeed,
            sleepTimer = sleepTimer,
            trimSilence = playbackProperties.trimSilence,
            audioBoostGain = playbackProperties.audioBoostGain,
            nowPlaying = nowPlaying,
            connectedPlayerName = playerName,
            eventSink = ::eventSink,
        )
    }
}
