@file:OptIn(InternalPancakeApi::class)

package studio.goodegg.capsule.details.podcast

import androidx.compose.animation.AnimatedContent
import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.animateColorAsState
import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.defaultMinSize
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.items
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberUpdatedState
import androidx.compose.runtime.setValue
import androidx.compose.runtime.snapshotFlow
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.platform.LocalSoftwareKeyboardController
import androidx.compose.ui.unit.dp
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.screen.Screen
import com.slack.circuit.runtime.ui.Ui
import com.slack.circuit.runtime.ui.ui
import io.daio.pancake.bottomsheet.BottomSheetState
import io.daio.pancake.components.buttons.Button
import io.daio.pancake.components.buttons.ButtonDefaults
import io.daio.pancake.components.progress.FullScreenLoadingIndicator
import io.daio.pancake.components.search.SearchBar
import io.daio.pancake.components.search.SearchBarDefaults
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.annotations.InternalPancakeApi
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.foundations.text.BodyTextLarge
import io.daio.pancake.foundations.text.TitleTextLarge
import io.daio.pancake.icons.Cancel
import io.daio.pancake.icons.Check
import io.daio.pancake.icons.Delete
import io.daio.pancake.icons.Download
import io.daio.pancake.icons.Downloaded
import io.daio.pancake.icons.Icons
import io.daio.pancake.icons.List
import io.daio.pancake.icons.Listen
import io.daio.pancake.icons.More
import io.daio.pancake.icons.Notification
import io.daio.pancake.icons.NotificationOff
import io.daio.pancake.icons.Played
import io.daio.pancake.icons.SearchOutline
import io.daio.pancake.icons.Share
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.Surface
import io.daio.pancake.layout.Tier
import io.daio.wild.content.LocalContentColor
import kotlinx.coroutines.flow.drop
import kotlinx.coroutines.flow.skip
import studio.goodegg.capsule.EpisodeAndMetadata
import studio.goodegg.capsule.isInProgress
import studio.goodegg.capsule.navigation.PodcastDetailsScreen
import studio.goodegg.capsule.ui.components.AccentButton
import studio.goodegg.capsule.ui.components.ArtworkTile
import studio.goodegg.capsule.ui.components.ContextMenu
import studio.goodegg.capsule.ui.components.ContextMenuCheckItem
import studio.goodegg.capsule.ui.components.ContextMenuItem
import studio.goodegg.capsule.ui.components.EpisodeCompactCard
import studio.goodegg.capsule.ui.components.EpisodePlaceholder
import studio.goodegg.capsule.ui.components.FilterMenu
import studio.goodegg.capsule.ui.components.IconButton
import studio.goodegg.capsule.ui.components.LocalBottomSheet
import studio.goodegg.capsule.ui.components.PlayerAwareLazyStack

class PodcastDetailsScreenUiFactory : Ui.Factory {
    override fun create(
        screen: Screen,
        context: CircuitContext,
    ): Ui<*>? {
        return when (screen) {
            is PodcastDetailsScreen -> podcastDetails()
            else -> null
        }
    }
}

private fun podcastDetails() =
    ui<PodcastDetailsUiState> { state, modifier ->
        PodcastDetails(
            state,
            modifier,
        )
    }

@OptIn(ExperimentalFoundationApi::class)
@Composable
fun PodcastDetails(
    state: PodcastDetailsUiState,
    modifier: Modifier = Modifier,
) {
    Box(modifier = modifier.fillMaxSize()) {
        Stack(
            modifier = Modifier.fillMaxSize(),
            spaceBetween = LayoutGap.Large,
        ) {
            val bottomSheet = LocalBottomSheet.current

            PlayerAwareLazyStack {
                item(key = "meta", contentType = "meta") {
                    Stack(
                        modifier = Modifier.fillMaxWidth(),
                        horizontalAlignment = Alignment.Start,
                    ) {
                        var color by remember { mutableStateOf(Color.Unspecified) }

                        Box(
                            modifier = Modifier
                                .fillMaxWidth()
                                .height(216.dp)
                                .background(color = animateColorAsState(color).value),
                        ) {
                            Box(
                                modifier = Modifier
                                    .fillMaxSize()
                                    .background(Theme.colors.surfaceBackground.copy(alpha = .2f)),
                            )
                            ArtworkTile(
                                modifier = Modifier.align(Alignment.Center),
                                imageUrl = state.podcast?.imageUrl,
                                size = 148.dp,
                                onDominateColor = {
                                    color = it
                                },
                            )
                        }

                        val actionButtonAlpha by animateFloatAsState(if (state.loading) 0f else 1f)
                        Tier(
                            horizontalArrangement = Arrangement.SpaceBetween,
                            modifier =
                                Modifier.fillMaxWidth()
                                    .align(Alignment.Start)
                                    .graphicsLayer {
                                        alpha = actionButtonAlpha
                                    },
                        ) {
                            AccentButton(
                                modifier = Modifier.defaultMinSize(minWidth = 148.dp),
                                text = if (state.isSubscribed) "Subscribed" else "Subscribe",
                                enabled = !state.loading,
                                onClick = {
                                    state.eventSink(PodcastDetailsUiEvent.Subscribe)
                                },
                            )

                            Tier(
                                spaceBetween = LayoutGap.Tiny,
                                horizontalAlignment = Alignment.End,
                            ) {
                                AnimatedVisibility(
                                    state.isSubscribed,
                                    enter = fadeIn(),
                                    exit = fadeOut(),
                                ) {
                                    IconButton(
                                        icon = if (state.notifications) Icons.Notification else Icons.NotificationOff,
                                        onClick = {
                                            state.eventSink(PodcastDetailsUiEvent.ToggleNotifications)
                                        },
                                    )
                                }
                                IconButton(
                                    icon = Icons.Share,
                                    onClick = {
                                        state.eventSink.invoke(PodcastDetailsUiEvent.SharePodcast)
                                    },
                                )
                                if (state.isSubscribed) {
                                    IconButton(
                                        icon = Icons.More,
                                        onClick = {
                                            bottomSheet.show {
                                                ContextMenu(
                                                    title = "Podcast Settings",
                                                    onDismiss = {
                                                        bottomSheet.dismiss()
                                                    },
                                                ) {
                                                    PodcastContextMenu(state)
                                                }
                                            }
                                        },
                                    )
                                }
                            }
                        }

                        TitleTextLarge(state.podcast?.title.orEmpty())
                        BodyTextLarge(
                            state.podcast?.primaryGenreName.orEmpty(),
                            modifier = Modifier.graphicsLayer {
                                alpha = .5f
                            },
                        )

                        HorizontalDivider(
                            color = Theme.colors.onSurfaceBackground,
                            modifier = Modifier.align(Alignment.Start)
                                .graphicsLayer {
                                    alpha = .2f
                                },
                        )
                    }
                }
                stickyHeader(key = "actions", contentType = "actions") {
                    Surface(
                        modifier = Modifier.fillMaxWidth(),
                        contentColor = Theme.colors.accent,
                    ) {
                        Tier(
                            horizontalArrangement = Arrangement.SpaceBetween,
                            verticalAlignment = Alignment.CenterVertically,
                            modifier = Modifier
                                .height(SearchBarDefaults.minHeight)
                                .padding(bottom = Theme.dimens.size_xs),
                        ) {
                            BodyText(
                                "Episodes",
                                textColor = LocalContentColor.current,
                            )

                            Tier {
                                var showSearch by remember { mutableStateOf(false) }
                                var showDropDown by remember { mutableStateOf(false) }
                                if (!showSearch) {
                                    Box {
                                        Button(
                                            buttonColors = ButtonDefaults.colors(
                                                backgroundColor = Theme.colors.surfaceContainer,
                                                contentColor = Theme.colors.onSurfaceContainer,
                                            ),
                                            modifier = Modifier.height(28.dp),
                                            onClick = {
                                                showDropDown = true
                                            },
                                        ) {
                                            Text(state.filter.title, style = Theme.typography.link)
                                        }
                                        FilterMenu(
                                            show = showDropDown,
                                            filters = state.filters,
                                            onClick = {
                                                state.eventSink(PodcastDetailsUiEvent.SetFilter(it))
                                            },
                                            onDismissRequest = {
                                                showDropDown = false
                                            },
                                        )
                                    }
                                }
                                AnimatedContent(showSearch) {
                                    if (!it) {
                                        IconButton(
                                            contentColor = Theme.colors.onSurfaceBackground,
                                            icon = Icons.SearchOutline,
                                            onClick = {
                                                showSearch = true
                                            },
                                        )
                                    } else {
                                        var query by remember { mutableStateOf("") }
                                        val keyboard = LocalSoftwareKeyboardController.current
                                        val focusRequester = remember { FocusRequester() }

                                        SearchBar(
                                            value = query,
                                            modifier = Modifier.padding(start = 16.dp)
                                                .focusRequester(focusRequester),
                                            onEnter = {
                                                keyboard?.hide()
                                            },
                                            trailing = {
                                                IconButton(
                                                    Icons.Cancel,
                                                    onClick = {
                                                        showSearch = false
                                                        state.eventSink.invoke(
                                                            PodcastDetailsUiEvent.Search(""),
                                                        )
                                                    },
                                                )
                                            },
                                            onValueChange = {
                                                query = it
                                                state.eventSink.invoke(
                                                    PodcastDetailsUiEvent.Search(
                                                        query,
                                                    ),
                                                )
                                            },
                                        )

                                        LaunchedEffect(Unit) {
                                            focusRequester.requestFocus()
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                if (state.loading) {
                    items(10) {
                        EpisodePlaceholder(
                            withImage = false,
                            withBadge = true,
                            withActions = true,
                            withBackground = true,
                            descriptionLines = 2,
                        )
                    }
                } else {
                    items(
                        items = state.podcast?.episodes.orEmpty(),
                        key = { it.episode.episodeSlug },
                        contentType = { "episode" },
                    ) {
                        Stack {
                            EpisodeCompactCard(
                                modifier = Modifier.animateItem(),
                                showImage = false,
                                descriptionLines = 2,
                                played = state.playedEpisodesSlugs.contains(it.episode.episodeSlug),
                                playedProgress = it.playingState?.currentPosition ?: 0L,
                                downloadPercentage = it.download?.downloadedPercentage ?: 0L,
                                episode = it.episode,
                                nowPlaying = it.playingState,
                                onMoreOptions = {
                                    bottomSheet.show {
                                        ContextMenu(
                                            it.episode.title,
                                            onDismiss = {
                                                bottomSheet.dismiss()
                                            },
                                        ) {
                                            EpisodesContextMenu(bottomSheet, state, it)
                                        }
                                    }
                                },
                                onPlay = {
                                    state.eventSink(PodcastDetailsUiEvent.PlayEpisode(it.episode))
                                },
                                onShare = {
                                    state.eventSink(PodcastDetailsUiEvent.ShareEpisode(it.episode))
                                },
                                onDownload = {
                                    state.eventSink(PodcastDetailsUiEvent.DownloadEpisode(it))
                                },
                                onOpen = {
                                    state.eventSink(PodcastDetailsUiEvent.OpenEpisodeDetails(it.episode))
                                },
                            )
                        }
                    }
                }

            }
        }
    }
}

@Composable
private fun PodcastContextMenu(
    state: PodcastDetailsUiState,
) {
    var toggledDownload by remember(state.autoDownload) { mutableStateOf(state.autoDownload) }

    LaunchedEffect(Unit) {
        snapshotFlow { toggledDownload }
            .drop(1)
            .collect {
                state.eventSink.invoke(PodcastDetailsUiEvent.ToggleAutoDownload)
            }
    }

    ContextMenuCheckItem(
        "Auto Download New Episodes",
        check = toggledDownload,
        icon = Icons.Download,
        onCheckedChange = {
            toggledDownload = !toggledDownload
        },
    )
    HorizontalDivider(
        color = Theme.colors.onSurfaceBackground.copy(
            alpha = .1f,
        ),
    )
}

@Composable
private fun EpisodesContextMenu(
    bottomSheet: BottomSheetState,
    state: PodcastDetailsUiState,
    it: EpisodeAndMetadata,
) {
    ContextMenuItem(
        "Go To Episode",
        onDismiss = {
            bottomSheet.dismiss()
        },
        icon = Icons.Listen,
        onClick = {
            state.eventSink.invoke(PodcastDetailsUiEvent.OpenEpisodeDetails(it.episode))
        },
    )
    HorizontalDivider(
        color = Theme.colors.onSurfaceBackground.copy(
            alpha = .1f,
        ),
    )

    ContextMenuItem(
        if (it.download != null) "Delete Download" else "Download Episode",
        onDismiss = {
            bottomSheet.dismiss()
        },
        icon = if (it.download != null) Icons.Delete else Icons.Download,
        onClick = {
            state.eventSink.invoke(
                PodcastDetailsUiEvent.DownloadEpisode(it),
            )
        },
    )
    HorizontalDivider(
        color = Theme.colors.onSurfaceBackground.copy(
            alpha = .1f,
        ),
    )

    ContextMenuItem(
        title = if (state.playQueue.contains(it.episode.episodeSlug)) "Remove From Play Queue" else "Add To Play Queue",
        onDismiss = {
            bottomSheet.dismiss()
        },
        icon = Icons.List,
        onClick = {
            if (state.playQueue.contains(it.episode.episodeSlug)) {
                state.eventSink.invoke(PodcastDetailsUiEvent.RemoveFromPlayQueue(it))
            } else {
                state.eventSink.invoke(PodcastDetailsUiEvent.AddToPlayQueue(it))
            }
        },
    )

    HorizontalDivider(
        color = Theme.colors.onSurfaceBackground.copy(
            alpha = .1f,
        ),
    )

    ContextMenuItem(
        title = if (state.playedEpisodesSlugs.contains(it.episode.episodeSlug)) "Unmark As Played" else "Mark As Played",
        onDismiss = {
            bottomSheet.dismiss()
        },
        icon = Icons.Played,
        onClick = {
            state.eventSink.invoke(PodcastDetailsUiEvent.MarkAsPlayed(it.episode))
        },
    )
}