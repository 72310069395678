package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.List: ImageVector
    get() {
        if (_List != null) {
            return _List!!
        }
        _List = ImageVector.Builder(
            name = "List",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(fill = SolidColor(Color(0xFFE8EAED))) {
                moveTo(7f, 9f)
                lineTo(7f, 7f)
                horizontalLineToRelative(14f)
                verticalLineToRelative(2f)
                lineTo(7f, 9f)
                close()
                moveTo(7f, 13f)
                verticalLineToRelative(-2f)
                horizontalLineToRelative(14f)
                verticalLineToRelative(2f)
                lineTo(7f, 13f)
                close()
                moveTo(7f, 17f)
                verticalLineToRelative(-2f)
                horizontalLineToRelative(14f)
                verticalLineToRelative(2f)
                lineTo(7f, 17f)
                close()
                moveTo(4f, 9f)
                arcToRelative(0.968f, 0.968f, 0f, isMoreThanHalf = false, isPositiveArc = true, -0.712f, -0.287f)
                arcTo(0.968f, 0.968f, 0f, isMoreThanHalf = false, isPositiveArc = true, 3f, 8f)
                arcToRelative(0.97f, 0.97f, 0f, isMoreThanHalf = false, isPositiveArc = true, 0.288f, -0.713f)
                arcTo(0.968f, 0.968f, 0f, isMoreThanHalf = false, isPositiveArc = true, 4f, 7f)
                curveToRelative(0.283f, 0f, 0.52f, 0.096f, 0.713f, 0.287f)
                curveToRelative(0.191f, 0.192f, 0.287f, 0.43f, 0.287f, 0.713f)
                reflectiveCurveToRelative(-0.096f, 0.52f, -0.287f, 0.713f)
                arcTo(0.968f, 0.968f, 0f, isMoreThanHalf = false, isPositiveArc = true, 4f, 9f)
                close()
                moveTo(4f, 13f)
                arcToRelative(0.967f, 0.967f, 0f, isMoreThanHalf = false, isPositiveArc = true, -0.712f, -0.287f)
                arcTo(0.968f, 0.968f, 0f, isMoreThanHalf = false, isPositiveArc = true, 3f, 12f)
                arcToRelative(0.97f, 0.97f, 0f, isMoreThanHalf = false, isPositiveArc = true, 0.288f, -0.713f)
                arcTo(0.967f, 0.967f, 0f, isMoreThanHalf = false, isPositiveArc = true, 4f, 11f)
                curveToRelative(0.283f, 0f, 0.52f, 0.096f, 0.713f, 0.287f)
                curveToRelative(0.191f, 0.192f, 0.287f, 0.43f, 0.287f, 0.713f)
                reflectiveCurveToRelative(-0.096f, 0.52f, -0.287f, 0.713f)
                arcTo(0.967f, 0.967f, 0f, isMoreThanHalf = false, isPositiveArc = true, 4f, 13f)
                close()
                moveTo(4f, 17f)
                arcToRelative(0.967f, 0.967f, 0f, isMoreThanHalf = false, isPositiveArc = true, -0.712f, -0.288f)
                arcTo(0.968f, 0.968f, 0f, isMoreThanHalf = false, isPositiveArc = true, 3f, 16f)
                arcToRelative(0.97f, 0.97f, 0f, isMoreThanHalf = false, isPositiveArc = true, 0.288f, -0.713f)
                arcTo(0.967f, 0.967f, 0f, isMoreThanHalf = false, isPositiveArc = true, 4f, 15f)
                curveToRelative(0.283f, 0f, 0.52f, 0.096f, 0.713f, 0.287f)
                curveToRelative(0.191f, 0.192f, 0.287f, 0.43f, 0.287f, 0.713f)
                reflectiveCurveToRelative(-0.096f, 0.52f, -0.287f, 0.712f)
                arcTo(0.967f, 0.967f, 0f, isMoreThanHalf = false, isPositiveArc = true, 4f, 17f)
                close()
            }
        }.build()

        return _List!!
    }

@Suppress("ObjectPropertyName")
private var _List: ImageVector? = null
