package studio.goodegg.capsule.inbox

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.items
import androidx.compose.material3.DropdownMenu
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.screen.Screen
import com.slack.circuit.runtime.ui.Ui
import com.slack.circuit.runtime.ui.ui
import io.daio.pancake.bottomsheet.BottomSheetState
import io.daio.pancake.components.buttons.Button
import io.daio.pancake.components.buttons.ButtonDefaults
import io.daio.pancake.components.progress.FullScreenLoadingIndicator
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.foundations.text.BodyTextSmall
import io.daio.pancake.icons.Check
import io.daio.pancake.icons.DensityLarge
import io.daio.pancake.icons.DensitySmall
import io.daio.pancake.icons.Download
import io.daio.pancake.icons.FeedOutline
import io.daio.pancake.icons.Icons
import io.daio.pancake.icons.List
import io.daio.pancake.icons.Listen
import io.daio.pancake.icons.Podcast
import io.daio.pancake.icons.SearchOutline
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.LazyTier
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.Tier
import io.daio.wild.container.Container
import io.daio.wild.style.StyleDefaults
import studio.goodegg.capsule.EpisodeAndMetadata
import studio.goodegg.capsule.navigation.InboxScreen
import studio.goodegg.capsule.ui.components.ArtworkTile
import studio.goodegg.capsule.ui.components.ContextMenu
import studio.goodegg.capsule.ui.components.ContextMenuItem
import studio.goodegg.capsule.ui.components.EpisodeCard
import studio.goodegg.capsule.ui.components.EpisodeCompactCard
import studio.goodegg.capsule.ui.components.Filter
import studio.goodegg.capsule.ui.components.FilterMenu
import studio.goodegg.capsule.ui.components.IconButton
import studio.goodegg.capsule.ui.components.LocalBottomSheet
import studio.goodegg.capsule.ui.components.PlayerAwareLazyStack
import studio.goodegg.capsule.ui.components.ZeroStateLayout

class InboxScreenUiFactory : Ui.Factory {
    override fun create(
        screen: Screen,
        context: CircuitContext,
    ): Ui<*>? {
        return when (screen) {
            is InboxScreen -> inbox()
            else -> null
        }
    }
}

private fun inbox() =
    ui<InboxUiState> { state, modifier ->
        Inbox(
            state,
            modifier,
        )
    }

enum class CardStyle {
    Default, Compact,
}

@Composable
fun Inbox(
    state: InboxUiState,
    modifier: Modifier = Modifier,
) {
    Box(modifier = modifier.fillMaxSize()) {
        if (state.loading) {
            FullScreenLoadingIndicator()
        }

        Stack(modifier, spaceBetween = LayoutGap.Tiny) {
            if (state.subscriptions.isNotEmpty()) {
                BodyText("Subscriptions", textColor = Theme.colors.accent)
                LazyTier {
                    items(
                        state.subscriptions,
                        key = { it.slug },
                    ) { sub ->
                        ArtworkTile(
                            modifier = Modifier.animateItem(),
                            size = 100.dp,
                            imageUrl = sub.image,
                            onClick = { state.eventSink(InboxUiEvent.OpenPodcast(sub.slug)) },
                        )
                    }
                    item {
                        Container(
                            modifier = Modifier.size(100.dp),
                            style = StyleDefaults.style(
                                colors = StyleDefaults.colors(backgroundColor = Theme.colors.surfaceContainer),
                                shapes = StyleDefaults.shapes(Theme.shapes.large),
                                scale = StyleDefaults.scale(pressedScale = .95f),
                            ),
                            onClick = { state.eventSink(InboxUiEvent.ViewAllSubscriptions) },
                        ) {
                            BodyTextSmall(
                                "View All",
                                modifier = Modifier.fillMaxSize(),
                                textAlign = TextAlign.Center,
                            )
                        }
                    }
                }
            }

            val bottomSheet = LocalBottomSheet.current

            if (state.showNoSubscriptions) {
                ZeroStateLayout(
                    "Your Inbox is empty",
                    "Subscribe to podcasts to get your feed going",
                    icon = Icons.SearchOutline,
                    actionText = "Explore",
                    onAction = {
                        state.eventSink.invoke(InboxUiEvent.OpenTrending)
                    },
                )
            } else if (state.showNoMoreEpisodes) {
                ZeroStateLayout(
                    "You're all caught up!",
                    "Nice going!",
                    icon = Icons.FeedOutline,
                )
            } else if (state.latestEpisodes.isNotEmpty() || state.filter != Filter.Latest) {
                Tier(
                    horizontalArrangement = Arrangement.SpaceBetween,
                    modifier = Modifier.fillMaxWidth(),
                ) {
                    BodyText("My Feed", textColor = Theme.colors.accent)
                    Tier {
                        Box {
                            var showDropDown by remember { mutableStateOf(false) }
                            Button(
                                buttonColors = ButtonDefaults.colors(
                                    backgroundColor = Theme.colors.surfaceContainer,
                                    contentColor = Theme.colors.onSurfaceContainer,
                                ),
                                modifier = Modifier.height(28.dp),
                                onClick = {
                                    showDropDown = true
                                },
                            ) {
                                Text(state.filter.title, style = Theme.typography.link)
                            }

                            FilterMenu(
                                show = showDropDown,
                                filters = state.availableFilters,
                                onClick = {
                                    state.eventSink(InboxUiEvent.SetFilter(it))
                                },
                                onDismissRequest = {
                                    showDropDown = false
                                },
                            )
                        }
                        IconButton(
                            icon = if (state.cardStyle == CardStyle.Compact) Icons.DensitySmall else Icons.DensityLarge,
                            onClick = {
                                val newCardStyle =
                                    if (state.cardStyle == CardStyle.Default) CardStyle.Compact else CardStyle.Default

                                state.eventSink.invoke(InboxUiEvent.ToggleCardStyle(newCardStyle))
                            },
                        )
                    }
                }
                PlayerAwareLazyStack {
                    items(
                        state.latestEpisodes,
                        key = { it.episode.episodeSlug },
                    ) {
                        Stack(modifier = Modifier.animateItem()) {
                            when (state.cardStyle) {
                                CardStyle.Default -> EpisodeCard(
                                    episode = it.episode,
                                    downloadPercentage = it.download?.downloadedPercentage
                                        ?: 0L,
                                    nowPlaying = it.playingState,
                                    playedProgress = it.playingState?.currentPosition ?: 0L,
                                    onMoreOptions = {
                                        bottomSheet.show {
                                            ContextMenu(
                                                it.episode.title,
                                                onDismiss = {
                                                    bottomSheet.dismiss()
                                                },
                                            ) {
                                                EpisodesContextMenu(bottomSheet, state, it)
                                            }
                                        }
                                    },
                                    onOpen = {
                                        state.eventSink.invoke(
                                            InboxUiEvent.OpenEpisode(
                                                it.episode.episodeSlug,
                                            ),
                                        )
                                    },
                                    onPlay = { state.eventSink(InboxUiEvent.PlayEpisode(it.episode)) },
                                    onShare =
                                        { state.eventSink(InboxUiEvent.ShareEpisode(it.episode)) },
                                    onDownload = {
                                        state.eventSink(
                                            InboxUiEvent.DownloadEpisode(
                                                it,
                                            ),
                                        )
                                    },
                                )

                                CardStyle.Compact,

                                    -> EpisodeCompactCard(
                                    episode = it.episode,
                                    playedProgress = it.playingState?.currentPosition ?: 0L,
                                    onMoreOptions = {
                                        bottomSheet.show {
                                            ContextMenu(
                                                it.episode.title,
                                                onDismiss = {
                                                    bottomSheet.dismiss()
                                                },
                                            ) {
                                                EpisodesContextMenu(bottomSheet, state, it)
                                            }
                                        }
                                    },
                                    onOpen = {
                                        state.eventSink.invoke(
                                            InboxUiEvent.OpenEpisode(
                                                it.episode.episodeSlug,
                                            ),
                                        )
                                    },
                                    downloadPercentage = it.download?.downloadedPercentage
                                        ?: 0L,
                                    nowPlaying = it.playingState,
                                    onPlay = { state.eventSink(InboxUiEvent.PlayEpisode(it.episode)) },
                                    onShare = { state.eventSink(InboxUiEvent.ShareEpisode(it.episode)) },
                                    onDownload = {
                                        state.eventSink(
                                            InboxUiEvent.DownloadEpisode(
                                                it,
                                            ),
                                        )
                                    },
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}

@Composable
private fun EpisodesContextMenu(
    bottomSheet: BottomSheetState,
    state: InboxUiState,
    it: EpisodeAndMetadata,
) {
    ContextMenuItem(
        "Go To Episode",
        onDismiss = {
            bottomSheet.dismiss()
        },
        icon = Icons.Listen,
        onClick = {
            state.eventSink.invoke(InboxUiEvent.OpenEpisode(it.episode.episodeSlug))
        },
    )
    HorizontalDivider(
        color = Theme.colors.onSurfaceBackground.copy(
            alpha = .1f,
        ),
    )
    ContextMenuItem(
        "Go To Podcast",
        onDismiss = {
            bottomSheet.dismiss()
        },
        icon = Icons.Podcast,
        onClick = {
            state.eventSink.invoke(InboxUiEvent.OpenPodcast(it.episode.parentSlug!!))
        },
    )
    HorizontalDivider(
        color = Theme.colors.onSurfaceBackground.copy(
            alpha = .1f,
        ),
    )
    ContextMenuItem(
        "Download Episode",
        onDismiss = {
            bottomSheet.dismiss()
        },
        icon = Icons.Download,
        onClick = {
            state.eventSink.invoke(
                InboxUiEvent.DownloadEpisode(it),
            )
        },
    )
    HorizontalDivider(
        color = Theme.colors.onSurfaceBackground.copy(
            alpha = .1f,
        ),
    )

    ContextMenuItem(
        title = if (state.playQueue.contains(it.episode.episodeSlug)) "Remove From Play Queue" else "Add To Play Queue",
        onDismiss = {
            bottomSheet.dismiss()
        },
        icon = Icons.List,
        onClick = {
            if (state.playQueue.contains(it.episode.episodeSlug)) {
                state.eventSink.invoke(InboxUiEvent.RemoveFromPlayQueue(it))
            } else {
                state.eventSink.invoke(InboxUiEvent.AddToPlayQueue(it))
            }
        },
    )
    HorizontalDivider(
        color = Theme.colors.onSurfaceBackground.copy(
            alpha = .1f,
        ),
    )

    ContextMenuItem(
        title = "Mark As Played",
        onDismiss = {
            bottomSheet.dismiss()
        },
        icon = Icons.Check,
        onClick = {
            state.eventSink.invoke(InboxUiEvent.MarkAsPlayed(it))
        },
    )
}
