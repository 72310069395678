package studio.goodegg.capsule.common.coroutines

import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.Dispatchers
import me.tatarka.inject.annotations.Provides
import studio.goodegg.capsule.common.di.ApplicationScope

expect interface IoDispatcherComponent

interface CoroutinesComponent : IoDispatcherComponent {
    @Provides
    @ApplicationScope
    @MainDispatcher
    fun providesMainCoroutineScope(): CoroutineDispatcher = Dispatchers.Main

    @Provides
    @ApplicationScope
    @DefaultDispatcher
    fun providesDefaultCoroutineScope(): CoroutineDispatcher = Dispatchers.Default
}