package io.daio.pancake.components.progress

import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.LinearProgressIndicator
import androidx.compose.runtime.Composable
import androidx.compose.runtime.NonRestartableComposable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.color.contentColorFor

@Composable
@NonRestartableComposable
fun LoadingIndicator(
    modifier: Modifier = Modifier,
    strokeWidth: Dp = 3.dp,
    color: Color = Theme.colors.onSurfaceBackground,
) {
    CircularProgressIndicator(
        modifier = modifier,
        strokeCap = StrokeCap.Round,
        strokeWidth = strokeWidth,
        color = color,
    )
}

@Composable
@NonRestartableComposable
fun LoadingBar(
    modifier: Modifier = Modifier,
    color: Color = Theme.colors.surfaceBackground,
) {
    LinearProgressIndicator(
        modifier = modifier,
        strokeCap = StrokeCap.Round,
        color = color,
        trackColor = contentColorFor(color),
    )
}
