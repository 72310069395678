package studio.goodegg.capsule.downloads

import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.rememberCoroutineScope
import com.slack.circuit.retained.collectAsRetainedState
import com.slack.circuit.retained.rememberRetained
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.Navigator
import com.slack.circuit.runtime.presenter.Presenter
import com.slack.circuit.runtime.screen.Screen
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.DownloadedEpisode
import studio.goodegg.capsule.domain.download.DeleteDownloadInteractor
import studio.goodegg.capsule.domain.download.ObserveDownloadedInteractor
import studio.goodegg.capsule.domain.playback.PlayInteractor
import studio.goodegg.capsule.navigation.DownloadsScreen
import studio.goodegg.capsule.navigation.EpisodeDetailsScreen
import studio.goodegg.capsule.navigation.PodcastDetailsScreen

@Inject
class DownloadsPresenterFactory(
    private val observeDownloadedInteractor: ObserveDownloadedInteractor,
    private val deleteDownloadInteractor: DeleteDownloadInteractor,
    private val playInteractor: PlayInteractor,
) : Presenter.Factory {
    override fun create(
        screen: Screen,
        navigator: Navigator,
        context: CircuitContext,
    ): Presenter<*>? {
        return when (screen) {
            is DownloadsScreen -> DownloadsPresenter(
                navigator = navigator,
                observeDownloadedInteractor = observeDownloadedInteractor,
                deleteDownloadInteractor = deleteDownloadInteractor,
                playInteractor = playInteractor,
            )

            else -> null
        }
    }
}

class DownloadsPresenter(
    private val navigator: Navigator,
    private val observeDownloadedInteractor: ObserveDownloadedInteractor,
    private val deleteDownloadInteractor: DeleteDownloadInteractor,
    private val playInteractor: PlayInteractor,
) : Presenter<DownloadsUiState> {
    @Composable
    override fun present(): DownloadsUiState {
        val scope = rememberCoroutineScope()
        val downloads by rememberRetained {
            observeDownloadedInteractor.invoke().map {
                it.groupBy {
                    it.episode.parentSlug!!
                }.map {
                    DownloadGroup(
                        it.value.first().episode.author,
                        it.value.firstOrNull()?.episode?.image,
                        it.value.first().episode.parentSlug!!,
                        it.value,
                    )
                }
            }
        }
            .collectAsRetainedState(emptyList())

        fun eventSink(event: DownloadsUiEvent) {
            when (event) {
                is DownloadsUiEvent.Delete -> scope.launch {
                    deleteDownloadInteractor.invoke(event.slug)
                }

                is DownloadsUiEvent.PlayDownload -> {
                    scope.launch {
                        playInteractor.invoke(event.episode.episode, 0)
                    }
                }

                DownloadsUiEvent.Back -> navigator.pop()
                is DownloadsUiEvent.OpenEpisodeDetails -> {
                    navigator.goTo(EpisodeDetailsScreen(event.episode.episode.episodeSlug))
                }
                is DownloadsUiEvent.OpenPodcastDetails -> {
                    navigator.goTo(PodcastDetailsScreen(event.slug))
                }
            }
        }

        return DownloadsUiState(
            items = downloads,
            eventSink = ::eventSink,
        )
    }
}

@Immutable
data class DownloadGroup(
    val title: String,
    val imageUrl: String?,
    val parentSlug: String,
    val episode: List<DownloadedEpisode>,
)