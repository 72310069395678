package studio.goodegg.capsule.core.playback

import io.daio.bass.BassContext
import io.daio.bass.BassPlayer
import io.daio.bass.bassPlayer
import kotlinx.coroutines.CoroutineDispatcher
import me.tatarka.inject.annotations.Provides
import studio.goodegg.capsule.common.coroutines.IoDispatcher
import studio.goodegg.capsule.common.coroutines.MainDispatcher
import studio.goodegg.capsule.common.di.ApplicationScope
import studio.goodegg.capsule.preferences.Preferences

actual interface PlatformPlayerComponent {
    @Provides
    @ApplicationScope
    fun providesPlayer(
        preferences: Preferences,
        playerQueue: PlayerQueue,
        @IoDispatcher ioDispatcher: CoroutineDispatcher,
        @MainDispatcher mainDispatcher: CoroutineDispatcher,
    ): Player = PlayerImpl(
        BassContext.INSTANCE.bassPlayer,
        preferences,
        playerQueue,
        ioDispatcher,
        mainDispatcher,
    )
}