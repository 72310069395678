package studio.goodegg.capsule.playqueue

import androidx.compose.runtime.Immutable
import com.slack.circuit.runtime.CircuitUiState
import studio.goodegg.capsule.Episode
import studio.goodegg.capsule.domain.playback.NowPlayingItem

@Immutable
data class PlayQueueUiState(
    val currentlyPlaying: NowPlayingItem?,
    val playQueue: List<Episode>,
    val eventSink: (PlayQueueUiEvent) -> Unit,
) : CircuitUiState
