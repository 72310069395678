package studio.goodegg.capsule.search

import androidx.compose.animation.core.LinearEasing
import androidx.compose.animation.core.RepeatMode
import androidx.compose.animation.core.animateFloat
import androidx.compose.animation.core.infiniteRepeatable
import androidx.compose.animation.core.rememberInfiniteTransition
import androidx.compose.animation.core.tween
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.grid.LazyGridItemScope
import androidx.compose.foundation.lazy.grid.items
import androidx.compose.foundation.lazy.items
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.NonRestartableComposable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.platform.LocalSoftwareKeyboardController
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.screen.Screen
import com.slack.circuit.runtime.ui.Ui
import com.slack.circuit.runtime.ui.ui
import io.daio.pancake.bottomsheet.BottomSheetState
import io.daio.pancake.components.search.SearchBar
import io.daio.pancake.components.tabs.TabBar
import io.daio.pancake.components.tabs.TabBarItem
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.foundations.text.BodyTextSmall
import io.daio.pancake.foundations.text.LinkText
import io.daio.pancake.icons.ArrowBack
import io.daio.pancake.icons.Icons
import io.daio.pancake.icons.List
import io.daio.pancake.icons.Listen
import io.daio.pancake.icons.More
import io.daio.pancake.icons.Podcast
import io.daio.pancake.icons.SearchOutline
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.LazyStackGrid
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.Tier
import io.daio.wild.container.Container
import io.daio.wild.style.clickable
import kotlinx.coroutines.delay
import studio.goodegg.capsule.Episode
import studio.goodegg.capsule.PodcastResult
import studio.goodegg.capsule.Station
import studio.goodegg.capsule.formatCreatedDate
import studio.goodegg.capsule.navigation.SearchScreen
import studio.goodegg.capsule.ui.components.ArtworkTile
import studio.goodegg.capsule.ui.components.ContextMenu
import studio.goodegg.capsule.ui.components.ContextMenuItem
import studio.goodegg.capsule.ui.components.EpisodePlaceholder
import studio.goodegg.capsule.ui.components.LocalBottomSheet
import studio.goodegg.capsule.ui.components.PlayerAwareLazyStack
import studio.goodegg.capsule.ui.components.ZeroStateLayout
import studio.goodegg.capsule.ui.components.shimmer

class SearchScreenUiFactory : Ui.Factory {
    override fun create(
        screen: Screen,
        context: CircuitContext,
    ): Ui<*>? {
        return when (screen) {
            is SearchScreen -> search()
            else -> null
        }
    }
}

private fun search() =
    ui<SearchUiState> { state, modifier ->
        Search(
            state,
            modifier,
        )
    }

private val tabs = listOf(
    TabBarItem("Podcasts"),
    TabBarItem("Episodes"),
//    TabBarItem("Radio"),
)

@Composable
fun Search(
    state: SearchUiState,
    modifier: Modifier = Modifier,
) {
    Box(modifier = modifier.fillMaxSize()) {
        Stack {
            Icon(
                imageVector = Icons.ArrowBack,
                contentDescription = "",
                modifier = Modifier
                    .size(24.dp)
                    .clickable(
                        style = {
                            scale = if (pressed) .95f else 1f
                        },
                        onClick = {
                            state.eventSink(SearchUiEvent.Back)
                        },
                    ),
            )
            val focusRequester = remember { FocusRequester() }
            val keyboard = LocalSoftwareKeyboardController.current

            var query by remember { mutableStateOf("") }
            SearchBar(
                modifier = Modifier.focusRequester(focusRequester),
                value = query,
                onValueChange = {
                    query = it
                },
                onEnter = {
                    keyboard?.hide()
                    state.eventSink(SearchUiEvent.Search(query))
                },
            )

            LaunchedEffect(Unit) {
                delay(200)
                focusRequester.requestFocus()
            }

            var selectedTab by rememberSaveable { mutableStateOf(tabs.first().title) }
            TabBar(
                initialItem = tabs.indexOfFirst { it.title == selectedTab },
                items = tabs,
                onClick = {
                    selectedTab = it.title
                },
            )

            state.zeroState?.let {
                ZeroStateLayout(
                    it,
                    subtitle = "",
                    icon = Icons.SearchOutline,
                )
            }

            when (selectedTab) {
                "Podcasts" -> {
                    LazyStackGrid(
                        columns = 2,
                        modifier = Modifier.fillMaxSize(),
                        contentPadding = PaddingValues(
                            bottom = 66.dp, // todo player dynamic padding
                        ),
                    ) {
                        if (state.searching && state.podcastResults.isEmpty()) {
                            items(12) {
                                PodcastSearchingPlaceholder(Modifier.shimmer())
                            }
                        } else {
                            items(
                                state.podcastResults,
                                key = { it.slug },
                            ) {
                                Container(
                                    modifier = Modifier.animateItem(),
                                    shape = Theme.shapes.large,
                                ) {
                                    SearchPodcastItem(
                                        modifier = Modifier.align(Alignment.Center),
                                        podcast = it,
                                        subscribed = state.subscriptions.contains(it.slug),
                                        onClick = {
                                            state.eventSink(SearchUiEvent.OpenPodcast(it.slug))
                                        },
                                        onSubscribe = {
                                            state.eventSink(SearchUiEvent.Subscribe(it))
                                        },
                                    )
                                }
                            }
                        }
                    }
                }

                "Episodes" -> {
                    val bottomSheet = LocalBottomSheet.current

                    PlayerAwareLazyStack(modifier = Modifier.fillMaxSize()) {
                        if (state.searching && state.episodeResults.isEmpty()) {
                            items(12) {
                                EpisodePlaceholder()
                            }
                        } else {
                            items(
                                state.episodeResults,
                                key = { it.episodeSlug },
                            ) {
                                Stack(modifier = Modifier.animateItem()) {
                                    SearchEpisodeItem(
                                        episode = it,
                                        onClick = {
                                            state.eventSink(SearchUiEvent.OpenEpisode(it.episodeSlug))
                                        },
                                        onMoreClick = {
                                            bottomSheet.show {
                                                ContextMenu(
                                                    title = it.title,
                                                    onDismiss = {
                                                        bottomSheet.dismiss()
                                                    },
                                                ) {
                                                    EpisodesContextMenu(bottomSheet, state, it)
                                                }
                                            }
                                        },
                                    )
                                }
                            }
                        }
                    }
                }

//                "Radio" -> {
//                    LazyStack(modifier = Modifier.fillMaxSize()) {
//                        items(
//                            state.radioResults,
//                            key = { it.slug },
//                        ) {
//                            Stack(modifier = Modifier.animateItem()) {
//                                SearchRadioItem(
//                                    station = it,
//                                    onClick = {
//                                        state.eventSink(SearchUiEvent.Tune(it))
//                                    },
//                                )
//                                HorizontalDivider(
//                                    color =
//                                        Theme.colors.onSurfaceBackground.copy(
//                                            alpha = .2f,
//                                        ),
//                                )
//                            }
//                        }
//                    }
//                }
            }
        }
    }
}

@Composable
private fun LazyGridItemScope.PodcastSearchingPlaceholder(modifier: Modifier = Modifier) {
    Container(
        modifier = modifier.animateItem().size(180.dp),
        shape = Theme.shapes.large,
        color = Theme.colors.surfaceContainer,
    ) {
    }
}

@Composable
@NonRestartableComposable
private fun SearchPodcastItem(
    podcast: PodcastResult,
    subscribed: Boolean,
    onSubscribe: () -> Unit,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
) {
    ArtworkTile(
        modifier = modifier.fillMaxSize(),
        subscribed = subscribed,
        onSubscribe = onSubscribe,
        size = null,
        imageUrl = podcast.imageUrl,
        onClick = onClick,
    )
}

@Composable
private fun SearchRadioItem(
    station: Station,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
) {
    Stack(
        modifier =
            modifier
                .wrapContentHeight()
                .fillMaxWidth()
                .clickable(onClick = onClick),
        spaceBetween = LayoutGap.Small,
    ) {
        Tier(verticalAlignment = Alignment.Top) {
            ArtworkTile(
                size = 76.dp,
                imageUrl = station.image,
            )
            Stack(
                spaceBetween = LayoutGap.Tiny,
                modifier = Modifier.weight(1f),
            ) {
                BodyText(
                    station.title,
                    maxLines = 3,
                    overflow = TextOverflow.Ellipsis,
                )

                BodyTextSmall(
                    station.subtext,
                    maxLines = 1,
                    overflow = TextOverflow.Ellipsis,
                )
            }
        }
    }
}


@Composable
private fun SearchEpisodeItem(
    episode: Episode,
    played: Boolean = false,
    onClick: () -> Unit,
    onMoreClick: () -> Unit,
    modifier: Modifier = Modifier,
) {
    Stack(
        modifier =
            modifier
                .wrapContentHeight()
                .fillMaxWidth()
                .clickable(onClick = onClick),
        spaceBetween = LayoutGap.Medium,
    ) {
        Tier(
            spaceBetween = LayoutGap.Tiny,
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.fillMaxWidth(),
        ) {
            ArtworkTile(
                size = 68.dp,
                imageUrl = episode.image,
                modifier =
                    Modifier.graphicsLayer {
                        alpha = if (played) .4f else 1f
                    },
            )
            Stack(
                spaceBetween = LayoutGap.Tiny,
                modifier =
                    Modifier.weight(1f).graphicsLayer {
                        alpha = if (played) .4f else 1f
                    },
            ) {
                BodyTextSmall(episode.title, maxLines = 3, overflow = TextOverflow.Ellipsis)
                LinkText(
                    text = episode.formatCreatedDate(),
                    textColor = Theme.colors.onSurfaceBackground.copy(alpha = .8f),
                )
            }

            Icon(
                imageVector = Icons.More,
                contentDescription = "",
                modifier = Modifier
                    .size(24.dp)
                    .clickable(
                        style = {
                            scale = if (pressed) .95f else 1f
                        },
                        onClick = onMoreClick,
                    ),
            )
        }
    }
}

@Composable
private fun EpisodesContextMenu(
    bottomSheet: BottomSheetState,
    state: SearchUiState,
    episode: Episode,
) {
    ContextMenuItem(
        "Go To Episode",
        onDismiss = {
            bottomSheet.dismiss()
        },
        icon = Icons.Listen,
        onClick = {
            state.eventSink.invoke(SearchUiEvent.OpenEpisode(episode.episodeSlug))
        },
    )
    HorizontalDivider(
        color = Theme.colors.onSurfaceBackground.copy(
            alpha = .1f,
        ),
    )

    episode.parentSlug?.let {
        ContextMenuItem(
            "Go To Podcast",
            onDismiss = {
                bottomSheet.dismiss()
            },
            icon = Icons.Podcast,
            onClick = {
                state.eventSink.invoke(SearchUiEvent.OpenPodcast(it))
            },
        )
        HorizontalDivider(
            color = Theme.colors.onSurfaceBackground.copy(
                alpha = .1f,
            ),
        )
    }

//    ContextMenuItem(
//        if (episode.download != null) "Delete Download" else "Download Episode",
//        onDismiss = {
//            bottomSheet.dismiss()
//        },
//        icon = if (episode.download != null) Icons.Delete else Icons.Download,
//        onClick = {
//            state.eventSink.invoke(
//                SearchUiEvent.Download(episode),
//            )
//        },
//    )
//    HorizontalDivider(
//        color = Theme.colors.onSurfaceBackground.copy(
//            alpha = .1f,
//        ),
//    )

    ContextMenuItem(
        title = if (state.playQueue.contains(episode.episodeSlug)) "Remove From Play Queue" else "Add To Play Queue",
        onDismiss = {
            bottomSheet.dismiss()
        },
        icon = Icons.List,
        onClick = {
            if (state.playQueue.contains(episode.episodeSlug)) {
                state.eventSink.invoke(SearchUiEvent.RemoveFromPlayQueue(episode))
            } else {
                state.eventSink.invoke(SearchUiEvent.AddToPlayQueue(episode))
            }
        },
    )
}