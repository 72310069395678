package studio.goodegg.capsule.repositories

import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.withContext
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.StationResponse
import studio.goodegg.capsule.TuneResponse
import studio.goodegg.capsule.api.clients.RadioClient
import studio.goodegg.capsule.common.coroutines.IoDispatcher
import studio.goodegg.capsule.utils.Failed
import studio.goodegg.capsule.utils.Success

interface RadioRepository {
    suspend fun search(q: String): StationResponse
    suspend fun tune(id: String): TuneResponse
}

@Inject
class RadioRepositoryImpl(
    private val radioClient: RadioClient,
    @IoDispatcher private val ioDispatcher: CoroutineDispatcher,
) :
    RadioRepository {
    override suspend fun search(q: String): StationResponse = withContext(ioDispatcher) {
        when (val result = radioClient.search(q)) {
            is Failed -> StationResponse("failed", emptyList())
            is Success -> result.data
        }
    }

    override suspend fun tune(id: String): TuneResponse = withContext(ioDispatcher) {
        when (val result = radioClient.tune(id)) {
            is Failed -> TuneResponse("failed", emptyList())
            is Success -> result.data
        }
    }
}