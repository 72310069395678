package studio.goodegg.capsule

import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock
import kotlinx.coroutines.withContext
import okio.Path
import okio.Path.Companion.toPath
import studio.goodegg.capsule.common.coroutines.IoDispatcher

internal const val DATA_FOLDER = "/data"

/**
 * Serializer to handle the read and write to a [File] within the [FileDataStore].
 */
interface FileDataSerializer<T> {
    suspend fun write(data: T): String?

    suspend fun read(text: String): T?
}

class FileDataStore<T>(
    private val filename: String,
    fileStorageLocation: FileStorageLocation,
    folderName: String = filename,
    private val fileSerializer: FileDataSerializer<T>,
    @IoDispatcher private val dispatcher: CoroutineDispatcher,
) {
    private val mutex = Mutex(false)
    internal val storagePath = "${fileStorageLocation.url}/$DATA_FOLDER/$folderName"
    internal val updates: MutableSharedFlow<Unit> = MutableSharedFlow()

    suspend fun get(key: String?): T? = withContext(dispatcher) {
        mutex.withLock {
            runCatching {
                read(getFileName(key).toPath())?.let { fileSerializer.read(it) }
            }.getOrNull()
        }
    }

    suspend fun delete(key: String?) = withContext(dispatcher) {
        mutex.withLock { deleteInternal(key) }
    }

    private suspend fun deleteInternal(key: String? = null) = withContext(dispatcher) {
        val path = getFileName(key).toPath()
        runCatching { delete(path) }.getOrElse {
            //log
            return@withContext
        }
        updates.emit(Unit)
    }

    private fun getFileName(key: String? = null): String {
        if (key.isNullOrBlank())
            return "$storagePath/${filename}"

        return "$storagePath/$key-$filename"
    }

    suspend fun save(data: T, key: String?) {
        withContext(dispatcher) {
            mutex.withLock {
                val serialized = fileSerializer.write(data) ?: return@withContext
                val path = getFileName(key)
                runCatching { this@FileDataStore.write(path.toPath(), serialized) }.getOrElse {
                    // log
                    println(it.message)
                }
                updates.emit(Unit)
            }
        }
    }
}

expect fun <T> FileDataStore<T>.observe(key: String?): Flow<T?>

expect suspend fun FileDataStore<*>.write(path: Path, data: String)

internal expect suspend fun delete(path: Path)

internal expect suspend fun FileDataStore<*>.read(path: Path): String?
