package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.LibraryFilled: ImageVector
    get() {
        if (_LibraryFilled != null) {
            return _LibraryFilled!!
        }
        _LibraryFilled = ImageVector.Builder(
            name = "LibraryFilled",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(fill = SolidColor(Color(0xFFE8EAED))) {
                moveToRelative(12.7f, 14.05f)
                lineToRelative(5.65f, -5.65f)
                lineToRelative(-1.4f, -1.45f)
                lineToRelative(-4.25f, 4.25f)
                lineToRelative(-2.15f, -2.1f)
                lineToRelative(-1.4f, 1.4f)
                lineToRelative(3.55f, 3.55f)
                close()
                moveTo(8f, 18f)
                curveToRelative(-0.55f, 0f, -1.02f, -0.196f, -1.412f, -0.587f)
                arcTo(1.926f, 1.926f, 0f, isMoreThanHalf = false, isPositiveArc = true, 6f, 16f)
                lineTo(6f, 4f)
                curveToRelative(0f, -0.55f, 0.196f, -1.02f, 0.588f, -1.413f)
                arcTo(1.926f, 1.926f, 0f, isMoreThanHalf = false, isPositiveArc = true, 8f, 2f)
                horizontalLineToRelative(12f)
                curveToRelative(0.55f, 0f, 1.02f, 0.196f, 1.413f, 0.587f)
                curveToRelative(0.39f, 0.393f, 0.587f, 0.863f, 0.587f, 1.413f)
                verticalLineToRelative(12f)
                curveToRelative(0f, 0.55f, -0.196f, 1.02f, -0.587f, 1.413f)
                arcTo(1.926f, 1.926f, 0f, isMoreThanHalf = false, isPositiveArc = true, 20f, 18f)
                lineTo(8f, 18f)
                close()
                moveTo(4f, 22f)
                curveToRelative(-0.55f, 0f, -1.02f, -0.196f, -1.413f, -0.587f)
                arcTo(1.926f, 1.926f, 0f, isMoreThanHalf = false, isPositiveArc = true, 2f, 20f)
                lineTo(2f, 6f)
                horizontalLineToRelative(2f)
                verticalLineToRelative(14f)
                horizontalLineToRelative(14f)
                verticalLineToRelative(2f)
                lineTo(4f, 22f)
                close()
            }
        }.build()

        return _LibraryFilled!!
    }

@Suppress("ObjectPropertyName")
private var _LibraryFilled: ImageVector? = null
