package io.daio.pancake.components.text

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.Text
import androidx.compose.material3.TextField
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.style.TextAlign
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.LinkText
import io.daio.wild.style.Border
import io.daio.wild.style.BorderDefaults
import io.daio.wild.style.modifiers.border

@Composable
fun TextArea(
    value: String,
    modifier: Modifier = Modifier,
    colors: TextAreaColors = TextAreaDefaults.colors(),
    label: String? = null,
    shape: RoundedCornerShape = Theme.shapes.large,
    placeholder: String? = null,
    maxLines: Int = Int.MAX_VALUE,
    textStyle: TextStyle = Theme.typography.bodyDefault,
    textAlign: TextAlign = TextAlign.Start,
    border: Border = BorderDefaults.None,
    keyboardOptions: KeyboardOptions = KeyboardOptions.Default,
    onEnter: () -> Unit = {},
    keyboardActions: KeyboardActions = KeyboardActions { onEnter() },
    onValueChange: (String) -> Unit,
) {
    TextField(
        value = value,
        textStyle = textStyle.merge(textAlign = textAlign),
        onValueChange = onValueChange,
        maxLines = maxLines,
        label = label?.let {
            {
                LinkText(
                    label,
                    textColor = colors.focusedTextColor,
                    modifier = Modifier.graphicsLayer {
                        alpha = .6f
                    },
                )
            }
        },
        placeholder = placeholder?.let {
            {
                Text(
                    text = it,
                    color = colors.focusedTextColor.copy(.6f),
                    style = textStyle.merge(textAlign = textAlign),
                    modifier = Modifier.fillMaxWidth().graphicsLayer {
                        alpha = .8f
                    },
                )
            }
        },
        keyboardActions = keyboardActions,
        keyboardOptions = keyboardOptions,
        modifier =
            modifier
                .fillMaxWidth()
                .border(border),
        colors = colors.toTextFieldColors(),
        shape = shape,
    )
}
