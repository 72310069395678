package studio.goodegg.capsule.ui.components

import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.unit.dp
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyTextLarge
import io.daio.pancake.foundations.text.TitleTextScreen
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Stack

@Composable
fun ZeroStateLayout(
    title: String,
    subtitle: String,
    modifier: Modifier = Modifier,
    onAction: (() -> Unit)? = null,
    actionText: String = "Go",
    icon: ImageVector? = null,
) {
    ZeroStateLayout(
        modifier = modifier,
        title = {
            TitleTextScreen(title)
        },
        subtitle = {
            BodyTextLarge(subtitle)
        },
        icon = {
            icon?.let {
                Icon(
                    modifier = Modifier.size(42.dp),
                    imageVector = it,
                    tint = Theme.colors.onSurfaceBackground,
                    contentDescription = "Zero State Icon",
                )
            }
        },
        action = onAction?.let {
            {
                AccentButton(actionText, onClick = onAction)
            }
        },
    )
}

@Composable
fun ZeroStateLayout(
    title: @Composable ColumnScope.() -> Unit,
    subtitle: @Composable ColumnScope.() -> Unit,
    icon: @Composable ColumnScope.() -> Unit,
    modifier: Modifier = Modifier,
    action: @Composable (ColumnScope.() -> Unit)? = null,
) {
    Stack(
        spaceBetween = LayoutGap.Small,
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalAlignment = Alignment.CenterVertically,
        modifier =
            modifier
                .fillMaxSize()
                .padding(Theme.dimens.size_l),
    ) {
        icon()
        title()
        subtitle()
        action?.invoke(this)
    }
}
