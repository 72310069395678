package studio.goodegg.capsule.signin

import androidx.compose.runtime.Immutable
import com.slack.circuit.runtime.CircuitUiState
import studio.goodegg.capsule.User

@Immutable
data class SignInUiState(
    val awaitingCode: Boolean,
    val currentEmail: String?,
    val user: User? = null,
    val eventSink: (SignInUiEvent) -> Unit,
) : CircuitUiState
