package io.daio.pancake.foundations.color

import androidx.compose.material3.LocalContentColor
import androidx.compose.runtime.Composable
import androidx.compose.runtime.ReadOnlyComposable
import androidx.compose.runtime.Stable
import androidx.compose.runtime.rememberUpdatedState
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.takeOrElse
import io.daio.pancake.foundations.Theme

data class Colors(
    val surfaceBackground: Color,
    val surfaceContainer: Color,
    val surfaceEmphasized: Color,
    val surfaceInteractive: Color,
    val onSurfaceBackground: Color,
    val onSurfaceContainer: Color,
    val onSurfaceEmphasized: Color,
    val onSurfaceInteractive: Color,
    val onAccent: Color,
    val negative: Color,
    val positive: Color,
    val warning: Color,
    val accent: Color,
    val light: Boolean,
)

val lightColors =
    Colors(
        onSurfaceBackground = ThemeColors.themeLightOnSurfaceBackground,
        onSurfaceContainer = ThemeColors.themeLightOnSurfaceContainer,
        onSurfaceEmphasized = ThemeColors.themeLightOnSurfaceEmphasized,
        onSurfaceInteractive = ThemeColors.themeLightOnSurfaceInteractive,
        negative = ThemeColors.themeLightInformNegative,
        positive = ThemeColors.themeLightInformPositive,
        warning = ThemeColors.themeLightInformWarning,
        accent = ThemeColors.themeLightAccent,
        surfaceBackground = ThemeColors.themeLightSurfaceBackground,
        surfaceContainer = ThemeColors.themeLightSurfaceContainer,
        surfaceEmphasized = ThemeColors.themeLightSurfaceEmphasized,
        surfaceInteractive = ThemeColors.themeLightSurfaceInteractive,
        onAccent = ThemeColors.themeLightOnSurfaceAccent,
        light = true,
    )

val darkColors =
    Colors(
        onSurfaceBackground = ThemeColors.themeDarkOnSurfaceBackground,
        onSurfaceContainer = ThemeColors.themeDarkOnSurfaceContainer,
        onSurfaceEmphasized = ThemeColors.themeDarkOnSurfaceEmphasized,
        onSurfaceInteractive = ThemeColors.themeDarkOnSurfaceInteractive,
        negative = ThemeColors.themeDarkInformNegative,
        positive = ThemeColors.themeDarkInformPositive,
        warning = ThemeColors.themeDarkInformWarning,
        accent = ThemeColors.themeDarkAccent,
        surfaceBackground = ThemeColors.themeDarkSurfaceBackground,
        surfaceContainer = ThemeColors.themeDarkSurfaceContainer,
        surfaceEmphasized = ThemeColors.themeDarkSurfaceEmphasized,
        surfaceInteractive = ThemeColors.themeDarkSurfaceInteractive,
        onAccent = ThemeColors.themeDarkOnSurfaceAccent,
        light = false,
    )

@Composable
@ReadOnlyComposable
@Stable
fun Color.disabled() = copy(alpha = .4f)

@Composable
fun Color.asState() = rememberUpdatedState(this)

@Stable
fun Colors.contentColorFor(backgroundColor: Color): Color =
    when (backgroundColor) {
        surfaceBackground -> onSurfaceBackground
        surfaceContainer -> onSurfaceContainer
        surfaceEmphasized -> onSurfaceEmphasized
        surfaceInteractive -> onSurfaceInteractive
        accent -> onAccent
        else -> Color.Unspecified
    }

@Composable
@ReadOnlyComposable
fun contentColorFor(backgroundColor: Color) =
    Theme.colors.contentColorFor(backgroundColor).takeOrElse {
        LocalContentColor.current
    }
