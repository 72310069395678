package studio.goodegg.capsule.player

import com.slack.circuit.runtime.CircuitUiEvent
import studio.goodegg.capsule.core.playback.AudioBoostGain
import studio.goodegg.capsule.core.playback.PlaybackSpeed

sealed class PlayerUiEvent : CircuitUiEvent {
    data object Play : PlayerUiEvent()

    data object Stop : PlayerUiEvent()

    data object OpenFullScreen : PlayerUiEvent()

    data object Pause : PlayerUiEvent()

    data object OpenPlayQueue : PlayerUiEvent()

    data class Seek(val deltaMs: Long) : PlayerUiEvent()

    data class SeekTo(val percent: Float) : PlayerUiEvent()

    data class SetSpeed(val speed: PlaybackSpeed) : PlayerUiEvent()

    data class SetAudioBootGain(val audioBoostGain: AudioBoostGain) : PlayerUiEvent()

    data class SetTrimSilence(val enabled: Boolean) : PlayerUiEvent()

    data class IncrementSleepTimer(val increment: Long) : PlayerUiEvent()
    data class DecrementSleepTimer(val decrement: Long) : PlayerUiEvent()
    data object StartSleepTimer : PlayerUiEvent()
    data object StopSleepTimer : PlayerUiEvent()
}
