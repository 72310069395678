package studio.goodegg.capsule.ui.components

import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier

fun parseHtmlToPlainText(html: String): String {
    return html.replace(Regex("<[^>]*>"), "")
        .replace(Regex("\n+"), "\n")
        .trim()
}

@Composable
expect fun HtmlText(
    html: String,
    modifier: Modifier = Modifier,
)