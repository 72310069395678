package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.PlayFilled: ImageVector
    get() {
        if (_PlayFilled != null) {
            return _PlayFilled!!
        }
        _PlayFilled = ImageVector.Builder(
            name = "PlayFilled",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(fill = SolidColor(Color(0xFFE8EAED))) {
                moveTo(7.461f, 18.707f)
                arcTo(0.3f, 0.3f, 0f, isMoreThanHalf = false, isPositiveArc = true, 7f, 18.453f)
                verticalLineTo(5.547f)
                arcToRelative(0.3f, 0.3f, 0f, isMoreThanHalf = false, isPositiveArc = true, 0.461f, -0.254f)
                lineToRelative(10.141f, 6.454f)
                arcToRelative(0.3f, 0.3f, 0f, isMoreThanHalf = false, isPositiveArc = true, 0f, 0.506f)
                lineToRelative(-10.14f, 6.454f)
                close()
            }
        }.build()

        return _PlayFilled!!
    }

@Suppress("ObjectPropertyName")
private var _PlayFilled: ImageVector? = null
