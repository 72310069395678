package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.Delete: ImageVector
    get() {
        if (_Delete != null) {
            return _Delete!!
        }
        _Delete = ImageVector.Builder(
            name = "Delete",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(fill = SolidColor(Color(0xFFE8EAED))) {
                moveTo(7f, 21f)
                curveToRelative(-0.55f, 0f, -1.02f, -0.196f, -1.412f, -0.587f)
                arcTo(1.926f, 1.926f, 0f, isMoreThanHalf = false, isPositiveArc = true, 5f, 19f)
                lineTo(5f, 6f)
                lineTo(4f, 6f)
                lineTo(4f, 4f)
                horizontalLineToRelative(5f)
                lineTo(9f, 3f)
                horizontalLineToRelative(6f)
                verticalLineToRelative(1f)
                horizontalLineToRelative(5f)
                verticalLineToRelative(2f)
                horizontalLineToRelative(-1f)
                verticalLineToRelative(13f)
                curveToRelative(0f, 0.55f, -0.196f, 1.02f, -0.587f, 1.413f)
                arcTo(1.926f, 1.926f, 0f, isMoreThanHalf = false, isPositiveArc = true, 17f, 21f)
                lineTo(7f, 21f)
                close()
                moveTo(17f, 6f)
                lineTo(7f, 6f)
                verticalLineToRelative(13f)
                horizontalLineToRelative(10f)
                lineTo(17f, 6f)
                close()
                moveTo(9f, 17f)
                horizontalLineToRelative(2f)
                lineTo(11f, 8f)
                lineTo(9f, 8f)
                verticalLineToRelative(9f)
                close()
                moveTo(13f, 17f)
                horizontalLineToRelative(2f)
                lineTo(15f, 8f)
                horizontalLineToRelative(-2f)
                verticalLineToRelative(9f)
                close()
            }
        }.build()

        return _Delete!!
    }

@Suppress("ObjectPropertyName")
private var _Delete: ImageVector? = null
