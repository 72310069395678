package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.PlayOutline: ImageVector
    get() {
        if (_PlayOutline != null) {
            return _PlayOutline!!
        }
        _PlayOutline = ImageVector.Builder(
            name = "PlayOutline",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(fill = SolidColor(Color(0xFFE8EAED))) {
                moveTo(7.461f, 18.707f)
                arcTo(0.3f, 0.3f, 0f, isMoreThanHalf = false, isPositiveArc = true, 7f, 18.453f)
                verticalLineTo(5.547f)
                arcToRelative(0.3f, 0.3f, 0f, isMoreThanHalf = false, isPositiveArc = true, 0.461f, -0.254f)
                lineToRelative(10.141f, 6.454f)
                arcToRelative(0.3f, 0.3f, 0f, isMoreThanHalf = false, isPositiveArc = true, 0f, 0.506f)
                lineToRelative(-10.14f, 6.454f)
                close()
                moveTo(9f, 14.803f)
                arcToRelative(0.3f, 0.3f, 0f, isMoreThanHalf = false, isPositiveArc = false, 0.461f, 0.253f)
                lineToRelative(4.393f, -2.803f)
                arcToRelative(0.3f, 0.3f, 0f, isMoreThanHalf = false, isPositiveArc = false, 0f, -0.506f)
                lineTo(9.46f, 8.944f)
                arcToRelative(0.3f, 0.3f, 0f, isMoreThanHalf = false, isPositiveArc = false, -0.46f, 0.253f)
                verticalLineToRelative(5.606f)
                close()
            }
        }.build()

        return _PlayOutline!!
    }

@Suppress("ObjectPropertyName")
private var _PlayOutline: ImageVector? = null
