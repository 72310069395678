package studio.goodegg.capsule.inbox

import com.slack.circuit.runtime.CircuitUiEvent
import studio.goodegg.capsule.Episode
import studio.goodegg.capsule.EpisodeAndMetadata
import studio.goodegg.capsule.ui.components.Filter

sealed class InboxUiEvent : CircuitUiEvent {
    data class OpenEpisode(val episodeSlug: String) : InboxUiEvent()

    data class SetFilter(val filter: Filter) : InboxUiEvent()

    data class OpenPodcast(val slug: String) : InboxUiEvent()

    data class PlayEpisode(val episode: Episode) : InboxUiEvent()

    data class DownloadEpisode(val data: EpisodeAndMetadata) : InboxUiEvent()

    data class MarkAsPlayed(val data: EpisodeAndMetadata) : InboxUiEvent()

    data class AddToPlayQueue(val data: EpisodeAndMetadata) : InboxUiEvent()

    data class RemoveFromPlayQueue(val data: EpisodeAndMetadata) : InboxUiEvent()

    data class ShareEpisode(val episode: Episode) : InboxUiEvent()

    data class ToggleCardStyle(val cardStyle: CardStyle) : InboxUiEvent()

    data object ViewAllSubscriptions : InboxUiEvent()
    data object OpenTrending : InboxUiEvent()
}
