package studio.goodegg.capsule.player

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.core.animateDpAsState
import androidx.compose.animation.core.spring
import androidx.compose.animation.slideInVertically
import androidx.compose.animation.slideOutVertically
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxScope
import androidx.compose.foundation.layout.RowScope
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.requiredHeight
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.material3.DropdownMenu
import androidx.compose.material3.Icon
import androidx.compose.material3.LinearProgressIndicator
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.NonRestartableComposable
import androidx.compose.runtime.Stable
import androidx.compose.runtime.compositionLocalOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.semantics.Role
import androidx.compose.ui.semantics.role
import androidx.compose.ui.semantics.semantics
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import androidx.compose.ui.zIndex
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.screen.Screen
import com.slack.circuit.runtime.ui.Ui
import com.slack.circuit.runtime.ui.ui
import io.daio.bass.compose.CastButton
import io.daio.bass.compose.CastButtonState
import io.daio.pancake.components.buttons.ButtonDefaults
import io.daio.pancake.components.buttons.SmallButton
import io.daio.pancake.components.progress.LoadingIndicator
import io.daio.pancake.components.progress.SeekBar
import io.daio.pancake.components.toggle.Toggle
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.foundations.text.BodyTextLarge
import io.daio.pancake.foundations.text.LinkText
import io.daio.pancake.foundations.text.TitleText
import io.daio.pancake.foundations.text.TitleTextScreen
import io.daio.pancake.icons.Add
import io.daio.pancake.icons.Icons
import io.daio.pancake.icons.List
import io.daio.pancake.icons.More
import io.daio.pancake.icons.Pause
import io.daio.pancake.icons.PlayFilled
import io.daio.pancake.icons.Remove
import io.daio.pancake.icons.ScrubBack
import io.daio.pancake.icons.ScrubForward
import io.daio.pancake.icons.Timer
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.SurfaceEmphasized
import io.daio.pancake.layout.Tier
import io.daio.wild.container.Container
import io.daio.wild.content.LocalContentColor
import io.daio.wild.style.StyleDefaults
import io.daio.wild.style.clickable
import studio.goodegg.capsule.PlayState
import studio.goodegg.capsule.core.playback.AudioBoostGain
import studio.goodegg.capsule.core.playback.PlaybackSpeed
import studio.goodegg.capsule.core.playback.SleepTimerState
import studio.goodegg.capsule.domain.playback.NowPlayingItem
import studio.goodegg.capsule.domain.playback.image
import studio.goodegg.capsule.domain.playback.playbackState
import studio.goodegg.capsule.domain.playback.subtitle
import studio.goodegg.capsule.domain.playback.title
import studio.goodegg.capsule.formatTimeShort
import studio.goodegg.capsule.isPlaying
import studio.goodegg.capsule.navigation.DockPlayerScreen
import studio.goodegg.capsule.navigation.FullPlayerScreen
import studio.goodegg.capsule.player.PlayerValue.Collapsed
import studio.goodegg.capsule.player.PlayerValue.Expanded
import studio.goodegg.capsule.player.PlayerValue.Hidden
import studio.goodegg.capsule.ui.components.ArtworkTile
import studio.goodegg.capsule.ui.components.ContextMenuItem
import studio.goodegg.capsule.ui.components.IconButton

class PlayerScreenUiFactory : Ui.Factory {
    override fun create(
        screen: Screen,
        context: CircuitContext,
    ): Ui<*>? {
        return when (screen) {
            is DockPlayerScreen -> player()
            is FullPlayerScreen -> player()
            else -> null
        }
    }
}

private fun player() =
    ui<PlayerUiState> { state, modifier ->
        Player(
            state,
            modifier,
        )
    }

object PLayerDefaults {
    val expandAnimation = spring(
        stiffness = 300f,
        visibilityThreshold = .1.dp,
    )
}

val LocalCastButtonState = compositionLocalOf<CastButtonState> {
    object : CastButtonState {
        override fun selectDevice() {
        }
    }
}

@Composable
fun Player(
    state: PlayerUiState,
    modifier: Modifier = Modifier,
) {
    val playerDock = LocalPlayerDock.current
    val castButtonState = LocalCastButtonState.current

    val expand by animateDpAsState(
        targetValue = playerDock.playerHeight,
        animationSpec = PLayerDefaults.expandAnimation,
    )

    val expandedPadding by animateDpAsState(
        targetValue = if (playerDock.isExpanded) 0.dp else Theme.dimens.size_xs,
        animationSpec = PLayerDefaults.expandAnimation,
    )

    val topPadding by animateDpAsState(
        targetValue = if (playerDock.isExpanded) 54.dp else Theme.dimens.size_s,
        animationSpec = PLayerDefaults.expandAnimation,
    )

    LaunchedEffect(state.nowPlaying) {
        if (state.nowPlaying != null && playerDock.isHidden) {
            playerDock.setValue(Collapsed)
        } else if (state.nowPlaying == null) {
            playerDock.setValue(Hidden)
        }
    }

    AnimatedVisibility(
        visible = !playerDock.isHidden,
        enter = slideInVertically { 500 },
        exit = slideOutVertically { 500 },
        modifier = modifier
            .padding(horizontal = expandedPadding),
    ) {
        val isPlaying = state.nowPlaying?.playbackState?.playState.isPlaying
        var seeking by remember { mutableStateOf(false) }
        var progress by remember {
            mutableStateOf(state.nowPlaying?.playbackState?.progress ?: 0f)
        }.apply {
            val playbackState = state.nowPlaying?.playbackState
            if (!seeking && playbackState?.playState == PlayState.Playing) {
                value = playbackState.progress
            }
        }

        var playerDisplay by remember { mutableStateOf(PlayerDisplay.Main) }

        SurfaceEmphasized(
            shape = Theme.shapes.large,
            modifier = Modifier.zIndex(if (playerDock.isExpanded) .5f else 0f)
                .requiredHeight(expand)
                .clickable(
                    style = {
                        color = Color.Transparent
                    },
                    onClick = {
                        playerDock.toggleState()
                        playerDisplay = PlayerDisplay.Main
                    },
                ),
        ) {
            Stack(
                spaceBetween = LayoutGap.Medium,
                verticalAlignment = Alignment.Top,
                modifier = Modifier.fillMaxSize()
                    .padding(horizontal = Theme.dimens.size_m),
            ) {
                Tier(
                    spaceBetween = LayoutGap.Tiny,
                    horizontalAlignment = Alignment.Start,
                    modifier =
                        Modifier
                            .graphicsLayer {
                                alpha = if (playerDisplay.isMain) 1f else .4f
                            }
                            .padding(top = topPadding)
                            .fillMaxWidth(),
                ) {
                    Box(
                        modifier = Modifier.align(Alignment.CenterVertically),
                        contentAlignment = Alignment.Center,
                    ) {
                        if (state.nowPlaying?.playbackState?.playState == PlayState.Buffering) {
                            LoadingIndicator(modifier = Modifier.size(24.dp))
                        }

                        ArtworkTile(
                            state.nowPlaying?.image,
                            size = 38.dp,
                            modifier =
                                Modifier.graphicsLayer {
                                    alpha =
                                        if (state.nowPlaying?.playbackState?.playState == PlayState.Buffering) .4f else 1f
                                },
                        )
                    }
                    // TODO more ranges to gaps in pancake
                    Stack(
                        modifier = Modifier.weight(1f),
                        spaceBetween = LayoutGap.None,
                        verticalAlignment = Alignment.CenterVertically,
                    ) {
                        BodyText(state.nowPlaying?.title.orEmpty(), maxLines = 1)
                        BodyText(state.nowPlaying?.subtitle.orEmpty(), maxLines = 1)
                        Spacer(modifier = Modifier.height(Theme.dimens.size_xxs))
                        if (state.nowPlaying !is NowPlayingItem.Radio && !playerDock.isExpanded) {
                            Spacer(modifier = Modifier.height(Theme.dimens.size_xxxs))
                            LinearProgressIndicator(
                                progress = { progress },
                                modifier = Modifier.height(2.5.dp).fillMaxWidth(),
                                color = Theme.colors.accent,
                                trackColor = Theme.colors.accent.copy(alpha = .4f),
                            )
                        }
                    }

                    val playIcon =
                        if (playerDock.isExpanded) {
                            Icons.List
                        } else if (isPlaying) {
                            Icons.Pause
                        } else {
                            Icons.PlayFilled
                        }

                    IconButton(
                        icon = playIcon,
                        onClick = {
                            if (playerDock.isExpanded) {
                                state.eventSink(PlayerUiEvent.OpenPlayQueue)
                            } else if (isPlaying) {
                                state.eventSink(PlayerUiEvent.Pause)
                            } else {
                                state.eventSink(PlayerUiEvent.Play)
                            }
                        },
                    )
                }


                if (playerDock.isExpanded) {
                    var updatingProperties by remember { mutableStateOf(false) }
                    var speed by remember {
                        mutableStateOf(state.playbackSpeed)
                    }.apply {
                        if (!updatingProperties) {
                            value = state.playbackSpeed
                        }
                    }

                    var gain by remember {
                        mutableStateOf(state.audioBoostGain)
                    }.apply {
                        if (!updatingProperties) {
                            value = state.audioBoostGain
                        }
                    }

                    if (playerDisplay == PlayerDisplay.SpeedControls) {
                        Spacer(modifier = Modifier.height(48.dp))
                        TitleText(text = "Adjust playback speed")

                        SeekBar(
                            valueRange = 0f..1f,
                            steps = 6,
                            value = PlaybackSpeed.speedToProgress(speed),
                            onValueChange = {
                                updatingProperties = true
                                speed = PlaybackSpeed.fromValue(PlaybackSpeed.progressToSpeed(it))
                            },
                            onValueChangeFinished = {
                                state.eventSink.invoke(PlayerUiEvent.SetSpeed(speed))
                                updatingProperties = false
                            },
                        )

                    } else if (playerDisplay == PlayerDisplay.AudioBoostControls) {
                        Spacer(modifier = Modifier.height(48.dp))
                        TitleText(text = "Adjust Audio Boost")

                        SeekBar(
                            valueRange = 0f..1f,
                            steps = 6,
                            value = AudioBoostGain.gainToProgress(gain),
                            onValueChange = {
                                updatingProperties = true
                                gain = AudioBoostGain.fromValue(AudioBoostGain.progressToGain(it))
                            },
                            onValueChangeFinished = {
                                state.eventSink.invoke(PlayerUiEvent.SetAudioBootGain(gain))
                                updatingProperties = false
                            },
                        )

                    } else if (playerDisplay == PlayerDisplay.SleepTimer) {
                        Spacer(modifier = Modifier.height(42.dp))

                        Stack(
                            modifier = Modifier.fillMaxWidth(),
                            horizontalAlignment = Alignment.CenterHorizontally,
                        ) {
                            BodyText("Sleep Timer")
                            Tier(modifier = Modifier.fillMaxWidth()) {
                                PlayerButton(
                                    modifier = Modifier.size(38.dp),
                                    onClick = {
                                        state.eventSink(PlayerUiEvent.DecrementSleepTimer(60000))
                                    },
                                ) {
                                    Icon(
                                        modifier = Modifier.align(Alignment.Center)
                                            .padding(Theme.dimens.size_xs),
                                        imageVector = Icons.Remove,
                                        contentDescription = "",
                                    )
                                }
                                TitleTextScreen(
                                    text = state.sleepTimer.timer.formatTimeShort(),
                                    textColor = Theme.colors.accent,
                                )
                                PlayerButton(
                                    modifier = Modifier.size(38.dp),
                                    onClick = {
                                        state.eventSink(PlayerUiEvent.IncrementSleepTimer(60000))
                                    },
                                ) {
                                    Icon(
                                        modifier = Modifier.align(Alignment.Center)
                                            .padding(Theme.dimens.size_xs),
                                        imageVector = Icons.Add,
                                        contentDescription = "",
                                    )
                                }
                            }

                            SmallButton(
                                colors = ButtonDefaults.colors(
                                    backgroundColor = Color.Transparent,
                                    contentColor = Theme.colors.onSurfaceBackground,
                                ),
                                text = if (state.sleepTimer is SleepTimerState.Started) "Stop" else "Start",
                                onClick = {
                                    when (state.sleepTimer) {
                                        is SleepTimerState.Started -> state.eventSink.invoke(
                                            PlayerUiEvent.StopSleepTimer,
                                        )

                                        is SleepTimerState.Stopped -> state.eventSink.invoke(
                                            PlayerUiEvent.StartSleepTimer,
                                        )
                                    }

                                },
                            )
                        }

                    } else {
                        if (state.nowPlaying !is NowPlayingItem.Radio) {
                            Spacer(modifier = Modifier.height(Theme.dimens.size_m))
                            Stack(
                                modifier = Modifier.fillMaxWidth(),
                                spaceBetween = LayoutGap.Tiny,
                            ) {
                                SeekBar(
                                    value = progress,
                                    onValueChange = {
                                        seeking = true
                                        progress = it
                                    },
                                    onValueChangeFinished = {
                                        state.eventSink.invoke(PlayerUiEvent.SeekTo(progress))
                                        seeking = false
                                    },
                                    modifier = Modifier.fillMaxWidth().height(2.5.dp),
                                )
                                Tier(
                                    modifier = Modifier.fillMaxWidth().wrapContentHeight()
                                        .padding(
                                            top = Theme.dimens.size_s,
                                            start = Theme.dimens.size_xxs,
                                            end = Theme.dimens.size_xxs,
                                        ),
                                    horizontalArrangement = Arrangement.SpaceBetween,
                                ) {
                                    when (val playing = state.nowPlaying) {
                                        is NowPlayingItem.Podcast -> {
                                            LinkText(
                                                text = playing.episode.formatCurrentPosition(),
                                                textColor = Theme.colors.onSurfaceBackground.copy(
                                                    alpha = .6f,
                                                ),
                                            )
                                            LinkText(
                                                text = "-${playing.episode.formatTimeRemainingShort()}",
                                                textColor = Theme.colors.onSurfaceBackground.copy(
                                                    alpha = .6f,
                                                ),
                                            )
                                        }

                                        else -> Unit
                                    }
                                }
                            }
                        }

                        Tier(
                            modifier = Modifier.fillMaxWidth(),
                            spaceBetween = LayoutGap.Large,
                            horizontalAlignment = Alignment.CenterHorizontally,
                        ) {
                            PlayerButton(
                                modifier = Modifier.size(38.dp),
                                onClick = {
                                    state.eventSink(PlayerUiEvent.Seek(-10000))
                                },
                            ) {
                                Icon(
                                    modifier = Modifier.align(Alignment.Center)
                                        .padding(Theme.dimens.size_xs),
                                    imageVector = Icons.ScrubBack,
                                    contentDescription = "",
                                )
                            }


                            PlayerButton(
                                modifier = Modifier.size(64.dp),
                                onClick = {
                                    if (isPlaying) {
                                        state.eventSink(PlayerUiEvent.Pause)
                                    } else {
                                        state.eventSink(PlayerUiEvent.Play)
                                    }
                                },
                            ) {
                                // When paused show the Play icon and offset by 2dp due to center point
                                // of Play button.
                                Icon(
                                    modifier = Modifier.align(Alignment.Center)
                                        .offset(x = if (isPlaying) 0.dp else 2.dp)
                                        .padding(Theme.dimens.size_xs),
                                    imageVector = if (isPlaying) {
                                        Icons.Pause
                                    } else {
                                        Icons.PlayFilled
                                    },
                                    contentDescription = "",
                                )
                            }

                            PlayerButton(
                                modifier = Modifier.size(38.dp),
                                onClick = {
                                    state.eventSink(PlayerUiEvent.Seek(10000))
                                },
                            ) {
                                Icon(
                                    modifier = Modifier.align(Alignment.Center)
                                        .padding(Theme.dimens.size_xs),
                                    imageVector = Icons.ScrubForward,
                                    contentDescription = "",
                                )
                            }
                        }
                    }
                    PlayerActionBar(
                        modifier = Modifier.weight(1f)
                            .padding(bottom = Theme.dimens.size_l),
                    ) {
                        if (playerDisplay == PlayerDisplay.Main) {
                            Container(
                                modifier = Modifier.height(24.dp),
                                style = StyleDefaults.style(
                                    scale = StyleDefaults.scale(pressedScale = .9f),
                                    colors = StyleDefaults.colors(
                                        backgroundColor = Color.Transparent,
                                        contentColor = if (!playerDisplay.isMain) Theme.colors.accent else Theme.colors.onSurfaceBackground,
                                    ),
                                ),
                                onClick = {
                                    playerDisplay = PlayerDisplay.SpeedControls
                                },
                            ) {
                                BodyTextLarge(
                                    text = "${speed.speed}x",
                                    textAlign = TextAlign.Center,
                                    modifier = Modifier.align(Alignment.Center),
                                )
                            }
                            Box(contentAlignment = Alignment.TopCenter) {
                                CastButton(castButtonState = castButtonState)
                                if (state.connectedPlayerName.isNotEmpty()) {
                                    LinkText(
                                        text = state.connectedPlayerName,
                                        textColor = Theme.colors.accent,
                                        overflow = TextOverflow.Ellipsis,
                                        modifier = Modifier.graphicsLayer {
                                            // Offset under the icon without moving things.
                                            this.translationY = 70f
                                        }.clickable(
                                            style = {
                                                color = Color.Transparent
                                            },
                                        ) {
                                            castButtonState.selectDevice()
                                        },
                                    )
                                }
                            }
                            if (state.sleepTimer is SleepTimerState.Started) {
                                Box {
                                    Tier {
                                        PlayerActionBarItem(
                                            tint = if (state.sleepTimer.timer > 0) Theme.colors.accent else Theme.colors.onSurfaceBackground,
                                            modifier = Modifier.size(24.dp),
                                            icon = Icons.Timer,
                                            onClick = {
                                                playerDisplay = PlayerDisplay.SleepTimer
                                            },
                                        )
                                    }
                                    LinkText(
                                        state.sleepTimer.timer.formatTimeShort(),
                                        textColor = if (state.sleepTimer.timer > 0) Theme.colors.accent else Theme.colors.onSurfaceBackground,
                                        modifier = Modifier.graphicsLayer {
                                            // Offset under the icon without moving things.
                                            this.translationY = 70f
                                        },
                                    )
                                }
                            }
                        }

                        if (playerDisplay.isMain) {
                            Box {
                                var openDropDown by remember { mutableStateOf(false) }
                                DropdownMenu(
                                    openDropDown,
                                    containerColor = Theme.colors.surfaceEmphasized,
                                    onDismissRequest = {
                                        openDropDown = false
                                    },
                                ) {
                                    ContextMenuItem(
                                        title = "Audio Boost",
                                        modifier = Modifier.width(200.dp),
                                        icon = null,
                                        containerColor = Theme.colors.surfaceEmphasized,
                                        onClick = {
                                            playerDisplay = PlayerDisplay.AudioBoostControls
                                        },
                                        onDismiss = {
                                            openDropDown = false
                                        },
                                    )
                                    ContextMenuItem(
                                        title = "Sleep Timer",
                                        modifier = Modifier.width(200.dp),
                                        icon = null,
                                        containerColor = Theme.colors.surfaceEmphasized,
                                        onClick = {
                                            playerDisplay = PlayerDisplay.SleepTimer
                                        },
                                        onDismiss = {
                                            openDropDown = false
                                        },
                                    )
                                    ContextMenuItem(
                                        title = "Trim Silence",
                                        modifier = Modifier.width(200.dp),
                                        icon = null,
                                        containerColor = Theme.colors.surfaceEmphasized,
                                        onClick = {
                                            state.eventSink.invoke(PlayerUiEvent.SetTrimSilence(!state.trimSilence))
                                        },
                                        trailingIcon = {
                                            Toggle(
                                                state.trimSilence,
                                                onCheckedChange = {
                                                    state.eventSink.invoke(
                                                        PlayerUiEvent.SetTrimSilence(
                                                            !state.trimSilence,
                                                        ),
                                                    )
                                                },
                                            )
                                        },
                                        onDismiss = {},
                                    )
                                }
                                Tier {
                                    PlayerActionBarItem(
                                        modifier = Modifier.size(24.dp),
                                        icon = Icons.More,
                                        onClick = {
                                            openDropDown = true
                                        },
                                    )
                                }
                            }
                        } else {
                            Container(
                                modifier = Modifier.height(24.dp),
                                style = StyleDefaults.style(
                                    scale = StyleDefaults.scale(pressedScale = .9f),
                                    colors = StyleDefaults.colors(
                                        backgroundColor = Color.Transparent,
                                        contentColor = if (!playerDisplay.isMain) Theme.colors.accent else Theme.colors.onSurfaceBackground,
                                    ),
                                ),
                                onClick = {
                                    playerDisplay = PlayerDisplay.Main
                                },
                            ) {
                                BodyTextLarge(
                                    text = "Done",
                                    textAlign = TextAlign.Center,
                                    modifier = Modifier.align(Alignment.Center),
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}

enum class PlayerValue {
    Collapsed,
    Expanded,
    Hidden,
}

enum class PlayerDisplay {
    Main,
    SpeedControls,
    AudioBoostControls,
    SleepTimer,
}

val PlayerDisplay.isMain: Boolean
    get() = this == PlayerDisplay.Main

@Stable
class PlayerDockState(
    private val expandedHeight: Dp = 360.dp,
    private val collapsedHeight: Dp = 58.dp,
) {
    private val playerValueState = mutableStateOf(Hidden)

    var playerValue: PlayerValue = playerValueState.value
        get() = playerValueState.value
        private set

    internal val playerHeight: Dp
        get() = when (playerValue) {
            Collapsed -> collapsedHeight
            Expanded -> expandedHeight
            Hidden -> 0.dp
        }

    fun setValue(value: PlayerValue) {
        playerValueState.value = value
    }

    fun toggleState() {
        playerValueState.value = if (playerValue == Collapsed) Expanded else Collapsed
    }

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as PlayerDockState

        if (expandedHeight != other.expandedHeight) return false
        if (collapsedHeight != other.collapsedHeight) return false
        if (playerValueState != other.playerValueState) return false

        return true
    }

    override fun hashCode(): Int {
        var result = expandedHeight.hashCode()
        result = 31 * result + collapsedHeight.hashCode()
        result = 31 * result + playerValueState.hashCode()
        return result
    }
}

val PlayerDockState.isExpanded: Boolean
    get() = this.playerValue == Expanded

val PlayerDockState.isCollapsed: Boolean
    get() = this.playerValue == Collapsed

val PlayerDockState.isHidden: Boolean
    get() = this.playerValue == Hidden

val LocalPlayerDock = compositionLocalOf { PlayerDockState() }

@Composable
@NonRestartableComposable
private fun PlayerButton(
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    content: @Composable BoxScope.() -> Unit,
) {
    Container(
        style = StyleDefaults.style(
            // TODO Use theme color
            colors = StyleDefaults.colors(backgroundColor = Color.Black),
            shapes = StyleDefaults.shapes(shape = Theme.shapes.circle),
            scale = StyleDefaults.scale(pressedScale = .9f),
        ),
        modifier = modifier
            .semantics {
                role = Role.Button
            },
        onClick = onClick,
        content = content,
    )
}

@Composable
@NonRestartableComposable
fun PlayerActionBar(
    modifier: Modifier = Modifier,
    content: @Composable RowScope.() -> Unit,
) {
    Tier(
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.Bottom,
        modifier = modifier.fillMaxWidth()
            .height(32.dp),
        content = content,
    )
}


@Composable
@NonRestartableComposable
fun RowScope.PlayerActionBarItem(
    icon: ImageVector,
    onClick: () -> Unit,
    tint: Color = LocalContentColor.current,
    modifier: Modifier = Modifier,
) {
    Icon(
        imageVector = icon,
        tint = tint,
        contentDescription = "",
        modifier = modifier
            .clickable(
                style = {
                    scale = if (pressed) .9f else 1f
                },
                onClick = onClick,
            ),
    )
}
