package studio.goodegg.capsule.settings

import androidx.compose.runtime.Immutable
import com.slack.circuit.runtime.CircuitUiState
import studio.goodegg.capsule.User

@Immutable
data class SettingsUiState(
    val signedIn: Boolean,
    val eventSink: (SettingsUiEvent) -> Unit,
    val user: User?,
) : CircuitUiState
