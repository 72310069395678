package io.daio.pancake.components.buttons

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.RowScope
import androidx.compose.foundation.layout.defaultMinSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.LocalContentAlpha
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.runtime.NonRestartableComposable
import androidx.compose.runtime.ReadOnlyComposable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.semantics.Role
import androidx.compose.ui.semantics.role
import androidx.compose.ui.semantics.semantics
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.foundations.text.BodyTextSmall
import io.daio.wild.container.Container
import io.daio.wild.content.LocalContentColor
import io.daio.wild.style.Border
import io.daio.wild.style.BorderDefaults
import io.daio.wild.style.StyleDefaults

/**
 * Creates a [SmallButton].
 *
 * @param text the text to be displayed on the button
 * @param modifier the [Modifier] to be applied to the button
 * @param colors the [ButtonColors] to be used for the button
 * @param onClick the action to be performed when the button is clicked
 */
@Composable
@NonRestartableComposable
fun SmallButton(
    text: String,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    enabled: Boolean = true,
    border: BorderStroke? = null,
    shape: RoundedCornerShape = ButtonDefaults.shape,
    contentPadding: PaddingValues = ButtonDefaults.ContentPadding,
    interactionSource: MutableInteractionSource? = null,
    colors: ButtonColors = ButtonDefaults.colors(),
) {
    Button(
        modifier = modifier.height(ButtonDefaults.smallHeight),
        buttonColors = colors,
        enabled = enabled,
        border = border,
        shape = shape,
        contentPadding = contentPadding,
        interactionSource = interactionSource,
        onClick = onClick,
    ) {
        BodyTextSmall(
            text = text,
            textColor = LocalContentColor.current,
            textAlign = TextAlign.Center,
        )
    }
}

/**
 * Creates a [MediumButton].
 *
 * @param text the text to be displayed on the button
 * @param modifier the [Modifier] to be applied to the button
 * @param onClick the action to be performed when the button is clicked
 * @param colors the [ButtonColors] to be used for the button
 */
@Composable
@NonRestartableComposable
fun MediumButton(
    text: String,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    border: BorderStroke? = null,
    enabled: Boolean = true,
    shape: RoundedCornerShape = ButtonDefaults.shape,
    contentPadding: PaddingValues = ButtonDefaults.ContentPadding,
    interactionSource: MutableInteractionSource? = null,
    colors: ButtonColors = ButtonDefaults.colors(),
) {
    Button(
        modifier = modifier.height(ButtonDefaults.mediumHeight),
        buttonColors = colors,
        border = border,
        enabled = enabled,
        shape = shape,
        contentPadding = contentPadding,
        interactionSource = interactionSource,
        onClick = onClick,
    ) {
        BodyText(
            text = text,
            textColor = LocalContentColor.current,
            textAlign = TextAlign.Center,
        )
    }
}

/**
 * Creates a [LargeButton].
 *
 * @param text the text to be displayed on the button
 * @param modifier the [Modifier] to be applied to the button
 * @param onClick the action to be performed when the button is clicked
 * @param colors the [ButtonColors] to be used for the button
 */
@Composable
@NonRestartableComposable
fun LargeButton(
    text: String,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    enabled: Boolean = true,
    border: BorderStroke? = null,
    contentPadding: PaddingValues = ButtonDefaults.ContentPadding,
    shape: RoundedCornerShape = ButtonDefaults.shape,
    interactionSource: MutableInteractionSource? = null,
    colors: ButtonColors = ButtonDefaults.colors(),
) {
    Button(
        buttonColors = colors,
        enabled = enabled,
        contentPadding = contentPadding,
        border = border,
        interactionSource = interactionSource,
        shape = shape,
        modifier = modifier.height(ButtonDefaults.largeHeight),
        onClick = onClick,
        content = {
            BodyText(
                text = text,
                textColor = LocalContentColor.current,
                textAlign = TextAlign.Center,
            )
        },
    )
}

@Composable
@NonRestartableComposable
fun Button(
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    buttonColors: ButtonColors = ButtonDefaults.colors(),
    enabled: Boolean = true,
    border: BorderStroke? = null,
    shape: RoundedCornerShape = ButtonDefaults.shape,
    contentPadding: PaddingValues = ButtonDefaults.ContentPadding,
    interactionSource: MutableInteractionSource? = null,
    content: @Composable RowScope.() -> Unit,
) {
    LocalContentAlpha
    Container(
        onClick = onClick,
        modifier =
            modifier.semantics {
                role = Role.Button
            },
        enabled = enabled,
        style =
            StyleDefaults.style(
                shapes =
                    StyleDefaults.shapes(
                        shape = shape,
                    ),
                borders =
                    StyleDefaults.borders(
                        border = if (border != null) Border(border) else BorderDefaults.None,
                    ),
                colors =
                    StyleDefaults.colors(
                        backgroundColor = buttonColors.backgroundColor,
                        pressedBackgroundColor = buttonColors.backgroundColor.copy(alpha = .6f),
                        contentColor = buttonColors.contentColor,
                        disabledBackgroundColor = buttonColors.disabledBackgroundColor,
                        disabledContentColor = buttonColors.disabledContentColor,
                    ),
                scale = StyleDefaults.scale(pressedScale = ButtonDefaults.PRESSED_SCALE),
            ),
        interactionSource = interactionSource,
        content = {
            Row(
                Modifier.defaultMinSize(minWidth = 58.dp)
                    .padding(contentPadding),
                horizontalArrangement = Arrangement.Center,
                verticalAlignment = Alignment.CenterVertically,
                content = content,
            )
        },
    )
}

object ButtonDefaults {
    val smallHeight = 32.dp
    val mediumHeight = 48.dp
    val largeHeight = 56.dp
    const val PRESSED_SCALE = .9f

    val shape: RoundedCornerShape
        @Composable
        @ReadOnlyComposable
        get() = Theme.shapes.circle

    @Composable
    @ReadOnlyComposable
    fun colors(
        backgroundColor: Color = Theme.colors.surfaceInteractive,
        contentColor: Color = Theme.colors.onSurfaceInteractive,
    ): ButtonColors =
        ButtonColors(
            backgroundColor = backgroundColor,
            contentColor = contentColor,
            disabledBackgroundColor = backgroundColor.copy(alpha = .4f),
            disabledContentColor = contentColor.copy(alpha = .4f),
        )

    val ContentPadding: PaddingValues =
        PaddingValues(
            start = 24.dp,
            top = 8.dp,
            end = 24.dp,
            bottom = 8.dp,
        )
}

@Immutable
data class ButtonColors(
    val backgroundColor: Color,
    val contentColor: Color,
    val disabledBackgroundColor: Color,
    val disabledContentColor: Color,
)
