package studio.goodegg.capsule.domain.playback

import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.onStart
import me.tatarka.inject.annotations.Component
import me.tatarka.inject.annotations.Inject
import me.tatarka.inject.annotations.Provides
import studio.goodegg.capsule.DownloadedEpisode
import studio.goodegg.capsule.EpisodeAndMetadata
import studio.goodegg.capsule.PlaybackState
import studio.goodegg.capsule.PlayState
import studio.goodegg.capsule.core.playback.Player
import studio.goodegg.capsule.core.playback.PlayerItem
import studio.goodegg.capsule.core.playback.ZeroProgress
import studio.goodegg.capsule.repositories.DownloadsRepository
import studio.goodegg.capsule.repositories.PlayedItemsRepository
import studio.goodegg.capsule.repositories.PodcastRepository
import studio.goodegg.capsule.repositories.RadioRepository
import studio.goodegg.capsule.repositories.percentage

@Component
interface PlaybackDomainComponent {
    @Provides
    @Inject
    fun providesGetNowPlayingInteractor(
        playedItemsRepository: PlayedItemsRepository,
        player: Player,
    ): GetNowPlayingInteractor =
        GetNowPlayingInteractor {
            val currentItem = player.currentItem as? PlayerItem.PodcastEpisode
            currentItem?.let {
                val progress = playedItemsRepository.getBySlug(it.episode.episodeSlug)
                val percentage = progress?.percentage() ?: 0f

                EpisodeAndPosition(
                    episode = it.episode,
                    currentPosition = progress?.currentPosition ?: 0,
                    progress = percentage,
                )
            }
        }

    @Provides
    @Inject
    fun providesObserveNowPlayingItemInteractor(
        playedItemsRepository: PlayedItemsRepository,
        podcastRepository: PodcastRepository,
        player: Player,
    ): ObserveNowPlayingItemInteractor =
        ObserveNowPlayingItemInteractor {
            player.playbackState()
                .combine(
                    player.playerProgress().onStart { emit(ZeroProgress) },
                ) { playState, progress ->
                    when (playState) {
                        PlayState.Buffering, PlayState.Paused, PlayState.Playing -> {
                            when (val currentItem = player.currentItem) {
                                is PlayerItem.PodcastEpisode -> {
                                    val playedItem =
                                        if (progress == ZeroProgress) {
                                            playedItemsRepository.getBySlug(currentItem.episode.episodeSlug)
                                        } else {
                                            null
                                        }

                                    val percentage =
                                        playedItem?.percentage() ?: progress.percentage

                                    NowPlayingItem.Podcast(
                                        podcastName = podcastRepository.getPodcastName(
                                            slug = currentItem.episode.parentSlug.orEmpty(),
                                        ),
                                        episode = EpisodeAndMetadata(
                                            episode = currentItem.episode,
                                            playingState = PlaybackState(
                                                currentPosition =
                                                    playedItem?.currentPosition
                                                        ?: progress.progress,
                                                progress = percentage,
                                                playState = playState,
                                                slug = currentItem.episode.episodeSlug,
                                            ),
                                        ),
                                    )
                                }

                                is PlayerItem.Radio -> NowPlayingItem.Radio(
                                    StationAndMetadata(
                                        currentItem.station,
                                        playbackState = PlaybackState(
                                            currentPosition = -1L,
                                            progress = 0f,
                                            playState = playState,
                                            slug = currentItem.station.slug,
                                        ),
                                    ),
                                )

                                else -> null
                            }

                        }

                        else -> null
                    }
                }
        }

    @Provides
    @Inject
    fun providesObservePodcastNowPlayingInteractor(
        observeNowPlayingItemInteractor: ObserveNowPlayingItemInteractor,
    ): ObservePodcastNowPlayingInteractor =
        ObservePodcastNowPlayingInteractor {
            observeNowPlayingItemInteractor().map { (it as? NowPlayingItem.Podcast)?.episode }
        }

    @Provides
    @Inject
    fun providesObserveAllPlayedInteractor(playedItemsRepository: PlayedItemsRepository): ObserveAllPlayedInteractor =
        ObserveAllPlayedInteractor {
            playedItemsRepository.observeCompletePlayed()
        }

    @Provides
    @Inject
    fun providesObservePlayedItemInteractor(playedItemsRepository: PlayedItemsRepository): ObservePlayedItem =
        ObservePlayedItem {
            playedItemsRepository.observeBySlug(it)
        }

    @Provides
    @Inject
    fun providesObservePlayerNameInteractor(player: Player): ObservePlayerNameInteractor =
        ObservePlayerNameInteractor { player.playerName() }

    @Provides
    @Inject
    fun providesPlayInteractor(
        player: Player,
        downloadsRepository: DownloadsRepository,
    ): PlayInteractor = PlayInteractor { episode, position ->
        val download = downloadsRepository.getBySlug(episode.episodeSlug)

        if (download != null) {
            player.play(DownloadedEpisode(download, episode), position)
        } else {
            player.play(episode, position)
        }
    }

    @Provides
    @Inject
    fun providesSeekInteractor(
        player: Player,
    ): SeekInteractor = SeekInteractor { deltaMs ->
        player.seek(deltaMs)
    }

    @Provides
    @Inject
    fun providesTuneInteractor(
        player: Player,
        radioRepository: RadioRepository,
    ): TuneInteractor = TuneInteractor { station ->
        val tuned = radioRepository.tune(station.guid).results.getOrNull(0)
        if (tuned != null) {
            player.play(station, tuned)
        }
    }

    @Provides
    @Inject
    fun providesPauseInteractor(player: Player): PauseInteractor =
        PauseInteractor { player.pause() }

    @Provides
    @Inject
    fun providesStopInteractor(player: Player): StopInteractor = StopInteractor { player.stop() }

    @Provides
    @Inject
    fun providesResumeInteractor(player: Player): ResumePlaybackInteractor =
        ResumePlaybackInteractor { player.resume() }

    @Provides
    @Inject
    fun providesSetPlaybackSpeedInteractor(player: Player): SetPlaybackSpeedInteractor =
        SetPlaybackSpeedInteractor { player.setPlaybackSpeed(it) }

    @Provides
    @Inject
    fun providesSetAudioBoostInteractor(player: Player): SetAudioBoostInteractor =
        SetAudioBoostInteractor { player.setAudioBoostGain(it) }

    @Provides
    @Inject
    fun providesSetTrimSilenceInteractor(player: Player): SetTrimSilenceInteractor =
        SetTrimSilenceInteractor { player.setTrimSilence(it) }

    @Provides
    @Inject
    fun providesGetPlaybackSpeedInteractor(player: Player): GetPlaybackSpeedInteractor =
        GetPlaybackSpeedInteractor { player.getPlaybackSpeed() }

    @Provides
    @Inject
    fun providesObservePlaybackSpeedInteractor(player: Player): ObservePlaybackPropertiesInteractor =
        ObservePlaybackPropertiesInteractor { player.observePlaybackProperties() }

    @Provides
    @Inject
    fun providesObservePlayStateInteractor(player: Player): ObservePlayStateInteractor =
        ObservePlayStateInteractor { player.playbackState() }

    @Provides
    @Inject
    fun providesObservePlayProgressInteractor(player: Player): ObservePlayProgressInteractor =
        ObservePlayProgressInteractor { player.playerProgress() }

    @Provides
    @Inject
    fun providesObservePlayQueueInteractor(player: Player): ObservePlayQueueInteractor =
        ObservePlayQueueInteractor { player.getPlayQueue().observeQueue() }

    @Provides
    @Inject
    fun providesObserveSleepTimerInteractor(player: Player): ObserveSleepTimerInteractor =
        ObserveSleepTimerInteractor { player.sleepTimer().observeSleepTimerProgress() }

    @Provides
    @Inject
    fun providesIncrementSleepInteractor(player: Player): IncrementSleepTimerInteractor =
        IncrementSleepTimerInteractor { player.sleepTimer().increment(it) }

    @Provides
    @Inject
    fun providesDecrementSleepInteractor(player: Player): DecrementSleepTimerInteractor =
        DecrementSleepTimerInteractor { player.sleepTimer().decrement(it) }

    @Provides
    @Inject
    fun providesStartSleepInteractor(player: Player): StartSleepTimerInteractor =
        StartSleepTimerInteractor { player.sleepTimer().start() }

    @Provides
    @Inject
    fun providesStopSleepInteractor(player: Player): StopSleepTimerInteractor =
        StopSleepTimerInteractor { player.sleepTimer().stop() }

    @Provides
    @Inject
    fun providesSetPlayQueueInteractor(player: Player): SetPlayQueueInteractor =
        SetPlayQueueInteractor { player.getPlayQueue().update(it) }

    @Provides
    @Inject
    fun providesAddToPlayQueueInteractor(player: Player): AddToPlayQueueInteractor =
        AddToPlayQueueInteractor { episode, position ->
            when (position) {
                PlayQueuePosition.Front -> player.getPlayQueue().addToFrontQueue(episode)
                PlayQueuePosition.End -> player.getPlayQueue().addToEndQueue(episode)
            }
        }

    @Provides
    @Inject
    fun providesRemoveFromPlayQueueInteractor(player: Player): RemoveFromPlayQueueInteractor =
        RemoveFromPlayQueueInteractor { episode ->
            player.getPlayQueue().remove(episode)
        }

    @Provides
    @Inject
    fun providesMarkAsPlayedInteractor(playedItemsRepository: PlayedItemsRepository): MarkAsPlayedInteractor =
        MarkAsPlayedInteractor { playedItemsRepository.markAsPlayed(it) }

    @Provides
    @Inject
    fun providesMarkAsUnPlayedInteractor(playedItemsRepository: PlayedItemsRepository): UnMarkAsPlayedInteractor =
        UnMarkAsPlayedInteractor { playedItemsRepository.unmarkAsPlayed(it) }

    @Provides
    @Inject
    fun providesObserveInProgressInteractor(
        playedItemsRepository: PlayedItemsRepository,
        podcastRepository: PodcastRepository,
    ): ObserveInProgressInteractor =
        ObserveInProgressInteractor {
            playedItemsRepository.observeInProgress().map {
                it.mapNotNull {
                    val episode = podcastRepository.getEpisode(it.slug) ?: return@mapNotNull null
                    EpisodeAndPosition(episode, it.currentPosition, it.percentage())
                }
            }
        }

    @Provides
    @Inject
    fun providesGetLastPlayedInteractor(
        playedItemsRepository: PlayedItemsRepository,
        podcastRepository: PodcastRepository,
        player: Player,
    ): GetLastPlayedInteractor =
        GetLastPlayedInteractor {
            val currentItem = player.currentItem as? PlayerItem.PodcastEpisode
            currentItem?.let {
                val progress = playedItemsRepository.getBySlug(it.episode.episodeSlug)
                val percentage = progress?.percentage() ?: 0f

                return@GetLastPlayedInteractor NowPlayingItem.Podcast(
                    podcastName = podcastRepository.getPodcastName(it.episode.parentSlug.orEmpty()),
                    episode = EpisodeAndMetadata(
                        episode = it.episode,
                        playingState = PlaybackState(
                            currentPosition = progress?.currentPosition ?: 0,
                            progress = percentage,
                            playState = PlayState.Idle,
                            slug = it.episode.episodeSlug,
                        ),
                    ),
                )
            }

            val recent = playedItemsRepository.getMostRecentlyPlayed()
            if (recent != null) {
                val episode =
                    podcastRepository.getEpisode(recent.slug) ?: return@GetLastPlayedInteractor null

                NowPlayingItem.Podcast(
                    podcastName = podcastRepository.getPodcastName(episode.parentSlug.orEmpty()),
                    episode = EpisodeAndMetadata(
                        episode = episode,
                        playingState = PlaybackState(
                            currentPosition = recent.currentPosition,
                            progress = recent.percentage(),
                            playState = PlayState.Idle,
                            slug = episode.episodeSlug,
                        ),
                    ),
                )
            } else {
                null
            }
        }
}
