package studio.goodegg.capsule.details.podcast

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.produceState
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.runtime.snapshotFlow
import com.slack.circuit.retained.collectAsRetainedState
import com.slack.circuit.retained.rememberRetained
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.Navigator
import com.slack.circuit.runtime.presenter.Presenter
import com.slack.circuit.runtime.screen.Screen
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.onStart
import kotlinx.coroutines.launch
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.PlayState
import studio.goodegg.capsule.PodcastSubscription
import studio.goodegg.capsule.domain.download.DeleteDownloadInteractor
import studio.goodegg.capsule.domain.download.DownloadInteractor
import studio.goodegg.capsule.domain.playback.AddToPlayQueueInteractor
import studio.goodegg.capsule.domain.playback.MarkAsPlayedInteractor
import studio.goodegg.capsule.domain.playback.ObserveAllPlayedInteractor
import studio.goodegg.capsule.domain.playback.ObservePlayQueueInteractor
import studio.goodegg.capsule.domain.playback.ObservePodcastNowPlayingInteractor
import studio.goodegg.capsule.domain.playback.PauseInteractor
import studio.goodegg.capsule.domain.playback.PlayInteractor
import studio.goodegg.capsule.domain.playback.PlayQueuePosition
import studio.goodegg.capsule.domain.playback.RemoveFromPlayQueueInteractor
import studio.goodegg.capsule.domain.playback.ResumePlaybackInteractor
import studio.goodegg.capsule.domain.playback.UnMarkAsPlayedInteractor
import studio.goodegg.capsule.domain.podcast.ObservePodcastInteractor
import studio.goodegg.capsule.domain.share.ShareEpisodeInteractor
import studio.goodegg.capsule.domain.share.SharePodcastInteractor
import studio.goodegg.capsule.domain.subscription.AddSubscriptionInteractor
import studio.goodegg.capsule.domain.subscription.DeleteSubscriptionInteractor
import studio.goodegg.capsule.domain.subscription.ObserveSubscriptionInteractor
import studio.goodegg.capsule.navigation.EpisodeDetailsScreen
import studio.goodegg.capsule.navigation.FullPlayerScreen
import studio.goodegg.capsule.navigation.PodcastDetailsScreen
import studio.goodegg.capsule.ui.components.Filter

@Inject
class PodcastDetailsPresenterFactory(
    private val observePodcastInteractor: ObservePodcastInteractor,
    private val playInteractor: PlayInteractor,
    private val observeNowPlaying: ObservePodcastNowPlayingInteractor,
    private val pauseInteractor: PauseInteractor,
    private val resumePlaybackInteractor: ResumePlaybackInteractor,
    private val observeSubscriptionInteractor: ObserveSubscriptionInteractor,
    private val deleteSubscriptionInteractor: DeleteSubscriptionInteractor,
    private val addSubscriptionInteractor: AddSubscriptionInteractor,
    private val observeAllPlayedInteractor: ObserveAllPlayedInteractor,
    private val downloadInteractor: DownloadInteractor,
    private val deleteDownloadInteractor: DeleteDownloadInteractor,
    private val observePlayQueueInteractor: ObservePlayQueueInteractor,
    private val addToPlayQueueInteractor: AddToPlayQueueInteractor,
    private val removeFromPlayQueueInteractor: RemoveFromPlayQueueInteractor,
    private val markPlayedInteractor: MarkAsPlayedInteractor,
    private val unmarkPlayedInteractor: UnMarkAsPlayedInteractor,
    private val shareEpisodeInteractor: ShareEpisodeInteractor,
    private val sharePodcastInteractor: SharePodcastInteractor,
) : Presenter.Factory {
    override fun create(
        screen: Screen,
        navigator: Navigator,
        context: CircuitContext,
    ): Presenter<*>? {
        return when (screen) {
            is PodcastDetailsScreen ->
                PodcastDetailsPresenter(
                    screen.slug,
                    navigator,
                    observePodcastInteractor,
                    playInteractor,
                    observeNowPlaying,
                    pauseInteractor,
                    resumePlaybackInteractor,
                    observeSubscriptionInteractor,
                    deleteSubscriptionInteractor,
                    addSubscriptionInteractor,
                    observeAllPlayedInteractor,
                    downloadInteractor,
                    deleteDownloadInteractor,
                    observePlayQueueInteractor,
                    addToPlayQueueInteractor,
                    removeFromPlayQueueInteractor,
                    markPlayedInteractor,
                    unmarkPlayedInteractor,
                    shareEpisodeInteractor,
                    sharePodcastInteractor,
                )

            else -> null
        }
    }
}

class PodcastDetailsPresenter(
    private val slug: String,
    private val navigator: Navigator,
    private val observePodcastInteractor: ObservePodcastInteractor,
    private val playInteractor: PlayInteractor,
    private val observeNowPlaying: ObservePodcastNowPlayingInteractor,
    private val pauseInteractor: PauseInteractor,
    private val resumePlaybackInteractor: ResumePlaybackInteractor,
    private val observeSubscriptionInteractor: ObserveSubscriptionInteractor,
    private val deleteSubscriptionInteractor: DeleteSubscriptionInteractor,
    private val addSubscriptionInteractor: AddSubscriptionInteractor,
    private val observeAllPlayedInteractor: ObserveAllPlayedInteractor,
    private val downloadInteractor: DownloadInteractor,
    private val deleteDownloadInteractor: DeleteDownloadInteractor,
    private val observePlayQueueInteractor: ObservePlayQueueInteractor,
    private val addToPlayQueueInteractor: AddToPlayQueueInteractor,
    private val removeFromPlayQueueInteractor: RemoveFromPlayQueueInteractor,
    private val markPlayedInteractor: MarkAsPlayedInteractor,
    private val unmarkPlayedInteractor: UnMarkAsPlayedInteractor,
    private val shareEpisodeInteractor: ShareEpisodeInteractor,
    private val sharePodcastInteractor: SharePodcastInteractor,
) : Presenter<PodcastDetailsUiState> {
    @Composable
    override fun present(): PodcastDetailsUiState {
        val scope = rememberCoroutineScope()

        val subscribed by rememberRetained { observeSubscriptionInteractor(slug) }
            .collectAsRetainedState(null)

        val played by rememberRetained {
            observeAllPlayedInteractor().map { playedItems ->
                playedItems.map { it.slug }.toSet()
            }
        }.collectAsRetainedState(emptySet())

        val playQueue by rememberRetained {
            observePlayQueueInteractor.invoke().map { it.map { it.episodeSlug } }
        }.collectAsRetainedState(emptyList())

        var query by remember { mutableStateOf("") }
        var filter by remember { mutableStateOf(Filter.Latest) }

        val podcast by rememberRetained { observePodcastInteractor.invoke(slug) }
            .collectAsState(null)

        val loading by produceState(true, podcast?.slug) {
            delay(100)
            value = podcast == null
        }

        val nowPlaying by remember { observeNowPlaying() }.collectAsState(null)

        fun eventSink(event: PodcastDetailsUiEvent) {
            when (event) {
                is PodcastDetailsUiEvent.OpenEpisodeDetails -> {
                    navigator.goTo(EpisodeDetailsScreen(event.episode.episodeSlug))
                }

                is PodcastDetailsUiEvent.PlayEpisode -> {
                    if (nowPlaying?.episode?.episodeSlug == event.episode.episodeSlug) {
                        if (event.openPlayer) {
                            navigator.goTo(FullPlayerScreen)
                            return
                        }

                        when (nowPlaying?.playingState?.playState) {
                            PlayState.Buffering, PlayState.Playing -> pauseInteractor()
                            PlayState.Paused -> resumePlaybackInteractor()
                            else -> Unit
                        }
                    } else {
                        scope.launch {
                            playInteractor(event.episode, 0)
                            if (event.openPlayer) {
                                navigator.goTo(FullPlayerScreen)
                            }
                        }
                    }
                }

                PodcastDetailsUiEvent.Subscribe -> {
                    scope.launch {
                        if (subscribed != null) {
                            deleteSubscriptionInteractor(slug)
                        } else {
                            podcast?.let {
                                addSubscriptionInteractor(
                                    PodcastSubscription(
                                        slug = slug,
                                        feedUrl = it.feed,
                                        title = it.title,
                                        artist = it.author,
                                        image = it.imageUrl,
                                        notify = true,
                                        collection = it.collectionId.toString(),
                                        autoDownload = false,
                                    ),
                                )
                            }
                        }
                    }
                }

                is PodcastDetailsUiEvent.DownloadEpisode -> {
                    val download = event.data.download
                    if (download != null) {
                        scope.launch {
                            deleteDownloadInteractor(download.slug)
                        }
                    } else {
                        downloadInteractor(
                            event.data.episode.episodeSlug,
                            event.data.episode.media,
                            event.data.episode.title,
                        )
                    }
                }

                is PodcastDetailsUiEvent.ShareEpisode -> {
                    shareEpisodeInteractor.invoke(event.episode)
                }

                is PodcastDetailsUiEvent.SharePodcast -> {
                    podcast?.let {
                        sharePodcastInteractor.invoke(it.title, it.slug)
                    }
                }

                is PodcastDetailsUiEvent.ToggleNotifications -> {
                    scope.launch {
                        subscribed?.let {
                            addSubscriptionInteractor(
                                it.copy(notify = !it.notify),
                            )
                        }
                    }
                }

                is PodcastDetailsUiEvent.Search -> {
                    query = event.query
                }

                is PodcastDetailsUiEvent.AddToPlayQueue -> {
                    addToPlayQueueInteractor.invoke(event.data.episode, PlayQueuePosition.End)
                }

                is PodcastDetailsUiEvent.RemoveFromPlayQueue -> {
                    removeFromPlayQueueInteractor.invoke(event.data.episode)
                }

                PodcastDetailsUiEvent.ToggleAutoDownload -> {
                    scope.launch {
                        subscribed?.let {
                            addSubscriptionInteractor(
                                it.copy(autoDownload = !it.autoDownload),
                            )
                        }
                    }
                }

                is PodcastDetailsUiEvent.MarkAsPlayed -> scope.launch {
                    if (played.contains(event.episode.episodeSlug)) {
                        unmarkPlayedInteractor.invoke(event.episode)
                    } else {
                        markPlayedInteractor.invoke(event.episode)
                    }
                }

                is PodcastDetailsUiEvent.SetFilter -> {
                    query = ""
                    filter = event.filter
                }
            }
        }

        return PodcastDetailsUiState(
            podcast = when (filter) {
                Filter.Latest -> {
                    podcast?.copy(
                        episodes = podcast?.episodes?.filter {
                            if (query.isBlank())
                                return@filter true

                            it.episode.title.contains(
                                other = query,
                                ignoreCase = true,
                            )
                        }.orEmpty(),
                    )
                }

                Filter.Downloaded -> podcast?.copy(
                    episodes = podcast?.episodes?.filter {
                        it.download != null
                    }.orEmpty(),
                )

                Filter.InProgress -> podcast?.copy(
                    episodes = podcast?.episodes?.filter {
                        (it.playingState?.currentPosition ?: 0L) > 0
                    }.orEmpty(),
                )
            },
            isSubscribed = subscribed != null,
            filter = filter,
            notifications = subscribed?.notify ?: false,
            autoDownload = subscribed?.autoDownload ?: false,
            loading = loading,
            playedEpisodesSlugs = played,
            playQueue = playQueue,
            eventSink = ::eventSink,
        )
    }
}
