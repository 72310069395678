package io.daio.pancake.scaffold

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.material.DrawerState
import androidx.compose.material.DrawerValue
import androidx.compose.material.ModalDrawer
import androidx.compose.material.rememberDrawerState
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import io.daio.pancake.bottomsheet.BottomSheetState
import io.daio.pancake.bottomsheet.ModalBottomSheetLayout
import io.daio.pancake.bottomsheet.rememberBottomSheetState
import io.daio.pancake.components.snackbar.SnackbarHolder
import io.daio.pancake.components.snackbar.SnackbarState
import io.daio.pancake.components.snackbar.rememberSnackbarState
import io.daio.pancake.foundations.PancakeTheme
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.annotations.InternalPancakeApi
import io.daio.pancake.foundations.color.Colors
import io.daio.pancake.foundations.color.contentColorFor
import io.daio.pancake.layout.Stack

@Composable
fun Scaffold(
    sideBar: @Composable (ColumnScope.() -> Unit),
    modifier: Modifier = Modifier,
    themeColors: Colors = Theme.colors,
    snackbarState: SnackbarState = rememberSnackbarState(),
    bottomSheetState: BottomSheetState = rememberBottomSheetState(),
    sidebarState: DrawerState = rememberDrawerState(DrawerValue.Closed),
    topBar: @Composable (() -> Unit)? = null,
    bottomBar: @Composable (() -> Unit)? = null,
    dock: @Composable ((PaddingValues) -> Unit)? = null,
    content: @Composable (PaddingValues) -> Unit,
) {
    PancakeTheme(themeColors = themeColors, modifier = modifier) {
        SideMenuScaffold(
            drawerState = sidebarState,
            drawerContent = sideBar,
        ) {
            MainScaffolding(
                bottomSheetState = bottomSheetState,
                snackbarState = snackbarState,
                bottomBar = bottomBar,
                topBar = topBar,
                dock = dock,
                content = content,
            )
        }
    }
}

@Composable
fun Scaffold(
    modifier: Modifier = Modifier,
    themeColors: Colors = Theme.colors,
    snackbarState: SnackbarState = rememberSnackbarState(),
    bottomSheetState: BottomSheetState = rememberBottomSheetState(),
    topBar: @Composable (() -> Unit)? = null,
    bottomBar: @Composable (() -> Unit)? = null,
    dock: @Composable ((PaddingValues) -> Unit)? = null,
    content: @Composable (PaddingValues) -> Unit,
) {
    PancakeTheme(themeColors = themeColors, modifier = modifier) {
        MainScaffolding(
            bottomSheetState = bottomSheetState,
            snackbarState = snackbarState,
            bottomBar = bottomBar,
            topBar = topBar,
            dock = dock,
            content = content,
        )
    }
}

@OptIn(InternalPancakeApi::class)
@Composable
private fun MainScaffolding(
    bottomSheetState: BottomSheetState,
    snackbarState: SnackbarState,
    bottomBar: @Composable (() -> Unit)?,
    topBar: @Composable (() -> Unit)?,
    modifier: Modifier = Modifier,
    dock: @Composable ((PaddingValues) -> Unit)?,
    content: @Composable (PaddingValues) -> Unit,
) {
    Box(modifier = modifier) {
        ModalBottomSheetLayout(
            sheetContent = { bottomSheetState.currentContent?.invoke() },
            scrimColor = Theme.colors.surfaceContainer.copy(alpha = .6f),
            sheetShape = Theme.shapes.large,
            sheetContentColor = contentColorFor(Theme.colors.surfaceEmphasized),
            sheetBackgroundColor = Theme.colors.surfaceEmphasized,
            sheetState = bottomSheetState.sheetState,
        ) {
            val paddingValues =
                PaddingValues(
                    bottom = if (bottomBar != null) 72.dp else Theme.dimens.size_s,
                    top = if (topBar != null) 72.dp else Theme.dimens.size_s,
                    start = Theme.dimens.size_s,
                    end = Theme.dimens.size_s,
                )
            Box {
                topBar?.let {
                    Box(modifier = Modifier.align(Alignment.TopCenter)) {
                        topBar.invoke()
                    }
                }
                content(paddingValues)
                bottomBar?.let {
                    Box(
                        modifier =
                            Modifier.padding(Theme.dimens.size_s)
                                .align(Alignment.BottomCenter),
                    ) {
                        bottomBar.invoke()
                    }
                }

                dock?.let {
                    Box(modifier = Modifier.align(Alignment.BottomCenter)) {
                        dock.invoke(paddingValues)
                    }
                }
            }
            SnackbarHolder(
                snackbarState,
                Modifier.align(Alignment.BottomCenter)
                    .padding(Theme.dimens.size_m),
            )
        }
    }
}

@Composable
fun SideMenuScaffold(
    drawerContent: @Composable ColumnScope.() -> Unit,
    modifier: Modifier = Modifier,
    drawerState: DrawerState = rememberDrawerState(DrawerValue.Closed),
    content: @Composable () -> Unit,
) {
    ModalDrawer(
        modifier = modifier.wrapContentWidth(),
        drawerState = drawerState,
        drawerBackgroundColor = Color.Transparent,
        drawerContent = {
            Stack(
                content = drawerContent,
                modifier =
                    Modifier
                        .fillMaxWidth(.5f)
                        .fillMaxHeight()
                        .background(Theme.colors.surfaceEmphasized)
                        .padding(vertical = Theme.dimens.size_xl),
            )
        },
        drawerElevation = 0.dp,
        drawerShape = Theme.shapes.small,
        content = {
            content()
        },
    )
}
