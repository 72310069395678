package io.daio.pancake.layout

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.layout.BoxScope
import androidx.compose.runtime.Composable
import androidx.compose.runtime.NonRestartableComposable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.RectangleShape
import androidx.compose.ui.graphics.Shape
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.color.contentColorFor
import io.daio.wild.container.Container
import io.daio.wild.style.Border
import io.daio.wild.style.BorderDefaults

@Composable
@NonRestartableComposable
fun Surface(
    modifier: Modifier = Modifier,
    shape: Shape = RectangleShape,
    color: Color = Theme.colors.surfaceBackground,
    contentColor: Color = contentColorFor(color),
    border: BorderStroke? = null,
    content: @Composable BoxScope.() -> Unit,
) {
    Container(
        modifier = modifier,
        shape = shape,
        color = color,
        contentColor = contentColor,
        border = if (border != null) Border(border) else BorderDefaults.None,
        content = content,
    )
}

@Composable
@NonRestartableComposable
fun SurfaceEmphasized(
    modifier: Modifier = Modifier,
    shape: Shape = RectangleShape,
    border: BorderStroke? = null,
    content: @Composable BoxScope.() -> Unit,
) {
    Surface(
        modifier = modifier,
        shape = shape,
        color = Theme.colors.surfaceEmphasized,
        border = border,
        content = content,
    )
}

@Composable
@NonRestartableComposable
fun SurfaceContainer(
    modifier: Modifier = Modifier,
    shape: Shape = RectangleShape,
    border: BorderStroke? = null,
    content: @Composable BoxScope.() -> Unit,
) {
    Surface(
        modifier = modifier,
        shape = shape,
        color = Theme.colors.surfaceContainer,
        border = border,
        content = content,
    )
}
