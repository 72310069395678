package studio.goodegg.capsule.db

import kotlin.Boolean
import kotlin.Long
import kotlin.String

public data class Subscription(
  public val slug: String,
  public val feedurl: String,
  public val title: String,
  public val artist: String,
  public val image: String?,
  public val notify: Boolean,
  public val collection: String?,
  public val autodownload: Boolean,
  public val updated_at: Long,
  public val deleted: Boolean,
)
