package studio.goodegg.capsule.db

import kotlin.Long
import kotlin.String

public data class Download(
  public val slug: String,
  public val mediaurl: String,
  public val filepath: String,
  public val percentage: Long,
)
