package studio.goodegg.capsule.resume

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.items
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.screen.Screen
import com.slack.circuit.runtime.ui.Ui
import com.slack.circuit.runtime.ui.ui
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.foundations.text.BodyTextSmall
import io.daio.pancake.foundations.text.TitleTextScreen
import io.daio.pancake.icons.ArrowBack
import io.daio.pancake.icons.Check
import io.daio.pancake.icons.Icons
import io.daio.pancake.icons.Pause
import io.daio.pancake.icons.PlayFilled
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.Tier
import io.daio.wild.style.clickable
import studio.goodegg.capsule.PlayState
import studio.goodegg.capsule.PlaybackState
import studio.goodegg.capsule.domain.playback.EpisodeAndPosition
import studio.goodegg.capsule.navigation.ResumeScreen
import studio.goodegg.capsule.ui.components.ArtworkTile
import studio.goodegg.capsule.ui.components.EpisodeItem
import studio.goodegg.capsule.ui.components.IconButton
import studio.goodegg.capsule.ui.components.IconTextButton
import studio.goodegg.capsule.ui.components.OutlineIconButton
import studio.goodegg.capsule.ui.components.PlayButton
import studio.goodegg.capsule.ui.components.PlayerAwareLazyStack

class ResumeScreenUiFactory : Ui.Factory {
    override fun create(
        screen: Screen,
        context: CircuitContext,
    ): Ui<*>? {
        return when (screen) {
            is ResumeScreen -> resume()
            else -> null
        }
    }
}

private fun resume() =
    ui<ResumeUiState> { state, modifier ->
        Resume(
            state,
            modifier,
        )
    }

@Composable
fun Resume(
    state: ResumeUiState,
    modifier: Modifier = Modifier,
) {
    Stack(modifier = modifier) {
        Tier {
            Icon(
                imageVector = Icons.ArrowBack,
                contentDescription = "",
                modifier = Modifier
                    .size(24.dp)
                    .clickable(
                        style = {
                            scale = if (pressed) .95f else 1f
                        },
                        onClick = {
                            state.eventSink(ResumeUiEvent.Back)
                        },
                    ),
            )
            TitleTextScreen("In Progress")
        }
        PlayerAwareLazyStack {
            items(
                state.items,
                key = { it.episode.episodeSlug },
            ) {
                Stack(modifier = Modifier.animateItem()) {
                    EpisodeItem(
                        episode = it.episode,
                        actions = {
                            PlayButton(
                                nowPlaying = state.nowPlaying?.playingState,
                                episode = it.episode,
                                onPlay = {
                                    state.eventSink(ResumeUiEvent.Resume(it))
                                },
                            )
                            IconButton(
                                icon = Icons.Check,
                                onClick = {
                                    state.eventSink(ResumeUiEvent.MarkAsPlayed(it.episode))
                                },
                            )
                        },
                    )
                    HorizontalDivider(color = Theme.colors.onSurfaceBackground.copy(alpha = .2f))
                }
            }
        }
    }
}

@Composable
private fun ResumeItem(
    episodeAndPosition: EpisodeAndPosition,
    nowPlaying: PlaybackState?,
    onPlay: () -> Unit,
    onMarkAsPlayed: () -> Unit,
    modifier: Modifier = Modifier,
) {
    Stack(
        modifier =
            modifier
                .wrapContentHeight()
                .fillMaxWidth(),
        spaceBetween = LayoutGap.Small,
    ) {
        Tier(verticalAlignment = Alignment.Top) {
            ArtworkTile(
                size = 68.dp,
                imageUrl = episodeAndPosition.episode.image,
            )
            Stack(spaceBetween = LayoutGap.Medium) {
                BodyTextSmall(
                    episodeAndPosition.episode.title,
                    maxLines = 3,
                    overflow = TextOverflow.Ellipsis,
                )

                Tier(spaceBetween = LayoutGap.Tiny) {
                    val isNowPlaying =
                        episodeAndPosition.episode.episodeSlug == nowPlaying?.slug
                    val isPaused = isNowPlaying && nowPlaying?.playState == PlayState.Paused

                    IconTextButton(
                        icon = if (isPaused || !isNowPlaying) Icons.PlayFilled else Icons.Pause,
                        text = "${episodeAndPosition.formatTimeRemaining()} left",
                        onClick = onPlay,
                    )
                    OutlineIconButton(
                        icon = Icons.Check,
                        onClick = onMarkAsPlayed,
                    )
                }
            }
        }
    }
}
