package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.NotificationOff: ImageVector
    get() {
        if (_NotificationOff != null) {
            return _NotificationOff!!
        }
        _NotificationOff = ImageVector.Builder(
            name = "NotificationOff",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(
                fill = SolidColor(Color(0xFFFFFFFF)),
                pathFillType = PathFillType.EvenOdd
            ) {
                moveTo(6f, 10f)
                curveTo(6f, 9.377f, 6.084f, 8.786f, 6.253f, 8.226f)
                lineTo(8f, 9.97f)
                lineTo(8f, 10f)
                verticalLineTo(17f)
                horizontalLineTo(15.041f)
                lineTo(17.045f, 19f)
                horizontalLineTo(4f)
                verticalLineTo(17f)
                horizontalLineTo(6f)
                verticalLineTo(10f)
                close()
                moveTo(16f, 10f)
                verticalLineTo(12.012f)
                lineTo(18f, 14.008f)
                verticalLineTo(10f)
                curveTo(18f, 8.617f, 17.583f, 7.387f, 16.75f, 6.313f)
                curveTo(15.917f, 5.238f, 14.833f, 4.533f, 13.5f, 4.2f)
                verticalLineTo(3.5f)
                curveTo(13.5f, 3.083f, 13.354f, 2.729f, 13.063f, 2.438f)
                curveTo(12.771f, 2.146f, 12.417f, 2f, 12f, 2f)
                curveTo(11.583f, 2f, 11.229f, 2.146f, 10.938f, 2.438f)
                curveTo(10.646f, 2.729f, 10.5f, 3.083f, 10.5f, 3.5f)
                verticalLineTo(4.2f)
                curveTo(9.9f, 4.35f, 9.351f, 4.575f, 8.852f, 4.875f)
                lineTo(10.333f, 6.353f)
                curveTo(10.841f, 6.118f, 11.397f, 6f, 12f, 6f)
                curveTo(13.1f, 6f, 14.042f, 6.392f, 14.825f, 7.175f)
                curveTo(15.608f, 7.958f, 16f, 8.9f, 16f, 10f)
                close()
                moveTo(12f, 22f)
                curveTo(11.45f, 22f, 10.979f, 21.804f, 10.587f, 21.413f)
                curveTo(10.196f, 21.021f, 10f, 20.55f, 10f, 20f)
                horizontalLineTo(14f)
                curveTo(14f, 20.55f, 13.804f, 21.021f, 13.413f, 21.413f)
                curveTo(13.021f, 21.804f, 12.55f, 22f, 12f, 22f)
                close()
                moveTo(5.835f, 3f)
                lineTo(4f, 4.837f)
                lineTo(18.78f, 19.594f)
                lineTo(20.614f, 17.757f)
                lineTo(5.835f, 3f)
                close()
            }
        }.build()

        return _NotificationOff!!
    }

@Suppress("ObjectPropertyName")
private var _NotificationOff: ImageVector? = null
