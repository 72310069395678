package studio.goodegg.capsule.signin

import com.slack.circuit.runtime.CircuitUiEvent

sealed class SignInUiEvent : CircuitUiEvent {
    data class SignInWithEmail(val email: String) : SignInUiEvent()
    data class VerifyCode(val email: String, val code: String) : SignInUiEvent()
    data object SignOut : SignInUiEvent()
    data object Close : SignInUiEvent()
    // TODO remove this, its just while the screen is in the nav menu.
    data object TestOnboarding : SignInUiEvent()
}
