package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.Cancel: ImageVector
    get() {
        if (_Cancel != null) {
            return _Cancel!!
        }
        _Cancel = ImageVector.Builder(
            name = "Cancel",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(fill = SolidColor(Color(0xFFE8EAED))) {
                moveToRelative(8.4f, 17f)
                lineToRelative(3.6f, -3.6f)
                lineToRelative(3.6f, 3.6f)
                lineToRelative(1.4f, -1.4f)
                lineToRelative(-3.6f, -3.6f)
                lineTo(17f, 8.4f)
                lineTo(15.6f, 7f)
                lineTo(12f, 10.6f)
                lineTo(8.4f, 7f)
                lineTo(7f, 8.4f)
                lineToRelative(3.6f, 3.6f)
                lineTo(7f, 15.6f)
                lineTo(8.4f, 17f)
                close()
                moveTo(12f, 22f)
                arcToRelative(9.738f, 9.738f, 0f, isMoreThanHalf = false, isPositiveArc = true, -3.9f, -0.788f)
                arcToRelative(10.099f, 10.099f, 0f, isMoreThanHalf = false, isPositiveArc = true, -3.175f, -2.137f)
                curveToRelative(-0.9f, -0.9f, -1.612f, -1.958f, -2.137f, -3.175f)
                arcTo(9.738f, 9.738f, 0f, isMoreThanHalf = false, isPositiveArc = true, 2f, 12f)
                arcToRelative(9.74f, 9.74f, 0f, isMoreThanHalf = false, isPositiveArc = true, 0.788f, -3.9f)
                arcToRelative(10.099f, 10.099f, 0f, isMoreThanHalf = false, isPositiveArc = true, 2.137f, -3.175f)
                curveToRelative(0.9f, -0.9f, 1.958f, -1.612f, 3.175f, -2.137f)
                arcTo(9.738f, 9.738f, 0f, isMoreThanHalf = false, isPositiveArc = true, 12f, 2f)
                arcToRelative(9.74f, 9.74f, 0f, isMoreThanHalf = false, isPositiveArc = true, 3.9f, 0.788f)
                arcToRelative(10.098f, 10.098f, 0f, isMoreThanHalf = false, isPositiveArc = true, 3.175f, 2.137f)
                curveToRelative(0.9f, 0.9f, 1.613f, 1.958f, 2.137f, 3.175f)
                arcTo(9.738f, 9.738f, 0f, isMoreThanHalf = false, isPositiveArc = true, 22f, 12f)
                arcToRelative(9.738f, 9.738f, 0f, isMoreThanHalf = false, isPositiveArc = true, -0.788f, 3.9f)
                arcToRelative(10.098f, 10.098f, 0f, isMoreThanHalf = false, isPositiveArc = true, -2.137f, 3.175f)
                curveToRelative(-0.9f, 0.9f, -1.958f, 1.613f, -3.175f, 2.137f)
                arcTo(9.738f, 9.738f, 0f, isMoreThanHalf = false, isPositiveArc = true, 12f, 22f)
                close()
                moveTo(12f, 20f)
                curveToRelative(2.233f, 0f, 4.125f, -0.775f, 5.675f, -2.325f)
                curveTo(19.225f, 16.125f, 20f, 14.233f, 20f, 12f)
                curveToRelative(0f, -2.233f, -0.775f, -4.125f, -2.325f, -5.675f)
                curveTo(16.125f, 4.775f, 14.233f, 4f, 12f, 4f)
                curveToRelative(-2.233f, 0f, -4.125f, 0.775f, -5.675f, 2.325f)
                curveTo(4.775f, 7.875f, 4f, 9.767f, 4f, 12f)
                curveToRelative(0f, 2.233f, 0.775f, 4.125f, 2.325f, 5.675f)
                curveTo(7.875f, 19.225f, 9.767f, 20f, 12f, 20f)
                close()
            }
        }.build()

        return _Cancel!!
    }

@Suppress("ObjectPropertyName")
private var _Cancel: ImageVector? = null
