package studio.goodegg.capsule.core.playback

import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.SharingStarted
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.flatMapLatest
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.flow.onStart
import kotlinx.coroutines.flow.shareIn
import studio.goodegg.capsule.preferences.Preferences

private const val SLEEP_TIMER_KEY = "capsule-sleep-timer-key"
internal const val SLEEP_TIMER_COMPLETE = -1000L

sealed class SleepTimerState(val timer: Long) {
    class Stopped(timer: Long) : SleepTimerState(timer)
    class Started(timer: Long) : SleepTimerState(timer)

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class != other::class) return false

        other as SleepTimerState

        return timer == other.timer
    }

    override fun hashCode(): Int = timer.hashCode()
}

class SleepTimer(private val preferences: Preferences) {

    private val scope = MainScope()
    private val running = MutableStateFlow<Boolean>(false)

    suspend fun currentSleepTime(): Long = preferences.getLong(SLEEP_TIMER_KEY)
        ?.coerceAtLeast(0L) ?: 0L

    @OptIn(ExperimentalCoroutinesApi::class)
    private val sleepTimerFlow: Flow<SleepTimerState> = running.flatMapLatest {
        if (!it) {
            preferences.observe<Long>(SLEEP_TIMER_KEY).filterNotNull()
                .onStart { emit(0L) }.map { SleepTimerState.Stopped(it) }
        } else flow {
            emit(SleepTimerState.Started(currentSleepTime()))
            while (true) {
                delay(500L)
                val update = currentSleepTime() - 500L
                preferences.put(SLEEP_TIMER_KEY, update.coerceAtLeast(0L))
                val emitValue = update.coerceAtLeast(SLEEP_TIMER_COMPLETE)
                emit(SleepTimerState.Started(emitValue))
            }
        }.onEach {
            if (it.timer <= SLEEP_TIMER_COMPLETE) stop()
        }
    }.shareIn(scope, started = SharingStarted.Lazily, 1)

    @OptIn(ExperimentalCoroutinesApi::class)
    fun observeSleepTimerProgress(): Flow<SleepTimerState> = sleepTimerFlow

    suspend fun start() {
        if (currentSleepTime() > 0L) {
            running.value = true
        }
    }

    suspend fun stop() {
        running.value = false
        preferences.put(SLEEP_TIMER_KEY, 0L)
    }

    suspend fun increment(time: Long) {
        val current = currentSleepTime()
        val update = current + time

        preferences.put(SLEEP_TIMER_KEY, update)
    }

    suspend fun decrement(time: Long) {
        val current = currentSleepTime()
        val update = (current - time).coerceAtLeast(0L)
        if (update <= 0L) {
            stop()
        } else {
            preferences.put(SLEEP_TIMER_KEY, update)
        }
    }
}