package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.Replay: ImageVector
    get() {
        if (_Replay != null) {
            return _Replay!!
        }
        _Replay = ImageVector.Builder(
            name = "Replay",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(
                fill = SolidColor(Color(0xFFE8EAED)),
                pathFillType = PathFillType.EvenOdd
            ) {
                moveTo(5.109f, 18.313f)
                arcToRelative(8.707f, 8.707f, 0f, isMoreThanHalf = false, isPositiveArc = false, 2.685f, 2.373f)
                arcToRelative(8.706f, 8.706f, 0f, isMoreThanHalf = false, isPositiveArc = false, 3.398f, 1.14f)
                arcToRelative(9.147f, 9.147f, 0f, isMoreThanHalf = false, isPositiveArc = false, 3.431f, -0.242f)
                arcToRelative(9.147f, 9.147f, 0f, isMoreThanHalf = false, isPositiveArc = false, 3.092f, -1.506f)
                arcToRelative(8.707f, 8.707f, 0f, isMoreThanHalf = false, isPositiveArc = false, 2.373f, -2.686f)
                lineToRelative(-1.732f, -1f)
                curveToRelative(-0.975f, 1.69f, -2.39f, 2.782f, -4.245f, 3.28f)
                curveToRelative(-1.856f, 0.496f, -3.628f, 0.258f, -5.317f, -0.718f)
                curveToRelative(-1.689f, -0.974f, -2.782f, -2.39f, -3.279f, -4.245f)
                curveToRelative(-0.497f, -1.856f, -0.258f, -3.628f, 0.717f, -5.317f)
                curveToRelative(0.975f, -1.688f, 2.39f, -2.781f, 4.246f, -3.279f)
                curveToRelative(1.855f, -0.497f, 3.627f, -0.258f, 5.316f, 0.717f)
                lineToRelative(0.13f, 0.075f)
                lineToRelative(-2.117f, 0.567f)
                lineToRelative(0.487f, 1.956f)
                lineToRelative(5.464f, -1.464f)
                lineTo(18.294f, 2.5f)
                lineToRelative(-1.937f, 0.556f)
                lineToRelative(0.567f, 2.117f)
                lineToRelative(-0.13f, -0.075f)
                arcToRelative(8.707f, 8.707f, 0f, isMoreThanHalf = false, isPositiveArc = false, -3.398f, -1.14f)
                arcToRelative(9.148f, 9.148f, 0f, isMoreThanHalf = false, isPositiveArc = false, -3.43f, 0.243f)
                arcToRelative(9.148f, 9.148f, 0f, isMoreThanHalf = false, isPositiveArc = false, -3.093f, 1.506f)
                arcTo(8.707f, 8.707f, 0f, isMoreThanHalf = false, isPositiveArc = false, 4.5f, 8.392f)
                arcToRelative(8.707f, 8.707f, 0f, isMoreThanHalf = false, isPositiveArc = false, -1.14f, 3.399f)
                arcToRelative(9.148f, 9.148f, 0f, isMoreThanHalf = false, isPositiveArc = false, 0.243f, 3.43f)
                arcToRelative(9.148f, 9.148f, 0f, isMoreThanHalf = false, isPositiveArc = false, 1.506f, 3.092f)
                close()
                moveTo(10.5f, 15.923f)
                arcToRelative(0.3f, 0.3f, 0f, isMoreThanHalf = false, isPositiveArc = false, 0.472f, 0.247f)
                lineToRelative(4.177f, -2.924f)
                arcToRelative(0.3f, 0.3f, 0f, isMoreThanHalf = false, isPositiveArc = false, 0f, -0.492f)
                lineTo(10.972f, 9.83f)
                arcToRelative(0.3f, 0.3f, 0f, isMoreThanHalf = false, isPositiveArc = false, -0.472f, 0.246f)
                verticalLineToRelative(5.848f)
                close()
            }
        }.build()

        return _Replay!!
    }

@Suppress("ObjectPropertyName")
private var _Replay: ImageVector? = null
