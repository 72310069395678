package studio.goodegg.capsule.preferences

import kotlinx.browser.localStorage
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.onStart
import me.tatarka.inject.annotations.Provides
import org.w3c.dom.get
import studio.goodegg.capsule.common.di.ApplicationScope

actual class Preferences {
    val emissions = MutableSharedFlow<String>()

    actual suspend fun put(key: String, value: String) {
        localStorage.setItem(key, value)
        emissions.emit(key)
    }

    actual suspend fun put(key: String, value: Boolean) {
        localStorage.setItem(key, value.toString())
        emissions.emit(key)
    }

    actual suspend fun put(key: String, value: Int) {
        localStorage.setItem(key, value.toString())
        emissions.emit(key)
    }

    actual suspend fun put(key: String, value: Long) {
        localStorage.setItem(key, value.toString())
        emissions.emit(key)
    }

    actual suspend fun put(key: String, value: Float) {
        localStorage.setItem(key, value.toString())
        emissions.emit(key)
    }

    actual suspend fun put(key: String, value: Any) {
        localStorage.setItem(key, value.toString())
        emissions.emit(key)
    }

    actual suspend fun getBoolean(key: String): Boolean? {
        return localStorage[key]?.toBoolean()
    }

    actual suspend fun getInt(key: String): Int? {
        return localStorage[key]?.toIntOrNull()
    }

    actual suspend fun getString(key: String): String? {
        return localStorage[key]
    }

    actual suspend fun getLong(key: String): Long? {
        return localStorage[key]?.toLongOrNull()
    }

    actual suspend fun getFloat(key: String): Float? {
        return localStorage[key]?.toFloatOrNull()
    }

    actual suspend fun getValue(key: String): Any? {
        return localStorage[key]
    }

    actual inline fun <reified T> observe(key: String): Flow<T?> {
        return emissions.filter {
            it == key
        }.map {
            getValue(key) as? T
        }.onStart {
            emit(getValue(key) as? T)
        }
    }
}

actual interface PlatformPreferencesComponent {
    @Provides
    @ApplicationScope
    fun providesPreferences(): Preferences = Preferences()
}