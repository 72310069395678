package studio.goodegg.capsule.repositories

import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.withContext
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.Episode
import studio.goodegg.capsule.PodcastResult
import studio.goodegg.capsule.SearchResult
import studio.goodegg.capsule.StationResponse
import studio.goodegg.capsule.api.clients.DiscoveryClient
import studio.goodegg.capsule.api.clients.RadioClient
import studio.goodegg.capsule.common.coroutines.IoDispatcher
import studio.goodegg.capsule.utils.Failed
import studio.goodegg.capsule.utils.Success

interface SearchRepository {
    suspend fun search(q: String): SearchResult
    suspend fun searchPodcasts(q: String): List<PodcastResult>
    suspend fun searchEpisodes(q: String): List<Episode>
    suspend fun searchGenres(genreId: String, locale: String): List<PodcastResult>
    suspend fun searchRadio(q: String): StationResponse
}

@Inject
class SearchRepositoryImpl(
    private val discoveryClient: DiscoveryClient,
    private val radioClient: RadioClient,
    @IoDispatcher private val ioDispatcher: CoroutineDispatcher,
) :
    SearchRepository {
    override suspend fun search(q: String): SearchResult = withContext(ioDispatcher) {
        when (val result = discoveryClient.search(q)) {
            is Failed -> SearchResult(emptyList(), emptyList())
            is Success -> result.data
        }
    }

    override suspend fun searchPodcasts(q: String): List<PodcastResult> =
        withContext(ioDispatcher) {
            when (val result = discoveryClient.podcastSearch(q)) {
                is Failed -> emptyList()
                is Success -> result.data
            }
        }

    override suspend fun searchEpisodes(q: String): List<Episode> = withContext(ioDispatcher) {
        when (val result = discoveryClient.episodeSearch(q)) {
            is Failed -> emptyList()
            is Success -> result.data
        }
    }

    override suspend fun searchGenres(genreId: String, locale: String): List<PodcastResult> =
        withContext(ioDispatcher) {
            when (val result = discoveryClient.genreSearch(genreId, locale)) {
                is Failed -> emptyList()
                is Success -> result.data.podcasts
            }
        }

    override suspend fun searchRadio(q: String): StationResponse = withContext(ioDispatcher) {
        when (val result = radioClient.search(q)) {
            is Failed -> StationResponse("failed", emptyList())
            is Success -> result.data
        }
    }
}