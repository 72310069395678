package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.Trending: ImageVector
    get() {
        if (_Trending != null) {
            return _Trending!!
        }
        _Trending = ImageVector.Builder(
            name = "Trending",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF))) {
                moveTo(3.4f, 18f)
                lineTo(2f, 16.6f)
                lineToRelative(7.4f, -7.45f)
                lineToRelative(4f, 4f)
                lineTo(18.6f, 8f)
                horizontalLineTo(16f)
                verticalLineTo(6f)
                horizontalLineToRelative(6f)
                verticalLineToRelative(6f)
                horizontalLineToRelative(-2f)
                verticalLineTo(9.4f)
                lineTo(13.4f, 16f)
                lineToRelative(-4f, -4f)
                lineToRelative(-6f, 6f)
                close()
            }
        }.build()

        return _Trending!!
    }

@Suppress("ObjectPropertyName")
private var _Trending: ImageVector? = null
