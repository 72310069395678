package studio.goodegg.capsule.domain.settings

import me.tatarka.inject.annotations.Component
import me.tatarka.inject.annotations.Provides
import studio.goodegg.capsule.preferences.Preferences

@Component
interface SettingsDomainComponent {
    @Provides
    fun providesObserveStringPreference(preferences: Preferences): ObserveStringPreferenceInteractor =
        ObserveStringPreferenceInteractor { key ->
            preferences.observe(key)
        }

    @Provides
    fun providesPutStringPreference(preferences: Preferences): PutStringPreferenceInteractor =
        PutStringPreferenceInteractor { key, value ->
            preferences.put(key, value)
        }


    @Provides
    fun providesGetStringPreference(preferences: Preferences): GetStringPreferenceInteractor =
        GetStringPreferenceInteractor { key ->
            preferences.getString(key)
        }

    @Provides
    fun providesPutBooleanPreference(preferences: Preferences): PutBooleanPreferenceInteractor =
        PutBooleanPreferenceInteractor { key, value ->
            preferences.put(key, value)
        }


    @Provides
    fun providesGetBooleanPreference(preferences: Preferences): GetBooleanPreferenceInteractor =
        GetBooleanPreferenceInteractor { key ->
            preferences.getBoolean(key)
        }
}
