package io.daio.pancake.components.text

import androidx.compose.foundation.text.selection.LocalTextSelectionColors
import androidx.compose.material3.TextFieldColors
import androidx.compose.material3.TextFieldDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.ui.graphics.Color
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.color.contentColorFor
import io.daio.pancake.foundations.color.disabled

object TextAreaDefaults {
    @Composable
    fun colors(
        backgroundColor: Color = Theme.colors.surfaceContainer,
        textColor: Color = contentColorFor(backgroundColor),
    ): TextAreaColors =
        TextAreaColors(
            focusedTextColor = textColor,
            unfocusedTextColor = textColor,
            disabledTextColor = textColor.disabled(),
            errorTextColor = Theme.colors.negative,
            focusedContainerColor = backgroundColor,
            unfocusedContainerColor = backgroundColor,
            disabledContainerColor = backgroundColor,
            errorContainerColor = backgroundColor,
            cursorColor = textColor,
            errorCursorColor = Theme.colors.negative,
            focusedIndicatorColor = Color.Transparent,
            unfocusedIndicatorColor = Color.Transparent,
            disabledIndicatorColor = Color.Transparent,
            errorIndicatorColor = Color.Transparent,
            focusedLeadingIconColor = textColor,
            unfocusedLeadingIconColor = textColor,
            disabledLeadingIconColor = textColor.disabled(),
            errorLeadingIconColor = Theme.colors.negative,
            focusedTrailingIconColor = textColor,
            unfocusedTrailingIconColor = textColor,
            disabledTrailingIconColor = textColor.disabled(),
            errorTrailingIconColor = Theme.colors.negative,
            focusedLabelColor = textColor.copy(alpha = .4f),
            unfocusedLabelColor = textColor.copy(alpha = .4f),
            disabledLabelColor = textColor.disabled(),
            errorLabelColor = Theme.colors.negative.disabled(),
            focusedPlaceholderColor = textColor.copy(alpha = .4f),
            unfocusedPlaceholderColor = textColor.copy(alpha = .4f),
            disabledPlaceholderColor = textColor.disabled(),
            errorPlaceholderColor = Theme.colors.negative,
            focusedSupportingTextColor = textColor,
            unfocusedSupportingTextColor = textColor,
            disabledSupportingTextColor = textColor.disabled(),
            errorSupportingTextColor = Theme.colors.negative,
        )
}

@Immutable
data class TextAreaColors(
    val focusedTextColor: Color,
    val unfocusedTextColor: Color,
    val disabledTextColor: Color,
    val errorTextColor: Color,
    val focusedContainerColor: Color,
    val unfocusedContainerColor: Color,
    val disabledContainerColor: Color,
    val errorContainerColor: Color,
    val cursorColor: Color,
    val errorCursorColor: Color,
    val focusedIndicatorColor: Color,
    val unfocusedIndicatorColor: Color,
    val disabledIndicatorColor: Color,
    val errorIndicatorColor: Color,
    val focusedLeadingIconColor: Color,
    val unfocusedLeadingIconColor: Color,
    val disabledLeadingIconColor: Color,
    val errorLeadingIconColor: Color,
    val focusedTrailingIconColor: Color,
    val unfocusedTrailingIconColor: Color,
    val disabledTrailingIconColor: Color,
    val errorTrailingIconColor: Color,
    val focusedLabelColor: Color,
    val unfocusedLabelColor: Color,
    val disabledLabelColor: Color,
    val errorLabelColor: Color,
    val focusedPlaceholderColor: Color,
    val unfocusedPlaceholderColor: Color,
    val disabledPlaceholderColor: Color,
    val errorPlaceholderColor: Color,
    val focusedSupportingTextColor: Color,
    val unfocusedSupportingTextColor: Color,
    val errorSupportingTextColor: Color,
    val disabledSupportingTextColor: Color,
)

@Composable
internal fun TextAreaColors.toTextFieldColors(): TextFieldColors =
    TextFieldDefaults.colors(
        focusedTextColor = focusedTextColor,
        unfocusedTextColor = unfocusedTextColor,
        disabledTextColor = disabledTextColor,
        errorTextColor = errorTextColor,
        focusedContainerColor = focusedContainerColor,
        unfocusedContainerColor = unfocusedContainerColor,
        disabledContainerColor = disabledContainerColor,
        errorContainerColor = errorContainerColor,
        cursorColor = cursorColor,
        errorCursorColor = errorCursorColor,
        selectionColors = LocalTextSelectionColors.current,
        focusedIndicatorColor = focusedIndicatorColor,
        unfocusedIndicatorColor = unfocusedIndicatorColor,
        disabledIndicatorColor = disabledIndicatorColor,
        errorIndicatorColor = errorIndicatorColor,
        focusedLeadingIconColor = focusedLeadingIconColor,
        unfocusedLeadingIconColor = unfocusedLeadingIconColor,
        disabledLeadingIconColor = disabledLeadingIconColor,
        errorLeadingIconColor = errorLeadingIconColor,
        focusedTrailingIconColor = focusedTrailingIconColor,
        unfocusedTrailingIconColor = unfocusedTrailingIconColor,
        disabledTrailingIconColor = disabledTrailingIconColor,
        errorTrailingIconColor = errorTrailingIconColor,
        focusedLabelColor = focusedLabelColor,
        unfocusedLabelColor = unfocusedLabelColor,
        disabledLabelColor = disabledLabelColor,
        errorLabelColor = errorLabelColor,
        focusedPlaceholderColor = focusedPlaceholderColor,
        unfocusedPlaceholderColor = unfocusedPlaceholderColor,
        disabledPlaceholderColor = disabledPlaceholderColor,
        errorPlaceholderColor = errorPlaceholderColor,
        focusedSupportingTextColor = focusedSupportingTextColor,
        unfocusedSupportingTextColor = unfocusedSupportingTextColor,
        disabledSupportingTextColor = disabledSupportingTextColor,
    )

fun TextAreaColors.textColor(focused: Boolean, error: Boolean, enabled: Boolean): Color {
    return when {
        !enabled -> disabledTextColor
        error -> errorTextColor
        focused -> focusedTextColor
        else -> unfocusedTextColor
    }
}

fun TextAreaColors.containerColor(focused: Boolean, error: Boolean, enabled: Boolean): Color {
    return when {
        !enabled -> disabledContainerColor
        error -> errorContainerColor
        focused -> focusedContainerColor
        else -> unfocusedContainerColor
    }
}

fun TextAreaColors.cursorColor(error: Boolean): Color {
    return if (error) errorCursorColor else cursorColor
}

fun TextAreaColors.indicatorColor(focused: Boolean, error: Boolean, enabled: Boolean): Color {
    return when {
        !enabled -> disabledIndicatorColor
        error -> errorIndicatorColor
        focused -> focusedIndicatorColor
        else -> unfocusedIndicatorColor
    }
}

fun TextAreaColors.leadingIconColor(focused: Boolean, error: Boolean, enabled: Boolean): Color {
    return when {
        !enabled -> disabledLeadingIconColor
        error -> errorLeadingIconColor
        focused -> focusedLeadingIconColor
        else -> unfocusedLeadingIconColor
    }
}

fun TextAreaColors.trailingIconColor(focused: Boolean, error: Boolean, enabled: Boolean): Color {
    return when {
        !enabled -> disabledTrailingIconColor
        error -> errorTrailingIconColor
        focused -> focusedTrailingIconColor
        else -> unfocusedTrailingIconColor
    }
}

fun TextAreaColors.labelColor(focused: Boolean, error: Boolean, enabled: Boolean): Color {
    return when {
        !enabled -> disabledLabelColor
        error -> errorLabelColor
        focused -> focusedLabelColor
        else -> unfocusedLabelColor
    }
}

fun TextAreaColors.placeholderColor(focused: Boolean, error: Boolean, enabled: Boolean): Color {
    return when {
        !enabled -> disabledPlaceholderColor
        error -> errorPlaceholderColor
        focused -> focusedPlaceholderColor
        else -> unfocusedPlaceholderColor
    }
}

fun TextAreaColors.supportingTextColor(focused: Boolean, error: Boolean, enabled: Boolean): Color {
    return when {
        !enabled -> disabledSupportingTextColor
        error -> errorSupportingTextColor
        focused -> focusedSupportingTextColor
        else -> unfocusedSupportingTextColor
    }
}