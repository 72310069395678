package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.Podcast: ImageVector
    get() {
        if (_Podcast != null) {
            return _Podcast!!
        }
        _Podcast = ImageVector.Builder(
            name = "Podcast",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(fill = SolidColor(Color(0xFFE8EAED))) {
                moveTo(11f, 22f)
                verticalLineToRelative(-8.275f)
                curveToRelative(-0.3f, -0.183f, -0.542f, -0.42f, -0.725f, -0.713f)
                arcTo(1.863f, 1.863f, 0f, isMoreThanHalf = false, isPositiveArc = true, 10f, 12f)
                curveToRelative(0f, -0.55f, 0.196f, -1.02f, 0.588f, -1.412f)
                arcTo(1.926f, 1.926f, 0f, isMoreThanHalf = false, isPositiveArc = true, 12f, 10f)
                curveToRelative(0.55f, 0f, 1.02f, 0.196f, 1.412f, 0.588f)
                curveToRelative(0.392f, 0.391f, 0.588f, 0.862f, 0.588f, 1.412f)
                arcToRelative(1.93f, 1.93f, 0f, isMoreThanHalf = false, isPositiveArc = true, -1f, 1.725f)
                lineTo(13f, 22f)
                horizontalLineToRelative(-2f)
                close()
                moveTo(5.1f, 19.25f)
                arcToRelative(10.347f, 10.347f, 0f, isMoreThanHalf = false, isPositiveArc = true, -2.262f, -3.238f)
                curveTo(2.278f, 14.771f, 2f, 13.433f, 2f, 12f)
                arcToRelative(9.74f, 9.74f, 0f, isMoreThanHalf = false, isPositiveArc = true, 0.788f, -3.9f)
                arcToRelative(10.099f, 10.099f, 0f, isMoreThanHalf = false, isPositiveArc = true, 2.137f, -3.175f)
                curveToRelative(0.9f, -0.9f, 1.958f, -1.612f, 3.175f, -2.137f)
                arcTo(9.738f, 9.738f, 0f, isMoreThanHalf = false, isPositiveArc = true, 12f, 2f)
                arcToRelative(9.74f, 9.74f, 0f, isMoreThanHalf = false, isPositiveArc = true, 3.9f, 0.788f)
                arcToRelative(10.098f, 10.098f, 0f, isMoreThanHalf = false, isPositiveArc = true, 3.175f, 2.137f)
                curveToRelative(0.9f, 0.9f, 1.613f, 1.958f, 2.137f, 3.175f)
                arcTo(9.738f, 9.738f, 0f, isMoreThanHalf = false, isPositiveArc = true, 22f, 12f)
                curveToRelative(0f, 1.433f, -0.28f, 2.775f, -0.837f, 4.025f)
                arcTo(10.013f, 10.013f, 0f, isMoreThanHalf = false, isPositiveArc = true, 18.9f, 19.25f)
                lineToRelative(-1.4f, -1.4f)
                arcToRelative(8.193f, 8.193f, 0f, isMoreThanHalf = false, isPositiveArc = false, 1.825f, -2.613f)
                arcTo(7.853f, 7.853f, 0f, isMoreThanHalf = false, isPositiveArc = false, 20f, 12f)
                curveToRelative(0f, -2.233f, -0.775f, -4.125f, -2.325f, -5.675f)
                curveTo(16.125f, 4.775f, 14.233f, 4f, 12f, 4f)
                curveToRelative(-2.233f, 0f, -4.125f, 0.775f, -5.675f, 2.325f)
                curveTo(4.775f, 7.875f, 4f, 9.767f, 4f, 12f)
                curveToRelative(0f, 1.15f, 0.225f, 2.225f, 0.675f, 3.225f)
                curveToRelative(0.45f, 1f, 1.067f, 1.867f, 1.85f, 2.6f)
                lineTo(5.1f, 19.25f)
                close()
                moveTo(7.925f, 16.425f)
                arcToRelative(6.288f, 6.288f, 0f, isMoreThanHalf = false, isPositiveArc = true, -1.4f, -1.963f)
                arcTo(5.804f, 5.804f, 0f, isMoreThanHalf = false, isPositiveArc = true, 6f, 12f)
                curveToRelative(0f, -1.667f, 0.583f, -3.083f, 1.75f, -4.25f)
                curveTo(8.917f, 6.583f, 10.333f, 6f, 12f, 6f)
                curveToRelative(1.667f, 0f, 3.083f, 0.583f, 4.25f, 1.75f)
                curveTo(17.417f, 8.917f, 18f, 10.333f, 18f, 12f)
                arcToRelative(5.89f, 5.89f, 0f, isMoreThanHalf = false, isPositiveArc = true, -0.525f, 2.475f)
                arcToRelative(5.958f, 5.958f, 0f, isMoreThanHalf = false, isPositiveArc = true, -1.4f, 1.95f)
                lineTo(14.65f, 15f)
                arcToRelative(4.28f, 4.28f, 0f, isMoreThanHalf = false, isPositiveArc = false, 0.987f, -1.35f)
                arcTo(3.847f, 3.847f, 0f, isMoreThanHalf = false, isPositiveArc = false, 16f, 12f)
                curveToRelative(0f, -1.1f, -0.392f, -2.042f, -1.175f, -2.825f)
                curveTo(14.042f, 8.392f, 13.1f, 8f, 12f, 8f)
                reflectiveCurveToRelative(-2.042f, 0.392f, -2.825f, 1.175f)
                curveTo(8.392f, 9.958f, 8f, 10.9f, 8f, 12f)
                curveToRelative(0f, 0.6f, 0.12f, 1.154f, 0.363f, 1.662f)
                curveToRelative(0.241f, 0.509f, 0.57f, 0.955f, 0.987f, 1.338f)
                lineToRelative(-1.425f, 1.425f)
                close()
            }
        }.build()

        return _Podcast!!
    }

@Suppress("ObjectPropertyName")
private var _Podcast: ImageVector? = null
