package studio.goodegg.capsule

sealed interface PlayState {
    data object Idle : PlayState

    data object Playing : PlayState

    data object Paused : PlayState

    data object Stopped : PlayState

    data object Error : PlayState

    data object Buffering : PlayState
}

val PlayState?.isPlaying: Boolean
    get() = when (this) {
        PlayState.Buffering, PlayState.Playing -> true
        else -> false
    }

val PlayState?.isInProgress: Boolean
    get() = isPlaying || this == PlayState.Paused

// TODO store the parent slug so we can query for all played by parent.
data class PlayedItem(
    val slug: String,
    val duration: Long,
    val currentPosition: Long,
    val lastPlayed: Long,
    val playedCount: Int,
)
