package studio.goodegg.capsule.base.main

import com.slack.circuit.foundation.Circuit
import me.tatarka.inject.annotations.Inject
import me.tatarka.inject.annotations.Provides
import studio.goodegg.capsule.categories.CategoriesPresenterFactory
import studio.goodegg.capsule.categories.CategoriesScreenUiFactory
import studio.goodegg.capsule.common.di.ActivityScope
import studio.goodegg.capsule.details.episode.EpisodeDetailsPresenterFactory
import studio.goodegg.capsule.details.episode.EpisodeDetailsScreenUiFactory
import studio.goodegg.capsule.details.podcast.PodcastDetailsPresenterFactory
import studio.goodegg.capsule.details.podcast.PodcastDetailsScreenUiFactory
import studio.goodegg.capsule.domain.auth.AuthDomainComponent
import studio.goodegg.capsule.domain.download.DownloadDomainComponent
import studio.goodegg.capsule.domain.playback.PlaybackDomainComponent
import studio.goodegg.capsule.domain.podcast.PodcastDomainComponent
import studio.goodegg.capsule.domain.search.SearchDomainComponent
import studio.goodegg.capsule.domain.settings.SettingsDomainComponent
import studio.goodegg.capsule.domain.share.ShareDomainComponent
import studio.goodegg.capsule.domain.subscription.SubscriptionDomainComponent
import studio.goodegg.capsule.domain.trending.TrendingDomainComponent
import studio.goodegg.capsule.downloads.DownloadsPresenterFactory
import studio.goodegg.capsule.downloads.DownloadsScreenUiFactory
import studio.goodegg.capsule.genres.GenrePresenterFactory
import studio.goodegg.capsule.genres.GenreScreenUiFactory
import studio.goodegg.capsule.inbox.InboxPresenterFactory
import studio.goodegg.capsule.inbox.InboxScreenUiFactory
import studio.goodegg.capsule.listen.ListenPresenterFactory
import studio.goodegg.capsule.listen.ListenScreenUiFactory
import studio.goodegg.capsule.onboarding.OnboardingPresenterFactory
import studio.goodegg.capsule.onboarding.OnboardingScreenUiFactory
import studio.goodegg.capsule.player.PlayerPresenterFactory
import studio.goodegg.capsule.player.PlayerScreenUiFactory
import studio.goodegg.capsule.playqueue.PlayQueuePresenterFactory
import studio.goodegg.capsule.playqueue.PlayQueueScreenUiFactory
import studio.goodegg.capsule.resume.ResumePresenterFactory
import studio.goodegg.capsule.resume.ResumeScreenUiFactory
import studio.goodegg.capsule.search.SearchPresenterFactory
import studio.goodegg.capsule.search.SearchScreenUiFactory
import studio.goodegg.capsule.settings.SettingsPresenterFactory
import studio.goodegg.capsule.settings.SettingsScreenUiFactory
import studio.goodegg.capsule.signin.SignInPresenterFactory
import studio.goodegg.capsule.signin.SignInScreenUiFactory
import studio.goodegg.capsule.subscriptions.SubscriptionsPresenterFactory
import studio.goodegg.capsule.subscriptions.SubscriptionsScreenUiFactory
import studio.goodegg.capsule.trending.TrendingPresenterFactory
import studio.goodegg.capsule.trending.TrendingScreenUiFactory

interface SharedUiComponent :
    SubscriptionDomainComponent,
    TrendingDomainComponent,
    SettingsDomainComponent,
    SearchDomainComponent,
    DownloadDomainComponent,
    AuthDomainComponent,
    ShareDomainComponent,
    PodcastDomainComponent,
    PlaybackDomainComponent {
    @Inject
    @Provides
    @ActivityScope
    fun providesCircuit(
        inboxPresenterFactory: InboxPresenterFactory,
        onboardingPresenterFactory: OnboardingPresenterFactory,
        trendingPresenterFactory: TrendingPresenterFactory,
        subscriptionsPresenterFactory: SubscriptionsPresenterFactory,
        playerPresenterFactory: PlayerPresenterFactory,
        listenPresenterFactory: ListenPresenterFactory,
        podcastDetailsPresenterFactory: PodcastDetailsPresenterFactory,
        resumePresenterFactory: ResumePresenterFactory,
        searchPresenterFactory: SearchPresenterFactory,
        signInPresenterFactory: SignInPresenterFactory,
        downloadsPresenterFactory: DownloadsPresenterFactory,
        episodeDetailsPresenterFactory: EpisodeDetailsPresenterFactory,
        genrePresenterFactory: GenrePresenterFactory,
        categoriesPresenterFactory: CategoriesPresenterFactory,
        splashPresenterFactory: SplashPresenterFactory,
        settingsPresenterFactory: SettingsPresenterFactory,
        playQueuePresenterFactory: PlayQueuePresenterFactory,
    ): Circuit {
        val circuit =
            Circuit.Builder()
                .addUiFactories(
                    listOf(
                        InboxScreenUiFactory(),
                        OnboardingScreenUiFactory(),
                        SignInScreenUiFactory(),
                        SubscriptionsScreenUiFactory(),
                        TrendingScreenUiFactory(),
                        PodcastDetailsScreenUiFactory(),
                        ListenScreenUiFactory(),
                        SearchScreenUiFactory(),
                        PlayerScreenUiFactory(),
                        ResumeScreenUiFactory(),
                        DownloadsScreenUiFactory(),
                        EpisodeDetailsScreenUiFactory(),
                        GenreScreenUiFactory(),
                        CategoriesScreenUiFactory(),
                        SplashScreenUiFactory(),
                        SettingsScreenUiFactory(),
                        PlayQueueScreenUiFactory(),
                    ),
                )
                .addPresenterFactories(
                    listOf(
                        inboxPresenterFactory,
                        onboardingPresenterFactory,
                        signInPresenterFactory,
                        trendingPresenterFactory,
                        subscriptionsPresenterFactory,
                        playerPresenterFactory,
                        listenPresenterFactory,
                        podcastDetailsPresenterFactory,
                        searchPresenterFactory,
                        resumePresenterFactory,
                        downloadsPresenterFactory,
                        episodeDetailsPresenterFactory,
                        genrePresenterFactory,
                        categoriesPresenterFactory,
                        splashPresenterFactory,
                        settingsPresenterFactory,
                        playQueuePresenterFactory,
                    ),
                )
                .build()

        return circuit
    }
}
