package studio.goodegg.capsule.ui

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Logos.CapsuleTop: ImageVector
    get() {
        if (_CapsuleTop != null) {
            return _CapsuleTop!!
        }
        _CapsuleTop = ImageVector.Builder(
            name = "CapsuleTop",
            defaultWidth = 258.dp,
            defaultHeight = 258.dp,
            viewportWidth = 258f,
            viewportHeight = 258f
        ).apply {
            path(
                fill = SolidColor(Color(0xFFA32323)),
                fillAlpha = 0.7f,
                pathFillType = PathFillType.EvenOdd
            ) {
                moveTo(4f, 225f)
                verticalLineTo(200f)
                horizontalLineTo(135.25f)
                curveTo(142.15f, 200f, 147.75f, 194.4f, 147.75f, 187.5f)
                curveTo(147.75f, 180.6f, 142.15f, 175f, 135.25f, 175f)
                horizontalLineTo(4f)
                verticalLineTo(150f)
                horizontalLineTo(135.25f)
                curveTo(142.15f, 150f, 147.75f, 144.4f, 147.75f, 137.5f)
                curveTo(147.75f, 130.6f, 142.15f, 125f, 135.25f, 125f)
                horizontalLineTo(4f)
                curveTo(4f, 116.44f, 4.86f, 108.08f, 6.5f, 100f)
                horizontalLineTo(135.25f)
                curveTo(142.15f, 100f, 147.75f, 94.4f, 147.75f, 87.5f)
                curveTo(147.75f, 80.6f, 142.15f, 75f, 135.25f, 75f)
                horizontalLineTo(14.4f)
                curveTo(33.69f, 30.85f, 77.74f, 0f, 129f, 0f)
                curveTo(180.26f, 0f, 224.31f, 30.85f, 243.6f, 75f)
                horizontalLineTo(197.75f)
                curveTo(190.85f, 75f, 185.25f, 80.6f, 185.25f, 87.5f)
                curveTo(185.25f, 94.4f, 190.85f, 100f, 197.75f, 100f)
                horizontalLineTo(251.5f)
                curveTo(253.14f, 108.08f, 254f, 116.44f, 254f, 125f)
                horizontalLineTo(197.75f)
                curveTo(190.85f, 125f, 185.25f, 130.6f, 185.25f, 137.5f)
                curveTo(185.25f, 144.4f, 190.85f, 150f, 197.75f, 150f)
                horizontalLineTo(254f)
                verticalLineTo(175f)
                horizontalLineTo(197.75f)
                curveTo(190.85f, 175f, 185.25f, 180.6f, 185.25f, 187.5f)
                curveTo(185.25f, 194.4f, 190.85f, 200f, 197.75f, 200f)
                horizontalLineTo(254f)
                verticalLineTo(237.5f)
                verticalLineTo(225f)
                horizontalLineTo(197.75f)
                curveTo(190.85f, 225f, 185.25f, 230.6f, 185.25f, 237.5f)
                curveTo(185.25f, 244.4f, 190.85f, 250f, 197.75f, 250f)
                horizontalLineTo(254f)
                horizontalLineTo(135.25f)
                curveTo(142.15f, 250f, 147.75f, 244.4f, 147.75f, 237.5f)
                curveTo(147.75f, 230.6f, 142.15f, 225f, 135.25f, 225f)
                horizontalLineTo(4f)
                close()
            }
        }.build()

        return _CapsuleTop!!
    }

@Suppress("ObjectPropertyName")
private var _CapsuleTop: ImageVector? = null
