package studio.goodegg.capsule.ui.components

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.defaultMinSize
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.unit.dp
import io.daio.pancake.components.buttons.Button
import io.daio.pancake.components.buttons.ButtonDefaults
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyTextLarge
import io.daio.pancake.foundations.text.BodyTextSmall
import io.daio.pancake.foundations.text.TitleText
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Tier
import io.daio.wild.container.Container
import io.daio.wild.content.LocalContentColor
import io.daio.wild.style.Border
import io.daio.wild.style.StyleDefaults

// TODO move to pancake
@Composable
fun IconTextButton(
    icon: ImageVector,
    text: String,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    enabled: Boolean = true,
) {
    Container(
        onClick = onClick,
        enabled = enabled,
        modifier = modifier.height(32.dp),
        style = StyleDefaults.style(
            shapes = StyleDefaults.shapes(Theme.shapes.circle),
            colors =
                StyleDefaults.colors(
                    contentColor = Theme.colors.onSurfaceBackground,
                    backgroundColor = Theme.colors.surfaceBackground,
                    focusedBackgroundColor = Theme.colors.surfaceInteractive,
                    focusedContentColor = Theme.colors.onSurfaceInteractive,
                    pressedBackgroundColor = Theme.colors.surfaceInteractive.copy(alpha = .1f),
                ),
            borders = StyleDefaults.borders(
                Border(
                    1.dp,
                    Theme.colors.surfaceInteractive,
                    shape = Theme.shapes.circle,
                ),
            ),
        ),
    ) {
        Tier(
            spaceBetween = LayoutGap.Tiny,
            modifier = Modifier.padding(horizontal = Theme.dimens.size_m),
        ) {
            Icon(
                imageVector = icon,
                modifier = Modifier.size(14.dp),
                tint = LocalContentColor.current,
                contentDescription = "",
            )
            BodyTextSmall(text, textColor = LocalContentColor.current)
        }
    }
}

@Composable
fun OutlineIconButton(
    icon: ImageVector,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
) {
    Container(
        onClick = onClick,
        modifier = modifier.size(32.dp),
        style = StyleDefaults.style(
            shapes = StyleDefaults.shapes(Theme.shapes.circle),
            colors =
                StyleDefaults.colors(
                    contentColor = Theme.colors.onSurfaceBackground,
                    backgroundColor = Theme.colors.surfaceBackground,
                    focusedBackgroundColor = Theme.colors.surfaceInteractive,
                    focusedContentColor = Theme.colors.onSurfaceInteractive,
                    pressedBackgroundColor = Theme.colors.surfaceInteractive.copy(alpha = .1f),
                ),
            borders = StyleDefaults.borders(
                Border(
                    1.dp,
                    Theme.colors.surfaceInteractive,
                    shape = Theme.shapes.circle,
                ),
            ),
        ),
    ) {
        Icon(
            imageVector = icon,
            modifier = Modifier.padding(Theme.dimens.size_s)
                .fillMaxSize(),
            tint = LocalContentColor.current,
            contentDescription = "",
        )
    }
}

@Composable
fun AccentButton(
    text: String,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    enabled: Boolean = true,
) {
    Button(
        onClick = onClick,
        enabled = enabled,
        modifier = modifier.height(32.dp),
        buttonColors = ButtonDefaults.colors(
            backgroundColor = Theme.colors.accent,
            contentColor = Theme.colors.onAccent,
        ),
        contentPadding = PaddingValues(horizontal = 34.dp),
    ) {
        TitleText(text, textColor = LocalContentColor.current)
    }
}


@Composable
fun OutlineButton(
    text: String,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    enabled: Boolean = true,
) {
    Container(
        onClick = onClick,
        enabled = enabled,
        modifier = modifier.height(32.dp),
        style = StyleDefaults.style(
            StyleDefaults.colors(
                backgroundColor = Color.Transparent,
                contentColor = Theme.colors.onSurfaceBackground,
                focusedContentColor = Theme.colors.onSurfaceInteractive,
                focusedBackgroundColor = Theme.colors.surfaceInteractive,
                pressedBackgroundColor = Theme.colors.surfaceInteractive,
                pressedContentColor = Theme.colors.onSurfaceInteractive,
            ),
            scale = StyleDefaults.scale(
                focusedScale = 1.025f,
            ),
            shapes = StyleDefaults.shapes(Theme.shapes.circle),
            borders = StyleDefaults.borders(Border(2.dp, Theme.colors.surfaceInteractive)),
        ),
    ) {
        Row(
            Modifier.defaultMinSize(minWidth = 58.dp)
                .padding(PaddingValues(horizontal = 34.dp)),
            horizontalArrangement = Arrangement.Center,
            verticalAlignment = Alignment.CenterVertically,
        ) {
            TitleText(text, textColor = LocalContentColor.current)
        }
    }
}