package studio.goodegg.capsule.genres

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.text.intl.Locale
import com.slack.circuit.retained.collectAsRetainedState
import com.slack.circuit.retained.produceRetainedState
import com.slack.circuit.retained.rememberRetained
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.Navigator
import com.slack.circuit.runtime.presenter.Presenter
import com.slack.circuit.runtime.screen.Screen
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.PodcastResult
import studio.goodegg.capsule.PodcastSubscription
import studio.goodegg.capsule.domain.search.SearchGenresInteractor
import studio.goodegg.capsule.domain.subscription.AddSubscriptionInteractor
import studio.goodegg.capsule.domain.subscription.DeleteSubscriptionInteractor
import studio.goodegg.capsule.domain.subscription.ObserveAllSubscriptionsInteractor
import studio.goodegg.capsule.navigation.GenrePodcastsGridScreen
import studio.goodegg.capsule.navigation.PodcastDetailsScreen

@Inject
class GenrePresenterFactory(
    private val searchGenreInteractor: SearchGenresInteractor,
    private val addSubscriptionInteractor: AddSubscriptionInteractor,
    private val observeAllSubscriptionsInteractor: ObserveAllSubscriptionsInteractor,
    private val deleteSubscriptionInteractor: DeleteSubscriptionInteractor,
) : Presenter.Factory {
    override fun create(
        screen: Screen,
        navigator: Navigator,
        context: CircuitContext,
    ): Presenter<*>? {
        return when (screen) {
            is GenrePodcastsGridScreen ->
                GenrePresenter(
                    screen.genreId,
                    screen.title,
                    navigator,
                    searchGenreInteractor,
                    addSubscriptionInteractor,
                    observeAllSubscriptionsInteractor,
                    deleteSubscriptionInteractor,
                )

            else -> null
        }
    }
}

@Inject
class GenrePresenter(
    private val genreId: String,
    private val title: String,
    private val navigator: Navigator,
    private val searchGenreInteractor: SearchGenresInteractor,
    private val addToSubscriptions: AddSubscriptionInteractor,
    private val observeAllSubscriptionsInteractor: ObserveAllSubscriptionsInteractor,
    private val deleteSubscriptionInteractor: DeleteSubscriptionInteractor,
) : Presenter<GenreUiState> {
    @Composable
    override fun present(): GenreUiState {
        val scope = rememberCoroutineScope()

        val subscriptions by rememberRetained {
            observeAllSubscriptionsInteractor()
                .map { subscriptions ->
                    subscriptions.map { it.slug }.toSet()
                }
        }.collectAsRetainedState(emptySet())

        fun events(event: GenreUiEvent) {
            when (event) {
                is GenreUiEvent.OpenPodcast -> {
                    navigator.goTo(PodcastDetailsScreen(event.slug))
                }

                is GenreUiEvent.AddToSubscriptions -> {
                    scope.launch {
                        if (subscriptions.contains(event.podcast.slug)) {
                            deleteSubscriptionInteractor(event.podcast.slug)
                        } else {
                            addToSubscriptions(event.podcast.toSubscription())
                        }
                    }
                }

                GenreUiEvent.Back -> navigator.pop()
            }
        }

        var loading by remember { mutableStateOf(false) }

        val podcasts by produceRetainedState<List<PodcastResult>>(
            emptyList(),
        ) {
            loading = true
            val genre = searchGenreInteractor.invoke(genreId, Locale.current.region)
//            value = genre.subList(0, 1)
//            // TODO just a short delay to make sure the transition is completed first to avoid jank.
//            delay(300)
            value = genre
            loading = false
        }

        return GenreUiState(
            refreshing = loading,
            subscriptions = subscriptions,
            items = podcasts,
            title = title,
            eventSink = ::events,
        )
    }
}

private fun PodcastResult.toSubscription(): PodcastSubscription {
    return PodcastSubscription(
        slug = slug,
        feedUrl = feed,
        title = title,
        artist = author,
        image = imageUrl,
        notify = true,
        collection = null,
        autoDownload = false,
    )
}
