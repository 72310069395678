package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.Download: ImageVector
    get() {
        if (_Download != null) {
            return _Download!!
        }
        _Download = ImageVector.Builder(
            name = "Download",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF))) {
                moveToRelative(12f, 16f)
                lineToRelative(-5f, -5f)
                lineToRelative(1.4f, -1.45f)
                lineToRelative(2.6f, 2.6f)
                lineTo(11f, 4f)
                horizontalLineToRelative(2f)
                verticalLineToRelative(8.15f)
                lineToRelative(2.6f, -2.6f)
                lineTo(17f, 11f)
                lineToRelative(-5f, 5f)
                close()
                moveTo(6f, 20f)
                curveToRelative(-0.55f, 0f, -1.02f, -0.196f, -1.412f, -0.587f)
                arcTo(1.926f, 1.926f, 0f, isMoreThanHalf = false, isPositiveArc = true, 4f, 18f)
                verticalLineToRelative(-3f)
                horizontalLineToRelative(2f)
                verticalLineToRelative(3f)
                horizontalLineToRelative(12f)
                verticalLineToRelative(-3f)
                horizontalLineToRelative(2f)
                verticalLineToRelative(3f)
                curveToRelative(0f, 0.55f, -0.196f, 1.02f, -0.587f, 1.413f)
                arcTo(1.926f, 1.926f, 0f, isMoreThanHalf = false, isPositiveArc = true, 18f, 20f)
                lineTo(6f, 20f)
                close()
            }
        }.build()

        return _Download!!
    }

@Suppress("ObjectPropertyName")
private var _Download: ImageVector? = null
