package studio.goodegg.capsule.trending

import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import com.slack.circuit.runtime.CircuitUiState
import kotlinx.coroutines.flow.Flow
import studio.goodegg.capsule.TrendingResult
import studio.goodegg.capsule.TrendingRow
import studio.goodegg.capsule.domain.playback.EpisodeAndPosition

@Immutable
data class TrendingUiState(
    val refreshing: Boolean,
    val subscriptions: Set<String>,
    val items: TrendingResult,
    val inProgress: @Composable () -> List<EpisodeAndPosition>,
    val eventSink: (TrendingUiEvent) -> Unit,
) : CircuitUiState
