package studio.goodegg.capsule.downloader

import me.tatarka.inject.annotations.Component
import me.tatarka.inject.annotations.Provides
import studio.goodegg.capsule.common.di.ApplicationScope

@Component
interface DownloaderComponent {
    @Provides
    @ApplicationScope
    fun providesDownloader(downloader: DownloaderImpl): Downloader = downloader
}
