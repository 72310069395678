package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.ProfileFilled: ImageVector
    get() {
        if (_ProfileFilled != null) {
            return _ProfileFilled!!
        }
        _ProfileFilled = ImageVector.Builder(
            name = "ProfileFilled",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF))) {
                moveTo(12f, 12f)
                curveToRelative(-1.1f, 0f, -2.042f, -0.392f, -2.825f, -1.175f)
                curveTo(8.392f, 10.042f, 8f, 9.1f, 8f, 8f)
                reflectiveCurveToRelative(0.392f, -2.042f, 1.175f, -2.825f)
                curveTo(9.958f, 4.392f, 10.9f, 4f, 12f, 4f)
                reflectiveCurveToRelative(2.042f, 0.392f, 2.825f, 1.175f)
                curveTo(15.608f, 5.958f, 16f, 6.9f, 16f, 8f)
                reflectiveCurveToRelative(-0.392f, 2.042f, -1.175f, 2.825f)
                curveTo(14.042f, 11.608f, 13.1f, 12f, 12f, 12f)
                close()
                moveTo(4f, 20f)
                verticalLineToRelative(-2.8f)
                curveToRelative(0f, -0.567f, 0.146f, -1.087f, 0.438f, -1.563f)
                arcTo(2.911f, 2.911f, 0f, isMoreThanHalf = false, isPositiveArc = true, 5.6f, 14.55f)
                arcToRelative(14.843f, 14.843f, 0f, isMoreThanHalf = false, isPositiveArc = true, 3.15f, -1.163f)
                arcTo(13.76f, 13.76f, 0f, isMoreThanHalf = false, isPositiveArc = true, 12f, 13f)
                curveToRelative(1.1f, 0f, 2.183f, 0.13f, 3.25f, 0.387f)
                curveToRelative(1.067f, 0.259f, 2.117f, 0.646f, 3.15f, 1.163f)
                curveToRelative(0.483f, 0.25f, 0.87f, 0.612f, 1.163f, 1.087f)
                curveToRelative(0.291f, 0.476f, 0.437f, 0.996f, 0.437f, 1.563f)
                lineTo(20f, 20f)
                lineTo(4f, 20f)
                close()
            }
        }.build()

        return _ProfileFilled!!
    }

@Suppress("ObjectPropertyName")
private var _ProfileFilled: ImageVector? = null
