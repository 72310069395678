package studio.goodegg.capsule.core.playback

import io.daio.bass.BassPlayer
import io.daio.bass.MediaItem
import io.daio.bass.PlayerState
import io.daio.bass.PlayerType
import io.daio.bass.observePlayerType
import io.daio.bass.playbackProgressChanges
import io.daio.bass.playbackStateChanges
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.distinctUntilChanged
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.onCompletion
import kotlinx.coroutines.flow.onStart
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.DownloadedEpisode
import studio.goodegg.capsule.Episode
import studio.goodegg.capsule.PlayState
import studio.goodegg.capsule.Station
import studio.goodegg.capsule.Tune
import studio.goodegg.capsule.common.coroutines.IoDispatcher
import studio.goodegg.capsule.common.coroutines.MainDispatcher
import studio.goodegg.capsule.core.playback.PlaybackSpeed.Default
import studio.goodegg.capsule.preferences.Preferences
import kotlin.math.abs
import kotlin.math.roundToInt

enum class AudioBoostGain(val gain: Int) {
    Normal(0),
    ExtraTiny(250),
    Tiny(500),
    Small(1000),
    Medium(1500),
    ExtraMedium(2000),
    Big(2500),
    Huge(3000);

    companion object {
        private val valueRange: ClosedRange<Int> = 0..3000
        private val steps = entries.size - 1 // 4 steps between 5 values

        // Convert actual gain to normalized progress (0f to 1f)
        fun gainToProgress(gain: AudioBoostGain): Float {
            val index = entries.indexOf(gain).coerceAtLeast(0)
            return index.toFloat() / steps
        }

        // Convert seek bar progress back to the closest playback speed
        fun progressToGain(progress: Float): Int {
            val index = (progress * steps).roundToInt().coerceIn(0, steps)
            return entries[index].gain
        }

        fun fromValue(value: Int): AudioBoostGain {
            val clampedValue = value.coerceIn(valueRange)
            return AudioBoostGain.entries.toTypedArray().minByOrNull { abs(it.gain - clampedValue) }
                ?: Normal
        }
    }
}

enum class PlaybackSpeed(val speed: Float) {
    ExtraSlow(0.25f),
    Slow(0.5f),
    MediumSlow(0.75f),
    Default(1f),
    MediumFast(1.25f),
    Fast(1.5f),
    ExtraFast(1.75f),
    Turbo(2f);

    companion object {
        private val valueRange: ClosedFloatingPointRange<Float> = 0.25f..2f
        private val steps = entries.size - 1 // 4 steps between 5 values

        // Convert actual speed to normalized progress (0f to 1f)
        fun speedToProgress(speed: PlaybackSpeed): Float {
            val index = entries.indexOf(speed).coerceAtLeast(0)
            return index.toFloat() / steps
        }

        // Convert seek bar progress back to the closest playback speed
        fun progressToSpeed(progress: Float): Float {
            val index = (progress * steps).roundToInt().coerceIn(0, steps)
            return entries[index].speed
        }

        fun fromValue(value: Float): PlaybackSpeed {
            val clampedValue = value.coerceIn(valueRange)
            return entries.toTypedArray().minByOrNull { abs(it.speed - clampedValue) } ?: Default
        }
    }
}

interface Player {
    fun play(episode: Episode, position: Long)

    fun play(downloadedEpisode: DownloadedEpisode, position: Long)

    fun play(station: Station, tune: Tune)

    fun stop()

    fun pause()

    fun resume()

    fun seek(deltaMs: Long)

    fun seekBackTen()

    fun seekForwardTen()

    fun getPlayQueue(): PlayerQueue

    fun sleepTimer(): SleepTimer

    suspend fun setPlaybackSpeed(speed: PlaybackSpeed)

    suspend fun getPlaybackSpeed(): PlaybackSpeed

    fun observePlaybackProperties(): Flow<PlaybackProperties>

    suspend fun setTrimSilence(enabled: Boolean)

    suspend fun getTrimSilenceEnabled(): Boolean

    suspend fun setAudioBoostGain(gain: AudioBoostGain)

    suspend fun getAudioBoostGain(): AudioBoostGain

    val currentItem: PlayerItem?
    val currentPosition: Long

    fun playerProgress(): Flow<PlayerProgress>

    fun playbackState(): Flow<PlayState>

    fun playerName(): Flow<String>
}

data class PlaybackProperties(
    val trimSilence: Boolean,
    val audioBoostGain: AudioBoostGain,
    val playbackSpeed: PlaybackSpeed,
)

sealed interface PlayerItem {
    data class PodcastEpisode(val episode: Episode) : PlayerItem
    data class Radio(val station: Station) : PlayerItem
}

private const val PlaybackSpeedKey = "capsule-playback-speed-preference"
private const val AudioBoostGainKey = "capsule-playback-audio-boost-preference"
private const val TrimSilenceKey = "capsule-playback-trim-silence-preference"

@Inject
internal class PlayerImpl(
    private val bassPlayer: BassPlayer,
    private val preferences: Preferences,
    private val playerQueue: PlayerQueue,
    @IoDispatcher private val ioDispatcher: CoroutineDispatcher,
    @MainDispatcher private val mainDispatcher: CoroutineDispatcher,
) : Player {

    private val scope = CoroutineScope(SupervisorJob() + ioDispatcher)
    private val sleepTimer = SleepTimer(preferences)

    init {
        scope.launch {
            val playbackSpeed = preferences.getFloat(PlaybackSpeedKey)
            val trimSilence = preferences.getBoolean(TrimSilenceKey)
            val audioBoostGain = preferences.getInt(AudioBoostGainKey)

            if (playbackSpeed == 0f) {
                preferences.put(PlaybackSpeedKey, Default.speed)
            }
            if (trimSilence == null) {
                preferences.put(TrimSilenceKey, false)
            }
            if (audioBoostGain == 0 || audioBoostGain == null) {
                preferences.put(AudioBoostGainKey, AudioBoostGain.Normal.gain)
            }

            scope.launch {
                sleepTimer.observeSleepTimerProgress()
                    .collect {
                        if (it.timer < 0L) {
                            withContext(mainDispatcher) {
                                stop()
                                sleepTimer.stop()
                            }
                        }
                    }
            }
        }

        scope.launch(mainDispatcher) {
            playbackState()
                .filter { it == PlayState.Stopped }
                .distinctUntilChanged()
                .collect {
                    val next = playerQueue.popNext
                    if (next != null) {
                        play(next, 0)
                    }
                }
        }
    }

    override val currentItem: PlayerItem?
        get() = when (bassPlayer.currentItem?.extractMetadata()?.get("basstype")) {
            "podcast" -> PlayerItem.PodcastEpisode(bassPlayer.currentItem?.asEpisode()!!)
            "radio" -> PlayerItem.Radio(bassPlayer.currentItem?.asStation()!!)
            else -> null
        }

    override val currentPosition: Long
        get() = bassPlayer.currentPosition

    override fun play(episode: Episode, position: Long) {
        bassPlayer.play(episode.asBassMediaItem(), position)
        scope.launch { assertPlaybackProperties() }
    }

    override fun play(downloadedEpisode: DownloadedEpisode, position: Long) {
        bassPlayer.play(
            downloadedEpisode.episode.asBassMediaItem(downloadedEpisode.download.filePath),
            position,
        )
        scope.launch { assertPlaybackProperties() }
    }

    override fun play(station: Station, tune: Tune) {
        bassPlayer.play(station.asBassMediaItem(tune))
        scope.launch { assertPlaybackProperties() }
    }

    override fun stop() {
        bassPlayer.stop()
    }

    override fun pause() {
        bassPlayer.pause()
    }

    override fun resume() {
        bassPlayer.resume()
        scope.launch { assertPlaybackProperties() }
    }

    override fun seek(deltaMs: Long) {
        bassPlayer.seekByDelta(deltaMs)
    }

    override fun seekBackTen() {
        seek(-10000)
    }

    override fun seekForwardTen() {
        seek(10000)
    }

    override fun getPlayQueue(): PlayerQueue = playerQueue

    override fun sleepTimer(): SleepTimer = sleepTimer

    override suspend fun setPlaybackSpeed(speed: PlaybackSpeed) = withContext(ioDispatcher) {
        preferences.put(PlaybackSpeedKey, speed.speed)
        assertPlaybackProperties()
    }

    private suspend fun assertPlaybackProperties() = withContext(mainDispatcher) {
        val trimSilence = preferences.getBoolean(TrimSilenceKey) == true
        bassPlayer.trimSilence = trimSilence

        val audioBoostGain = preferences.getInt(AudioBoostGainKey) ?: 0
        bassPlayer.audioBoostGain = audioBoostGain

        // Radio should maintain a default speed.
        if (currentItem is PlayerItem.Radio) {
            preferences.put(PlaybackSpeedKey, PlaybackSpeed.Default.speed)
            bassPlayer.setPlaybackSpeed(PlaybackSpeed.Default.speed)

            return@withContext
        }

        val playbackSpeed = preferences.getFloat(PlaybackSpeedKey) ?: PlaybackSpeed.Default.speed
        bassPlayer.setPlaybackSpeed(playbackSpeed)
    }

    override suspend fun getPlaybackSpeed(): PlaybackSpeed = withContext(ioDispatcher) {
        val speed = preferences.getFloat(PlaybackSpeedKey) ?: 1f
        PlaybackSpeed.fromValue(speed)
    }

    override fun observePlaybackProperties(): Flow<PlaybackProperties> {
        return combine(
            preferences.observe<Float>(PlaybackSpeedKey).onStart { emit(null) },
            preferences.observe<Int>(AudioBoostGainKey).onStart { emit(null) },
            preferences.observe<Boolean>(TrimSilenceKey).onStart { emit(null) },
        ) { speed, audioGain, silence ->
            val playbackSpeed =
                if (speed == null || speed <= 0.0f) Default else PlaybackSpeed.fromValue(speed)

            val audioBoostGain =
                if (audioGain == null || audioGain <= 0) {
                    AudioBoostGain.Normal
                } else {
                    AudioBoostGain.fromValue(audioGain)
                }

            val trimSilence = silence == true

            PlaybackProperties(
                trimSilence = trimSilence,
                audioBoostGain = audioBoostGain,
                playbackSpeed = playbackSpeed,
            )
        }
    }

    override suspend fun setTrimSilence(enabled: Boolean) {
        preferences.put(TrimSilenceKey, enabled)
        assertPlaybackProperties()
    }

    override suspend fun getTrimSilenceEnabled(): Boolean {
        return preferences.getBoolean(TrimSilenceKey) == true
    }

    override suspend fun setAudioBoostGain(gain: AudioBoostGain) {
        preferences.put(AudioBoostGainKey, gain.gain)
        assertPlaybackProperties()
    }

    override suspend fun getAudioBoostGain(): AudioBoostGain {
        val gain = preferences.getInt(AudioBoostGainKey) ?: 0
        return AudioBoostGain.fromValue(gain)
    }

    override fun playerProgress(): Flow<PlayerProgress> {
        return bassPlayer.playbackProgressChanges().map {
            PlayerProgress(it.duration, it.progress, it.percentage)
        }
    }

    override fun playbackState(): Flow<PlayState> {
        return bassPlayer.playbackStateChanges()
            .onStart { emit(PlayerState.Idle) }.map {
                when (it) {
                    PlayerState.Buffering -> PlayState.Buffering
                    PlayerState.Error -> PlayState.Error
                    PlayerState.Idle -> PlayState.Idle
                    PlayerState.Paused -> PlayState.Paused
                    PlayerState.Playing -> PlayState.Playing
                    PlayerState.Stopped -> PlayState.Stopped
                }
            }
    }

    override fun playerName(): Flow<String> {
        return bassPlayer.observePlayerType().map {
            when (it) {
                PlayerType.Local -> ""
                is PlayerType.Remote -> it.name
            }
        }.distinctUntilChanged()
    }
}

fun Map<String, String?>.toJson(): String = Json.encodeToString(this)
fun String.toMap(): Map<String, String?> =
    runCatching { Json.decodeFromString<Map<String, String>>(this) }
        .getOrDefault(emptyMap())

fun Episode.asBassMediaItem(mediaUrl: String = media): MediaItem {
    val metadata = mapOf(
        "id" to episodeSlug,
        "created" to created.toString(),
        "filesize" to fileSize.toString(),
        "parentslug" to parentSlug,
        "parentfeed" to parentFeed,
        "mediatype" to mediaType,
        "description" to description,
        "episodetype" to episodeType,
        "basstype" to "podcast",
    ).toJson()

    return MediaItem(
        url = mediaUrl,
        title = title,
        artist = author,
        artworkUrl = image,
        id = metadata,
        length = duration,
    )
}


fun Station.asBassMediaItem(tune: Tune): MediaItem {
    val metadata = mapOf(
        "id" to slug,
        "genre" to genre,
        "genreId" to genreId.toString(),
        "guid" to guid,
        "subtext" to subtext,
        "basstype" to "radio",
    ).toJson()

    return MediaItem(
        url = tune.url,
        title = title,
        id = metadata,
        artist = title,
        artworkUrl = image,
    )
}

private fun MediaItem.extractMetadata(): Map<String, String?> = id.toMap()

fun MediaItem.asEpisode(): Episode {
    val metadata = extractMetadata()
    return Episode(
        media = url,
        title = title!!,
        author = artist!!,
        image = artworkUrl.orEmpty(),
        duration = length,
        episodeSlug = metadata["id"].orEmpty(),
        parentSlug = metadata["parentslug"],
        fileSize = metadata["filesize"]?.toInt() ?: 0,
        mediaType = metadata["mediatype"].orEmpty(),
        created = metadata["created"]?.toLong() ?: 0L,
        description = metadata["description"],
        parentFeed = metadata["parentfeed"].orEmpty(),
        episodeType = metadata["episodetype"].orEmpty(),
    )
}

fun MediaItem.asStation(): Station {
    val metadata = extractMetadata()
    return Station(
        title = title!!,
        image = artworkUrl.orEmpty(),
        slug = metadata["id"].orEmpty(),
        guid = metadata["guid"].orEmpty(),
        genre = metadata["genre"].orEmpty(),
        genreId = metadata["genreId"].orEmpty(),
        subtext = metadata["subtext"].orEmpty(),
    )
}

data class PlayerProgress(
    val duration: Long,
    val progress: Long,
    val percentage: Float,
)

val ZeroProgress = PlayerProgress(0, 0, 0f)
