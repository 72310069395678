package studio.goodegg.capsule.ui

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Logos.CapsuleBottom: ImageVector
    get() {
        if (_CapsuleBottom != null) {
            return _CapsuleBottom!!
        }
        _CapsuleBottom = ImageVector.Builder(
            name = "CapsuleBottom",
            defaultWidth = 250.dp,
            defaultHeight = 250.dp,
            viewportWidth = 250f,
            viewportHeight = 250f
        ).apply {
            path(
                fill = SolidColor(Color(0xFFA32323)),
                fillAlpha = 0.7f,
                pathFillType = PathFillType.EvenOdd
            ) {
                moveTo(0f, 0f)
                horizontalLineTo(250f)
                verticalLineTo(125f)
                curveTo(250f, 194.04f, 194.04f, 250f, 125f, 250f)
                curveTo(55.96f, 250f, 0f, 194.04f, 0f, 125f)
                verticalLineTo(0f)
                close()
            }
        }.build()

        return _CapsuleBottom!!
    }

@Suppress("ObjectPropertyName")
private var _CapsuleBottom: ImageVector? = null
