package studio.goodegg.capsule.playqueue

import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.lazy.itemsIndexed
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.screen.Screen
import com.slack.circuit.runtime.ui.Ui
import com.slack.circuit.runtime.ui.ui
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.foundations.text.TitleText
import io.daio.pancake.icons.ArrowBack
import io.daio.pancake.icons.Drag
import io.daio.pancake.icons.Icons
import io.daio.pancake.icons.Remove
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.Tier
import io.daio.wild.style.clickable
import studio.goodegg.capsule.domain.playback.NowPlayingItem
import studio.goodegg.capsule.domain.playback.image
import studio.goodegg.capsule.domain.playback.subtitle
import studio.goodegg.capsule.domain.playback.title
import studio.goodegg.capsule.navigation.PlayQueueScreen
import studio.goodegg.capsule.ui.components.ArtworkTile
import studio.goodegg.capsule.ui.components.DraggableItem
import studio.goodegg.capsule.ui.components.IconButton
import studio.goodegg.capsule.ui.components.PlayerAwareLazyStack
import studio.goodegg.capsule.ui.components.dragContainer
import studio.goodegg.capsule.ui.components.rememberDragDropState

class PlayQueueScreenUiFactory : Ui.Factory {
    override fun create(
        screen: Screen,
        context: CircuitContext,
    ): Ui<*>? {
        return when (screen) {
            is PlayQueueScreen -> playqueue()
            else -> null
        }
    }
}

private fun playqueue() =
    ui<PlayQueueUiState> { state, modifier ->
        PlayQueue(
            state,
            modifier,
        )
    }

@Composable
fun PlayQueue(
    state: PlayQueueUiState,
    modifier: Modifier = Modifier,
) {
    Box(modifier = modifier.fillMaxSize()) {
        Stack(spaceBetween = LayoutGap.Small) {
            Icon(
                imageVector = Icons.ArrowBack,
                contentDescription = "",
                modifier = Modifier
                    .size(24.dp)
                    .clickable(
                        style = {
                            scale = if (pressed) .95f else 1f
                        },
                        onClick = {
                            state.eventSink(PlayQueueUiEvent.Back)
                        },
                    ),
            )

            Spacer(modifier = Modifier.height(Theme.dimens.size_s))
            state.currentlyPlaying?.let {
                BodyText("Currently Playing", textColor = Theme.colors.accent)
                when (it) {
                    is NowPlayingItem.Podcast -> {
                        PlayQueueItem(
                            it.title,
                            it.subtitle,
                            it.image,
                        )
                    }

                    is NowPlayingItem.Radio -> {
                        PlayQueueItem(
                            it.title,
                            it.subtitle,
                            it.image,
                        )
                    }
                }
            }
            Spacer(modifier = Modifier.height(Theme.dimens.size_s))
            HorizontalDivider(
                color = Theme.colors.onSurfaceBackground,
                modifier = Modifier.graphicsLayer {
                    alpha = .2f
                },
            )

            Spacer(modifier = Modifier.height(Theme.dimens.size_s))
            BodyText("Next Up", textColor = Theme.colors.accent)

            if (state.playQueue.isEmpty()) {
                TitleText(
                    modifier = Modifier.fillMaxWidth(),
                    textAlign = TextAlign.Center,
                    text = "Nothing in the queue",
                )
            }

            var items by remember { mutableStateOf(state.playQueue.toList()) }  // Ensure copy is tracked properly

            LaunchedEffect(state.playQueue) {
                items = state.playQueue.toList()
            }

            fun move(fromIndex: Int, toIndex: Int) {
                items = items.toMutableList().apply {
                    val removed = removeAt(fromIndex)
                    add(toIndex, removed)
                }
            }

            val listState = rememberLazyListState()
            val dragDropState = rememberDragDropState(
                listState,
                onEnd = {
                    state.eventSink(PlayQueueUiEvent.UpdatePlayQueue(items))
                },
            ) { fromIndex, toIndex ->
                move(fromIndex, toIndex)
            }

            PlayerAwareLazyStack(
                modifier = Modifier.dragContainer(dragDropState).fillMaxSize(),
                lazyListState = listState,
                spaceBetween = LayoutGap.ExtraLarge,
            ) {
                itemsIndexed(items, key = { _, item -> item.episodeSlug }) { index, item ->
                    DraggableItem(dragDropState, index) { isDragging ->
                        val dragTranslation by animateFloatAsState(if (isDragging) -2f else 0f)
                        PlayQueueItem(
                            item.title,
                            item.author,
                            item.image,
                            modifier = Modifier.animateItem()
                                .graphicsLayer {
                                    rotationZ = dragTranslation
                                },
                            onDrag = {
                            },
                            onRemove = {
                                state.eventSink.invoke(PlayQueueUiEvent.RemoveFromPlayQueue(item))
                            },
                        )
                    }
                }
            }
        }
    }
}

@Composable
private fun PlayQueueItem(
    title: String,
    subtitle: String?,
    image: String?,
    onDrag: (() -> Unit)? = null,
    onRemove: (() -> Unit)? = null,
    modifier: Modifier = Modifier,
) {
    Tier(
        spaceBetween = LayoutGap.Small,
        horizontalAlignment = Alignment.Start,
        modifier = modifier.fillMaxWidth(),
    ) {
        onRemove?.let {
            IconButton(
                icon = Icons.Remove,
                color = Theme.colors.negative,
                contentColor = Theme.colors.onSurfaceBackground,
                modifier = Modifier.size(16.dp),
                contentPadding = PaddingValues(0.dp),
                onClick = it,
            )
        }
        Box(
            modifier = Modifier.align(Alignment.CenterVertically),
            contentAlignment = Alignment.Center,
        ) {
            ArtworkTile(
                image,
                size = 38.dp,
            )
        }
        // TODO more ranges to gaps in pancake
        Stack(
            modifier = Modifier.weight(1f),
            spaceBetween = LayoutGap.None,
            verticalAlignment = Alignment.CenterVertically,
        ) {
            TitleText(title, maxLines = 1)
            BodyText(subtitle.orEmpty(), maxLines = 1)
        }

        onDrag?.let {
            IconButton(
                icon = Icons.Drag,
                onClick = it,
            )
        }
    }
}
