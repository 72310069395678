package studio.goodegg.capsule

import kotlinx.serialization.Serializable

@Serializable
data class Download(
    val slug: String,
    val mediaUrl: String,
    val filePath: String,
    val downloadedPercentage: Long,
)

@Serializable
data class DownloadedEpisode(
    val download: Download,
    val episode: Episode,
)

data class EpisodeAndMetadata(
    val download: Download? = null,
    val playingState: PlaybackState? = null,
    val episode: Episode,
) {
    fun formatTimeRemaining(): String =
        playingState?.currentPosition?.let { episode.formatTimeRemaining(it) } ?: ""

    fun formatTimeRemainingShort(): String =
        playingState?.currentPosition?.let { episode.formatTimeRemainingShort(it) } ?: ""

    fun formatCurrentPosition(): String =
        playingState?.currentPosition?.let { episode.formatCurrentPosition(it) } ?: ""
}
