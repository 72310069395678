package studio.goodegg.capsule.details.episode

import com.slack.circuit.runtime.CircuitUiEvent
import studio.goodegg.capsule.Episode
import studio.goodegg.capsule.EpisodeAndMetadata

sealed class EpisodeDetailsUiEvent : CircuitUiEvent {
    data class PlayEpisode(val data: EpisodeAndMetadata) : EpisodeDetailsUiEvent()

    data class DownloadEpisode(val data: EpisodeAndMetadata) : EpisodeDetailsUiEvent()

    data class ShareEpisode(val episode: Episode) : EpisodeDetailsUiEvent()

    data class OpenPodcast(val podcastSlug: String) : EpisodeDetailsUiEvent()

    data class AddToPlayQueue(val data: EpisodeAndMetadata) : EpisodeDetailsUiEvent()

    data class RemoveFromPlayQueue(val data: EpisodeAndMetadata) : EpisodeDetailsUiEvent()

    data class MarkAsPlayed(val data: EpisodeAndMetadata) : EpisodeDetailsUiEvent()
    data class UnMarkAsPlayed(val data: EpisodeAndMetadata) : EpisodeDetailsUiEvent()
}
