package studio.goodegg.capsule.ui.components

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Icon
import androidx.compose.material3.minimumInteractiveComponentSize
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.semantics.Role
import androidx.compose.ui.semantics.role
import androidx.compose.ui.semantics.semantics
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import coil3.compose.AsyncImage
import coil3.compose.AsyncImagePainter
import coil3.compose.LocalPlatformContext
import coil3.request.ImageRequest
import coil3.request.crossfade
import com.kmpalette.loader.rememberPainterLoader
import com.kmpalette.rememberDominantColorState
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.color.contentColorFor
import io.daio.pancake.icons.Add
import io.daio.pancake.icons.Icons
import io.daio.pancake.icons.MyStuffFilled
import io.daio.pancake.layout.Surface
import io.daio.wild.container.Container
import io.daio.wild.style.StyleDefaults
import io.daio.wild.style.interactable
import kotlinx.coroutines.launch

const val IMAGE_TRANSCODE_URL =
    "https://kycrpvroitgvdzlozrbj.supabase.co/functions/v1/image-resizer?url="

private object ArtworkBucket {
    val small = 50.dp
    val medium = 100.dp
    val large = 150.dp
    val xLarge = 200.dp

    fun closestBucket(value: Dp): Dp = when {
        value <= (small + medium) / 2 -> small
        value <= (medium + large) / 2 -> medium
        value <= (large + xLarge) / 2 -> large
        else -> xLarge
    }
}

@Composable
fun ArtworkTile(
    imageUrl: String?,
    modifier: Modifier = Modifier,
    backgroundColor: Color = Theme.colors.surfaceContainer,
    subscribed: Boolean = false,
    onSubscribe: (() -> Unit)? = null,
    onClick: (() -> Unit)? = null,
    onDominateColor: ((Color) -> Unit)? = null,
    size: Dp? = 200.dp,
) {
    val imageShape = Theme.shapes.large
    val pixelSize = with(LocalDensity.current) {
        val suppliedSize = size ?: 200.dp
        ArtworkBucket.closestBucket(suppliedSize).roundToPx()
    }

    val internalModifier = modifier.interactable(
        onClick = onClick ?: {},
        style = {
            scale = if (pressed) .95f else 1f
            shape = imageShape
        },
    )

    val context = LocalPlatformContext.current
    val request =
        remember(imageUrl) {
            ImageRequest.Builder(context)
                .data("${IMAGE_TRANSCODE_URL}$imageUrl&width=$pixelSize")
                .allowHardware(false)
                .crossfade(true)
                .build()
        }

    Surface(
        color = backgroundColor,
        shape = imageShape,
        modifier = internalModifier.then(if (size != null) Modifier.size(size) else Modifier)
            .aspectRatio(1f),
    ) {
        val painterLoader = if (onDominateColor != null)
            rememberPainterLoader()
        else
            null

        val dominantColorState = if (onDominateColor != null)
            rememberDominantColorState(painterLoader!!)
        else
            null

        val scope = rememberCoroutineScope()

        val onSuccess: ((AsyncImagePainter.State.Success) -> Unit)? =
            onDominateColor?.let { mainColor ->
                {
                    scope.launch {
                        painterLoader?.load(it.painter)
                        dominantColorState?.updateFrom(it.painter)
                        mainColor(dominantColorState!!.color)
                    }
                }
            }

        Box(modifier = Modifier.fillMaxSize()) {
            AsyncImage(
                model = request,
                onSuccess = onSuccess,
                contentScale = ContentScale.Crop,
                contentDescription = "",
                modifier = Modifier.fillMaxSize(),
            )
            onSubscribe?.let {
                Box(
                    contentAlignment = Alignment.Center,
                    modifier =
                        Modifier
                            .padding(
                                end = Theme.dimens.size_xxs,
                                bottom = Theme.dimens.size_xxs,
                            )
                            .graphicsLayer {
                                shape = RoundedCornerShape(50.dp)
                                clip = true
                            }
                            .background(if (subscribed) Theme.colors.accent else Theme.colors.surfaceBackground)
                            .size(32.dp)
                            .align(Alignment.BottomEnd)
                            .minimumInteractiveComponentSize()
                            .clickable(
                                onClick = {
                                    onSubscribe()
                                },
                            )
                            .semantics { role = Role.Button },
                ) {
                    Icon(
                        modifier = Modifier.padding(Theme.dimens.size_xs),
                        imageVector =
                            if (subscribed) {
                                Icons.MyStuffFilled
                            } else {
                                Icons.Add
                            },
                        contentDescription = "subscribe",
                        tint = if (subscribed) Theme.colors.onAccent else Theme.colors.onSurfaceBackground,
                    )
                }
            }
        }

    }
}

@Composable
fun IconButton(
    icon: ImageVector,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    color: Color = Color.Transparent,
    contentColor: Color = contentColorFor(color),
    contentPadding: PaddingValues = PaddingValues(6.dp),
) {
    IconButton(
        modifier = modifier,
        onClick = onClick,
        color = color,
        contentColor = contentColor,
        icon = {
            Icon(
                modifier = Modifier.padding(contentPadding),
                imageVector = icon,
                contentDescription = null,
            )
        },
    )
}

@Composable
fun IconButton(
    icon: @Composable () -> Unit,
    color: Color = Color.Transparent,
    contentColor: Color = contentColorFor(color),
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
) {
    Container(
        modifier = modifier.size(32.dp)
            .semantics { role = Role.Button },
        style = StyleDefaults.style(
            shapes = StyleDefaults.shapes(shape = Theme.shapes.circle),
            colors = StyleDefaults.colors(
                backgroundColor = color,
                contentColor = contentColor,
                focusedBackgroundColor = Theme.colors.surfaceInteractive,
                focusedContentColor = Theme.colors.onSurfaceInteractive,
                pressedBackgroundColor = Theme.colors.surfaceInteractive,
                pressedContentColor = Theme.colors.onSurfaceInteractive,
            ),
            scale = StyleDefaults.scale(pressedScale = .9f, focusedScale = 1.1f),
        ),
        onClick = onClick,
    ) {
        Box(
            modifier = Modifier.fillMaxSize().align(Alignment.Center),
            contentAlignment = Alignment.Center,
        ) {
            icon()
        }
    }
}
