package studio.goodegg.capsule.search

import androidx.compose.runtime.Immutable
import com.slack.circuit.runtime.CircuitUiState
import studio.goodegg.capsule.Episode
import studio.goodegg.capsule.EpisodeAndMetadata
import studio.goodegg.capsule.PlaybackState
import studio.goodegg.capsule.PodcastResult
import studio.goodegg.capsule.Station

@Immutable
data class SearchUiState(
    val searching: Boolean,
    val zeroState: String?,
    val subscriptions: Set<String>,
    val nowPlaying: EpisodeAndMetadata?,
    val podcastResults: List<PodcastResult>,
    val episodeResults: List<Episode>,
    val radioResults: List<Station>,
    val playQueue: List<String>,
    val eventSink: (SearchUiEvent) -> Unit,
) : CircuitUiState
