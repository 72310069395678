package studio.goodegg.capsule.ui.components

import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import io.daio.pancake.foundations.text.BodyText

@Composable
actual fun HtmlText(html: String, modifier: Modifier) {
    val renderedHtml = remember(html) { parseHtmlToPlainText(html) }
    BodyText(
        text = renderedHtml,
        modifier = Modifier.verticalScroll(rememberScrollState()),
    )
}