package studio.goodegg.capsule

import kotlinx.browser.localStorage
import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.buffer
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.onStart
import okio.Path
import org.w3c.dom.get

actual fun <T> FileDataStore<T>.observe(key: String?): Flow<T?> {
    return updates.buffer(Channel.CONFLATED)
        .map { get(key) }
        .onStart { emit(get(key)) }
}

actual suspend fun FileDataStore<*>.write(path: Path, data: String) {
    localStorage.setItem(path.toString(), data)
//    NodeJsFileSystem.write(path) {
//        writeUtf8(data)
//    }
}

internal actual suspend fun delete(path: Path) {
    localStorage.removeItem(path.toString())
//    NodeJsFileSystem.delete(path)
}

internal actual suspend fun FileDataStore<*>.read(path: Path): String? {
    return localStorage[path.toString()]
//    return NodeJsFileSystem.read(path) {
//        readUtf8()
//    }
}