package studio.goodegg.capsule.details.podcast

import androidx.compose.runtime.Immutable
import com.slack.circuit.runtime.CircuitUiState
import studio.goodegg.capsule.EpisodeAndMetadata
import studio.goodegg.capsule.PlaybackState
import studio.goodegg.capsule.PodcastWithEpisodeMeta
import studio.goodegg.capsule.ui.components.Filter

@Immutable
data class PodcastDetailsUiState(
    val loading: Boolean,
    val filters: List<Filter> = Filter.entries,
    val filter: Filter = Filter.Latest,
    val isSubscribed: Boolean,
    val autoDownload: Boolean,
    val notifications: Boolean,
    val podcast: PodcastWithEpisodeMeta?,
    val playedEpisodesSlugs: Set<String>,
    val playQueue: List<String>,
    val eventSink: (PodcastDetailsUiEvent) -> Unit,
) : CircuitUiState
