package studio.goodegg.capsule.genres

import com.slack.circuit.runtime.CircuitUiEvent
import studio.goodegg.capsule.PodcastResult

sealed class GenreUiEvent : CircuitUiEvent {
    data class OpenPodcast(val slug: String) : GenreUiEvent()

    data class AddToSubscriptions(val podcast: PodcastResult) : GenreUiEvent()

    data object Back : GenreUiEvent()
}
