package studio.goodegg.capsule.base.main

import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlSchema
import com.slack.circuit.foundation.Circuit
import io.ktor.client.HttpClient
import kotlin.Unit
import kotlinx.coroutines.CoroutineDispatcher
import me.tatarka.inject.`internal`.LazyMap
import me.tatarka.inject.`internal`.ScopedComponent
import studio.goodegg.capsule.FileStorageLocation
import studio.goodegg.capsule.api.clients.AuthClient
import studio.goodegg.capsule.api.clients.DiscoveryClient
import studio.goodegg.capsule.api.clients.RadioClient
import studio.goodegg.capsule.categories.CategoriesPresenterFactory
import studio.goodegg.capsule.core.playback.Player
import studio.goodegg.capsule.db.CapsuleDbCreator
import studio.goodegg.capsule.details.episode.EpisodeDetailsPresenterFactory
import studio.goodegg.capsule.details.podcast.PodcastDetailsPresenterFactory
import studio.goodegg.capsule.domain.auth.ObserveSignedInInteractor
import studio.goodegg.capsule.domain.auth.SignInInteractor
import studio.goodegg.capsule.domain.auth.SignOutInteractor
import studio.goodegg.capsule.domain.auth.VerifySignInInteractor
import studio.goodegg.capsule.domain.download.DeleteDownloadInteractor
import studio.goodegg.capsule.domain.download.DownloadInteractor
import studio.goodegg.capsule.domain.playback.AddToPlayQueueInteractor
import studio.goodegg.capsule.domain.playback.GetLastPlayedInteractor
import studio.goodegg.capsule.domain.playback.MarkAsPlayedInteractor
import studio.goodegg.capsule.domain.playback.ObserveAllPlayedInteractor
import studio.goodegg.capsule.domain.playback.ObserveInProgressInteractor
import studio.goodegg.capsule.domain.playback.ObserveNowPlayingItemInteractor
import studio.goodegg.capsule.domain.playback.ObservePlayQueueInteractor
import studio.goodegg.capsule.domain.playback.ObservePodcastNowPlayingInteractor
import studio.goodegg.capsule.domain.playback.PauseInteractor
import studio.goodegg.capsule.domain.playback.PlayInteractor
import studio.goodegg.capsule.domain.playback.RemoveFromPlayQueueInteractor
import studio.goodegg.capsule.domain.playback.ResumePlaybackInteractor
import studio.goodegg.capsule.domain.playback.TuneInteractor
import studio.goodegg.capsule.domain.playback.UnMarkAsPlayedInteractor
import studio.goodegg.capsule.domain.podcast.ObserveEpisodesInteractor
import studio.goodegg.capsule.domain.settings.PutBooleanPreferenceInteractor
import studio.goodegg.capsule.domain.settings.PutStringPreferenceInteractor
import studio.goodegg.capsule.domain.share.ShareEpisodeInteractor
import studio.goodegg.capsule.domain.subscription.AddSubscriptionInteractor
import studio.goodegg.capsule.domain.subscription.DeleteSubscriptionInteractor
import studio.goodegg.capsule.domain.subscription.ObserveAllSubscriptionsInteractor
import studio.goodegg.capsule.domain.trending.GetTrendingInteractor
import studio.goodegg.capsule.downloader.DownloaderImpl
import studio.goodegg.capsule.downloads.DownloadsPresenterFactory
import studio.goodegg.capsule.genres.GenrePresenterFactory
import studio.goodegg.capsule.inbox.InboxPresenterFactory
import studio.goodegg.capsule.listen.ListenPresenterFactory
import studio.goodegg.capsule.onboarding.OnboardingPresenterFactory
import studio.goodegg.capsule.player.PlayerPresenterFactory
import studio.goodegg.capsule.playqueue.PlayQueuePresenterFactory
import studio.goodegg.capsule.preferences.Preferences
import studio.goodegg.capsule.repositories.DownloadsRepository
import studio.goodegg.capsule.repositories.PlayedItemsRepository
import studio.goodegg.capsule.repositories.PodcastRepository
import studio.goodegg.capsule.repositories.RadioRepositoryImpl
import studio.goodegg.capsule.repositories.SearchRepository
import studio.goodegg.capsule.repositories.SearchRepositoryImpl
import studio.goodegg.capsule.repositories.SubscriptionsRepository
import studio.goodegg.capsule.repositories.TrendingRepository
import studio.goodegg.capsule.repositories.TrendingRepositoryImpl
import studio.goodegg.capsule.resume.ResumePresenterFactory
import studio.goodegg.capsule.search.SearchPresenterFactory
import studio.goodegg.capsule.settings.SettingsPresenterFactory
import studio.goodegg.capsule.signin.SignInPresenterFactory
import studio.goodegg.capsule.subscriptions.SubscriptionsPresenterFactory
import studio.goodegg.capsule.trending.TrendingPresenterFactory
import studio.goodegg.capsule.work.WorkScheduler

public fun JsUiComponent.Companion.create(appComponent: JsAppComponent): JsUiComponent =
    InjectJsUiComponent(appComponent)

public class InjectJsUiComponent(
  appComponent: JsAppComponent,
) : JsUiComponent(appComponent), ScopedComponent {
  override val _scoped: LazyMap = LazyMap()

  private val observeAllSubscriptionsInteractor: ObserveAllSubscriptionsInteractor
    get() = providesObserveAllSubscriptionsInteractor(
      subscriptionsRepository = subscriptionsRepository
    )

  private val subscriptionsRepository: SubscriptionsRepository
    get() {
      require(appComponent is ScopedComponent)
      return appComponent._scoped.get("studio.goodegg.capsule.repositories.SubscriptionsRepository") {
        appComponent.providesSubscriptionsRepository(
          capsuleDb = capsuleDbCreator,
          ioDispatcher = coroutineDispatcher,
          subscriptionsClient =
              appComponent._scoped.get("studio.goodegg.capsule.api.clients.SubscriptionsClient") {
            appComponent.providesSubscriptionsClient(
              httpClient = httpClient,
              authClient = authClient
            )
          }
        )
      }
    }

  private val capsuleDbCreator: CapsuleDbCreator
    get() {
      require(appComponent is ScopedComponent)
      return appComponent._scoped.get("studio.goodegg.capsule.db.CapsuleDbCreator") {
        appComponent.providesDbCreator(
          schema = sqlSchemaQueryResult_AsyncValueUnit,
          driver = appComponent._scoped.get("app.cash.sqldelight.db.SqlDriver") {
            appComponent.providesDbDriver(
              schema = sqlSchemaQueryResult_AsyncValueUnit
            )
          }
        )
      }
    }

  private val sqlSchemaQueryResult_AsyncValueUnit: SqlSchema<QueryResult.AsyncValue<Unit>>
    get() {
      require(appComponent is ScopedComponent)
      return appComponent._scoped.get("app.cash.sqldelight.db.SqlSchema<app.cash.sqldelight.db.QueryResult.AsyncValue<kotlin.Unit>>") {
        appComponent.providesDatabaseSchema()
      }
    }

  private val coroutineDispatcher: CoroutineDispatcher
    get() {
      require(appComponent is ScopedComponent)
      return appComponent._scoped.get("@IoDispatcher" + "kotlinx.coroutines.CoroutineDispatcher") {
        appComponent.providesIoDispatcher()
      }
    }

  private val httpClient: HttpClient
    get() {
      require(appComponent is ScopedComponent)
      return appComponent._scoped.get("io.ktor.client.HttpClient") {
        appComponent.providesKtorClient()
      }
    }

  private val authClient: AuthClient
    get() {
      require(appComponent is ScopedComponent)
      return appComponent._scoped.get("studio.goodegg.capsule.api.clients.AuthClient") {
        appComponent.providesAuthClient()
      }
    }

  private val workScheduler: WorkScheduler
    get() {
      require(appComponent is ScopedComponent)
      return appComponent._scoped.get("studio.goodegg.capsule.work.WorkScheduler") {
        appComponent.providesScheduler()
      }
    }

  private val preferences: Preferences
    get() {
      require(appComponent is ScopedComponent)
      return appComponent._scoped.get("studio.goodegg.capsule.preferences.Preferences") {
        appComponent.providesPreferences()
      }
    }

  private val putStringPreferenceInteractor: PutStringPreferenceInteractor
    get() = providesPutStringPreference(
      preferences = preferences
    )

  private val observeEpisodesInteractor: ObserveEpisodesInteractor
    get() = providesObserveEpisodesInteractor(
      podcastsRepository = podcastRepository,
      downloadsRepository = downloadsRepository,
      playedItemsRepository = playedItemsRepository,
      player = player,
      ioDispatcher = coroutineDispatcher,
      mainDispatcher = coroutineDispatcher_
    )

  private val podcastRepository: PodcastRepository
    get() {
      require(appComponent is ScopedComponent)
      return appComponent._scoped.get("studio.goodegg.capsule.repositories.PodcastRepository") {
        appComponent.providesPodcastRepository(
          podcastClient =
              appComponent._scoped.get("studio.goodegg.capsule.api.clients.PodcastClient") {
            appComponent.providesPodcastClient(
              httpClient = httpClient
            )
          },
          podcastQueries = capsuleDbCreator,
          ioDispatcher = coroutineDispatcher
        )
      }
    }

  private val downloadsRepository: DownloadsRepository
    get() {
      require(appComponent is ScopedComponent)
      return appComponent._scoped.get("studio.goodegg.capsule.repositories.DownloadsRepository") {
        appComponent.providesDownloadsRepository(
          dbCreator = capsuleDbCreator,
          ioDispatcher = coroutineDispatcher,
          downloader = appComponent._scoped.get("studio.goodegg.capsule.downloader.Downloader") {
            appComponent.providesDownloader(
              downloader = DownloaderImpl(
                httpClient = httpClient
              )
            )
          },
          fileStorageLocation = fileStorageLocation
        )
      }
    }

  private val fileStorageLocation: FileStorageLocation
    get() {
      require(appComponent is ScopedComponent)
      return appComponent._scoped.get("studio.goodegg.capsule.FileStorageLocation") {
        appComponent.providesFileStorage()
      }
    }

  private val playedItemsRepository: PlayedItemsRepository
    get() {
      require(appComponent is ScopedComponent)
      return appComponent._scoped.get("studio.goodegg.capsule.repositories.PlayedItemsRepository") {
        appComponent.providesPlayedRepository(
          dbCreator = capsuleDbCreator,
          ioDispatcher = coroutineDispatcher
        )
      }
    }

  private val player: Player
    get() {
      require(appComponent is ScopedComponent)
      return appComponent._scoped.get("studio.goodegg.capsule.core.playback.Player") {
        appComponent.providesPlayer(
          preferences = preferences,
          playerQueue = appComponent.providesPlayQueue(
            playQueueDataStore = appComponent.providesPlayQueueDataStore(
              fileStorageLocation = fileStorageLocation,
              dispatcher = coroutineDispatcher
            ),
            dispatcher = coroutineDispatcher
          ),
          ioDispatcher = coroutineDispatcher,
          mainDispatcher = coroutineDispatcher_
        )
      }
    }

  private val coroutineDispatcher_: CoroutineDispatcher
    get() {
      require(appComponent is ScopedComponent)
      return appComponent._scoped.get("@MainDispatcher" +
          "kotlinx.coroutines.CoroutineDispatcher") {
        appComponent.providesMainCoroutineScope()
      }
    }

  private val observePodcastNowPlayingInteractor: ObservePodcastNowPlayingInteractor
    get() = providesObservePodcastNowPlayingInteractor(
      observeNowPlayingItemInteractor = observeNowPlayingItemInteractor
    )

  private val observeNowPlayingItemInteractor: ObserveNowPlayingItemInteractor
    get() = providesObserveNowPlayingItemInteractor(
      playedItemsRepository = playedItemsRepository,
      podcastRepository = podcastRepository,
      player = player
    )

  private val pauseInteractor: PauseInteractor
    get() = providesPauseInteractor(
      player = player
    )

  private val resumePlaybackInteractor: ResumePlaybackInteractor
    get() = providesResumeInteractor(
      player = player
    )

  private val playInteractor: PlayInteractor
    get() = providesPlayInteractor(
      player = player,
      downloadsRepository = downloadsRepository
    )

  private val observeAllPlayedInteractor: ObserveAllPlayedInteractor
    get() = providesObserveAllPlayedInteractor(
      playedItemsRepository = playedItemsRepository
    )

  private val downloadInteractor: DownloadInteractor
    get() = providesDownloadInteractor(
      scheduler = workScheduler
    )

  private val deleteDownloadInteractor: DeleteDownloadInteractor
    get() = providesDeleteDownloadedInteractor(
      repository = downloadsRepository
    )

  private val observePlayQueueInteractor: ObservePlayQueueInteractor
    get() = providesObservePlayQueueInteractor(
      player = player
    )

  private val addToPlayQueueInteractor: AddToPlayQueueInteractor
    get() = providesAddToPlayQueueInteractor(
      player = player
    )

  private val removeFromPlayQueueInteractor: RemoveFromPlayQueueInteractor
    get() = providesRemoveFromPlayQueueInteractor(
      player = player
    )

  private val markAsPlayedInteractor: MarkAsPlayedInteractor
    get() = providesMarkAsPlayedInteractor(
      playedItemsRepository = playedItemsRepository
    )

  private val shareEpisodeInteractor: ShareEpisodeInteractor
    get() = _scoped.get("studio.goodegg.capsule.domain.share.ShareEpisodeInteractor") {
      providesShareEpisodeInteractor()
    }

  private val addSubscriptionInteractor: AddSubscriptionInteractor
    get() = providesAddSubscriptionInteractor(
      subscriptionsRepository = subscriptionsRepository
    )

  private val deleteSubscriptionInteractor: DeleteSubscriptionInteractor
    get() = providesDeleteSubscriptionInteractor(
      subscriptionsRepository = subscriptionsRepository
    )

  private val trendingRepository: TrendingRepository
    get() {
      require(appComponent is ScopedComponent)
      return appComponent._scoped.get("studio.goodegg.capsule.repositories.TrendingRepository") {
        appComponent.providesTrendingRepository(
          trendingRepository = TrendingRepositoryImpl(
            discoveryClient = discoveryClient,
            ioDispatcher = coroutineDispatcher
          )
        )
      }
    }

  private val discoveryClient: DiscoveryClient
    get() {
      require(appComponent is ScopedComponent)
      return appComponent._scoped.get("studio.goodegg.capsule.api.clients.DiscoveryClient") {
        appComponent.providesDiscoveryClient(
          httpClient = httpClient
        )
      }
    }

  private val signInInteractor: SignInInteractor
    get() = providesSignInInteractor(
      client = authClient
    )

  private val verifySignInInteractor: VerifySignInInteractor
    get() = providesVerifySignInInteractor(
      client = authClient
    )

  private val putBooleanPreferenceInteractor: PutBooleanPreferenceInteractor
    get() = providesPutBooleanPreference(
      preferences = preferences
    )

  private val observeSignedInInteractor: ObserveSignedInInteractor
    get() = providesObserveSignedIn(
      client = authClient
    )

  private val getTrendingInteractor: GetTrendingInteractor
    get() = providesGetTrendingInteractor(
      trendingRepository = trendingRepository
    )

  private val observeInProgressInteractor: ObserveInProgressInteractor
    get() = providesObserveInProgressInteractor(
      playedItemsRepository = playedItemsRepository,
      podcastRepository = podcastRepository
    )

  private val tuneInteractor: TuneInteractor
    get() {
      require(appComponent is ScopedComponent)
      return providesTuneInteractor(
        player = player,
        radioRepository =
            appComponent._scoped.get("studio.goodegg.capsule.repositories.RadioRepository") {
          appComponent.providesRadioRepository(
            radioRepository = RadioRepositoryImpl(
              radioClient = radioClient,
              ioDispatcher = coroutineDispatcher
            )
          )
        }
      )
    }

  private val radioClient: RadioClient
    get() {
      require(appComponent is ScopedComponent)
      return appComponent._scoped.get("studio.goodegg.capsule.api.clients.RadioClient") {
        appComponent.providesRadioClient(
          httpClient = httpClient
        )
      }
    }

  private val getLastPlayedInteractor: GetLastPlayedInteractor
    get() = providesGetLastPlayedInteractor(
      playedItemsRepository = playedItemsRepository,
      podcastRepository = podcastRepository,
      player = player
    )

  private val unMarkAsPlayedInteractor: UnMarkAsPlayedInteractor
    get() = providesMarkAsUnPlayedInteractor(
      playedItemsRepository = playedItemsRepository
    )

  private val searchRepository: SearchRepository
    get() {
      require(appComponent is ScopedComponent)
      return appComponent._scoped.get("studio.goodegg.capsule.repositories.SearchRepository") {
        appComponent.providesSearchRepository(
          searchRepository = SearchRepositoryImpl(
            discoveryClient = discoveryClient,
            radioClient = radioClient,
            ioDispatcher = coroutineDispatcher
          )
        )
      }
    }

  private val signOutInteractor: SignOutInteractor
    get() = providesSignOutInteractor(
      client = authClient
    )

  override val circuit: Circuit
    get() = _scoped.get("com.slack.circuit.foundation.Circuit") {
      providesCircuit(
        inboxPresenterFactory = InboxPresenterFactory(
          observeAllSubscriptionsInteractor = lazy {
            observeAllSubscriptionsInteractor
          },
          refreshSubscriptionEpisodesInteractor = providesRefreshSubscriptionEpisodesInteractor(
            workScheduler = workScheduler
          ),
          observeStringPreferenceInteractor = providesObserveStringPreference(
            preferences = preferences
          ),
          putStringPreferenceInteractor = putStringPreferenceInteractor,
          observeEpisodesInteractor = observeEpisodesInteractor,
          observeNowPlaying = observePodcastNowPlayingInteractor,
          pauseInteractor = pauseInteractor,
          resumePlaybackInteractor = resumePlaybackInteractor,
          playInteractor = playInteractor,
          observeAllPlayedInteractor = observeAllPlayedInteractor,
          downloadsInteractor = downloadInteractor,
          deleteDownloadInteractor = deleteDownloadInteractor,
          observePlayQueueInteractor = observePlayQueueInteractor,
          addToPlayQueueInteractor = addToPlayQueueInteractor,
          removeFromPlayQueueInteractor = removeFromPlayQueueInteractor,
          markPlayedInteractor = markAsPlayedInteractor,
          shareEpisodeInteractor = shareEpisodeInteractor
        ),
        onboardingPresenterFactory = OnboardingPresenterFactory(
          addSubscriptionInteractor = addSubscriptionInteractor,
          deleteSubscriptionInteractor = deleteSubscriptionInteractor,
          getDiscoveryInteractor = providesGetDiscoveryInteractor(
            trendingRepository = trendingRepository
          ),
          signInInteractor = signInInteractor,
          verifySignInInteractor = verifySignInInteractor,
          putBooleanPreferenceInteractor = putBooleanPreferenceInteractor,
          observeSignedInInteractor = observeSignedInInteractor,
          observeAllSubscriptionsInteractor = observeAllSubscriptionsInteractor,
          podcastInteractor = providesGetPodcastInteractor(
            podcastsRepository = podcastRepository
          )
        ),
        trendingPresenterFactory = TrendingPresenterFactory(
          getTrendingInteractor = getTrendingInteractor,
          addSubscriptionInteractor = addSubscriptionInteractor,
          observeAllSubscriptionsInteractor = observeAllSubscriptionsInteractor,
          deleteSubscriptionInteractor = deleteSubscriptionInteractor,
          playInteractor = playInteractor,
          getNowPlaying = providesGetNowPlayingInteractor(
            playedItemsRepository = playedItemsRepository,
            player = player
          ),
          observeInProgressInteractor = lazy {
            observeInProgressInteractor
          }
        ),
        subscriptionsPresenterFactory = SubscriptionsPresenterFactory(
          observeSubscriptionInteractor = observeAllSubscriptionsInteractor,
          deleteSubscriptionInteractor = deleteSubscriptionInteractor
        ),
        playerPresenterFactory = PlayerPresenterFactory(
          playInteractor = playInteractor,
          tuneInteractor = tuneInteractor,
          pauseInteractor = pauseInteractor,
          seekInteractor = providesSeekInteractor(
            player = player
          ),
          stopInteractor = providesStopInteractor(
            player = player
          ),
          resumeInteractor = resumePlaybackInteractor,
          nowPlayingInteractor = observeNowPlayingItemInteractor,
          observePlayStateInteractor = providesObservePlayStateInteractor(
            player = player
          ),
          lastPlayedInteractor = getLastPlayedInteractor,
          setPlaybackSpeedInteractor = providesSetPlaybackSpeedInteractor(
            player = player
          ),
          setAudioBoostInteractor = providesSetAudioBoostInteractor(
            player = player
          ),
          setTrimSilenceInteractor = providesSetTrimSilenceInteractor(
            player = player
          ),
          observePlaybackPropertiesInteractor = providesObservePlaybackSpeedInteractor(
            player = player
          ),
          observePlayerNameInteractor = providesObservePlayerNameInteractor(
            player = player
          ),
          observeSleepTimerInteractor = providesObserveSleepTimerInteractor(
            player = player
          ),
          incrementSleepTimerInteractor = providesIncrementSleepInteractor(
            player = player
          ),
          decrementSleepTimerInteractor = providesDecrementSleepInteractor(
            player = player
          ),
          startSleepTimerInteractor = providesStartSleepInteractor(
            player = player
          ),
          stopSleepTimerInteractor = providesStopSleepInteractor(
            player = player
          )
        ),
        listenPresenterFactory = ListenPresenterFactory(),
        podcastDetailsPresenterFactory = PodcastDetailsPresenterFactory(
          observePodcastInteractor = providesObservePodcastInteractor(
            podcastsRepository = podcastRepository,
            observeEpisodesInteractor = observeEpisodesInteractor
          ),
          playInteractor = playInteractor,
          observeNowPlaying = observePodcastNowPlayingInteractor,
          pauseInteractor = pauseInteractor,
          resumePlaybackInteractor = resumePlaybackInteractor,
          observeSubscriptionInteractor = providesObserveSubscriptionInteractor(
            subscriptionsRepository = subscriptionsRepository
          ),
          deleteSubscriptionInteractor = deleteSubscriptionInteractor,
          addSubscriptionInteractor = addSubscriptionInteractor,
          observeAllPlayedInteractor = observeAllPlayedInteractor,
          downloadInteractor = downloadInteractor,
          deleteDownloadInteractor = deleteDownloadInteractor,
          observePlayQueueInteractor = observePlayQueueInteractor,
          addToPlayQueueInteractor = addToPlayQueueInteractor,
          removeFromPlayQueueInteractor = removeFromPlayQueueInteractor,
          markPlayedInteractor = markAsPlayedInteractor,
          unmarkPlayedInteractor = unMarkAsPlayedInteractor,
          shareEpisodeInteractor = shareEpisodeInteractor,
          sharePodcastInteractor =
              _scoped.get("studio.goodegg.capsule.domain.share.SharePodcastInteractor") {
            providesSharePodcastInteractor()
          }
        ),
        resumePresenterFactory = ResumePresenterFactory(
          playInteractor = playInteractor,
          observeNowPlaying = observePodcastNowPlayingInteractor,
          pauseInteractor = pauseInteractor,
          resumePlaybackInteractor = resumePlaybackInteractor,
          observeInProgressInteractor = observeInProgressInteractor,
          markAsPlayedInteractor = markAsPlayedInteractor,
          unMarkAsPlayedInteractor = unMarkAsPlayedInteractor
        ),
        searchPresenterFactory = SearchPresenterFactory(
          searchPodcastsInteractor = providesSearchPodcastsInteractor(
            trendingRepository = searchRepository
          ),
          searchEpisodesInteractor = providesSearchEpisodesInteractor(
            trendingRepository = searchRepository
          ),
          searchRadioInteractor = providesSearchRadioInteractor(
            trendingRepository = searchRepository
          ),
          observeNowPlaying = observePodcastNowPlayingInteractor,
          pauseInteractor = pauseInteractor,
          resumePlaybackInteractor = resumePlaybackInteractor,
          playInteractor = playInteractor,
          tuneInteractor = tuneInteractor,
          observeAllSubscriptionsInteractor = observeAllSubscriptionsInteractor,
          addSubscriptionInteractor = addSubscriptionInteractor,
          deleteSubscriptionInteractor = deleteSubscriptionInteractor,
          downloadsInteractor = downloadInteractor,
          deleteDownloadInteractor = deleteDownloadInteractor,
          observePlayQueueInteractor = observePlayQueueInteractor,
          addToPlayQueueInteractor = addToPlayQueueInteractor,
          removeFromPlayQueueInteractor = removeFromPlayQueueInteractor
        ),
        signInPresenterFactory = SignInPresenterFactory(
          signInInteractor = signInInteractor,
          verifySignInInteractor = verifySignInInteractor,
          signOutInteractor = signOutInteractor,
          observeSignedInInteractor = observeSignedInInteractor
        ),
        downloadsPresenterFactory = DownloadsPresenterFactory(
          observeDownloadedInteractor = providesObserveDownloadedInteractor(
            repository = downloadsRepository
          ),
          deleteDownloadInteractor = deleteDownloadInteractor,
          playInteractor = playInteractor
        ),
        episodeDetailsPresenterFactory = EpisodeDetailsPresenterFactory(
          observeEpisodeInteractor = providesObserveEpisode(
            podcastsRepository = podcastRepository,
            downloadsRepository = downloadsRepository,
            playedItemsRepository = playedItemsRepository,
            player = player
          ),
          observeNowPlaying = observePodcastNowPlayingInteractor,
          pauseInteractor = pauseInteractor,
          resumePlaybackInteractor = resumePlaybackInteractor,
          playInteractor = playInteractor,
          downloadsInteractor = downloadInteractor,
          deleteDownloadInteractor = deleteDownloadInteractor,
          observePlayQueueInteractor = observePlayQueueInteractor,
          addToPlayQueueInteractor = addToPlayQueueInteractor,
          removeFromPlayQueueInteractor = removeFromPlayQueueInteractor,
          shareEpisodeInteractor = shareEpisodeInteractor,
          markPlayedInteractor = markAsPlayedInteractor,
          unmarkPlayedInteractor = unMarkAsPlayedInteractor,
          observePlayedItem = providesObservePlayedItemInteractor(
            playedItemsRepository = playedItemsRepository
          )
        ),
        genrePresenterFactory = GenrePresenterFactory(
          searchGenreInteractor = providesSearchGenresInteractor(
            searchRepository = searchRepository
          ),
          addSubscriptionInteractor = addSubscriptionInteractor,
          observeAllSubscriptionsInteractor = observeAllSubscriptionsInteractor,
          deleteSubscriptionInteractor = deleteSubscriptionInteractor
        ),
        categoriesPresenterFactory = CategoriesPresenterFactory(
          getTrendingInteractor = getTrendingInteractor
        ),
        splashPresenterFactory = SplashPresenterFactory(
          getBooleanPreferenceInteractor = providesGetBooleanPreference(
            preferences = preferences
          )
        ),
        settingsPresenterFactory = SettingsPresenterFactory(
          putStringPreferenceInteractor = putStringPreferenceInteractor,
          putBooleanPreferenceInteractor = putBooleanPreferenceInteractor,
          signOutInteractor = signOutInteractor,
          observeSignedInInteractor = observeSignedInInteractor
        ),
        playQueuePresenterFactory = PlayQueuePresenterFactory(
          observePlayQueueInteractor = observePlayQueueInteractor,
          observeNowPlayingItemInteractor = observeNowPlayingItemInteractor,
          getLastPlayedInteractor = getLastPlayedInteractor,
          removeFromPlayQueueInteractor = removeFromPlayQueueInteractor,
          setPlayQueueInteractor = providesSetPlayQueueInteractor(
            player = player
          )
        )
      )
    }
}
