package studio.goodegg.capsule.signin

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.runtime.snapshotFlow
import com.slack.circuit.retained.collectAsRetainedState
import com.slack.circuit.retained.rememberRetained
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.Navigator
import com.slack.circuit.runtime.presenter.Presenter
import com.slack.circuit.runtime.resetRoot
import com.slack.circuit.runtime.screen.Screen
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.launch
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.domain.auth.GetIsSignedInInteractor
import studio.goodegg.capsule.domain.auth.ObserveSignedInInteractor
import studio.goodegg.capsule.domain.auth.SignInInteractor
import studio.goodegg.capsule.domain.auth.SignOutInteractor
import studio.goodegg.capsule.domain.auth.VerifySignInInteractor
import studio.goodegg.capsule.navigation.DiscoverScreen
import studio.goodegg.capsule.navigation.InboxScreen
import studio.goodegg.capsule.navigation.OnboardingScreen
import studio.goodegg.capsule.navigation.SignInScreen

@Inject
class SignInPresenterFactory(
    private val signInInteractor: SignInInteractor,
    private val verifySignInInteractor: VerifySignInInteractor,
    private val signOutInteractor: SignOutInteractor,
    private val observeSignedInInteractor: ObserveSignedInInteractor,
) : Presenter.Factory {
    override fun create(
        screen: Screen,
        navigator: Navigator,
        context: CircuitContext,
    ): Presenter<*>? {
        return when (screen) {
            is SignInScreen -> SignInPresenter(
                signInInteractor,
                verifySignInInteractor,
                signOutInteractor,
                observeSignedInInteractor,
                navigator,
            )

            else -> null
        }
    }
}

class SignInPresenter(
    private val signInInteractor: SignInInteractor,
    private val verifySignInInteractor: VerifySignInInteractor,
    private val signOutInteractor: SignOutInteractor,
    private val observeSignedInInteractor: ObserveSignedInInteractor,
    private val navigator: Navigator,
) : Presenter<SignInUiState> {
    @Composable
    override fun present(): SignInUiState {

        val user by remember { observeSignedInInteractor() }
            .collectAsRetainedState(null)

        val scope = rememberCoroutineScope()
        var currentEmail by remember { mutableStateOf<String?>(null) }
        var awaitingCode by remember { mutableStateOf(false) }

        LaunchedEffect(user) {
            // After sign in navigate back if there is a back stack.
            if (user != null && navigator.peekBackStack().size > 1)
                navigator.pop()
        }

        fun eventSink(event: SignInUiEvent) {
            when (event) {
                is SignInUiEvent.SignInWithEmail -> scope.launch {
                    currentEmail = event.email
                    awaitingCode = true
                    signInInteractor.invoke(event.email)
                }

                is SignInUiEvent.VerifyCode -> scope.launch {
                    verifySignInInteractor.invoke(event.email, event.code)
                    if (navigator.peekBackStack().count() > 1)
                        navigator.pop()
                    else
                        navigator.resetRoot(DiscoverScreen)
                }

                SignInUiEvent.SignOut -> scope.launch {
                    signOutInteractor.invoke()
                }

                SignInUiEvent.TestOnboarding -> navigator.resetRoot(OnboardingScreen)
                SignInUiEvent.Close -> {
                    if (navigator.peekBackStack().count() > 1)
                        navigator.pop()
                    else
                        navigator.resetRoot(DiscoverScreen) // TODO Tv vs mobile have different home routes.
                }
            }
        }

        return SignInUiState(
            currentEmail = currentEmail,
            awaitingCode = awaitingCode,
            user = user,
            eventSink = ::eventSink,
        )
    }
}
