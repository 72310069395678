package studio.goodegg.capsule.ui.components

import androidx.compose.foundation.layout.width
import androidx.compose.material3.DropdownMenu
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import io.daio.pancake.foundations.Theme

enum class Filter(val title: String) {
    Latest("Latest"),
    Downloaded("Downloaded"),
    InProgress("In Progress")
}

@Composable
fun FilterMenu(
    onDismissRequest: () -> Unit,
    show: Boolean,
    onClick: (Filter) -> Unit,
    filters: List<Filter> = Filter.entries,
) {
    DropdownMenu(
        tonalElevation = 0.dp,
        containerColor = Theme.colors.surfaceEmphasized,
        expanded = show,
        onDismissRequest = onDismissRequest,
    ) {
        filters.forEach { option ->
            ContextMenuItem(
                title = option.title,
                modifier = Modifier.width(200.dp),
                icon = null,
                containerColor = Theme.colors.surfaceEmphasized,
                onClick = {
                    onClick(option)
                },
                onDismiss = onDismissRequest,
            )
        }
    }
}