package studio.goodegg.capsule.search

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.slack.circuit.retained.collectAsRetainedState
import com.slack.circuit.retained.rememberRetained
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.Navigator
import com.slack.circuit.runtime.presenter.Presenter
import com.slack.circuit.runtime.screen.Screen
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.joinAll
import kotlinx.coroutines.launch
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.Episode
import studio.goodegg.capsule.PlayState
import studio.goodegg.capsule.PodcastResult
import studio.goodegg.capsule.PodcastSubscription
import studio.goodegg.capsule.Station
import studio.goodegg.capsule.domain.download.DeleteDownloadInteractor
import studio.goodegg.capsule.domain.download.DownloadInteractor
import studio.goodegg.capsule.domain.playback.AddToPlayQueueInteractor
import studio.goodegg.capsule.domain.playback.ObservePlayQueueInteractor
import studio.goodegg.capsule.domain.playback.ObservePodcastNowPlayingInteractor
import studio.goodegg.capsule.domain.playback.PauseInteractor
import studio.goodegg.capsule.domain.playback.PlayInteractor
import studio.goodegg.capsule.domain.playback.PlayQueuePosition
import studio.goodegg.capsule.domain.playback.RemoveFromPlayQueueInteractor
import studio.goodegg.capsule.domain.playback.ResumePlaybackInteractor
import studio.goodegg.capsule.domain.playback.TuneInteractor
import studio.goodegg.capsule.domain.search.SearchEpisodesInteractor
import studio.goodegg.capsule.domain.search.SearchPodcastsInteractor
import studio.goodegg.capsule.domain.search.SearchRadioInteractor
import studio.goodegg.capsule.domain.subscription.AddSubscriptionInteractor
import studio.goodegg.capsule.domain.subscription.DeleteSubscriptionInteractor
import studio.goodegg.capsule.domain.subscription.ObserveAllSubscriptionsInteractor
import studio.goodegg.capsule.navigation.EpisodeDetailsScreen
import studio.goodegg.capsule.navigation.PodcastDetailsScreen
import studio.goodegg.capsule.navigation.SearchScreen

@Inject
class SearchPresenterFactory(
    private val searchPodcastsInteractor: SearchPodcastsInteractor,
    private val searchEpisodesInteractor: SearchEpisodesInteractor,
    private val searchRadioInteractor: SearchRadioInteractor,
    private val observeNowPlaying: ObservePodcastNowPlayingInteractor,
    private val pauseInteractor: PauseInteractor,
    private val resumePlaybackInteractor: ResumePlaybackInteractor,
    private val playInteractor: PlayInteractor,
    private val tuneInteractor: TuneInteractor,
    private val observeAllSubscriptionsInteractor: ObserveAllSubscriptionsInteractor,
    private val addSubscriptionInteractor: AddSubscriptionInteractor,
    private val deleteSubscriptionInteractor: DeleteSubscriptionInteractor,
    private val downloadsInteractor: DownloadInteractor,
    private val deleteDownloadInteractor: DeleteDownloadInteractor,
    private val observePlayQueueInteractor: ObservePlayQueueInteractor,
    private val addToPlayQueueInteractor: AddToPlayQueueInteractor,
    private val removeFromPlayQueueInteractor: RemoveFromPlayQueueInteractor,
) : Presenter.Factory {
    override fun create(
        screen: Screen,
        navigator: Navigator,
        context: CircuitContext,
    ): Presenter<*>? {
        return when (screen) {
            is SearchScreen ->
                SearchPresenter(
                    navigator,
                    searchPodcastsInteractor,
                    searchEpisodesInteractor,
                    searchRadioInteractor,
                    observeNowPlaying,
                    pauseInteractor,
                    resumePlaybackInteractor,
                    playInteractor,
                    tuneInteractor,
                    observeAllSubscriptionsInteractor,
                    addSubscriptionInteractor,
                    deleteSubscriptionInteractor,
                    downloadsInteractor,
                    deleteDownloadInteractor,
                    observePlayQueueInteractor,
                    addToPlayQueueInteractor,
                    removeFromPlayQueueInteractor,
                )

            else -> null
        }
    }
}

class SearchPresenter(
    private val navigator: Navigator,
    private val searchPodcastsInteractor: SearchPodcastsInteractor,
    private val searchEpisodesInteractor: SearchEpisodesInteractor,
    private val searchRadioInteractor: SearchRadioInteractor,
    private val observeNowPlaying: ObservePodcastNowPlayingInteractor,
    private val pauseInteractor: PauseInteractor,
    private val resumePlaybackInteractor: ResumePlaybackInteractor,
    private val playInteractor: PlayInteractor,
    private val tuneInteractor: TuneInteractor,
    private val observeAllSubscriptionsInteractor: ObserveAllSubscriptionsInteractor,
    private val addSubscriptionInteractor: AddSubscriptionInteractor,
    private val deleteSubscriptionInteractor: DeleteSubscriptionInteractor,
    private val downloadsInteractor: DownloadInteractor,
    private val deleteDownloadInteractor: DeleteDownloadInteractor,
    private val observePlayQueueInteractor: ObservePlayQueueInteractor,
    private val addToPlayQueueInteractor: AddToPlayQueueInteractor,
    private val removeFromPlayQueueInteractor: RemoveFromPlayQueueInteractor,
) : Presenter<SearchUiState> {
    @Composable
    override fun present(): SearchUiState {
        var episodeResults by rememberRetained { mutableStateOf<List<Episode>>(emptyList()) }
        var podcastResults by rememberRetained { mutableStateOf<List<PodcastResult>>(emptyList()) }
        var radioResults by remember { mutableStateOf<List<Station>>(emptyList()) }
        var searching by remember { mutableStateOf(false) }
        val scope = rememberCoroutineScope()
        var zeroState by remember { mutableStateOf<String?>(null) }

        val subscriptions by rememberRetained {
            observeAllSubscriptionsInteractor().map { it.map { sub -> sub.slug }.toSet() }
        }.collectAsRetainedState(emptySet())

        val playQueue by rememberRetained {
            observePlayQueueInteractor.invoke().map { it.map { it.episodeSlug } }
        }.collectAsRetainedState(emptyList())

        val nowPlaying by remember { observeNowPlaying() }.collectAsState(null)

        fun eventSink(event: SearchUiEvent) {
            when (event) {
                is SearchUiEvent.OpenEpisode -> {
                    navigator.goTo(EpisodeDetailsScreen(event.slug))
                }

                is SearchUiEvent.OpenPodcast -> {
                    navigator.goTo(PodcastDetailsScreen(event.slug))
                }

                is SearchUiEvent.Search -> {
                    searching = true
                    episodeResults = emptyList()
                    podcastResults = emptyList()
                    radioResults = emptyList()
                    zeroState = null
                    scope.launch {
                        listOf(
                            launch {
                                episodeResults =
                                    if (event.includeEpisodes) searchEpisodesInteractor(event.query) else emptyList()
                            },
                            launch {
                                podcastResults = searchPodcastsInteractor(event.query)
                            },
//                            launch {
//                                radioResults = searchRadioInteractor(event.query)
//                            },
                        ).joinAll()

                        zeroState =
                            if (episodeResults.isEmpty() && podcastResults.isEmpty() && radioResults.isEmpty()) {
                                "No Results"
                            } else {
                                null
                            }

                        searching = false
                    }
                }

                is SearchUiEvent.Play -> {
                    if (nowPlaying?.episode?.episodeSlug == event.episode.episodeSlug) {
                        when (nowPlaying?.playingState?.playState) {
                            PlayState.Buffering, PlayState.Playing -> pauseInteractor()
                            PlayState.Paused -> resumePlaybackInteractor()
                            else -> Unit
                        }
                    } else {
                        scope.launch {
                            playInteractor(event.episode, 0)
                        }
                    }
                }

                is SearchUiEvent.Subscribe ->
                    scope.launch {
                        if (subscriptions.contains(event.podcastResult.slug)) {
                            deleteSubscriptionInteractor(event.podcastResult.slug)
                        } else {
                            addSubscriptionInteractor(
                                PodcastSubscription(
                                    event.podcastResult.slug,
                                    event.podcastResult.feed,
                                    event.podcastResult.title,
                                    event.podcastResult.author,
                                    event.podcastResult.imageUrl,
                                    true,
                                    null,
                                    false,
                                ),
                            )
                        }
                    }

                is SearchUiEvent.Download -> {
                    val download = event.episode.download
                    if (download != null) {
                        scope.launch {
                            deleteDownloadInteractor(download.slug)
                        }
                    } else {
                        downloadsInteractor(
                            event.episode.episode.episodeSlug,
                            event.episode.episode.media,
                            event.episode.episode.title,
                        )
                    }
                }

                is SearchUiEvent.Share -> {
                }

                is SearchUiEvent.Tune -> {
                    scope.launch {
                        tuneInteractor(event.station)
                    }
                }

                SearchUiEvent.Back -> navigator.pop()
                is SearchUiEvent.AddToPlayQueue -> {
                    addToPlayQueueInteractor.invoke(event.data, PlayQueuePosition.End)
                }

                is SearchUiEvent.RemoveFromPlayQueue -> {
                    removeFromPlayQueueInteractor.invoke(event.data)
                }
            }
        }

        return SearchUiState(
            searching = searching,
            podcastResults = podcastResults,
            episodeResults = episodeResults,
            radioResults = radioResults,
            zeroState = zeroState,
            nowPlaying = nowPlaying,
            playQueue = playQueue,
            subscriptions = subscriptions,
            eventSink = ::eventSink,
        )
    }
}
