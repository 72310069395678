package studio.goodegg.capsule.subscriptions

import androidx.compose.foundation.lazy.grid.items
import androidx.compose.runtime.Composable
import androidx.compose.runtime.key
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.screen.Screen
import com.slack.circuit.runtime.ui.Ui
import com.slack.circuit.runtime.ui.ui
import io.daio.pancake.layout.LazyStackGrid
import studio.goodegg.capsule.navigation.SubscriptionsScreen
import studio.goodegg.capsule.ui.components.ArtworkTile

class SubscriptionsScreenUiFactory : Ui.Factory {
    override fun create(
        screen: Screen,
        context: CircuitContext,
    ): Ui<*>? {
        return when (screen) {
            is SubscriptionsScreen -> subscriptions()
            else -> null
        }
    }
}

private fun subscriptions() =
    ui<SubscriptionsUiState> { state, modifier ->
        Subscriptions(
            state,
            modifier,
        )
    }

@Composable
fun Subscriptions(
    state: SubscriptionsUiState,
    modifier: Modifier = Modifier,
) {
    LazyStackGrid(modifier = modifier, columns = 3) {
        items(state.subscriptions, key = { it.slug }) {
            ArtworkTile(
                size = 120.dp,
                imageUrl = it.image,
                onClick = {
                    state.eventSink(SubscriptionsUiEvent.Open(it.slug))
                },
            )
        }
    }
}
