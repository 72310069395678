package studio.goodegg.capsule.core.playback

import kotlinx.coroutines.CoroutineDispatcher
import me.tatarka.inject.annotations.Component
import me.tatarka.inject.annotations.Provides
import studio.goodegg.capsule.FileStorageLocation
import studio.goodegg.capsule.common.coroutines.IoDispatcher

expect interface PlatformPlayerComponent

@Component
interface PlayerComponent : PlatformPlayerComponent {
    @Provides
    fun providesPlayQueueDataStore(
        fileStorageLocation: FileStorageLocation,
        @IoDispatcher dispatcher: CoroutineDispatcher,
    ): PlayQueueDataStore = PlayQueueDataStore(
        fileStorageLocation,
        dispatcher,
    )

    @Provides
    fun providesPlayQueue(
        playQueueDataStore: PlayQueueDataStore,
        @IoDispatcher dispatcher: CoroutineDispatcher,
    ): PlayerQueue =
        PlayerQueue(playQueueDataStore, dispatcher)
}
