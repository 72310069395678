package studio.goodegg.capsule.repositories

import app.cash.sqldelight.async.coroutines.awaitAsList
import app.cash.sqldelight.async.coroutines.awaitAsOneOrNull
import app.cash.sqldelight.coroutines.asFlow
import app.cash.sqldelight.coroutines.mapToList
import app.cash.sqldelight.coroutines.mapToOneOrNull
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.async
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.distinctUntilChanged
import kotlinx.coroutines.flow.flatMapMerge
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.flowOn
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.flow.onEmpty
import kotlinx.coroutines.flow.onStart
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.Episode
import studio.goodegg.capsule.Podcast
import studio.goodegg.capsule.api.clients.PodcastBatch
import studio.goodegg.capsule.api.clients.PodcastClient
import studio.goodegg.capsule.common.coroutines.IoDispatcher
import studio.goodegg.capsule.db.CapsuleDbCreator
import studio.goodegg.capsule.db.Dbepisode
import studio.goodegg.capsule.db.Dbpodcast
import studio.goodegg.capsule.db.asFlow
import studio.goodegg.capsule.utils.Failed
import studio.goodegg.capsule.utils.Success

enum class RefreshPolicy {
    Network, Database, Default
}

interface PodcastRepository {
    suspend fun getPodcast(slug: String): Podcast?
    suspend fun getPodcastName(slug: String): String?
    suspend fun getEpisode(
        slug: String,
        refreshPolicy: RefreshPolicy = RefreshPolicy.Default,
    ): Episode?

    suspend fun getLatestEpisodes(slugs: List<String>): List<Episode>
    fun observePodcastAndEpisodes(slug: String): Flow<Podcast?>
    fun observePodcastOnly(slug: String): Flow<Podcast?>
    fun observeEpisode(slug: String): Flow<Episode?>
    fun observeEpisodes(parentSlugs: List<String>, limit: Int): Flow<List<Episode>>
}

@Inject
internal class PodcastRepositoryImpl(
    private val capsuleDbCreator: CapsuleDbCreator,
    private val client: PodcastClient,
    @IoDispatcher private val ioDispatcher: CoroutineDispatcher,
) : PodcastRepository {

    private val updateScope = CoroutineScope(
        SupervisorJob() + ioDispatcher.limitedParallelism(1),
    )

    private suspend fun queries() = capsuleDbCreator.get().podcastQueries

    override suspend fun getPodcast(slug: String): Podcast? = withContext(ioDispatcher) {
        val episodes = async {
            queries().selectPodcastEpisodes(slug).awaitAsList()
                .map {
                    it.toEpisode()
                }
        }

        val dbPodcast = queries().selectPodcast(slug)
            .awaitAsOneOrNull()?.toPodcast(episodes.await())

        if (dbPodcast != null) {
            updateScope.launch {
                refreshPodcast(slug)
            }
            return@withContext dbPodcast
        }

        when (val response = client.getPodcast(slug)) {
            is Failed -> null
            is Success -> response.data.also {
                updateScope.launch {
                    storeResults(it)
                }
            }
        }
    }

    override suspend fun getPodcastName(slug: String): String? = withContext(ioDispatcher) {
        if (slug.isBlank())
            return@withContext null

        queries().selectPodcast(slug).awaitAsOneOrNull()?.title
    }

    override suspend fun getEpisode(slug: String, refreshPolicy: RefreshPolicy): Episode? =
        withContext(ioDispatcher) {
            val dbEpisode = queries().selectEpisode(slug)
                .awaitAsOneOrNull()

            when (refreshPolicy) {
                RefreshPolicy.Network -> {
                    when (val response = client.getEpisode(slug)) {
                        is Failed -> null
                        is Success -> response.data
                    }
                }

                RefreshPolicy.Database -> dbEpisode?.toEpisode()
                RefreshPolicy.Default -> {
                    if (dbEpisode != null) {
                        updateScope.launch { refreshEpisode(dbEpisode.episodeSlug) }
                        return@withContext dbEpisode.toEpisode()
                    }

                    when (val response = client.getEpisode(slug)) {
                        is Failed -> null
                        is Success -> response.data
                    }
                }
            }
        }

    override suspend fun getLatestEpisodes(slugs: List<String>): List<Episode> =
        withContext(ioDispatcher) {
            val batch = slugs.mapNotNull { slug ->
                val episode = queries().selectLatestPodcastEpisodes(slug).awaitAsOneOrNull()
                    ?: return@mapNotNull null

                PodcastBatch(slug, episode.created)
            }

            refreshEpisodes(batch).flatMap { it.episodes }
        }

    @OptIn(ExperimentalCoroutinesApi::class)
    override fun observePodcastAndEpisodes(slug: String): Flow<Podcast?> {
        return capsuleDbCreator.asFlow {
            combine(
                podcastQueries.selectPodcast(slug)
                    .asFlow()
                    .mapToOneOrNull(ioDispatcher)
                    .distinctUntilChanged(),
                podcastQueries.selectPodcastEpisodes(slug)
                    .asFlow()
                    .mapToList(ioDispatcher)
                    .distinctUntilChanged().onStart { emit(emptyList()) },
            ) { podcastResults, episodesResults ->
                if (podcastResults == null)
                    return@combine null

                val episodes = episodesResults.map { result ->
                    result.toEpisode()
                }

                podcastResults.toPodcast(episodes)
            }.distinctUntilChanged()
                .flatMapMerge {
                    flow {
                        emit(it)
                        updateScope.launch {
                            refreshPodcast(slug)
                        }
                    }
                }
                .flowOn(ioDispatcher)
        }
    }

    override fun observePodcastOnly(slug: String): Flow<Podcast?> {
        return capsuleDbCreator.asFlow {
            podcastQueries.selectPodcast(slug)
                .asFlow()
                .onStart {
                    updateScope.launch {
                        refreshPodcast(slug)
                    }
                }
                .mapToOneOrNull(ioDispatcher)
                .map { it?.toPodcast() }
                .distinctUntilChanged()
                .flowOn(ioDispatcher)
        }
    }

    override fun observeEpisode(slug: String): Flow<Episode?> {
        return capsuleDbCreator.asFlow {
            podcastQueries.selectEpisode(slug)
                .asFlow()
                .mapToOneOrNull(ioDispatcher)
                .distinctUntilChanged()
                .map {
                    it?.toEpisode()
                }
                //  TODO refreshing the single episode fetches the specific episode data which
                // can change some of the details.
                .onStart {
                    updateScope.launch {
                        refreshEpisode(slug)
                    }
                }
        }
    }

    @OptIn(ExperimentalCoroutinesApi::class)
    override fun observeEpisodes(parentSlugs: List<String>, limit: Int): Flow<List<Episode>> {
        return capsuleDbCreator.asFlow {
            podcastQueries.selectListEpisodes(parentSlugs, limit.toLong())
                .asFlow()
                .mapToList(ioDispatcher)
                .distinctUntilChanged()
                .map { dbEpisodes ->
                    dbEpisodes.map { it.toEpisode() }.distinctBy { it.episodeSlug }
                }
                .distinctUntilChanged()
                .flatMapMerge {
                    flow {
                        emit(it)
                        val batches = it.distinctBy { it.parentSlug }
                            .mapNotNull { episode ->
                                episode.parentSlug?.let {
                                    PodcastBatch(it, episode.created)
                                }
                            }

                        val batchesSlugs = batches.map { it.slug }.toSet()
                        val missing = parentSlugs.filter { it !in batchesSlugs }.map {
                            PodcastBatch(it, 0)
                        }

                        refreshEpisodes(batches + missing)
                    }
                }
                .flowOn(ioDispatcher)
        }
    }

    private suspend fun refreshPodcast(slug: String) = withContext(ioDispatcher) {
        // TODO until paging support we request an initial small limit to increase speed
        // then follow up with the full request.
        val podcast = client.getPodcast(slug, limit = 5) as? Success

        if (podcast != null) {
            storeResults(podcast.data)

            // TODO this is a hack to fetch all of the podcasts after an initial limit of 100
            val all = client.getPodcast(slug) as? Success

            if (all != null)
                storeResults(podcast.data)
        }
    }

    private suspend fun refreshEpisode(slug: String) = withContext(ioDispatcher) {
        val episode = queries().selectEpisode(slug).awaitAsOneOrNull()
        if (episode != null)
            return@withContext

        val fetchEpisode = client.getEpisode(slug) as? Success
        val feed = fetchEpisode?.data?.parentFeed
        val parentSlug = fetchEpisode?.data?.parentSlug
        if (fetchEpisode != null && parentSlug != null && feed != null)
            storeEpisode(fetchEpisode.data, feed, slug)
    }

    private suspend fun refreshEpisodes(podcasts: List<PodcastBatch>): List<Podcast> =
        withContext(ioDispatcher) {
            val fetchEpisode = client.getPodcastsBatch(podcasts) as? Success
            fetchEpisode?.data?.onEach { podcast ->
                storeResults(podcast)
            }.orEmpty()
        }

    private suspend fun storeResults(podcast: Podcast) = withContext(ioDispatcher) {
        queries().transaction {
            queries().insertPodcast(
                podcast.slug,
                podcast.id.toLong(),
                podcast.title,
                podcast.author,
                podcast.feed,
                podcast.collectionId.toLong(),
                podcast.artistId?.toLong(),
                podcast.thumbnail!!,
                podcast.imageUrl,
                podcast.trackCount.toLong(),
                podcast.primaryGenreName,
                podcast.summary,
                podcast.primaryGenreName,
                podcast.feedImage,
            )
            podcast.episodes.forEach { episode ->
                storeEpisode(episode, podcast.feed, podcast.slug)
            }
        }
    }

    private suspend fun storeEpisode(
        episode: Episode,
        parentFeed: String,
        parentSlug: String,
    ) = withContext(ioDispatcher) {
        queries().insertEpisode(
            episode.episodeSlug,
            episode.title,
            episode.author,
            episode.created,
            episode.description,
            episode.image,
            episode.media,
            episode.mediaType,
            episode.fileSize.toLong(),
            episode.duration,
            episode.parentFeed ?: parentFeed,
            episode.parentSlug ?: parentSlug,
        )
    }
}

private fun Dbpodcast.toPodcast(episodes: List<Episode> = emptyList()) = Podcast(
    slug = slug,
    id = id.toInt(),
    title = title,
    author = author,
    feed = feed,
    collectionId = collectionId.toInt(),
    artistId = artistId?.toInt(),
    thumbnail = thumbnail,
    imageUrl = imageUrl,
    feedImage = imageUrl,
    trackCount = trackCount?.toInt() ?: 0,
    primaryGenreName = primaryGenreName,
    summary = summary,
    episodes = episodes,
)

internal fun Dbepisode.toEpisode() = Episode(
    episodeSlug = episodeSlug,
    title = title,
    author = author,
    created = created,
    description = description,
    image = image ?: "",
    media = media,
    mediaType = mediaType,
    fileSize = fileSize?.toInt() ?: 0,
    duration = duration ?: 0,
    parentFeed = parentFeed,
    parentSlug = parent_slug,
    episodeType = "full", // TODO add to db
)