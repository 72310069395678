package studio.goodegg.capsule.categories

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.text.intl.Locale
import com.slack.circuit.retained.produceRetainedState
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.Navigator
import com.slack.circuit.runtime.presenter.Presenter
import com.slack.circuit.runtime.screen.Screen
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.Category
import studio.goodegg.capsule.domain.trending.GetTrendingInteractor
import studio.goodegg.capsule.navigation.CategoriesGrid
import studio.goodegg.capsule.navigation.GenrePodcastsGridScreen

@Inject
class CategoriesPresenterFactory(
    private val getTrendingInteractor: GetTrendingInteractor,
) : Presenter.Factory {
    override fun create(
        screen: Screen,
        navigator: Navigator,
        context: CircuitContext,
    ): Presenter<*>? {
        return when (screen) {
            is CategoriesGrid ->
                CategoriesPresenter(
                    getTrendingInteractor,
                    navigator,
                )

            else -> null
        }
    }
}

@Inject
class CategoriesPresenter(
    private val getTrendingInteractor: GetTrendingInteractor,
    private val navigator: Navigator,
) : Presenter<CategoriesUiState> {
    @Composable
    override fun present(): CategoriesUiState {
        fun events(event: CategoriesUiEvent) {
            when (event) {

                CategoriesUiEvent.Back -> navigator.pop()
                is CategoriesUiEvent.OpenCategory -> navigator.goTo(
                    GenrePodcastsGridScreen(
                        event.title,
                        event.genreId,
                    ),
                )
            }
        }

        var loading by remember { mutableStateOf(false) }

        val podcasts by produceRetainedState<List<Category>>(
            emptyList(),
        ) {
            loading = true
            val categories = getTrendingInteractor.invoke(Locale.current.region).categories
            value = categories
            loading = false
        }

        return CategoriesUiState(
            refreshing = loading,
            items = podcasts,
            eventSink = ::events,
        )
    }
}
