package studio.goodegg.capsule.trending

import androidx.compose.animation.scaleIn
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.text.intl.Locale
import com.slack.circuit.retained.collectAsRetainedState
import com.slack.circuit.retained.produceRetainedState
import com.slack.circuit.retained.rememberRetained
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.Navigator
import com.slack.circuit.runtime.presenter.Presenter
import com.slack.circuit.runtime.screen.Screen
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.PodcastResult
import studio.goodegg.capsule.PodcastSubscription
import studio.goodegg.capsule.TrendingResult
import studio.goodegg.capsule.domain.playback.GetNowPlayingInteractor
import studio.goodegg.capsule.domain.playback.ObserveInProgressInteractor
import studio.goodegg.capsule.domain.playback.ObservePodcastNowPlayingInteractor
import studio.goodegg.capsule.domain.playback.PauseInteractor
import studio.goodegg.capsule.domain.playback.PlayInteractor
import studio.goodegg.capsule.domain.playback.ResumePlaybackInteractor
import studio.goodegg.capsule.domain.subscription.AddSubscriptionInteractor
import studio.goodegg.capsule.domain.subscription.DeleteSubscriptionInteractor
import studio.goodegg.capsule.domain.subscription.ObserveAllSubscriptionsInteractor
import studio.goodegg.capsule.domain.trending.GetTrendingInteractor
import studio.goodegg.capsule.navigation.CategoriesGrid
import studio.goodegg.capsule.navigation.DiscoverScreen
import studio.goodegg.capsule.navigation.FullPlayerScreen
import studio.goodegg.capsule.navigation.GenrePodcastsGridScreen
import studio.goodegg.capsule.navigation.PodcastDetailsScreen
import studio.goodegg.capsule.navigation.SearchScreen

@Inject
class TrendingPresenterFactory(
    private val getTrendingInteractor: GetTrendingInteractor,
    private val addSubscriptionInteractor: AddSubscriptionInteractor,
    private val observeAllSubscriptionsInteractor: ObserveAllSubscriptionsInteractor,
    private val deleteSubscriptionInteractor: DeleteSubscriptionInteractor,
    private val playInteractor: PlayInteractor,
    private val getNowPlaying: GetNowPlayingInteractor,
    private val observeInProgressInteractor: Lazy<ObserveInProgressInteractor>,
) : Presenter.Factory {
    override fun create(
        screen: Screen,
        navigator: Navigator,
        context: CircuitContext,
    ): Presenter<*>? {
        return when (screen) {
            is DiscoverScreen ->
                TrendingPresenter(
                    navigator,
                    getTrendingInteractor,
                    addSubscriptionInteractor,
                    observeAllSubscriptionsInteractor,
                    deleteSubscriptionInteractor,
                    playInteractor,
                    getNowPlaying,
                    observeInProgressInteractor,
                )

            else -> null
        }
    }
}

@Inject
class TrendingPresenter(
    private val navigator: Navigator,
    private val getTrendingInteractor: GetTrendingInteractor,
    private val addToSubscriptions: AddSubscriptionInteractor,
    private val observeAllSubscriptionsInteractor: ObserveAllSubscriptionsInteractor,
    private val deleteSubscriptionInteractor: DeleteSubscriptionInteractor,
    private val playInteractor: PlayInteractor,
    private val getNowPlaying: GetNowPlayingInteractor,
    private val observeInProgressInteractor: Lazy<ObserveInProgressInteractor>,
) : Presenter<TrendingUiState> {
    @Composable
    override fun present(): TrendingUiState {
        val scope = rememberCoroutineScope()

        val subscriptions by rememberRetained {
            observeAllSubscriptionsInteractor()
                .map { subscriptions ->
                    subscriptions.map { it.slug }.toSet()
                }
        }.collectAsRetainedState(emptySet())

        fun events(event: TrendingUiEvent) {
            when (event) {
                is TrendingUiEvent.OpenPodcast -> {
                    navigator.goTo(PodcastDetailsScreen(event.slug))
                }

                is TrendingUiEvent.OpenSearch -> navigator.goTo(SearchScreen)
                is TrendingUiEvent.AddToSubscriptions -> {
                    scope.launch {
                        if (subscriptions.contains(event.podcast.slug)) {
                            deleteSubscriptionInteractor(event.podcast.slug)
                        } else {
                            addToSubscriptions(event.podcast.toSubscription())
                        }
                    }
                }

                is TrendingUiEvent.OpenCategory -> navigator.goTo(
                    GenrePodcastsGridScreen(
                        event.label,
                        event.genreId,
                    ),
                )

                TrendingUiEvent.OpenCategories -> navigator.goTo(CategoriesGrid)
                is TrendingUiEvent.Play -> {
                    scope.launch {
                        val nowPlaying = getNowPlaying.invoke()

                        if (nowPlaying?.episode?.episodeSlug == event.episode.episode.episodeSlug) {
                            if (event.openPlayer) {
                                navigator.goTo(FullPlayerScreen)
                            }
                            return@launch
                        }

                        playInteractor.invoke(
                            event.episode.episode,
                            event.episode.currentPosition,
                        )
                        if (event.openPlayer) {
                            navigator.goTo(FullPlayerScreen)
                        }
                    }
                }
            }
        }

        val trending by produceRetainedState(
            TrendingResult(
                emptyList(), emptyList(), emptyList(),
            ),
        ) {
            val trending = getTrendingInteractor.invoke(Locale.current.region)
//            value = trending.subList(0, 1)
//            // TODO just a short delay to make sure the transition is completed first to avoid jank.
//            delay(300)
            value = trending
        }

        return TrendingUiState(
            refreshing = trending.trending.isEmpty() && trending.categories.isEmpty() && trending.top.isEmpty(),
            subscriptions = subscriptions,
            items = trending,
            inProgress = {
                rememberRetained { observeInProgressInteractor.value() }
                    .collectAsRetainedState(emptyList())
                    .value
            },
            eventSink = ::events,
        )
    }
}

private fun PodcastResult.toSubscription(): PodcastSubscription {
    return PodcastSubscription(
        slug = slug,
        feedUrl = feed,
        title = title,
        artist = author,
        image = imageUrl,
        notify = true,
        collection = null,
        autoDownload = false,
    )
}
