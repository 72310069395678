package studio.goodegg.capsule.subscriptions

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.rememberCoroutineScope
import com.slack.circuit.retained.rememberRetained
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.Navigator
import com.slack.circuit.runtime.presenter.Presenter
import com.slack.circuit.runtime.screen.Screen
import kotlinx.coroutines.launch
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.domain.subscription.DeleteSubscriptionInteractor
import studio.goodegg.capsule.domain.subscription.ObserveAllSubscriptionsInteractor
import studio.goodegg.capsule.navigation.PodcastDetailsScreen
import studio.goodegg.capsule.navigation.SubscriptionsScreen

@Inject
class SubscriptionsPresenterFactory(
    private val observeSubscriptionInteractor: ObserveAllSubscriptionsInteractor,
    private val deleteSubscriptionInteractor: DeleteSubscriptionInteractor,
) : Presenter.Factory {
    override fun create(
        screen: Screen,
        navigator: Navigator,
        context: CircuitContext,
    ): Presenter<*>? {
        return when (screen) {
            is SubscriptionsScreen ->
                SubscriptionsPresenter(
                    navigator = navigator,
                    observeSubscriptionInteractor = observeSubscriptionInteractor,
                    removeSubscription = deleteSubscriptionInteractor,
                )

            else -> null
        }
    }
}

class SubscriptionsPresenter(
    private val navigator: Navigator,
    private val observeSubscriptionInteractor: ObserveAllSubscriptionsInteractor,
    private val removeSubscription: DeleteSubscriptionInteractor,
) : Presenter<SubscriptionsUiState> {
    @Composable
    override fun present(): SubscriptionsUiState {
        val subscriptions by rememberRetained { observeSubscriptionInteractor() }
            .collectAsState(emptyList())

        val scope = rememberCoroutineScope()

        fun eventSink(event: SubscriptionsUiEvent) {
            when (event) {
                is SubscriptionsUiEvent.Open -> {
                    navigator.goTo(PodcastDetailsScreen(event.slug))
                }

                is SubscriptionsUiEvent.Remove -> {
                    scope.launch {
                        removeSubscription(event.slug)
                    }
                }
            }
        }

        return SubscriptionsUiState(
            subscriptions = subscriptions,
            eventSink = ::eventSink,
        )
    }
}
