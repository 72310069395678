

// Do not edit directly
// Generated on Thu, 06 Feb 2025 22:47:57 GMT



package io.daio.pancake.foundations.color;

import androidx.compose.ui.graphics.Color

object ThemeColors {
  val themeDarkAccent = Color(0xffff7f50)
  val themeDarkInformNegative = Color(0xffd64933)
  val themeDarkInformPositive = Color(0xff7ea77b)
  val themeDarkInformWarning = Color(0xfff4d35e)
  val themeDarkOnSurfaceAccent = Color(0xff000000)
  val themeDarkOnSurfaceBackground = Color(0xffffffff)
  val themeDarkOnSurfaceContainer = Color(0xffffffff)
  val themeDarkOnSurfaceEmphasized = Color(0xffffffff)
  val themeDarkOnSurfaceInteractive = Color(0xff000000)
  val themeDarkSurfaceBackground = Color(0xff121212)
  val themeDarkSurfaceContainer = Color(0xff1d1d1d)
  val themeDarkSurfaceEmphasized = Color(0xff000000)
  val themeDarkSurfaceInteractive = Color(0xffffffff)
  val themeLightAccent = Color(0xff8b4513)
  val themeLightInformNegative = Color(0xffd64933)
  val themeLightInformPositive = Color(0xff7ea77b)
  val themeLightInformWarning = Color(0xfff4d35e)
  val themeLightOnSurfaceAccent = Color(0xffffffff)
  val themeLightOnSurfaceBackground = Color(0xff000000)
  val themeLightOnSurfaceContainer = Color(0xff000000)
  val themeLightOnSurfaceEmphasized = Color(0xff000000)
  val themeLightOnSurfaceInteractive = Color(0xffffffff)
  val themeLightSurfaceBackground = Color(0xffffffff)
  val themeLightSurfaceContainer = Color(0xffececec)
  val themeLightSurfaceEmphasized = Color(0xffc5c5c5)
  val themeLightSurfaceInteractive = Color(0xff000000)
}
