package studio.goodegg.capsule.inbox

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.slack.circuit.retained.collectAsRetainedState
import com.slack.circuit.retained.rememberRetained
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.Navigator
import com.slack.circuit.runtime.presenter.Presenter
import com.slack.circuit.runtime.screen.Screen
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.FlowPreview
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.flow.flatMapLatest
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.onStart
import kotlinx.coroutines.launch
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.EpisodeAndMetadata
import studio.goodegg.capsule.PlayState
import studio.goodegg.capsule.PodcastSubscription
import studio.goodegg.capsule.domain.download.DeleteDownloadInteractor
import studio.goodegg.capsule.domain.download.DownloadInteractor
import studio.goodegg.capsule.domain.playback.AddToPlayQueueInteractor
import studio.goodegg.capsule.domain.playback.MarkAsPlayedInteractor
import studio.goodegg.capsule.domain.playback.ObserveAllPlayedInteractor
import studio.goodegg.capsule.domain.playback.ObservePlayQueueInteractor
import studio.goodegg.capsule.domain.playback.ObservePodcastNowPlayingInteractor
import studio.goodegg.capsule.domain.playback.PauseInteractor
import studio.goodegg.capsule.domain.playback.PlayInteractor
import studio.goodegg.capsule.domain.playback.PlayQueuePosition
import studio.goodegg.capsule.domain.playback.RemoveFromPlayQueueInteractor
import studio.goodegg.capsule.domain.playback.ResumePlaybackInteractor
import studio.goodegg.capsule.domain.podcast.ObserveEpisodesInteractor
import studio.goodegg.capsule.domain.settings.ObserveStringPreferenceInteractor
import studio.goodegg.capsule.domain.settings.PutStringPreferenceInteractor
import studio.goodegg.capsule.domain.share.ShareEpisodeInteractor
import studio.goodegg.capsule.domain.share.SharePodcastInteractor
import studio.goodegg.capsule.domain.subscription.ObserveAllSubscriptionsInteractor
import studio.goodegg.capsule.domain.subscription.RefreshSubscriptionEpisodesInteractor
import studio.goodegg.capsule.navigation.DiscoverScreen
import studio.goodegg.capsule.navigation.EpisodeDetailsScreen
import studio.goodegg.capsule.navigation.InboxScreen
import studio.goodegg.capsule.navigation.PodcastDetailsScreen
import studio.goodegg.capsule.navigation.SubscriptionsScreen
import studio.goodegg.capsule.ui.components.Filter

@Inject
class InboxPresenterFactory(
    private val observeAllSubscriptionsInteractor: Lazy<ObserveAllSubscriptionsInteractor>,
    private val refreshSubscriptionEpisodesInteractor: RefreshSubscriptionEpisodesInteractor,
    private val observeStringPreferenceInteractor: ObserveStringPreferenceInteractor,
    private val putStringPreferenceInteractor: PutStringPreferenceInteractor,
    private val observeEpisodesInteractor: ObserveEpisodesInteractor,
    private val observeNowPlaying: ObservePodcastNowPlayingInteractor,
    private val pauseInteractor: PauseInteractor,
    private val resumePlaybackInteractor: ResumePlaybackInteractor,
    private val playInteractor: PlayInteractor,
    private val observeAllPlayedInteractor: ObserveAllPlayedInteractor,
    private val downloadsInteractor: DownloadInteractor,
    private val deleteDownloadInteractor: DeleteDownloadInteractor,
    private val observePlayQueueInteractor: ObservePlayQueueInteractor,
    private val addToPlayQueueInteractor: AddToPlayQueueInteractor,
    private val removeFromPlayQueueInteractor: RemoveFromPlayQueueInteractor,
    private val markPlayedInteractor: MarkAsPlayedInteractor,
    private val shareEpisodeInteractor: ShareEpisodeInteractor,
) : Presenter.Factory {
    override fun create(
        screen: Screen,
        navigator: Navigator,
        context: CircuitContext,
    ): Presenter<*>? {
        return when (screen) {
            is InboxScreen ->
                InboxPresenter(
                    navigator,
                    observeAllSubscriptionsInteractor,
                    refreshSubscriptionEpisodesInteractor,
                    observeEpisodesInteractor,
                    observeNowPlaying,
                    observeStringPreferenceInteractor,
                    putStringPreferenceInteractor,
                    pauseInteractor,
                    resumePlaybackInteractor,
                    playInteractor,
                    observeAllPlayedInteractor,
                    downloadsInteractor,
                    deleteDownloadInteractor,
                    observePlayQueueInteractor,
                    addToPlayQueueInteractor,
                    removeFromPlayQueueInteractor,
                    markPlayedInteractor,
                    shareEpisodeInteractor,
                )

            else -> null
        }
    }
}

class InboxPresenter(
    private val navigator: Navigator,
    private val observeAllSubscriptionsInteractor: Lazy<ObserveAllSubscriptionsInteractor>,
    private val refreshSubscriptionEpisodesInteractor: RefreshSubscriptionEpisodesInteractor,
    private val observeEpisodesInteractor: ObserveEpisodesInteractor,
    private val observeNowPlaying: ObservePodcastNowPlayingInteractor,
    private val observeStringPreferenceInteractor: ObserveStringPreferenceInteractor,
    private val putStringPreferenceInteractor: PutStringPreferenceInteractor,
    private val pauseInteractor: PauseInteractor,
    private val resumePlaybackInteractor: ResumePlaybackInteractor,
    private val playInteractor: PlayInteractor,
    private val observeAllPlayedInteractor: ObserveAllPlayedInteractor,
    private val downloadsInteractor: DownloadInteractor,
    private val deleteDownloadInteractor: DeleteDownloadInteractor,
    private val observePlayQueueInteractor: ObservePlayQueueInteractor,
    private val addToPlayQueueInteractor: AddToPlayQueueInteractor,
    private val removeFromPlayQueueInteractor: RemoveFromPlayQueueInteractor,
    private val markPlayedInteractor: MarkAsPlayedInteractor,
    private val shareEpisodeInteractor: ShareEpisodeInteractor,
) : Presenter<InboxUiState> {
    @OptIn(FlowPreview::class, ExperimentalCoroutinesApi::class)
    @Composable
    override fun present(): InboxUiState {
        val scope = rememberCoroutineScope()

        val cardStyle by rememberRetained {
            observeStringPreferenceInteractor("inbox-card-style")
                .map {
                    when (it) {
                        "default" -> CardStyle.Default
                        else -> CardStyle.Compact
                    }
                }
        }.collectAsState(CardStyle.Compact)

        val playQueue by rememberRetained {
            observePlayQueueInteractor.invoke().map { it.map { it.episodeSlug } }
        }.collectAsRetainedState(emptyList())

        val inboxData by rememberRetained {
            observeAllSubscriptionsInteractor.value.invoke()
                .debounce(200)
                .flatMapLatest { subs ->
                    observeEpisodesInteractor.invoke(subs.map { it.slug }, 40)
                        .debounce { if (it.isEmpty()) 1000 else 0 }
                        .combine(
                            observeAllPlayedInteractor().onStart { emit(emptyList()) },
                        ) { episodes, played ->
                            val playedSlugs = played.map { it.slug }
                            val latestEpisodes =
                                episodes.filterNot { playedSlugs.contains(it.episode.episodeSlug) }

                            InboxData(
                                latestEpisodes = latestEpisodes,
                                subscriptions = subs,
                                loading = false,
                                showNoSubscriptions = subs.isEmpty(),
                                showNoMoreEpisodes = subs.isNotEmpty() && latestEpisodes.isEmpty(),
                            )
                        }
                }

        }.collectAsRetainedState(
            InboxData(
                subscriptions = emptyList(),
                latestEpisodes = emptyList(),
                loading = true,
                showNoMoreEpisodes = false,
                showNoSubscriptions = false,
            ),
        )

        val nowPlaying by remember { observeNowPlaying() }.collectAsState(null)
        var filter by remember { mutableStateOf(Filter.Latest) }

        fun eventSink(event: InboxUiEvent) {
            when (event) {
                is InboxUiEvent.OpenPodcast -> navigator.goTo(PodcastDetailsScreen(event.slug))
                InboxUiEvent.ViewAllSubscriptions -> navigator.goTo(SubscriptionsScreen)
                is InboxUiEvent.PlayEpisode -> {
                    if (nowPlaying?.episode?.episodeSlug == event.episode.episodeSlug) {
                        when (nowPlaying?.playingState?.playState) {
                            PlayState.Buffering, PlayState.Playing -> pauseInteractor()
                            PlayState.Paused -> resumePlaybackInteractor()
                            else -> Unit
                        }
                    } else {
                        scope.launch {
                            playInteractor(event.episode, 0)
                        }
                    }
                }

                is InboxUiEvent.DownloadEpisode -> {
                    val download = event.data.download
                    if (download != null) {
                        scope.launch {
                            deleteDownloadInteractor(download.slug)
                        }
                    } else {
                        downloadsInteractor(
                            event.data.episode.episodeSlug,
                            event.data.episode.media,
                            event.data.episode.title,
                        )
                    }
                }

                is InboxUiEvent.ShareEpisode -> {
                    shareEpisodeInteractor.invoke(event.episode)
                }

                is InboxUiEvent.OpenEpisode -> navigator.goTo(EpisodeDetailsScreen(event.episodeSlug))
                InboxUiEvent.OpenTrending -> navigator.resetRoot(DiscoverScreen)
                is InboxUiEvent.ToggleCardStyle -> {
                    scope.launch {
                        when (event.cardStyle) {
                            CardStyle.Default -> putStringPreferenceInteractor.invoke(
                                "inbox-card-style",
                                "default",
                            )

                            CardStyle.Compact -> putStringPreferenceInteractor.invoke(
                                "inbox-card-style",
                                "compact",
                            )
                        }
                    }
                }

                is InboxUiEvent.AddToPlayQueue -> {
                    addToPlayQueueInteractor.invoke(event.data.episode, PlayQueuePosition.End)
                }

                is InboxUiEvent.RemoveFromPlayQueue -> {
                    removeFromPlayQueueInteractor.invoke(event.data.episode)
                }

                is InboxUiEvent.MarkAsPlayed -> {
                    scope.launch {
                        markPlayedInteractor.invoke(event.data.episode)
                    }
                }

                is InboxUiEvent.SetFilter -> filter = event.filter
            }
        }

        return InboxUiState(
            cardStyle = cardStyle,
            subscriptions = inboxData.subscriptions,
            latestEpisodes = inboxData.latestEpisodes.filter {
                when (filter) {
                    Filter.Latest -> true
                    Filter.Downloaded -> it.download != null
                    Filter.InProgress -> (it.playingState?.currentPosition ?: 0L) > 0L
                }
            },
            eventSink = ::eventSink,
            loading = inboxData.loading,
            showNoMoreEpisodes = inboxData.showNoMoreEpisodes,
            showNoSubscriptions = inboxData.showNoSubscriptions,
            filter = filter,
            playQueue = playQueue,
        )
    }
}

private data class InboxData(
    val subscriptions: List<PodcastSubscription>,
    val latestEpisodes: List<EpisodeAndMetadata>,
    val showNoMoreEpisodes: Boolean = false,
    val showNoSubscriptions: Boolean = false,
    val loading: Boolean = false,
)