package studio.goodegg.capsule.domain.share

import me.tatarka.inject.annotations.Provides
import studio.goodegg.capsule.common.di.ActivityScope

actual interface PlatformShareDomainComponent {
    @Provides
    @ActivityScope
    fun providesSharePodcastInteractor(): SharePodcastInteractor = SharePodcastInteractor { title, slug ->

    }

    @Provides
    @ActivityScope
    fun providesShareEpisodeInteractor(): ShareEpisodeInteractor = ShareEpisodeInteractor {

    }
}