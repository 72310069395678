package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.ExploreFilled: ImageVector
    get() {
        if (_ExploreFilled != null) {
            return _ExploreFilled!!
        }
        _ExploreFilled = ImageVector.Builder(
            name = "ExploreFilled",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF))) {
                moveToRelative(7.5f, 16.5f)
                lineToRelative(7f, -2f)
                lineToRelative(2f, -7f)
                lineToRelative(-7f, 2f)
                lineToRelative(-2f, 7f)
                close()
                moveTo(12f, 13.5f)
                curveToRelative(-0.417f, 0f, -0.77f, -0.146f, -1.063f, -0.438f)
                arcTo(1.446f, 1.446f, 0f, isMoreThanHalf = false, isPositiveArc = true, 10.5f, 12f)
                curveToRelative(0f, -0.417f, 0.146f, -0.77f, 0.438f, -1.063f)
                arcTo(1.446f, 1.446f, 0f, isMoreThanHalf = false, isPositiveArc = true, 12f, 10.5f)
                curveToRelative(0.417f, 0f, 0.77f, 0.146f, 1.063f, 0.438f)
                curveToRelative(0.291f, 0.291f, 0.437f, 0.645f, 0.437f, 1.062f)
                curveToRelative(0f, 0.417f, -0.146f, 0.77f, -0.438f, 1.063f)
                arcTo(1.446f, 1.446f, 0f, isMoreThanHalf = false, isPositiveArc = true, 12f, 13.5f)
                close()
                moveTo(12f, 22f)
                arcToRelative(9.738f, 9.738f, 0f, isMoreThanHalf = false, isPositiveArc = true, -3.9f, -0.788f)
                arcToRelative(10.099f, 10.099f, 0f, isMoreThanHalf = false, isPositiveArc = true, -3.175f, -2.137f)
                curveToRelative(-0.9f, -0.9f, -1.612f, -1.958f, -2.137f, -3.175f)
                arcTo(9.738f, 9.738f, 0f, isMoreThanHalf = false, isPositiveArc = true, 2f, 12f)
                arcToRelative(9.74f, 9.74f, 0f, isMoreThanHalf = false, isPositiveArc = true, 0.788f, -3.9f)
                arcToRelative(10.099f, 10.099f, 0f, isMoreThanHalf = false, isPositiveArc = true, 2.137f, -3.175f)
                curveToRelative(0.9f, -0.9f, 1.958f, -1.612f, 3.175f, -2.137f)
                arcTo(9.738f, 9.738f, 0f, isMoreThanHalf = false, isPositiveArc = true, 12f, 2f)
                arcToRelative(9.74f, 9.74f, 0f, isMoreThanHalf = false, isPositiveArc = true, 3.9f, 0.788f)
                arcToRelative(10.098f, 10.098f, 0f, isMoreThanHalf = false, isPositiveArc = true, 3.175f, 2.137f)
                curveToRelative(0.9f, 0.9f, 1.613f, 1.958f, 2.137f, 3.175f)
                arcTo(9.738f, 9.738f, 0f, isMoreThanHalf = false, isPositiveArc = true, 22f, 12f)
                arcToRelative(9.738f, 9.738f, 0f, isMoreThanHalf = false, isPositiveArc = true, -0.788f, 3.9f)
                arcToRelative(10.098f, 10.098f, 0f, isMoreThanHalf = false, isPositiveArc = true, -2.137f, 3.175f)
                curveToRelative(-0.9f, 0.9f, -1.958f, 1.613f, -3.175f, 2.137f)
                arcTo(9.738f, 9.738f, 0f, isMoreThanHalf = false, isPositiveArc = true, 12f, 22f)
                close()
            }
        }.build()

        return _ExploreFilled!!
    }

@Suppress("ObjectPropertyName")
private var _ExploreFilled: ImageVector? = null
