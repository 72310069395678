package studio.goodegg.capsule.listen

import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.size
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.unit.dp
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.screen.Screen
import com.slack.circuit.runtime.ui.Ui
import com.slack.circuit.runtime.ui.ui
import io.daio.pancake.components.item.ListItem
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.TitleText
import io.daio.pancake.icons.Download
import io.daio.pancake.icons.Icons
import io.daio.pancake.icons.Listen
import io.daio.pancake.layout.Stack
import io.daio.wild.style.StyleDefaults
import studio.goodegg.capsule.navigation.ListenScreen
import studio.goodegg.capsule.ui.components.PlayerAwareLazyStack

class ListenScreenUiFactory : Ui.Factory {
    override fun create(
        screen: Screen,
        context: CircuitContext,
    ): Ui<*>? {
        return when (screen) {
            is ListenScreen -> listen()
            else -> null
        }
    }
}

private fun listen() =
    ui<ListenUiState> { state, modifier ->
        Listen(
            state,
            modifier,
        )
    }

@Composable
fun Listen(
    state: ListenUiState,
    modifier: Modifier = Modifier,
) {
    Stack(modifier = modifier) {
        Spacer(modifier = Modifier.height(32.dp))
        PlayerAwareLazyStack {
            item("in-progress") {
                ListenItem(
                    "In Progress",
                    icon = Icons.Listen,
                    onClick = {
                        state.eventSink(ListenUiEvent.OpenInProgress)
                    },
                )
            }
            item("downloads") {
                ListenItem(
                    "Downloads",
                    icon = Icons.Download,
                    onClick = {
                        state.eventSink(ListenUiEvent.OpenDownloads)
                    },
                )
            }
        }
    }
}

@Composable
private fun ListenItem(
    title: String,
    icon: ImageVector,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
) {
    ListItem(
        modifier = modifier.fillMaxWidth().height(54.dp),
        containerColor = Theme.colors.surfaceContainer,
        scale = StyleDefaults.scale(pressedScale = .95f),
        onClick = onClick,
        trailingIcon = {
            Icon(
                imageVector = icon,
                tint = Theme.colors.onSurfaceBackground,
                contentDescription = "",
                modifier = Modifier.size(24.dp),
            )
        },
    ) {
        TitleText(title, modifier = Modifier.weight(1f))
    }
}
