package studio.goodegg.capsule.details.episode

import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.sizeIn
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.scale
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.screen.Screen
import com.slack.circuit.runtime.ui.Ui
import com.slack.circuit.runtime.ui.ui
import io.daio.pancake.bottomsheet.BottomSheetState
import io.daio.pancake.components.progress.CircularProgress
import io.daio.pancake.components.progress.FullScreenLoadingIndicator
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.foundations.text.TitleText
import io.daio.pancake.icons.Check
import io.daio.pancake.icons.Delete
import io.daio.pancake.icons.Download
import io.daio.pancake.icons.Downloaded
import io.daio.pancake.icons.Icons
import io.daio.pancake.icons.List
import io.daio.pancake.icons.More
import io.daio.pancake.icons.PlayFilled
import io.daio.pancake.icons.Played
import io.daio.pancake.icons.Podcast
import io.daio.pancake.icons.Replay
import io.daio.pancake.icons.Share
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.Surface
import io.daio.pancake.layout.Tier
import io.daio.wild.container.Container
import io.daio.wild.style.StyleDefaults
import studio.goodegg.capsule.Episode
import studio.goodegg.capsule.EpisodeAndMetadata
import studio.goodegg.capsule.PlaybackState
import studio.goodegg.capsule.formatCreatedDate
import studio.goodegg.capsule.formatDuration
import studio.goodegg.capsule.formatFileSize
import studio.goodegg.capsule.formatTimeRemaining
import studio.goodegg.capsule.navigation.EpisodeDetailsScreen
import studio.goodegg.capsule.ui.components.ArtworkTile
import studio.goodegg.capsule.ui.components.ContextMenu
import studio.goodegg.capsule.ui.components.ContextMenuItem
import studio.goodegg.capsule.ui.components.HtmlText
import studio.goodegg.capsule.ui.components.IconButton
import studio.goodegg.capsule.ui.components.LocalBottomSheet
import studio.goodegg.capsule.ui.components.PlayButton

class EpisodeDetailsScreenUiFactory : Ui.Factory {
    override fun create(
        screen: Screen,
        context: CircuitContext,
    ): Ui<*>? {
        return when (screen) {
            is EpisodeDetailsScreen -> episodeDetails()
            else -> null
        }
    }
}

private fun episodeDetails() =
    ui<EpisodeDetailsUiState> { state, modifier ->
        EpisodeDetails(
            state,
            modifier,
        )
    }

@Composable
fun EpisodeDetails(
    state: EpisodeDetailsUiState,
    modifier: Modifier = Modifier,
) {
    Box(
        modifier =
            modifier.fillMaxSize()
                .padding(top = Theme.dimens.size_l),
    ) {
        if (state.episode == null) {
            FullScreenLoadingIndicator()
        }

        val bottomSheet = LocalBottomSheet.current

        Stack(
            modifier = Modifier.fillMaxSize(),
            spaceBetween = LayoutGap.Medium,
        ) {
            state.episode?.let {
                EpisodeHeader(
                    episode = state.episode.episode,
                    played = state.played,
                    downloadPercentage = state.episode.download?.downloadedPercentage ?: 0L,
                    nowPlaying = state.episode.playingState,
                    onOpen = { },
                    onPlay = {
                        state.eventSink.invoke(EpisodeDetailsUiEvent.PlayEpisode(it))
                    },
                    onShare = {
                        state.eventSink.invoke(EpisodeDetailsUiEvent.ShareEpisode(it.episode))
                    },
                    onDownload = {
                        state.eventSink.invoke(EpisodeDetailsUiEvent.DownloadEpisode(it))
                    },
                    onMoreOptions = {
                        bottomSheet.show {
                            ContextMenu(
                                title = "Episode Options",
                                onDismiss = {
                                    bottomSheet.dismiss()
                                },
                            ) {
                                EpisodesContextMenu(bottomSheet, state, it)
                            }
                        }
                    },
                )

                BodyText(
                    text = "Release Date: ${it.episode.formatCreatedDate()}",
                )

                BodyText(
                    text = "File Size: ${it.episode.formatFileSize()}",
                )

                HorizontalDivider(
                    color = Theme.colors.onSurfaceBackground,
                    modifier = Modifier.graphicsLayer {
                        alpha = .2f
                    },
                )

                HtmlText(
                    html = it.episode.description.orEmpty(),
                    modifier = Modifier.verticalScroll(rememberScrollState())
                        .padding(
                            PaddingValues(
                                bottom = 66.dp, // playerheight plus 8dp padding
                            ),
                        ),
                )
            }
        }
    }
}

@Composable
fun EpisodeHeader(
    episode: Episode,
    nowPlaying: PlaybackState?,
    onPlay: () -> Unit,
    onDownload: () -> Unit,
    onShare: () -> Unit,
    modifier: Modifier = Modifier,
    backgroundColor: Color = Theme.colors.surfaceContainer,
    onMoreOptions: (() -> Unit)? = null,
    onOpen: () -> Unit = { },
    downloadPercentage: Long = 0,
    played: Boolean = false,
) {
    Surface(
        color = backgroundColor,
        modifier = modifier.wrapContentHeight(),
        shape = Theme.shapes.large,
    ) {
        Stack(
            spaceBetween = LayoutGap.Small,
            modifier = Modifier.padding(
                top = Theme.dimens.size_m,
                bottom = Theme.dimens.size_xs,
                start = Theme.dimens.size_m,
                end = Theme.dimens.size_m,
            ),
        ) {
            Container(
                onClick = onOpen,
                style = StyleDefaults.style(
                    colors = StyleDefaults.colors(backgroundColor = Color.Transparent),
                ),
            ) {
                Tier(
                    verticalAlignment = Alignment.Top,
                    modifier = Modifier.fillMaxWidth(),
                ) {
                    ArtworkTile(
                        size = 128.dp,
                        imageUrl = episode.image,
                        onClick = onOpen,
                    )
                    Stack(
                        spaceBetween = LayoutGap.Tiny,
                        modifier = Modifier.weight(1f),
                    ) {
                        Spacer(Modifier.height(Theme.dimens.size_xxxs))
                        TitleText(
                            text = episode.title,
                            maxLines = 4,
                            overflow = TextOverflow.Ellipsis,
                        )
                        BodyText(
                            text = episode.formatDuration(),
                        )
                    }
                    Tier {
                        if (played) {
                            Icon(
                                modifier = Modifier.size(20.dp),
                                imageVector = Icons.Played,
                                contentDescription = "played indicator",
                                tint = Theme.colors.positive,
                            )
                        }
                        onMoreOptions?.let {
                            IconButton(
                                modifier = Modifier.size(38.dp),
                                icon = Icons.More,
                                onClick = onMoreOptions,
                            )
                        }
                    }
                }
            }

            Tier(
                modifier = Modifier.fillMaxWidth()
                    .height(42.dp),
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.Top,
            ) {
                Tier(
                    spaceBetween = LayoutGap.Tiny,
                    verticalAlignment = Alignment.Bottom,
                    modifier = Modifier.fillMaxHeight(),
                ) {
                    IconButton(
                        icon = Icons.Share,
                        onClick = onShare,
                        modifier = Modifier.size(38.dp),
                    )
                    when (downloadPercentage) {
                        0L -> {
                            IconButton(
                                modifier = Modifier.size(38.dp),
                                icon = Icons.Download,
                                onClick = onDownload,
                            )
                        }

                        100L -> {
                            IconButton(
                                modifier = Modifier.size(38.dp),
                                icon = Icons.Delete,
                                onClick = onDownload,
                            )
                        }

                        in 1L..99L -> {
                            val progress by animateFloatAsState(downloadPercentage / 100f)
                            IconButton(
                                modifier = Modifier.size(38.dp),
                                icon = {
                                    CircularProgress(
                                        modifier = Modifier.scale(.5f),
                                        progress = { progress },
                                    )
                                },
                                onClick = onDownload,
                            )
                        }
                    }
                }
                Tier {
                    if ((nowPlaying?.currentPosition ?: 0L) > 0L && !played) {
                        BodyText(
                            text = "${episode.formatTimeRemaining(nowPlaying?.currentPosition ?: 0L)} left",
                            textColor = Theme.colors.accent,
                        )
                    }
                    PlayButton(
                        nowPlaying,
                        episode,
                        onPlay,
                        playIcon = if (played) Icons.Replay else Icons.PlayFilled,
                    )
                }
            }
        }
    }
}


@Composable
private fun EpisodesContextMenu(
    bottomSheet: BottomSheetState,
    state: EpisodeDetailsUiState,
    it: EpisodeAndMetadata,
) {
    it.episode.parentSlug?.let {
        ContextMenuItem(
            "Go To Podcast",
            onDismiss = {
                bottomSheet.dismiss()
            },
            icon = Icons.Podcast,
            onClick = {
                state.eventSink.invoke(EpisodeDetailsUiEvent.OpenPodcast(it))
            },
        )
        HorizontalDivider(
            color = Theme.colors.onSurfaceBackground.copy(
                alpha = .1f,
            ),
        )
    }

    ContextMenuItem(
        if (it.download != null) "Delete Download" else "Download Episode",
        onDismiss = {
            bottomSheet.dismiss()
        },
        icon = if (it.download != null) Icons.Delete else Icons.Download,
        onClick = {
            state.eventSink.invoke(
                EpisodeDetailsUiEvent.DownloadEpisode(it),
            )
        },
    )
    HorizontalDivider(
        color = Theme.colors.onSurfaceBackground.copy(
            alpha = .1f,
        ),
    )

    if (state.episode?.playingState == null) {
        ContextMenuItem(
            title = if (state.playQueue.contains(it.episode.episodeSlug)) "Remove From Play Queue" else "Add To Play Queue",
            onDismiss = {
                bottomSheet.dismiss()
            },
            icon = Icons.List,
            onClick = {
                if (state.playQueue.contains(it.episode.episodeSlug)) {
                    state.eventSink.invoke(EpisodeDetailsUiEvent.RemoveFromPlayQueue(it))
                } else {
                    state.eventSink.invoke(EpisodeDetailsUiEvent.AddToPlayQueue(it))
                }
            },
        )
    }

    HorizontalDivider(
        color = Theme.colors.onSurfaceBackground.copy(
            alpha = .1f,
        ),
    )

    ContextMenuItem(
        title = if (state.played) "Unmark As Played" else "Mark As Played",
        onDismiss = {
            bottomSheet.dismiss()
        },
        icon = Icons.Played,
        onClick = {
            if (state.played) {
                state.eventSink.invoke(EpisodeDetailsUiEvent.UnMarkAsPlayed(it))
            } else {
                state.eventSink.invoke(EpisodeDetailsUiEvent.MarkAsPlayed(it))
            }
        },
    )
}
