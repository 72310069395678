package studio.goodegg.capsule.repositories

import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock
import kotlinx.coroutines.withContext
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.PodcastList
import studio.goodegg.capsule.TrendingResult
import studio.goodegg.capsule.TrendingRow
import studio.goodegg.capsule.api.clients.DiscoveryClient
import studio.goodegg.capsule.common.coroutines.IoDispatcher
import studio.goodegg.capsule.utils.Failed
import studio.goodegg.capsule.utils.Success

interface TrendingRepository {
    suspend fun getTrending(locale: String): TrendingResult
    suspend fun getDiscovery(locale: String): PodcastList
}

private const val TRENDING_CACHE_KEY = "trending.cache.key"
private const val DISCOVER_CACHE_KEY = "discover.cache.key"

@Inject
class TrendingRepositoryImpl(
    private val discoveryClient: DiscoveryClient,
    @IoDispatcher private val ioDispatcher: CoroutineDispatcher,
) : TrendingRepository {

    private val mutex = Mutex()
    private val cache = mutableMapOf<String, Any>()

    override suspend fun getTrending(locale: String): TrendingResult =
        withContext(ioDispatcher) {
            getFromCache(TRENDING_CACHE_KEY)?.let { it as? TrendingResult }
                ?.also { return@withContext it }

            when (val result = discoveryClient.getTrending(locale)) {
                is Failed -> TrendingResult(emptyList(), emptyList(), emptyList())
                is Success -> result.data.also { cachePut(TRENDING_CACHE_KEY, it) }
            }
        }

    override suspend fun getDiscovery(locale: String): PodcastList =
        withContext(ioDispatcher) {
            getFromCache(DISCOVER_CACHE_KEY)?.let { it as? PodcastList }
                ?.also { return@withContext it }

            when (val result = discoveryClient.getDiscovery(locale)) {
                is Failed -> PodcastList(emptyList(), emptyList())
                is Success -> result.data.also { cachePut(DISCOVER_CACHE_KEY, it) }
            }
        }

    private suspend fun cachePut(key: String, data: Any) {
        mutex.withLock { cache.put(key, data) }
    }

    private suspend fun getFromCache(key: String): Any? = mutex.withLock {
        cache[key]
    }

}
