package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.Listen: ImageVector
    get() {
        if (_Listen != null) {
            return _Listen!!
        }
        _Listen = ImageVector.Builder(
            name = "Listen",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(fill = SolidColor(Color(0xFFE8EAED))) {
                moveTo(9f, 21f)
                lineTo(5f, 21f)
                curveToRelative(-0.55f, 0f, -1.02f, -0.196f, -1.413f, -0.587f)
                arcTo(1.926f, 1.926f, 0f, isMoreThanHalf = false, isPositiveArc = true, 3f, 19f)
                verticalLineToRelative(-7f)
                curveToRelative(0f, -1.25f, 0.237f, -2.42f, 0.712f, -3.512f)
                arcToRelative(9.148f, 9.148f, 0f, isMoreThanHalf = false, isPositiveArc = true, 1.925f, -2.85f)
                arcToRelative(9.148f, 9.148f, 0f, isMoreThanHalf = false, isPositiveArc = true, 2.85f, -1.925f)
                arcTo(8.707f, 8.707f, 0f, isMoreThanHalf = false, isPositiveArc = true, 12f, 3f)
                curveToRelative(1.25f, 0f, 2.42f, 0.237f, 3.512f, 0.712f)
                arcToRelative(9.147f, 9.147f, 0f, isMoreThanHalf = false, isPositiveArc = true, 2.85f, 1.925f)
                arcToRelative(9.149f, 9.149f, 0f, isMoreThanHalf = false, isPositiveArc = true, 1.926f, 2.85f)
                arcTo(8.707f, 8.707f, 0f, isMoreThanHalf = false, isPositiveArc = true, 21f, 12f)
                verticalLineToRelative(7f)
                curveToRelative(0f, 0.55f, -0.196f, 1.02f, -0.587f, 1.413f)
                arcTo(1.926f, 1.926f, 0f, isMoreThanHalf = false, isPositiveArc = true, 19f, 21f)
                horizontalLineToRelative(-4f)
                verticalLineToRelative(-8f)
                horizontalLineToRelative(4f)
                verticalLineToRelative(-1f)
                curveToRelative(0f, -1.95f, -0.68f, -3.604f, -2.038f, -4.963f)
                curveTo(15.604f, 5.68f, 13.95f, 5f, 12f, 5f)
                curveToRelative(-1.95f, 0f, -3.604f, 0.68f, -4.963f, 2.037f)
                curveTo(5.68f, 8.396f, 5f, 10.05f, 5f, 12f)
                verticalLineToRelative(1f)
                horizontalLineToRelative(4f)
                verticalLineToRelative(8f)
                close()
                moveTo(7f, 15f)
                lineTo(5f, 15f)
                verticalLineToRelative(4f)
                horizontalLineToRelative(2f)
                verticalLineToRelative(-4f)
                close()
                moveTo(17f, 15f)
                verticalLineToRelative(4f)
                horizontalLineToRelative(2f)
                verticalLineToRelative(-4f)
                horizontalLineToRelative(-2f)
                close()
            }
        }.build()

        return _Listen!!
    }

@Suppress("ObjectPropertyName")
private var _Listen: ImageVector? = null
