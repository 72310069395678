

// Do not edit directly
// Generated on Thu, 06 Feb 2025 22:47:57 GMT



package io.daio.pancake.foundations.text;

import androidx.compose.ui.unit.sp

object FontSize {
  val fontBodyDefault = 12.00.sp
  val fontBodyLarge = 14.00.sp
  val fontBodySmall = 12.00.sp
  val fontLink = 10.00.sp
  val fontTitleDefault = 15.00.sp
  val fontTitleLarge = 20.00.sp
  val fontTitleScreen = 26.00.sp
}
