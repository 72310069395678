package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.MyStuffFilled: ImageVector
    get() {
        if (_MyStuffFilled != null) {
            return _MyStuffFilled!!
        }
        _MyStuffFilled = ImageVector.Builder(
            name = "MyStuffFilled",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF))) {
                moveTo(5f, 21f)
                verticalLineTo(5f)
                curveToRelative(0f, -0.55f, 0.196f, -1.02f, 0.588f, -1.413f)
                arcTo(1.926f, 1.926f, 0f, isMoreThanHalf = false, isPositiveArc = true, 7f, 3f)
                horizontalLineToRelative(10f)
                curveToRelative(0.55f, 0f, 1.02f, 0.196f, 1.413f, 0.587f)
                curveToRelative(0.39f, 0.393f, 0.587f, 0.863f, 0.587f, 1.413f)
                verticalLineToRelative(16f)
                lineToRelative(-7f, -3f)
                lineToRelative(-7f, 3f)
                close()
            }
        }.build()

        return _MyStuffFilled!!
    }

@Suppress("ObjectPropertyName")
private var _MyStuffFilled: ImageVector? = null
