package studio.goodegg.capsule.onboarding

import androidx.compose.runtime.Composable
import androidx.compose.runtime.compositionLocalOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.slack.circuit.retained.collectAsRetainedState
import com.slack.circuit.retained.produceRetainedState
import com.slack.circuit.retained.rememberRetained
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.Navigator
import com.slack.circuit.runtime.presenter.Presenter
import com.slack.circuit.runtime.screen.Screen
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.PodcastList
import studio.goodegg.capsule.PodcastSubscription
import studio.goodegg.capsule.domain.auth.ObserveSignedInInteractor
import studio.goodegg.capsule.domain.auth.SignInInteractor
import studio.goodegg.capsule.domain.auth.SignOutInteractor
import studio.goodegg.capsule.domain.auth.VerifySignInInteractor
import studio.goodegg.capsule.domain.podcast.GetPodcastInteractor
import studio.goodegg.capsule.domain.settings.PutBooleanPreferenceInteractor
import studio.goodegg.capsule.domain.subscription.DeleteSubscriptionInteractor
import studio.goodegg.capsule.domain.subscription.AddSubscriptionInteractor
import studio.goodegg.capsule.domain.subscription.ObserveAllSubscriptionsInteractor
import studio.goodegg.capsule.domain.trending.GetDiscoveryInteractor
import studio.goodegg.capsule.navigation.InboxScreen
import studio.goodegg.capsule.navigation.PodcastDetailsScreen
import studio.goodegg.capsule.navigation.OnboardingScreen
import studio.goodegg.capsule.navigation.SignInScreen
import kotlin.math.sign

@Inject
class OnboardingPresenterFactory(
    private val addSubscriptionInteractor: AddSubscriptionInteractor,
    private val deleteSubscriptionInteractor: DeleteSubscriptionInteractor,
    private val getDiscoveryInteractor: GetDiscoveryInteractor,
    private val signInInteractor: SignInInteractor,
    private val verifySignInInteractor: VerifySignInInteractor,
    private val putBooleanPreferenceInteractor: PutBooleanPreferenceInteractor,
    private val observeSignedInInteractor: ObserveSignedInInteractor,
    private val observeAllSubscriptionsInteractor: ObserveAllSubscriptionsInteractor,
    private val podcastInteractor: GetPodcastInteractor,
) : Presenter.Factory {
    override fun create(
        screen: Screen,
        navigator: Navigator,
        context: CircuitContext,
    ): Presenter<*>? {
        return when (screen) {
            is OnboardingScreen ->
                OnboardingPresenter(
                    navigator = navigator,
                    addSubscriptionInteractor = addSubscriptionInteractor,
                    removeSubscription = deleteSubscriptionInteractor,
                    getDiscoveryInteractor = getDiscoveryInteractor,
                    signInInteractor = signInInteractor,
                    verifySignInInteractor = verifySignInInteractor,
                    putBooleanPreferenceInteractor = putBooleanPreferenceInteractor,
                    observeSignedInInteractor = observeSignedInInteractor,
                    observeAllSubscriptionsInteractor = observeAllSubscriptionsInteractor,
                    podcastInteractor = podcastInteractor,
                )

            else -> null
        }
    }
}

class OnboardingPresenter(
    private val navigator: Navigator,
    private val addSubscriptionInteractor: AddSubscriptionInteractor,
    private val removeSubscription: DeleteSubscriptionInteractor,
    private val getDiscoveryInteractor: GetDiscoveryInteractor,
    private val signInInteractor: SignInInteractor,
    private val verifySignInInteractor: VerifySignInInteractor,
    private val putBooleanPreferenceInteractor: PutBooleanPreferenceInteractor,
    private val observeSignedInInteractor: ObserveSignedInInteractor,
    private val observeAllSubscriptionsInteractor: ObserveAllSubscriptionsInteractor,
    private val podcastInteractor: GetPodcastInteractor,
) : Presenter<OnboardingUiState> {
    @Composable
    override fun present(): OnboardingUiState {
        val scope = rememberCoroutineScope()
        var loading by remember { mutableStateOf(true) }
        var awaitingCode by remember { mutableStateOf(false) }
        var verifying by remember { mutableStateOf(false) }
        var currentEmail by remember { mutableStateOf("") }

        scope.launch {
            putBooleanPreferenceInteractor.invoke("onboarding-complete", true)
        }

        val discover by produceRetainedState(PodcastList(emptyList(), emptyList())) {
            value = getDiscoveryInteractor.invoke("gb")
            loading = false
        }

        val notificationPermissionHandler = LocalNotificationPermissionHandler.current
        val user by observeSignedInInteractor().collectAsRetainedState(null)
        val notificationsEnabled by notificationPermissionHandler.notificationsGranted
            .collectAsRetainedState(false)

        val subscriptions by rememberRetained {
            observeAllSubscriptionsInteractor()
                .map { subscriptions ->
                    subscriptions.map { it.slug }.toSet()
                }
        }.collectAsRetainedState(emptySet())

        fun eventSink(event: OnboardingUiEvent) {
            when (event) {
                is OnboardingUiEvent.Remove -> {
                    scope.launch {
                        removeSubscription(event.slug)
                    }
                }

                is OnboardingUiEvent.Subscribe -> {
                    scope.launch {
                        if (subscriptions.contains(event.podcastResult.slug)) {
                            removeSubscription(event.podcastResult.slug)
                        } else {
                            addSubscriptionInteractor(
                                PodcastSubscription(
                                    event.podcastResult.slug,
                                    event.podcastResult.feed,
                                    event.podcastResult.title,
                                    event.podcastResult.author,
                                    event.podcastResult.imageUrl,
                                    true,
                                    null,
                                    false,
                                ),
                            )
                            launch {
                                podcastInteractor.invoke(event.podcastResult.slug)
                            }
                        }
                    }
                }

                OnboardingUiEvent.Skip -> navigator.resetRoot(InboxScreen)

                is OnboardingUiEvent.SignUp -> {
                    scope.launch {
                        currentEmail = event.email
                        awaitingCode = true
                        signInInteractor.invoke(event.email)
                    }
                }

                is OnboardingUiEvent.VerifySignUp -> {
                    scope.launch {
                        awaitingCode = false
                        verifying = true
                        verifySignInInteractor.invoke(currentEmail, event.code)
                        verifying = false
                        currentEmail = ""
                    }
                }

                OnboardingUiEvent.EnableNotifications -> notificationPermissionHandler.requestNotificationPermission()
                OnboardingUiEvent.OpenDedicatedSignIn -> {
                    navigator.resetRoot(SignInScreen)
                }
            }
        }

        return OnboardingUiState(
            user = user,
            loading = loading,
            discover = discover,
            awaitingSignInCode = awaitingCode,
            notificationsEnabled = notificationsEnabled,
            awaitingVerifySignIn = verifying,
            subscriptions = subscriptions,
            eventSink = ::eventSink,
        )
    }
}

val LocalNotificationPermissionHandler = compositionLocalOf<NotificationPermissionHandler> {
    NoopNotificationPermissionHandler
}

private object NoopNotificationPermissionHandler : NotificationPermissionHandler {
    override val notificationsGranted: Flow<Boolean> = flowOf(true)

    override fun requestNotificationPermission() {

    }
}

interface NotificationPermissionHandler {
    val notificationsGranted: Flow<Boolean>

    fun requestNotificationPermission()
}
