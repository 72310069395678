package studio.goodegg.capsule.playqueue

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import com.slack.circuit.retained.collectAsRetainedState
import com.slack.circuit.retained.rememberRetained
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.Navigator
import com.slack.circuit.runtime.presenter.Presenter
import com.slack.circuit.runtime.screen.Screen
import kotlinx.coroutines.flow.map
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.domain.playback.GetLastPlayedInteractor
import studio.goodegg.capsule.domain.playback.ObserveNowPlayingItemInteractor
import studio.goodegg.capsule.domain.playback.ObservePlayQueueInteractor
import studio.goodegg.capsule.domain.playback.RemoveFromPlayQueueInteractor
import studio.goodegg.capsule.domain.playback.SetPlayQueueInteractor
import studio.goodegg.capsule.navigation.PlayQueueScreen

@Inject
class PlayQueuePresenterFactory(
    private val observePlayQueueInteractor: ObservePlayQueueInteractor,
    private val observeNowPlayingItemInteractor: ObserveNowPlayingItemInteractor,
    private val getLastPlayedInteractor: GetLastPlayedInteractor,
    private val removeFromPlayQueueInteractor: RemoveFromPlayQueueInteractor,
    private val setPlayQueueInteractor: SetPlayQueueInteractor,
) : Presenter.Factory {
    override fun create(
        screen: Screen,
        navigator: Navigator,
        context: CircuitContext,
    ): Presenter<*>? {
        return when (screen) {
            is PlayQueueScreen ->
                PlayQueuePresenter(
                    navigator,
                    observePlayQueueInteractor,
                    observeNowPlayingItemInteractor,
                    getLastPlayedInteractor,
                    removeFromPlayQueueInteractor,
                    setPlayQueueInteractor,
                )

            else -> null
        }
    }
}

class PlayQueuePresenter(
    private val navigator: Navigator,
    private val observePlayQueueInteractor: ObservePlayQueueInteractor,
    private val observeNowPlayingItemInteractor: ObserveNowPlayingItemInteractor,
    private val getLastPlayedInteractor: GetLastPlayedInteractor,
    private val removeFromPlayQueueInteractor: RemoveFromPlayQueueInteractor,
    private val setPlayQueueInteractor: SetPlayQueueInteractor,
) : Presenter<PlayQueueUiState> {
    @Composable
    override fun present(): PlayQueueUiState {

        val playQueue by remember {
            observePlayQueueInteractor.invoke()
        }.collectAsRetainedState(emptyList())

        val nowPlaying by rememberRetained {
            observeNowPlayingItemInteractor().map { it ?: getLastPlayedInteractor() }
        }.collectAsRetainedState(null)

        fun eventSink(event: PlayQueueUiEvent) {
            when (event) {
                PlayQueueUiEvent.Back -> navigator.pop()
                is PlayQueueUiEvent.RemoveFromPlayQueue -> {
                    removeFromPlayQueueInteractor.invoke(event.data)
                }

                is PlayQueueUiEvent.UpdatePlayQueue -> {
                    setPlayQueueInteractor(event.queue)
                }
            }
        }

        return PlayQueueUiState(
            currentlyPlaying = nowPlaying,
            playQueue = playQueue,
            eventSink = ::eventSink,
        )
    }
}
