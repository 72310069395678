package studio.goodegg.capsule.repositories

import kotlinx.coroutines.CoroutineDispatcher
import me.tatarka.inject.annotations.Component
import me.tatarka.inject.annotations.Provides
import studio.goodegg.capsule.FileStorageLocation
import studio.goodegg.capsule.api.clients.PodcastClient
import studio.goodegg.capsule.api.clients.SubscriptionsClient
import studio.goodegg.capsule.common.coroutines.IoDispatcher
import studio.goodegg.capsule.common.di.ApplicationScope
import studio.goodegg.capsule.db.CapsuleDbCreator
import studio.goodegg.capsule.downloader.Downloader

@Component
interface RepositoriesComponent {
    @Provides
    @ApplicationScope
    fun providesSubscriptionsRepository(
        capsuleDb: CapsuleDbCreator,
        @IoDispatcher ioDispatcher: CoroutineDispatcher,
        subscriptionsClient: SubscriptionsClient,
    ): SubscriptionsRepository =
        SubscriptionsRepositoryImpl(capsuleDb, subscriptionsClient, ioDispatcher)

    @Provides
    @ApplicationScope
    fun providesPodcastRepository(
        podcastClient: PodcastClient,
        podcastQueries: CapsuleDbCreator,
        @IoDispatcher ioDispatcher: CoroutineDispatcher,
    ): PodcastRepository =
        PodcastRepositoryImpl(podcastQueries, podcastClient, ioDispatcher)

    @Provides
    @ApplicationScope
    fun providesTrendingRepository(trendingRepository: TrendingRepositoryImpl): TrendingRepository =
        trendingRepository

    @Provides
    @ApplicationScope
    fun providesSearchRepository(searchRepository: SearchRepositoryImpl): SearchRepository =
        searchRepository

    @Provides
    @ApplicationScope
    fun providesRadioRepository(radioRepository: RadioRepositoryImpl): RadioRepository =
        radioRepository

    @Provides
    @ApplicationScope
    fun providesPlayedRepository(
        dbCreator: CapsuleDbCreator,
        @IoDispatcher ioDispatcher: CoroutineDispatcher,
    ): PlayedItemsRepository = PlayedRepositoryImpl(dbCreator, ioDispatcher)

    @Provides
    @ApplicationScope
    fun providesDownloadsRepository(
        dbCreator: CapsuleDbCreator,
        @IoDispatcher ioDispatcher: CoroutineDispatcher,
        downloader: Downloader,
        fileStorageLocation: FileStorageLocation,
    ): DownloadsRepository =
        DownloadsRepositoryImpl(dbCreator, downloader, fileStorageLocation, ioDispatcher)
}
