package studio.goodegg.capsule.player

import androidx.compose.runtime.Immutable
import com.slack.circuit.runtime.CircuitUiState
import studio.goodegg.capsule.core.playback.AudioBoostGain
import studio.goodegg.capsule.core.playback.PlaybackSpeed
import studio.goodegg.capsule.core.playback.SleepTimer
import studio.goodegg.capsule.core.playback.SleepTimerState
import studio.goodegg.capsule.domain.playback.NowPlayingItem

@Immutable
data class PlayerUiState(
    val playbackSpeed: PlaybackSpeed,
    val sleepTimer: SleepTimerState,
    val trimSilence: Boolean,
    val audioBoostGain: AudioBoostGain,
    val connectedPlayerName: String,
    val nowPlaying: NowPlayingItem?,
    val eventSink: (PlayerUiEvent) -> Unit,
) : CircuitUiState
