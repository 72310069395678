package studio.goodegg.capsule.repositories

import app.cash.sqldelight.async.coroutines.awaitAsList
import app.cash.sqldelight.coroutines.asFlow
import app.cash.sqldelight.coroutines.mapToList
import app.cash.sqldelight.coroutines.mapToOneOrNull
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flowOn
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.onStart
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.datetime.Clock
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.PodcastSubscription
import studio.goodegg.capsule.api.clients.SubscriptionsClient
import studio.goodegg.capsule.common.coroutines.IoDispatcher
import studio.goodegg.capsule.db.CapsuleDbCreator
import studio.goodegg.capsule.db.Subscription
import studio.goodegg.capsule.db.asFlow
import studio.goodegg.capsule.utils.Failed
import studio.goodegg.capsule.utils.Success

interface SubscriptionsRepository {
    suspend fun getAll(): List<PodcastSubscription>
    suspend fun insert(podcastSubscription: PodcastSubscription)
    suspend fun delete(slug: String)
    fun observeAll(): Flow<List<PodcastSubscription>>
    fun observeSubscription(slug: String): Flow<PodcastSubscription?>
}

@Inject
internal class SubscriptionsRepositoryImpl(
    private val db: CapsuleDbCreator,
    private val subscriptionsClient: SubscriptionsClient,
    @IoDispatcher private val ioDispatcher: CoroutineDispatcher,
) : SubscriptionsRepository {

    private val scope = CoroutineScope(SupervisorJob() + ioDispatcher)

    private suspend fun queries() = db.get().subscriptionQueries

    override suspend fun getAll(): List<PodcastSubscription> = withContext(ioDispatcher) {
        scope.launch { refreshFromServer() }
        queries().selectAll().awaitAsList().map { it.toPodcastSubscription() }
    }

    override suspend fun insert(podcastSubscription: PodcastSubscription) =
        withContext(ioDispatcher) {
            queries().insert(
                slug = podcastSubscription.slug,
                feedurl = podcastSubscription.feedUrl,
                title = podcastSubscription.title,
                artist = podcastSubscription.artist,
                image = podcastSubscription.image,
                collection = podcastSubscription.collection,
                notify = podcastSubscription.notify,
                autodownload = podcastSubscription.autoDownload,
                updated_at = Clock.System.now().toEpochMilliseconds(),
                deleted = false,
            ).also {
                scope.launch {
                    subscriptionsClient.insert(
                        podcastSubscription.copy(deleted = false),
                    )
                }
            }
        }

    override suspend fun delete(slug: String) = withContext(ioDispatcher) {
        queries().softDelete(
            slug = slug,
            updated_at = Clock.System.now().toEpochMilliseconds(),
        ).also {
            scope.launch {
                subscriptionsClient.delete(slug)
            }
        }
    }

    override fun observeAll(): Flow<List<PodcastSubscription>> {
        return db.asFlow {
            subscriptionQueries.selectAll().asFlow()
                .mapToList(ioDispatcher)
                .map { subs ->
                    subs.map { it.toPodcastSubscription() }
                }.onStart { scope.launch { refreshFromServer() } }
                .flowOn(ioDispatcher)
        }
    }

    override fun observeSubscription(slug: String): Flow<PodcastSubscription?> {
        return db.asFlow {
            subscriptionQueries.selectSubscription(slug)
                .asFlow()
                .mapToOneOrNull(ioDispatcher)
                .map { it?.toPodcastSubscription() }
        }
    }

    private suspend fun refreshFromServer() = withContext(ioDispatcher) {
        when (val subsResponse = subscriptionsClient.getSubscriptions()) {
            is Failed -> Unit
            is Success<List<PodcastSubscription>> -> {
                val local = queries().selectAllIncludingDeleted().awaitAsList()
                val localMapped = local.map { it.toPodcastSubscription() }

                mergeSubscriptions(localMapped, subsResponse.data)
                    .forEach { podcastSubscription ->
                        queries().insert(
                            slug = podcastSubscription.slug,
                            feedurl = podcastSubscription.feedUrl,
                            title = podcastSubscription.title,
                            artist = podcastSubscription.artist,
                            image = podcastSubscription.image,
                            collection = podcastSubscription.collection,
                            notify = podcastSubscription.notify,
                            autodownload = podcastSubscription.autoDownload,
                            updated_at = podcastSubscription.updatedAt,
                            deleted = podcastSubscription.deleted,
                        )
                    }
            }
        }
    }

    private fun mergeSubscriptions(
        local: List<PodcastSubscription>,
        server: List<PodcastSubscription>,
    ): List<PodcastSubscription> {
        // Combine the two lists
        val combinedList = local + server

        // Group by slug and pick the most recent updatedAt subscription for each slug
        return combinedList
            .groupBy { it.slug } // Group by slug
            .map { (_, subscriptions) ->
                subscriptions.onEach { println(it) }
                // Take the subscription with the latest updatedAt
                subscriptions.maxBy { it.updatedAt }
            }
    }
}

private fun Subscription.toPodcastSubscription(): PodcastSubscription = PodcastSubscription(
    slug = this.slug,
    feedUrl = this.feedurl,
    title = this.title,
    artist = this.artist,
    image = this.image,
    notify = this.notify,
    collection = this.collection,
    autoDownload = this.autodownload,
    updatedAt = this.updated_at,
    deleted = this.deleted,
)
