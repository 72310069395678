package io.daio.pancake.components.item

import androidx.compose.foundation.layout.RowScope
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.semantics.Role
import androidx.compose.ui.semantics.role
import androidx.compose.ui.semantics.semantics
import androidx.compose.ui.unit.dp
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.color.contentColorFor
import io.daio.pancake.layout.Tier
import io.daio.wild.container.Container
import io.daio.wild.style.Scale
import io.daio.wild.style.StyleDefaults

/**
 * A building block component to build custom list items.
 *
 * @param modifier The modifier to be applied to the layout of the list item.
 * @param onClick The action to be performed when the list item is clicked.
 * @param leadingIcon The leading item to be displayed in the list item.
 * @param content The main content to be displayed in the list item.
 * @param trailingIcon The trailing item to be displayed in the list item.
 */
@Composable
fun ListItem(
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    containerColor: Color = Theme.colors.surfaceBackground,
    contentColor: Color = contentColorFor(containerColor),
    shape: RoundedCornerShape = Theme.shapes.large,
    scale: Scale = StyleDefaults.scale(),
    leadingIcon: (@Composable RowScope.() -> Unit)? = null,
    trailingIcon: (@Composable RowScope.() -> Unit)? = null,
    content: @Composable RowScope.() -> Unit,
) {
    Container(
        onClick = onClick,
        style =
            StyleDefaults.style(
                colors = StyleDefaults.colors(
                    backgroundColor = containerColor,
                    contentColor = contentColor,
                ),
                shapes = StyleDefaults.shapes(shape),
                scale = scale,
            ),
        modifier =
            modifier.heightIn(min = 48.dp)
                .semantics { role = Role.Button },
    ) {
        Tier(
            horizontalAlignment = Alignment.Start,
            modifier =
                Modifier.padding(horizontal = Theme.dimens.size_m),
        ) {
            leadingIcon?.invoke(this)
            content()
            trailingIcon?.invoke(this)
        }
    }
}
