package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.Cast: ImageVector
    get() {
        if (_Cast != null) {
            return _Cast!!
        }
        _Cast = ImageVector.Builder(
            name = "Cast",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF))) {
                moveTo(20f, 20f)
                horizontalLineToRelative(-5f)
                arcToRelative(13.366f, 13.366f, 0f, isMoreThanHalf = false, isPositiveArc = false, -0.15f, -2f)
                lineTo(20f, 18f)
                lineTo(20f, 6f)
                lineTo(4f, 6f)
                verticalLineToRelative(1.15f)
                arcTo(13.592f, 13.592f, 0f, isMoreThanHalf = false, isPositiveArc = false, 2f, 7f)
                lineTo(2f, 6f)
                curveToRelative(0f, -0.55f, 0.196f, -1.02f, 0.587f, -1.412f)
                arcTo(1.926f, 1.926f, 0f, isMoreThanHalf = false, isPositiveArc = true, 4f, 4f)
                horizontalLineToRelative(16f)
                curveToRelative(0.55f, 0f, 1.02f, 0.196f, 1.413f, 0.588f)
                curveToRelative(0.391f, 0.391f, 0.587f, 0.862f, 0.587f, 1.412f)
                verticalLineToRelative(12f)
                curveToRelative(0f, 0.55f, -0.196f, 1.02f, -0.587f, 1.413f)
                arcTo(1.926f, 1.926f, 0f, isMoreThanHalf = false, isPositiveArc = true, 20f, 20f)
                close()
                moveTo(2f, 20f)
                verticalLineToRelative(-3f)
                curveToRelative(0.833f, 0f, 1.542f, 0.292f, 2.125f, 0.875f)
                reflectiveCurveTo(5f, 19.167f, 5f, 20f)
                lineTo(2f, 20f)
                close()
                moveTo(7f, 20f)
                curveToRelative(0f, -1.383f, -0.487f, -2.563f, -1.463f, -3.538f)
                curveTo(4.563f, 15.488f, 3.383f, 15f, 2f, 15f)
                verticalLineToRelative(-2f)
                curveToRelative(1.95f, 0f, 3.604f, 0.68f, 4.963f, 2.037f)
                curveTo(8.32f, 16.396f, 9f, 18.05f, 9f, 20f)
                lineTo(7f, 20f)
                close()
                moveTo(11f, 20f)
                curveToRelative(0f, -1.25f, -0.238f, -2.42f, -0.713f, -3.512f)
                arcToRelative(9.147f, 9.147f, 0f, isMoreThanHalf = false, isPositiveArc = false, -1.925f, -2.85f)
                arcToRelative(9.149f, 9.149f, 0f, isMoreThanHalf = false, isPositiveArc = false, -2.85f, -1.925f)
                arcTo(8.707f, 8.707f, 0f, isMoreThanHalf = false, isPositiveArc = false, 2f, 11f)
                lineTo(2f, 9f)
                curveToRelative(1.517f, 0f, 2.942f, 0.287f, 4.275f, 0.863f)
                curveToRelative(1.333f, 0.575f, 2.5f, 1.362f, 3.5f, 2.362f)
                reflectiveCurveToRelative(1.787f, 2.167f, 2.362f, 3.5f)
                arcTo(10.68f, 10.68f, 0f, isMoreThanHalf = false, isPositiveArc = true, 13f, 20f)
                horizontalLineToRelative(-2f)
                close()
            }
        }.build()

        return _Cast!!
    }

@Suppress("ObjectPropertyName")
private var _Cast: ImageVector? = null
