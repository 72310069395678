package studio.goodegg.capsule.api.clients

import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.delete
import io.ktor.client.request.get
import io.ktor.client.request.headers
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.request.url
import io.ktor.http.ContentType
import io.ktor.http.contentType
import io.ktor.http.isSuccess
import kotlinx.serialization.Serializable
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.PodcastSubscription
import studio.goodegg.capsule.api.FUNCTIONS_BASE_URL
import studio.goodegg.capsule.utils.Failed
import studio.goodegg.capsule.utils.Response
import studio.goodegg.capsule.utils.Success

interface SubscriptionsClient {
    suspend fun getSubscriptions(): Response<List<PodcastSubscription>>
    suspend fun insert(podcastSubscription: PodcastSubscription): Response<Boolean>
    suspend fun delete(slug: String): Response<Boolean>
}

@Inject
internal class SubscriptionsClientImpl(
    private val httpClient: HttpClient,
    private val tokenResolver: () -> String?,
) : SubscriptionsClient {
    override suspend fun getSubscriptions(): Response<List<PodcastSubscription>> {
        return try {
            val token = tokenResolver.invoke() ?: return Failed(Throwable("User is not authorised"))

            val response = httpClient.get {
                headers {
                    append("Authorization", "Bearer $token")
                }
                url("${FUNCTIONS_BASE_URL}subscriptions")
            }

            Success(response.body())
        } catch (error: Throwable) {
            error.printStackTrace()
            Failed(error)
        }
    }

    override suspend fun insert(podcastSubscription: PodcastSubscription): Response<Boolean> {
        return try {
            val token = tokenResolver.invoke() ?: return Failed(Throwable("User is not authorised"))

            val response =
                httpClient.post(urlString = "${FUNCTIONS_BASE_URL}subscriptions") {
                    contentType(ContentType.Application.Json)
                    setBody(podcastSubscription)
                    headers {
                        append("Authorization", "Bearer $token")
                    }
                }

            Success(response.status.isSuccess())
        } catch (error: Throwable) {
            error.printStackTrace()
            Failed(error)
        }
    }

    override suspend fun delete(slug: String): Response<Boolean> {
        return try {
            val token = tokenResolver.invoke() ?: return Failed(Throwable("User is not authorised"))

            val response =
                httpClient.delete(urlString = "${FUNCTIONS_BASE_URL}subscriptions") {
                    contentType(ContentType.Application.Json)
                    setBody(DeleteRequest(slug))
                    headers {
                        append("Authorization", "Bearer $token")
                    }
                }

            Success(response.status.isSuccess())
        } catch (error: Throwable) {
            error.printStackTrace()
            Failed(error)
        }
    }
}

@Serializable
private data class DeleteRequest(
    val slug: String,
)
