package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.Chevron: ImageVector
    get() {
        if (_Chevron != null) {
            return _Chevron!!
        }
        _Chevron = ImageVector.Builder(
            name = "Chevron",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(fill = SolidColor(Color(0xFFE8EAED))) {
                moveTo(8.025f, 22f)
                lineTo(6.25f, 20.225f)
                lineTo(14.475f, 12f)
                lineTo(6.25f, 3.775f)
                lineTo(8.025f, 2f)
                lineToRelative(10f, 10f)
                lineToRelative(-10f, 10f)
                close()
            }
        }.build()

        return _Chevron!!
    }

@Suppress("ObjectPropertyName")
private var _Chevron: ImageVector? = null
