package studio.goodegg.capsule.genres

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.lazy.grid.items
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.screen.Screen
import com.slack.circuit.runtime.ui.Ui
import com.slack.circuit.runtime.ui.ui
import io.daio.pancake.components.progress.FullScreenLoadingIndicator
import io.daio.pancake.foundations.text.TitleTextScreen
import io.daio.pancake.icons.ArrowBack
import io.daio.pancake.icons.Icons
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.LazyStackGrid
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.Tier
import io.daio.wild.style.clickable
import studio.goodegg.capsule.navigation.GenrePodcastsGridScreen
import studio.goodegg.capsule.ui.components.ArtworkTile

class GenreScreenUiFactory : Ui.Factory {
    override fun create(
        screen: Screen,
        context: CircuitContext,
    ): Ui<*>? {
        return when (screen) {
            is GenrePodcastsGridScreen -> genre()
            else -> null
        }
    }
}

private fun genre() =
    ui<GenreUiState> { state, modifier ->
        Genre(
            state,
            modifier,
        )
    }

@Composable
fun Genre(
    state: GenreUiState,
    modifier: Modifier = Modifier,
) {
    Box(modifier = modifier.fillMaxSize()) {
        if (state.refreshing) {
            FullScreenLoadingIndicator()
        }

        Stack(modifier = modifier) {
            Tier {
                Icon(
                    imageVector = Icons.ArrowBack,
                    contentDescription = "",
                    modifier = Modifier
                        .size(24.dp)
                        .clickable(
                            style = {
                                scale = if (pressed) .95f else 1f
                            },
                            onClick = {
                                state.eventSink(GenreUiEvent.Back)
                            },
                        ),
                )
                TitleTextScreen(state.title)
            }

            LazyStackGrid(
                columns = 3,
                modifier = Modifier.fillMaxSize(),
                gap = LayoutGap.Medium,
                contentPadding = PaddingValues(
                    bottom = 66.dp, // todo player dynamic padding
                ),
            ) {
                items(state.items, key = { it.slug }) {
                    ArtworkTile(
                        modifier = modifier.fillMaxSize(),
                        subscribed = state.subscriptions.contains(it.slug),
                        onSubscribe = null,
                        size = null,
                        imageUrl = it.imageUrl,
                        onClick = {
                            state.eventSink.invoke(GenreUiEvent.OpenPodcast(it.slug))
                        },
                    )
                }
            }
        }
    }
}
