package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.Timer: ImageVector
    get() {
        if (_Timer != null) {
            return _Timer!!
        }
        _Timer = ImageVector.Builder(
            name = "Timer",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(fill = SolidColor(Color(0xFFE8EAED))) {
                moveTo(9f, 4f)
                lineTo(9f, 2f)
                horizontalLineToRelative(6f)
                verticalLineToRelative(2f)
                lineTo(9f, 4f)
                close()
                moveTo(11f, 15f)
                horizontalLineToRelative(2f)
                lineTo(13f, 9f)
                horizontalLineToRelative(-2f)
                verticalLineToRelative(6f)
                close()
                moveTo(12f, 23f)
                arcToRelative(8.646f, 8.646f, 0f, isMoreThanHalf = false, isPositiveArc = true, -3.488f, -0.712f)
                arcTo(9.192f, 9.192f, 0f, isMoreThanHalf = false, isPositiveArc = true, 5.65f, 20.35f)
                arcToRelative(9.193f, 9.193f, 0f, isMoreThanHalf = false, isPositiveArc = true, -1.938f, -2.863f)
                arcTo(8.646f, 8.646f, 0f, isMoreThanHalf = false, isPositiveArc = true, 3f, 14f)
                arcToRelative(8.65f, 8.65f, 0f, isMoreThanHalf = false, isPositiveArc = true, 0.712f, -3.488f)
                arcTo(9.193f, 9.193f, 0f, isMoreThanHalf = false, isPositiveArc = true, 5.65f, 7.65f)
                arcToRelative(9.193f, 9.193f, 0f, isMoreThanHalf = false, isPositiveArc = true, 2.862f, -1.938f)
                arcTo(8.646f, 8.646f, 0f, isMoreThanHalf = false, isPositiveArc = true, 12f, 5f)
                arcToRelative(8.92f, 8.92f, 0f, isMoreThanHalf = false, isPositiveArc = true, 2.975f, 0.5f)
                curveToRelative(0.95f, 0.333f, 1.842f, 0.817f, 2.675f, 1.45f)
                lineToRelative(1.4f, -1.4f)
                lineToRelative(1.4f, 1.4f)
                lineToRelative(-1.4f, 1.4f)
                arcToRelative(9.723f, 9.723f, 0f, isMoreThanHalf = false, isPositiveArc = true, 1.45f, 2.675f)
                curveToRelative(0.333f, 0.95f, 0.5f, 1.942f, 0.5f, 2.975f)
                arcToRelative(8.646f, 8.646f, 0f, isMoreThanHalf = false, isPositiveArc = true, -0.712f, 3.488f)
                arcToRelative(9.192f, 9.192f, 0f, isMoreThanHalf = false, isPositiveArc = true, -1.938f, 2.862f)
                arcToRelative(9.192f, 9.192f, 0f, isMoreThanHalf = false, isPositiveArc = true, -2.863f, 1.938f)
                arcTo(8.646f, 8.646f, 0f, isMoreThanHalf = false, isPositiveArc = true, 12f, 23f)
                close()
                moveTo(12f, 21f)
                curveToRelative(1.933f, 0f, 3.583f, -0.683f, 4.95f, -2.05f)
                curveTo(18.317f, 17.583f, 19f, 15.933f, 19f, 14f)
                curveToRelative(0f, -1.933f, -0.683f, -3.583f, -2.05f, -4.95f)
                curveTo(15.583f, 7.683f, 13.933f, 7f, 12f, 7f)
                curveToRelative(-1.933f, 0f, -3.583f, 0.683f, -4.95f, 2.05f)
                curveTo(5.683f, 10.417f, 5f, 12.067f, 5f, 14f)
                curveToRelative(0f, 1.933f, 0.683f, 3.583f, 2.05f, 4.95f)
                curveTo(8.417f, 20.317f, 10.067f, 21f, 12f, 21f)
                close()
            }
        }.build()

        return _Timer!!
    }

@Suppress("ObjectPropertyName")
private var _Timer: ImageVector? = null
