package studio.goodegg.capsule.downloads

import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.foundation.layout.BoxScope
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.lazy.items
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import androidx.compose.runtime.NonRestartableComposable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.semantics.Role
import androidx.compose.ui.semantics.role
import androidx.compose.ui.semantics.semantics
import androidx.compose.ui.unit.dp
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.screen.Screen
import com.slack.circuit.runtime.ui.Ui
import com.slack.circuit.runtime.ui.ui
import io.daio.pancake.bottomsheet.BottomSheetState
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.foundations.text.BodyTextLarge
import io.daio.pancake.foundations.text.TitleTextScreen
import io.daio.pancake.icons.ArrowBack
import io.daio.pancake.icons.Check
import io.daio.pancake.icons.Chevron
import io.daio.pancake.icons.Delete
import io.daio.pancake.icons.Download
import io.daio.pancake.icons.Icons
import io.daio.pancake.icons.List
import io.daio.pancake.icons.Listen
import io.daio.pancake.icons.More
import io.daio.pancake.icons.PlayFilled
import io.daio.pancake.icons.Podcast
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.Tier
import io.daio.wild.container.Container
import io.daio.wild.style.StyleDefaults
import io.daio.wild.style.clickable
import studio.goodegg.capsule.DownloadedEpisode
import studio.goodegg.capsule.formatCreatedDate
import studio.goodegg.capsule.navigation.DownloadsScreen
import studio.goodegg.capsule.ui.components.ArtworkTile
import studio.goodegg.capsule.ui.components.ContextMenu
import studio.goodegg.capsule.ui.components.ContextMenuItem
import studio.goodegg.capsule.ui.components.LocalBottomSheet
import studio.goodegg.capsule.ui.components.PlayerAwareLazyStack

class DownloadsScreenUiFactory : Ui.Factory {
    override fun create(
        screen: Screen,
        context: CircuitContext,
    ): Ui<*>? {
        return when (screen) {
            is DownloadsScreen -> downloads()
            else -> null
        }
    }
}

private fun downloads() =
    ui<DownloadsUiState> { state, modifier ->
        Downloads(
            state,
            modifier,
        )
    }

@Composable
fun Downloads(
    state: DownloadsUiState,
    modifier: Modifier = Modifier,
) {
    Stack(modifier = modifier) {
        Tier {
            Icon(
                imageVector = Icons.ArrowBack,
                contentDescription = "",
                modifier = Modifier
                    .size(24.dp)
                    .clickable(
                        style = {
                            scale = if (pressed) .95f else 1f
                        },
                        onClick = {
                            state.eventSink(DownloadsUiEvent.Back)
                        },
                    ),
            )
            TitleTextScreen("Downloads")
        }
        val bottomSheet = LocalBottomSheet.current

        PlayerAwareLazyStack(
            modifier = Modifier.fillMaxSize(),
            spaceBetween = LayoutGap.Large,
        ) {
            items(state.items, key = { it.parentSlug }) { items ->
                var expanded by remember { mutableStateOf(true) }

                Stack(
                    modifier = Modifier.animateItem().fillMaxWidth(),
                ) {
                    DownloadGroup(
                        image = items.imageUrl,
                        active = expanded,
                        title = items.title,
                        onClick = {
                            expanded = !expanded
                        },
                    )
                    if (expanded) {
                        items.episode.forEachIndexed { index, it ->
                            Stack {
                                Episode(
                                    title = it.episode.title,
                                    subtitle = it.episode.formatCreatedDate(),
                                    onPlay = {
                                        state.eventSink(DownloadsUiEvent.PlayDownload(it))
                                    },
                                    onDelete = {
                                        state.eventSink(DownloadsUiEvent.Delete(it.download.slug))
                                    },
                                    onMore = {
                                        bottomSheet.show {
                                            ContextMenu(
                                                it.episode.title,
                                                onDismiss = {
                                                    bottomSheet.dismiss()
                                                },
                                            ) {
                                                EpisodesContextMenu(bottomSheet, state, it)
                                            }
                                        }
                                    },
                                )

                                if (index != items.episode.lastIndex) {
                                    HorizontalDivider(
                                        color = Theme.colors.onSurfaceBackground,
                                        modifier = Modifier.graphicsLayer {
                                            alpha = .2f
                                        },
                                    )
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


@Composable
private fun DownloadGroup(
    image: String?,
    title: String,
    active: Boolean,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
) {
    Container(
        modifier = modifier.height(62.dp).fillMaxWidth(),
        style = StyleDefaults.style(
            colors = StyleDefaults.colors(
                backgroundColor = Theme.colors.surfaceBackground,
                contentColor = Theme.colors.onSurfaceBackground,
            ),
        ),
        onClick = onClick,
    ) {
        Tier(
            horizontalAlignment = Alignment.Start,
            modifier = Modifier.fillMaxWidth(),
        ) {
            ArtworkTile(
                image,
                size = 62.dp,
            )

            val animateRotation by animateFloatAsState(if (active) 270f else 90f)
            BodyTextLarge(title, modifier = Modifier.weight(1f))
            Icon(
                imageVector = Icons.Chevron,
                modifier = Modifier
                    .size(18.dp)
                    .graphicsLayer {
                        rotationZ = animateRotation
                    },
                contentDescription = "",
            )
        }
    }
}

@Composable
private fun Episode(
    title: String,
    subtitle: String?,
    onPlay: () -> Unit,
    onDelete: () -> Unit,
    onMore: () -> Unit,
    modifier: Modifier = Modifier,
) {
    Tier(
        verticalAlignment = Alignment.CenterVertically,
        horizontalAlignment = Alignment.Start,
        modifier = modifier.fillMaxWidth().height(58.dp),
    ) {
        PlayerButton(
            modifier = Modifier.size(38.dp),
            onClick = onPlay,
        ) {
            Icon(
                modifier = Modifier.align(Alignment.Center)
                    .padding(Theme.dimens.size_xs),
                imageVector = Icons.PlayFilled,
                contentDescription = "",
            )
        }

        Stack(modifier = Modifier.weight(1f), spaceBetween = LayoutGap.Tiny) {
            BodyText(title, maxLines = 2)
            subtitle?.let {
                BodyText(
                    text = it,
                    textColor = Theme.colors.onSurfaceBackground.copy(alpha = .5f),
                )
            }
        }

        Icon(
            imageVector = Icons.Delete,
            modifier = Modifier
                .size(18.dp)
                .clickable(
                    style = StyleDefaults.style(
                        colors = StyleDefaults.colors(
                            Theme.colors.surfaceBackground,
                            Theme.colors.onSurfaceBackground,
                        ),
                        scale = StyleDefaults.scale(pressedScale = .9f),
                    ),
                    onClick = onDelete,
                ),
            contentDescription = "",
        )

        Icon(
            imageVector = Icons.More,
            modifier = Modifier
                .size(24.dp)
                .clickable(
                    style = StyleDefaults.style(
                        colors = StyleDefaults.colors(
                            Theme.colors.surfaceBackground,
                            Theme.colors.onSurfaceBackground,
                        ),
                        scale = StyleDefaults.scale(pressedScale = .9f),
                    ),
                    onClick = onMore,
                ),
            contentDescription = "",
        )
    }
}

@Composable
private fun EpisodesContextMenu(
    bottomSheet: BottomSheetState,
    state: DownloadsUiState,
    download: DownloadedEpisode,
) {
    download.episode.parentSlug?.let { slug ->
        ContextMenuItem(
            "Go To Podcast",
            onDismiss = {
                bottomSheet.dismiss()
            },
            icon = Icons.Podcast,
            onClick = {
                state.eventSink.invoke(DownloadsUiEvent.OpenPodcastDetails(slug))
            },
        )
        HorizontalDivider(
            color = Theme.colors.onSurfaceBackground.copy(
                alpha = .1f,
            ),
        )
    }

    ContextMenuItem(
        "Go To Episode",
        onDismiss = {
            bottomSheet.dismiss()
        },
        icon = Icons.Listen,
        onClick = {
            state.eventSink.invoke(DownloadsUiEvent.OpenEpisodeDetails(download))
        },
    )
    HorizontalDivider(
        color = Theme.colors.onSurfaceBackground.copy(
            alpha = .1f,
        ),
    )

    ContextMenuItem(
        "Delete Download",
        onDismiss = {
            bottomSheet.dismiss()
        },
        icon = Icons.Delete,
        onClick = {
            state.eventSink.invoke(
                DownloadsUiEvent.Delete(download.episode.episodeSlug),
            )
        },
    )
}

@Composable
@NonRestartableComposable
private fun PlayerButton(
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    backgroundColor: Color = Theme.colors.surfaceContainer,
    content: @Composable BoxScope.() -> Unit,
) {
    Container(
        style = StyleDefaults.style(
            colors = StyleDefaults.colors(backgroundColor = backgroundColor),
            shapes = StyleDefaults.shapes(shape = Theme.shapes.circle),
            scale = StyleDefaults.scale(pressedScale = .9f),
        ),
        modifier = modifier
            .semantics {
                role = Role.Button
            },
        onClick = onClick,
        content = content,
    )
}
