package studio.goodegg.capsule.playqueue

import com.slack.circuit.runtime.CircuitUiEvent
import studio.goodegg.capsule.Episode

sealed class PlayQueueUiEvent : CircuitUiEvent {
    data object Back : PlayQueueUiEvent()
    data class UpdatePlayQueue(val queue: List<Episode>) : PlayQueueUiEvent()
    data class RemoveFromPlayQueue(val data: Episode) : PlayQueueUiEvent()
}
