package studio.goodegg.capsule

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class TuneResponse(
    val status: String,
    val results: List<Tune>,
)

@Serializable
data class Tune(
    val type: String,
    val url: String,
    @SerialName("media_type")
    val mediaType: String,
)

@Serializable
data class StationResponse(
    val status: String,
    val results: List<Station>,
)

@Serializable
data class Station(
    val title: String,
    val slug: String,
    @SerialName("genre_id")
    val genreId: String? = null,
    val genre: String? = null,
    val guid: String,
    val subtext: String,
    val image: String,
)