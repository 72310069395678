package studio.goodegg.capsule

import kotlinx.datetime.Clock
import kotlinx.serialization.Serializable

@Serializable
data class PodcastSubscription(
    val slug: String,
    val feedUrl: String,
    val title: String,
    val artist: String,
    val image: String?,
    val notify: Boolean,
    val collection: String?,
    val autoDownload: Boolean,
    val updatedAt: Long = Clock.System.now().toEpochMilliseconds(),
    val deleted: Boolean = false,
)
