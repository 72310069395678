package studio.goodegg.capsule.domain.auth

import kotlinx.coroutines.flow.map
import me.tatarka.inject.annotations.Component
import me.tatarka.inject.annotations.Provides
import studio.goodegg.capsule.api.clients.AuthClient

@Component
interface AuthDomainComponent {
    @Provides
    fun providesSignInInteractor(client: AuthClient): SignInInteractor =
        SignInInteractor { email ->
            client.signInWithOTP(email)
        }

    @Provides
    fun providesSignOutInteractor(client: AuthClient): SignOutInteractor =
        SignOutInteractor { client.signOut() }

    @Provides
    fun providesVerifySignInInteractor(client: AuthClient): VerifySignInInteractor =
        VerifySignInInteractor { email, code -> client.verify(email, code) }

    @Provides
    fun providesGetSignedIn(client: AuthClient): GetIsSignedInInteractor =
        GetIsSignedInInteractor { client.isSignedIn }

    @Provides
    fun providesObserveSignedIn(client: AuthClient): ObserveSignedInInteractor =
        ObserveSignedInInteractor { client.observeCurrentUser() }
}
