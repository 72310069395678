package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.HomeOutline: ImageVector
    get() {
        if (_HomeOutline != null) {
            return _HomeOutline!!
        }
        _HomeOutline = ImageVector.Builder(
            name = "HomeOutline",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(fill = SolidColor(Color(0xFFE8EAED))) {
                moveTo(6f, 19f)
                horizontalLineToRelative(3f)
                verticalLineToRelative(-6f)
                horizontalLineToRelative(6f)
                verticalLineToRelative(6f)
                horizontalLineToRelative(3f)
                verticalLineToRelative(-9f)
                lineToRelative(-6f, -4.5f)
                lineTo(6f, 10f)
                verticalLineToRelative(9f)
                close()
                moveTo(4f, 21f)
                lineTo(4f, 9f)
                lineToRelative(8f, -6f)
                lineToRelative(8f, 6f)
                verticalLineToRelative(12f)
                horizontalLineToRelative(-7f)
                verticalLineToRelative(-6f)
                horizontalLineToRelative(-2f)
                verticalLineToRelative(6f)
                lineTo(4f, 21f)
                close()
            }
        }.build()

        return _HomeOutline!!
    }

@Suppress("ObjectPropertyName")
private var _HomeOutline: ImageVector? = null
