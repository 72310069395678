package studio.goodegg.capsule.repositories

import app.cash.sqldelight.async.coroutines.awaitAsOne
import app.cash.sqldelight.async.coroutines.awaitAsOneOrNull
import app.cash.sqldelight.coroutines.asFlow
import app.cash.sqldelight.coroutines.mapToList
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.buffer
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.flowOn
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.onCompletion
import kotlinx.coroutines.withContext
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.Download
import studio.goodegg.capsule.DownloadedEpisode
import studio.goodegg.capsule.FileStorageLocation
import studio.goodegg.capsule.common.coroutines.IoDispatcher
import studio.goodegg.capsule.db.CapsuleDbCreator
import studio.goodegg.capsule.db.asFlow
import studio.goodegg.capsule.downloader.Downloader

interface DownloadsRepository {
    fun download(slug: String, mediaUrl: String): Flow<Download>
    suspend fun getBySlug(slug: String): Download?
    suspend fun delete(slug: String)
    fun observe(): Flow<List<DownloadedEpisode>>
    fun observeEpisode(slug: String): Flow<Download?>
    fun observeAll(): Flow<List<Download>>
    fun observeInProgress(): Flow<List<Download>>
    fun observeDownloaded(): Flow<List<Download>>
}

@Inject
internal class DownloadsRepositoryImpl(
    private val capsuleDbCreator: CapsuleDbCreator,
    private val downloader: Downloader,
    fileStorageLocation: FileStorageLocation,
    @IoDispatcher private val ioDispatcher: CoroutineDispatcher,
) :
    DownloadsRepository {
    private val path = "${fileStorageLocation.url}/downloads/"

    override fun download(
        slug: String,
        mediaUrl: String,
    ): Flow<Download> = downloader.download(
        downloadTag = slug,
        directoryPath = path,
        fileName = slug,
        url = mediaUrl,
    ).map {
        capsuleDbCreator.get().downloadQueries.insert(
            slug = slug,
            mediaurl = mediaUrl,
            filepath = it.filePath,
            percentage = it.percentage.toLong(),
        )

        Download(
            slug = slug,
            mediaUrl = mediaUrl,
            filePath = it.filePath,
            downloadedPercentage = it.percentage.toLong(),
        )
    }
        .onCompletion { error ->
            if (error == null) {
                capsuleDbCreator.get().downloadQueries.insert(
                    slug = slug,
                    mediaurl = mediaUrl,
                    filepath = path + slug,
                    percentage = 100L,
                )
            } else {
                capsuleDbCreator.get().downloadQueries.delete(slug)
            }
        }.flowOn(ioDispatcher)

    override suspend fun getBySlug(slug: String): Download? = withContext(ioDispatcher) {
        capsuleDbCreator.get().downloadQueries.selectDownload(slug).awaitAsOneOrNull()
            ?.toDownload()
    }

    override suspend fun delete(slug: String) = withContext(ioDispatcher) {
        downloader.cancel(slug)
        capsuleDbCreator.get().downloadQueries.delete(slug)
    }

    override fun observe(): Flow<List<DownloadedEpisode>> {
        return capsuleDbCreator.asFlow {
            downloadQueries.selectAllIncludingInProgress()
                .asFlow()
                .mapToList(ioDispatcher)
                .buffer()
                .map {
                    it.map { download ->
                        val episode = capsuleDbCreator.get().podcastQueries
                            .selectEpisode(download.slug)
                            .awaitAsOne()

                        DownloadedEpisode(
                            download = download.toDownload(),
                            episode = episode.toEpisode(),
                        )
                    }.sortedBy { it.episode.author }
                }.flowOn(ioDispatcher)
        }
    }

    override fun observeEpisode(slug: String): Flow<Download?> {
        return observeAll().map { downloads ->
            downloads.find { download -> download.slug == slug }
        }
    }

    override fun observeAll(): Flow<List<Download>> {
        return capsuleDbCreator.asFlow {
            downloadQueries.selectAllIncludingInProgress()
                .asFlow()
                .mapToList(ioDispatcher)
                .map { it.map { download -> download.toDownload() } }
                .flowOn(ioDispatcher)
        }
    }

    override fun observeInProgress(): Flow<List<Download>> {
        return capsuleDbCreator.asFlow {
            downloadQueries.selectAllInProgress()
                .asFlow()
                .mapToList(ioDispatcher)
                .map {
                    it.map { download -> download.toDownload() }
                }.flowOn(ioDispatcher)
        }
    }

    override fun observeDownloaded(): Flow<List<Download>> {
        return capsuleDbCreator.asFlow {
            downloadQueries.selectAll()
                .asFlow()
                .mapToList(ioDispatcher)
                .map {
                    it.map { download -> download.toDownload() }
                }.flowOn(ioDispatcher)
        }
    }
}

private fun studio.goodegg.capsule.db.Download.toDownload(): Download = Download(
    slug = slug,
    mediaUrl = mediaurl,
    filePath = filepath,
    downloadedPercentage = percentage,
)

