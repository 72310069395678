import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.runtime.remember
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.CanvasBasedWindow
import io.daio.pancake.layout.Surface
import org.jetbrains.skiko.wasm.onWasmReady
import studio.goodegg.capsule.base.main.JsAppComponent
import studio.goodegg.capsule.base.main.JsUiComponent
import studio.goodegg.capsule.base.main.MainApp
import studio.goodegg.capsule.base.main.create

@OptIn(ExperimentalComposeUiApi::class)
fun main() {
    val appComponent = JsAppComponent.create()

    onWasmReady {
        CanvasBasedWindow("Capsule") {
            val uiScope = remember { JsUiComponent.create(appComponent) }
            Surface(modifier = Modifier.fillMaxSize()) {
                MainApp(
                    uiScope.circuit,
                    Modifier.padding(start = 300.dp, end = 300.dp),
                )
            }
        }
    }
}
