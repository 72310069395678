package studio.goodegg.capsule.signin

import androidx.compose.material3.TextField
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.screen.Screen
import com.slack.circuit.runtime.ui.Ui
import com.slack.circuit.runtime.ui.ui
import io.daio.pancake.components.buttons.SmallButton
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Stack
import studio.goodegg.capsule.navigation.SignInScreen

class SignInScreenUiFactory : Ui.Factory {
    override fun create(
        screen: Screen,
        context: CircuitContext,
    ): Ui<*>? {
        return when (screen) {
            is SignInScreen -> signin()
            else -> null
        }
    }
}

private fun signin() =
    ui<SignInUiState> { state, modifier ->
        SignIn(
            state,
            modifier,
        )
    }

@Composable
fun SignIn(
    state: SignInUiState,
    modifier: Modifier = Modifier,
) {
    Stack(modifier, spaceBetween = LayoutGap.Large) {
        if (state.user != null) {
            BodyText("Current user: ${state.user?.email}")
            SmallButton(
                "Sign Out",
                onClick = {
                    state.eventSink(SignInUiEvent.SignOut)
                },
            )
        } else if (state.awaitingCode) {
            var code by remember { mutableStateOf("") }
            BodyText("${state.currentEmail}")
            TextField(
                value = code,
                onValueChange = {
                    code = it
                },
                singleLine = true,
            )
            SmallButton(
                "Verify",
                onClick = {
                    state.eventSink(SignInUiEvent.VerifyCode(state.currentEmail!!, code))
                },
            )
        } else if (state.currentEmail == null) {
            var email by remember { mutableStateOf("") }
            TextField(
                value = email,
                onValueChange = {
                    email = it
                },
                singleLine = true,
            )
            SmallButton(
                "SignIn",
                onClick = {
                    state.eventSink(SignInUiEvent.SignInWithEmail(email))
                },
            )
        }
        SmallButton(
            "Test Onboarding",
            onClick = {
                state.eventSink(SignInUiEvent.TestOnboarding)
            },
        )
    }
}
