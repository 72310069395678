package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.SearchFilled: ImageVector
    get() {
        if (_SearchFilled != null) {
            return _SearchFilled!!
        }
        _SearchFilled = ImageVector.Builder(
            name = "SearchFilled",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(
                fill = SolidColor(Color(0xFFFFFFFF)),
                pathFillType = PathFillType.EvenOdd
            ) {
                moveToRelative(13.3f, 14.7f)
                lineToRelative(6.3f, 6.3f)
                lineToRelative(1.4f, -1.4f)
                lineToRelative(-6.3f, -6.3f)
                arcTo(6.096f, 6.096f, 0f, isMoreThanHalf = false, isPositiveArc = false, 16f, 9.5f)
                curveToRelative(0f, -1.817f, -0.63f, -3.354f, -1.887f, -4.612f)
                curveTo(12.854f, 3.629f, 11.317f, 3f, 9.5f, 3f)
                curveToRelative(-1.817f, 0f, -3.354f, 0.63f, -4.612f, 1.888f)
                curveTo(3.629f, 6.146f, 3f, 7.683f, 3f, 9.5f)
                curveToRelative(0f, 1.817f, 0.63f, 3.354f, 1.888f, 4.613f)
                curveTo(6.146f, 15.37f, 7.683f, 16f, 9.5f, 16f)
                arcToRelative(6.096f, 6.096f, 0f, isMoreThanHalf = false, isPositiveArc = false, 3.8f, -1.3f)
                close()
                moveTo(12.687f, 12.688f)
                curveTo(11.813f, 13.562f, 10.75f, 14f, 9.5f, 14f)
                curveToRelative(-1.25f, 0f, -2.313f, -0.438f, -3.188f, -1.313f)
                curveTo(5.438f, 11.813f, 5f, 10.75f, 5f, 9.5f)
                curveToRelative(0f, -1.25f, 0.438f, -2.313f, 1.313f, -3.188f)
                reflectiveCurveTo(8.25f, 5f, 9.5f, 5f)
                curveToRelative(1.25f, 0f, 2.313f, 0.438f, 3.188f, 1.313f)
                curveTo(13.562f, 7.188f, 14f, 8.25f, 14f, 9.5f)
                curveToRelative(0f, 1.25f, -0.438f, 2.313f, -1.313f, 3.188f)
                close()
                moveTo(9.5f, 13f)
                arcToRelative(3.5f, 3.5f, 0f, isMoreThanHalf = true, isPositiveArc = false, 0f, -7f)
                arcToRelative(3.5f, 3.5f, 0f, isMoreThanHalf = false, isPositiveArc = false, 0f, 7f)
                close()
            }
        }.build()

        return _SearchFilled!!
    }

@Suppress("ObjectPropertyName")
private var _SearchFilled: ImageVector? = null
