package studio.goodegg.capsule

import kotlinx.serialization.Serializable

@Serializable
data class PodcastList(val trending: List<PodcastResult>, val categories: List<Category>)

@Serializable
data class TrendingRow(val name: String, val podcasts: List<PodcastResult>)

@Serializable
data class TrendingResult(
    val trending: List<TrendingRow>,
    val top: List<PodcastResult>,
    val categories: List<Category>,
)
