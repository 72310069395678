package studio.goodegg.capsule.domain.podcast

import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.distinctUntilChanged
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.flowOn
import kotlinx.coroutines.flow.onStart
import kotlinx.coroutines.withContext
import me.tatarka.inject.annotations.Component
import me.tatarka.inject.annotations.Provides
import studio.goodegg.capsule.Episode
import studio.goodegg.capsule.EpisodeAndMetadata
import studio.goodegg.capsule.PlayState
import studio.goodegg.capsule.PlaybackState
import studio.goodegg.capsule.common.coroutines.IoDispatcher
import studio.goodegg.capsule.common.coroutines.MainDispatcher
import studio.goodegg.capsule.core.playback.Player
import studio.goodegg.capsule.core.playback.PlayerItem
import studio.goodegg.capsule.core.playback.PlayerProgress
import studio.goodegg.capsule.core.playback.ZeroProgress
import studio.goodegg.capsule.mergeWithEpisodes
import studio.goodegg.capsule.repositories.DownloadsRepository
import studio.goodegg.capsule.repositories.PlayedItemsRepository
import studio.goodegg.capsule.repositories.PodcastRepository
import studio.goodegg.capsule.repositories.RefreshPolicy
import studio.goodegg.capsule.repositories.percentage

@Component
interface PodcastDomainComponent {
    @Provides
    fun providesGetPodcastInteractor(podcastsRepository: PodcastRepository): GetPodcastInteractor =
        GetPodcastInteractor { slug -> podcastsRepository.getPodcast(slug) }

    @Provides
    fun providesObservePodcastInteractor(
        podcastsRepository: PodcastRepository,
        observeEpisodesInteractor: ObserveEpisodesInteractor,
    ): ObservePodcastInteractor =
        ObservePodcastInteractor { slug ->
            podcastsRepository.observePodcastOnly(slug)
                .distinctUntilChanged()
                .combine(
                    observeEpisodesInteractor.invoke(
                        listOf(slug),
                        2000,
                    ).onStart { emit(emptyList()) },
                ) { podcast, episodes ->
                    podcast?.mergeWithEpisodes(episodes)
                }
        }

    @Provides
    fun providesObserveEpisodesInteractor(
        podcastsRepository: PodcastRepository,
        downloadsRepository: DownloadsRepository,
        playedItemsRepository: PlayedItemsRepository,
        player: Player,
        @IoDispatcher ioDispatcher: CoroutineDispatcher,
        @MainDispatcher mainDispatcher: CoroutineDispatcher,
    ): ObserveEpisodesInteractor =
        ObserveEpisodesInteractor { slugs, limit ->
            combine(
                podcastsRepository.observeEpisodes(slugs, limit).distinctUntilChanged().onStart {
                    emit(
                        emptyList(),
                    )
                },
                downloadsRepository.observeAll().onStart { emit(emptyList()) },
                playedItemsRepository.observeInProgress().onStart { emit(emptyList()) },
                player.playbackState().flowOn(mainDispatcher).onStart { emit(PlayState.Idle) },
                player.playerProgress().flowOn(mainDispatcher).onStart { emit(ZeroProgress) },
            )
            { episodes, downloads, played, playState, progress ->
                episodes.map { episode ->
                    val currentItem =
                        withContext(mainDispatcher) { player.currentItem as? PlayerItem.PodcastEpisode }
                    val inProgressState =
                        if (currentItem?.episode?.episodeSlug == episode.episodeSlug) {
                            withContext(mainDispatcher) {
                                getInProgressState(
                                    episode = currentItem.episode,
                                    progress = progress,
                                    playState = playState,
                                    playedItemsRepository = playedItemsRepository,
                                    player = player,
                                )
                            }
                        } else {
                            null
                        }

                    val playedState =
                        inProgressState ?: played.find { it.slug == episode.episodeSlug }?.let {
                            PlaybackState(
                                currentPosition = it.currentPosition,
                                progress = it.percentage(),
                                playState = PlayState.Idle,
                                slug = episode.episodeSlug,
                            )
                        }
                    val download = downloads.find { it.slug == episode.episodeSlug }
                    EpisodeAndMetadata(
                        download = download,
                        episode = episode,
                        playingState = playedState,
                    )
                }
            }.flowOn(ioDispatcher)
        }

    @Provides
    fun providesObserveEpisode(
        podcastsRepository: PodcastRepository,
        downloadsRepository: DownloadsRepository,
        playedItemsRepository: PlayedItemsRepository,
        player: Player,
    ): ObserveEpisodeInteractor = ObserveEpisodeInteractor { slug ->
        combine(
            flow {
                // We always go to network to get the full details.
                emit(podcastsRepository.getEpisode(slug, RefreshPolicy.Network))
            },
            downloadsRepository.observeEpisode(slug),
            player.playbackState()
                .filter {
                    (player.currentItem as? PlayerItem.PodcastEpisode)?.episode?.episodeSlug == slug
                }
                .onStart { emit(PlayState.Idle) },
            player.playerProgress()
                .filter {
                    (player.currentItem as? PlayerItem.PodcastEpisode)?.episode?.episodeSlug == slug
                }
                .onStart { emit(ZeroProgress) },
        ) { episode, download, state, progress ->
            episode?.let {
                val isPLaying =
                    (player.currentItem as? PlayerItem.PodcastEpisode)?.episode?.episodeSlug == slug

                val playbackState = if (isPLaying) {
                    PlaybackState(
                        slug,
                        player.currentPosition,
                        progress.percentage,
                        state,
                    )
                } else {
                    null
                }

                EpisodeAndMetadata(download, playbackState, episode)
            }
        }
    }
}

private suspend fun getInProgressState(
    episode: Episode,
    progress: PlayerProgress,
    playState: PlayState,
    playedItemsRepository: PlayedItemsRepository,
    player: Player,
): PlaybackState? {
    return when (playState) {
        PlayState.Buffering, PlayState.Paused, PlayState.Playing -> {
            val playedItem =
                if (progress == ZeroProgress) {
                    playedItemsRepository.getBySlug(episode.episodeSlug)
                } else {
                    null
                }

            val percentage = playedItem?.percentage() ?: progress.percentage

            PlaybackState(
                currentPosition = player.currentPosition,
                progress = percentage,
                playState = playState,
                slug = episode.episodeSlug,
            )
        }

        else -> null
    }
}