package studio.goodegg.capsule.ui.components

import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.foundation.layout.RowScope
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import androidx.compose.runtime.compositionLocalOf
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.unit.dp
import com.slack.circuit.foundation.internal.BackHandler
import io.daio.pancake.bottomsheet.BottomSheetState
import io.daio.pancake.components.item.ListItem
import io.daio.pancake.components.toggle.Toggle
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.foundations.text.TitleText
import io.daio.pancake.icons.Cancel
import io.daio.pancake.icons.Icons
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.SurfaceContainer
import io.daio.pancake.layout.Tier

val LocalBottomSheet = compositionLocalOf<BottomSheetState> { error("No") }

@Composable
fun ContextMenu(
    title: String,
    modifier: Modifier = Modifier,
    onDismiss: (() -> Unit)? = null,
    content: @Composable ColumnScope.() -> Unit,
) {
    BackHandler(onDismiss != null) {
        onDismiss?.invoke()
    }
    SurfaceContainer(modifier = modifier.fillMaxWidth()) {
        Stack(
            spaceBetween = LayoutGap.Large,
            modifier = modifier.fillMaxWidth()
                .padding(20.dp),
        ) {
            Tier(
                modifier = Modifier.fillMaxWidth(),
            ) {
                TitleText(title, modifier = Modifier.weight(.9f))
                onDismiss?.let {
                    IconButton(
                        modifier = Modifier.weight(.1f),
                        icon = Icons.Cancel,
                        onClick = onDismiss,
                    )
                }
            }
            Stack(spaceBetween = LayoutGap.Medium) {
                content()
            }
        }
    }
}

@Composable
fun ContextMenuItem(
    title: String,
    icon: ImageVector?,
    onDismiss: () -> Unit,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    trailingIcon: (@Composable RowScope.() -> Unit)? = null,
    containerColor: Color = Theme.colors.surfaceContainer,
) {
    ListItem(
        modifier = modifier.fillMaxWidth(),
        containerColor = containerColor,
        leadingIcon = icon?.let {
            {
                Icon(
                    modifier = Modifier.size(12.dp),
                    imageVector = icon,
                    contentDescription = null,
                )
            }
        },
        trailingIcon = trailingIcon,
        onClick = {
            onDismiss()
            onClick()
        },
    ) {
        BodyText(title, modifier = Modifier.weight(1f))
    }
}

@Composable
fun ContextMenuCheckItem(
    title: String,
    check: Boolean,
    icon: ImageVector,
    onCheckedChange: (Boolean) -> Unit,
    modifier: Modifier = Modifier,
) {
    ListItem(
        modifier = modifier.fillMaxWidth(),
        containerColor = Theme.colors.surfaceContainer,
        leadingIcon = {
            Icon(
                modifier = Modifier.size(12.dp),
                imageVector = icon,
                contentDescription = null,
            )
        },
        onClick = {

        },
        trailingIcon = {
            Toggle(check, onCheckedChange)
        },
    ) {
        BodyText(title, modifier = Modifier.weight(1f))
    }
}