package studio.goodegg.capsule.genres

import androidx.compose.runtime.Immutable
import com.slack.circuit.runtime.CircuitUiState
import studio.goodegg.capsule.PodcastResult

@Immutable
data class GenreUiState(
    val refreshing: Boolean,
    val subscriptions: Set<String>,
    val items: List<PodcastResult>,
    val title: String,
    val eventSink: (GenreUiEvent) -> Unit,
) : CircuitUiState
