package studio.goodegg.capsule.search

import com.slack.circuit.runtime.CircuitUiEvent
import studio.goodegg.capsule.Episode
import studio.goodegg.capsule.EpisodeAndMetadata
import studio.goodegg.capsule.PodcastResult
import studio.goodegg.capsule.Station

sealed class SearchUiEvent : CircuitUiEvent {
    data class OpenPodcast(val slug: String) : SearchUiEvent()

    data class OpenEpisode(val slug: String) : SearchUiEvent()

    data class Subscribe(val podcastResult: PodcastResult) : SearchUiEvent()

    data class Play(val episode: Episode) : SearchUiEvent()

    data class Tune(val station: Station) : SearchUiEvent()

    data class Download(val episode: EpisodeAndMetadata) : SearchUiEvent()

    data class Share(val episode: Episode) : SearchUiEvent()

    data class Search(val query: String, val includeEpisodes: Boolean = true) : SearchUiEvent()

    data class AddToPlayQueue(val data: Episode) : SearchUiEvent()

    data class RemoveFromPlayQueue(val data: Episode) : SearchUiEvent()

    data object Back : SearchUiEvent()
}
