package studio.goodegg.capsule.ui.components

import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.TitleText
import io.daio.wild.container.Container
import io.daio.wild.style.Border
import io.daio.wild.style.BorderDefaults
import io.daio.wild.style.StyleDefaults

@Composable
fun CategoryCard(
    title: String,
    backgroundColor: Color = Theme.colors.surfaceContainer,
    contentColor: Color = Theme.colors.onSurfaceBackground,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
) {
    Container(
        modifier = modifier.size(height = 120.dp, width = 184.dp),
        style = StyleDefaults.style(
            shapes = StyleDefaults.shapes(Theme.shapes.large),
            colors = StyleDefaults.colors(
                backgroundColor = backgroundColor.copy(alpha = .5f),
                contentColor = contentColor,
            ),
            borders = StyleDefaults.borders(
                focusedBorder = Border(
                    width = 2.dp,
                    color = Theme.colors.surfaceInteractive,
                ),
            ),
            scale = StyleDefaults.scale(pressedScale = .9f),
        ),
        onClick = onClick,
    ) {
        TitleText(
            text = title,
            textAlign = TextAlign.Center,
            modifier = Modifier.fillMaxSize()
                .padding(Theme.dimens.size_m)
                .align(Alignment.Center),
        )
    }
}

private val categoryColors = listOf(
    Color(0xFF095B53), Color(0xFF114047),
    Color(0xFF2C2543), Color(0xFF362647),
    Color(0xFF2D0C1D), Color(0xFF2B1224),
    Color(0xFF23223D), Color(0xFF1E3447),
    Color(0xFF1A3042), Color(0xFF4B284F),
    Color(0xFF291830), Color(0xFF142135),
    Color(0xFF1E2A41), Color(0xFF50233E),
    Color(0xFF1E162A), Color(0xFF161B2E),
    Color(0xFF21243F), Color(0xFF551E2D),
    Color(0xFF231B35), Color(0xFF13152A),
)

fun getCategoryColor(index: Int): Color {
    return categoryColors[index % categoryColors.size]
}

private fun createCategoryCardColorSequence() = sequence {
    while (true) {
        yieldAll(categoryColors)
    }
}.iterator()

@Composable
fun rememberCategoryCardColorSequence(key: Any? = null): Iterator<Color> =
    remember(key) { createCategoryCardColorSequence() }