package studio.goodegg.capsule.db.db

import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.AfterVersion
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlDriver
import app.cash.sqldelight.db.SqlSchema
import kotlin.Long
import kotlin.Unit
import kotlin.reflect.KClass
import studio.goodegg.capsule.db.CapsuleDb
import studio.goodegg.capsule.db.DownloadQueries
import studio.goodegg.capsule.db.PlayedQueries
import studio.goodegg.capsule.db.PodcastQueries
import studio.goodegg.capsule.db.SubscriptionQueries

internal val KClass<CapsuleDb>.schema: SqlSchema<QueryResult.AsyncValue<Unit>>
  get() = CapsuleDbImpl.Schema

internal fun KClass<CapsuleDb>.newInstance(driver: SqlDriver): CapsuleDb = CapsuleDbImpl(driver)

private class CapsuleDbImpl(
  driver: SqlDriver,
) : SuspendingTransacterImpl(driver), CapsuleDb {
  override val downloadQueries: DownloadQueries = DownloadQueries(driver)

  override val playedQueries: PlayedQueries = PlayedQueries(driver)

  override val podcastQueries: PodcastQueries = PodcastQueries(driver)

  override val subscriptionQueries: SubscriptionQueries = SubscriptionQueries(driver)

  public object Schema : SqlSchema<QueryResult.AsyncValue<Unit>> {
    override val version: Long
      get() = 1

    override fun create(driver: SqlDriver): QueryResult.AsyncValue<Unit> = QueryResult.AsyncValue {
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS download (
          |    slug TEXT PRIMARY KEY,
          |    mediaurl TEXT NOT NULL,
          |    filepath TEXT NOT NULL,
          |    percentage INTEGER NOT NULL
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS played (
          |    slug text PRIMARY KEY,
          |    duration INTEGER NOT NULL,
          |    current_position INTEGER NOT NULL,
          |    last_played INTEGER NOT NULL,
          |    played_count INTEGER NOT NULL
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS dbpodcast (
          |    slug text PRIMARY KEY,
          |    id INTEGER NOT NULL,
          |    title text NOT NULL,
          |    author text NOT NULL,
          |    feed text NOT NULL,
          |    "collectionId" INTEGER NOT NULL,
          |    "artistId" INTEGER ,
          |    thumbnail text NOT NULL,
          |    "imageUrl" text NOT NULL,
          |    "trackCount" INTEGER ,
          |    "primaryGenreName" text NOT NULL,
          |    summary text NOT NULL DEFAULT 'No summary available',
          |    categories text,
          |    feedimage text
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS dbepisode (
          |    "episodeSlug" TEXT PRIMARY KEY,
          |    title TEXT NOT NULL,
          |    author TEXT NOT NULL,
          |    created INTEGER NOT NULL,
          |    description TEXT DEFAULT 'No description available',
          |    image TEXT,
          |    media TEXT NOT NULL,
          |    "mediaType" TEXT NOT NULL,
          |    "fileSize" INTEGER ,
          |    duration INTEGER ,
          |    "parentFeed" TEXT NOT NULL,
          |    parent_slug TEXT NOT NULL,
          |    FOREIGN KEY (parent_slug) REFERENCES dbpodcast(slug) ON DELETE CASCADE
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS subscription (
          |    slug TEXT PRIMARY KEY,
          |    feedurl TEXT NOT NULL,
          |    title TEXT NOT NULL,
          |    artist TEXT NOT NULL,
          |    image TEXT,
          |    notify INTEGER DEFAULT 0 NOT NULL,
          |    collection TEXT,
          |    autodownload INTEGER DEFAULT 0 NOT NULL,
          |    updated_at INTEGER NOT NULL, -- Added updated_at for sync
          |    deleted INTEGER DEFAULT 0 NOT NULL -- Added deleted for soft delete
          |)
          """.trimMargin(), 0).await()
    }

    override fun migrate(
      driver: SqlDriver,
      oldVersion: Long,
      newVersion: Long,
      vararg callbacks: AfterVersion,
    ): QueryResult.AsyncValue<Unit> = QueryResult.AsyncValue {
    }
  }
}
