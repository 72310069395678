package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.FeedFilled: ImageVector
    get() {
        if (_FeedFilled != null) {
            return _FeedFilled!!
        }
        _FeedFilled = ImageVector.Builder(
            name = "FeedFilled",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(
                fill = SolidColor(Color(0xFFFFFFFF)),
                pathFillType = PathFillType.EvenOdd
            ) {
                moveTo(4f, 4f)
                horizontalLineToRelative(16f)
                verticalLineToRelative(11f)
                lineTo(4f, 15f)
                lineTo(4f, 4f)
                close()
                moveTo(4.5f, 17.75f)
                horizontalLineToRelative(15f)
                verticalLineToRelative(-1.5f)
                horizontalLineToRelative(-15f)
                verticalLineToRelative(1.5f)
                close()
                moveTo(6f, 20.25f)
                horizontalLineToRelative(12.5f)
                verticalLineToRelative(-1.5f)
                lineTo(6f, 18.75f)
                verticalLineToRelative(1.5f)
                close()
            }
        }.build()

        return _FeedFilled!!
    }

@Suppress("ObjectPropertyName")
private var _FeedFilled: ImageVector? = null
