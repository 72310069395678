package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.DensityLarge: ImageVector
    get() {
        if (_DensityLarge != null) {
            return _DensityLarge!!
        }
        _DensityLarge = ImageVector.Builder(
            name = "DensityLarge",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(fill = SolidColor(Color(0xFFE8EAED))) {
                moveTo(5f, 17f)
                curveToRelative(-0.55f, 0f, -1.02f, -0.196f, -1.413f, -0.587f)
                arcTo(1.926f, 1.926f, 0f, isMoreThanHalf = false, isPositiveArc = true, 3f, 15f)
                lineTo(3f, 9f)
                curveToRelative(0f, -0.55f, 0.196f, -1.02f, 0.587f, -1.412f)
                arcTo(1.926f, 1.926f, 0f, isMoreThanHalf = false, isPositiveArc = true, 5f, 7f)
                horizontalLineToRelative(14f)
                curveToRelative(0.55f, 0f, 1.02f, 0.196f, 1.413f, 0.588f)
                curveToRelative(0.391f, 0.391f, 0.587f, 0.862f, 0.587f, 1.412f)
                verticalLineToRelative(6f)
                curveToRelative(0f, 0.55f, -0.196f, 1.02f, -0.587f, 1.413f)
                arcTo(1.926f, 1.926f, 0f, isMoreThanHalf = false, isPositiveArc = true, 19f, 17f)
                lineTo(5f, 17f)
                close()
                moveTo(5f, 15f)
                horizontalLineToRelative(14f)
                lineTo(19f, 9f)
                lineTo(5f, 9f)
                verticalLineToRelative(6f)
                close()
                moveTo(3f, 5f)
                lineTo(3f, 3f)
                horizontalLineToRelative(18f)
                verticalLineToRelative(2f)
                lineTo(3f, 5f)
                close()
                moveTo(3f, 21f)
                verticalLineToRelative(-2f)
                horizontalLineToRelative(18f)
                verticalLineToRelative(2f)
                lineTo(3f, 21f)
                close()
            }
        }.build()

        return _DensityLarge!!
    }

@Suppress("ObjectPropertyName")
private var _DensityLarge: ImageVector? = null
