package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.Played: ImageVector
    get() {
        if (_Played != null) {
            return _Played!!
        }
        _Played = ImageVector.Builder(
            name = "Played",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(fill = SolidColor(Color(0xFFE8EAED))) {
                moveToRelative(9.55f, 16f)
                lineToRelative(-5.675f, -5.675f)
                lineTo(5.3f, 8.9f)
                lineToRelative(4.25f, 4.25f)
                lineTo(18.7f, 4f)
                lineToRelative(1.425f, 1.425f)
                lineTo(9.55f, 16f)
                close()
                moveTo(5f, 20f)
                verticalLineToRelative(-2f)
                horizontalLineToRelative(14f)
                verticalLineToRelative(2f)
                horizontalLineTo(5f)
                close()
            }
        }.build()

        return _Played!!
    }

@Suppress("ObjectPropertyName")
private var _Played: ImageVector? = null
