package studio.goodegg.capsule

import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.datetime.format
import kotlinx.datetime.format.DateTimeComponents
import kotlinx.datetime.format.MonthNames
import kotlinx.datetime.format.char
import kotlinx.serialization.Serializable
import kotlin.time.DurationUnit
import kotlin.time.toDuration

@Serializable
data class Podcast(
    val id: Int,
    val title: String,
    val imageUrl: String,
    val author: String,
    val feed: String,
    val collectionId: Int,
    val slug: String,
    val artistId: Int? = null,
    val thumbnail: String? = null,
    val feedImage: String? = null,
    val trackCount: Int,
    val primaryGenreName: String,
    val summary: String,
    val episodes: List<Episode> = emptyList(),
)

@Serializable
data class Episode(
    val title: String,
    val image: String?,
    val author: String,
    val created: Long,
    val description: String? = null,
    val media: String,
    val fileSize: Int,
    val duration: Long,
    val mediaType: String,
    val parentFeed: String? = null,
    val parentSlug: String? = null,
    val episodeSlug: String,
    val episodeType: String,
)

data class PodcastWithEpisodeMeta(
    val id: Int,
    val title: String,
    val imageUrl: String,
    val author: String,
    val feed: String,
    val collectionId: Int,
    val slug: String,
    val artistId: Int? = null,
    val thumbnail: String? = null,
    val feedImage: String? = null,
    val trackCount: Int,
    val primaryGenreName: String,
    val summary: String,
    val episodes: List<EpisodeAndMetadata> = emptyList(),
)

fun Podcast.mergeWithEpisodes(episodes: List<EpisodeAndMetadata>): PodcastWithEpisodeMeta =
    PodcastWithEpisodeMeta(
        id = id,
        title = title,
        imageUrl = imageUrl,
        author = author,
        feed = feed,
        collectionId = collectionId,
        slug = slug,
        artistId = artistId,
        thumbnail = thumbnail,
        feedImage = feedImage,
        trackCount = trackCount,
        primaryGenreName = primaryGenreName,
        summary = summary,
        episodes = episodes,
    )

fun Episode.formatCreatedDate(): String {
    val now = Clock.System.now()
    val duration = (now.toEpochMilliseconds() - created).toDuration(DurationUnit.MILLISECONDS)

    val days = duration.inWholeDays
    if (days > 30) {
        val dateFormat =
            DateTimeComponents.Format {
                dayOfMonth()
                char(' ')
                monthName(MonthNames.ENGLISH_ABBREVIATED)
                char(' ')
                year()
            }

        return Instant.fromEpochMilliseconds(created).format(dateFormat)
    }

    if (days > 0) {
        return "$days day${if (days > 1) "s" else ""} ago"
    }

    val hours = duration.inWholeHours
    if (hours > 0) {
        return "$hours hour${if (hours > 1) "s" else ""} ago"
    }

    val minutes = duration.inWholeMinutes.coerceAtLeast(1)
    return "$minutes minute${if (minutes > 1) "s" else ""} ago"
}

fun Episode.formatDuration(): String {
    val duration = this.duration.toDuration(DurationUnit.MILLISECONDS)
    val hours = duration.inWholeHours
    val minutes = duration.inWholeMinutes % 60
    val seconds = duration.inWholeSeconds % 60

    return if (hours > 0) {
        "${hours}h ${minutes}m ${seconds}s"
    } else if (minutes > 0) {
        "${minutes}m ${seconds}s"
    } else if (seconds > 0) {
        "${seconds}s"
    } else {
        ""
    }
}

fun Episode.formatTimeRemaining(currentPosition: Long): String {
    val remaining = this.duration - currentPosition
    return remaining.formatTime()
}

fun Long.formatTime(): String {
    val duration = toDuration(DurationUnit.MILLISECONDS)
    val hours = duration.inWholeHours
    val minutes = duration.inWholeMinutes % 60
    val seconds = duration.inWholeSeconds % 60

    val hoursText = if (hours > 1) "hours" else "hour"
    val minsText = if (minutes > 1) "minutes" else "minute"
    val secsText = if (seconds > 1) "seconds" else "second"

    return if (hours > 0) {
        "$hours $hoursText $minutes $minsText"
    } else if (minutes > 0) {
        "$minutes $minsText"
    } else if (seconds > 0) {
        "$seconds $secsText"
    } else {
        ""
    }
}


fun Episode.formatTimeRemainingShort(currentPosition: Long): String {
    val remaining = this.duration - currentPosition
    return remaining.formatTimeShort()
}

fun Long.formatTimeShort(): String {
    val duration = toDuration(DurationUnit.MILLISECONDS)

    val hours = duration.inWholeHours
    val minutes = duration.inWholeMinutes % 60
    val seconds = duration.inWholeSeconds % 60

    if (hours > 24 || minutes > 60 || seconds > 60)
        return "00:00:00"

    if (hours > 0) {
        return "${hours.toString().padStart(2, '0')}:${
            minutes.toString().padStart(2, '0')
        }:${seconds.toString().padStart(2, '0')}"
    }

    return "${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}"
}

fun Episode.formatCurrentPosition(currentPosition: Long): String {
    val elapsed = currentPosition.toDuration(DurationUnit.MILLISECONDS)

    val hours = elapsed.inWholeHours
    val minutes = elapsed.inWholeMinutes % 60
    val seconds = elapsed.inWholeSeconds % 60

    if (hours > 0) {
        return "${hours.toString().padStart(2, '0')}:${
            minutes.toString().padStart(2, '0')
        }:${seconds.toString().padStart(2, '0')}"
    }

    return "${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}"
}

fun Episode.formatFileSize(): String {
    return if (fileSize <= 0) {
        "unknown"
    } else {
        "${fileSize}mb"
    }
}

data class PlaybackState(
    val slug: String,
    val currentPosition: Long,
    val progress: Float,
    val playState: PlayState,
)
