package studio.goodegg.capsule.trending

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.lazy.itemsIndexed
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.onFocusChanged
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.text.intl.Locale
import androidx.compose.ui.unit.dp
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.screen.Screen
import com.slack.circuit.runtime.ui.Ui
import com.slack.circuit.runtime.ui.ui
import io.daio.pancake.components.progress.FullScreenLoadingIndicator
import io.daio.pancake.components.search.SearchBar
import io.daio.pancake.components.search.SearchBarDefaults
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.TitleText
import io.daio.pancake.icons.Icons
import io.daio.pancake.icons.Trending
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.LazyTier
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.Surface
import io.daio.pancake.layout.Tier
import io.daio.wild.container.Container
import io.daio.wild.style.clickable
import studio.goodegg.capsule.PodcastResult
import studio.goodegg.capsule.TrendingRow
import studio.goodegg.capsule.navigation.DiscoverScreen
import studio.goodegg.capsule.ui.components.ArtworkTile
import studio.goodegg.capsule.ui.components.CategoryCard
import studio.goodegg.capsule.ui.components.PlayerAwareLazyStack
import studio.goodegg.capsule.ui.components.getCategoryColor
import studio.goodegg.capsule.ui.components.shimmer

class TrendingScreenUiFactory : Ui.Factory {
    override fun create(
        screen: Screen,
        context: CircuitContext,
    ): Ui<*>? {
        return when (screen) {
            is DiscoverScreen -> trending()
            else -> null
        }
    }
}

private fun trending() =
    ui<TrendingUiState> { state, modifier ->
        Trending(
            state,
            modifier,
        )
    }

@Composable
fun Trending(
    state: TrendingUiState,
    modifier: Modifier = Modifier,
) {
    Box(modifier = modifier.fillMaxSize()) {
        if (state.refreshing) {
            TrendingLoadingPlaceholder()
        } else {
            Stack(modifier = modifier) {
                Spacer(modifier = Modifier.height(Theme.dimens.size_xxxs))
                SearchBar(
                    "",
                    onValueChange = {
                    },
                    modifier =
                        Modifier.onFocusChanged {
                            if (it.isFocused) {
                                state.eventSink(TrendingUiEvent.OpenSearch)
                            }
                        },
                )
                PlayerAwareLazyStack(
                    modifier = Modifier.fillMaxSize(),
                    spaceBetween = LayoutGap.ExtraLarge,
                ) {
                    if (state.items.top.isNotEmpty()) {
                        item(key = "top") {
                            TrendingRow(
                                modifier = Modifier.animateItem(),
                                icon = Icons.Trending,
                                trending = TrendingRow(
                                    Locale.current.region,
                                    state.items.top,
                                ),
                                isSubscribed = {
                                    state.subscriptions.contains(it.slug)
                                },
                                onSubscribe = { podcast ->
                                    state.eventSink(TrendingUiEvent.AddToSubscriptions(podcast))
                                },
                                onClick = { podcast ->
                                    state.eventSink(TrendingUiEvent.OpenPodcast(podcast.slug))
                                },
                            )
                        }
                    }
                    if (state.items.categories.isNotEmpty()) {
                        item(key = "categories") {
                            Stack(modifier = modifier, spaceBetween = LayoutGap.Tiny) {
                                Tier(
                                    modifier = Modifier.fillMaxWidth(),
                                    horizontalArrangement = Arrangement.SpaceBetween,
                                ) {
                                    TitleText("Explore by Category")
                                    TitleText(
                                        "See All",
                                        textColor = Theme.colors.accent,
                                        modifier = Modifier.clickable(
                                            style = {
                                                color = Color.Transparent
                                                scale = if (pressed) .9f else 1f
                                            },
                                        ) {
                                            state.eventSink.invoke(TrendingUiEvent.OpenCategories)
                                        },
                                    )
                                }
                                LazyTier {
                                    itemsIndexed(
                                        state.items.categories,
                                        key = { _, it -> it.genreId },
                                    ) { index, item ->
                                        CategoryCard(
                                            title = item.label,
                                            backgroundColor = getCategoryColor(index),
                                            onClick = {
                                                state.eventSink.invoke(
                                                    TrendingUiEvent.OpenCategory(
                                                        item.genreId,
                                                        item.label,
                                                    ),
                                                )
                                            },
                                        )
                                    }
                                }
                            }
                        }
                    }
                    items(state.items.trending, key = { it.name }) { trendingRow ->
                        TrendingRow(
                            modifier = Modifier.animateItem(),
                            icon = Icons.Trending,
                            trending = trendingRow,
                            isSubscribed = {
                                state.subscriptions.contains(it.slug)
                            },
                            onSubscribe = { podcast ->
                                state.eventSink(TrendingUiEvent.AddToSubscriptions(podcast))
                            },
                            onClick = { podcast ->
                                state.eventSink(TrendingUiEvent.OpenPodcast(podcast.slug))
                            },
                        )
                    }
                }
            }
        }

    }
}

@Composable
private fun TrendingLoadingPlaceholder(modifier: Modifier = Modifier) {
    Stack(modifier = modifier.shimmer()) {
        Spacer(modifier = Modifier.height(Theme.dimens.size_xxxs))
        Container(
            color = Theme.colors.surfaceContainer,
            shape = Theme.shapes.large,
            modifier = Modifier.fillMaxWidth().height(SearchBarDefaults.minHeight),
        ) {
        }

        PlayerAwareLazyStack(
            modifier = Modifier.fillMaxSize(),
            spaceBetween = LayoutGap.ExtraLarge,
        ) {
            item {
                TrendingRowPlaceholder()
            }
            item {
                CategoryRowPlaceholder()
            }
            item {
                TrendingRowPlaceholder()
            }
            item {
                TrendingRowPlaceholder()
            }
            item {
                TrendingRowPlaceholder()
            }
        }
    }
}

@Composable
private fun TrendingRowPlaceholder(modifier: Modifier = Modifier) {
    Stack(modifier = modifier, spaceBetween = LayoutGap.Tiny) {
        Tier(spaceBetween = LayoutGap.ExtraTiny) {
            TitleText(
                "",
                modifier = Modifier.background(Theme.colors.surfaceContainer).width(100.dp),
            )
        }
        LazyTier {
            items(5) {
                Surface(
                    modifier = Modifier.size(120.dp).animateItem(),
                    shape = Theme.shapes.large,
                    color = Theme.colors.surfaceContainer,
                ) {

                }
            }
        }
    }
}

@Composable
private fun CategoryRowPlaceholder(modifier: Modifier = Modifier) {
    Stack(modifier = modifier, spaceBetween = LayoutGap.Tiny) {
        Tier(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.SpaceBetween,
        ) {
            TitleText(
                "",
                modifier = Modifier.background(Theme.colors.surfaceContainer).width(100.dp),
            )

            TitleText(
                "",
                modifier = Modifier.background(Theme.colors.surfaceContainer).width(80.dp),
            )
        }
        LazyTier {
            items(5) { item ->
                Surface(
                    modifier = Modifier.size(height = 120.dp, width = 184.dp),
                    color = getCategoryColor(item),
                    shape = Theme.shapes.large,
                ) {

                }
            }
        }
    }
}

@Composable
private fun TrendingRow(
    trending: TrendingRow,
    onClick: (PodcastResult) -> Unit,
    isSubscribed: (PodcastResult) -> Boolean,
    onSubscribe: (PodcastResult) -> Unit,
    modifier: Modifier = Modifier,
    icon: ImageVector? = null,
) {
    Stack(modifier = modifier, spaceBetween = LayoutGap.Tiny) {
        Tier(spaceBetween = LayoutGap.ExtraTiny) {
            icon?.let {
                Icon(
                    tint = Theme.colors.accent,
                    imageVector = icon,
                    contentDescription = "",
                    modifier = Modifier.size(20.dp),
                )
            }
            TitleText("Trending in ${trending.name}")
        }
        LazyTier {
            items(
                trending.podcasts,
                key = { it.slug },
            ) {
                ArtworkTile(
                    size = 120.dp,
                    modifier = Modifier.animateItem(),
                    imageUrl = it.imageUrl,
                    subscribed = isSubscribed(it),
                    onSubscribe = null,
                    onClick = {
                        onClick(it)
                    },
                )
            }
        }
    }
}
