package studio.goodegg.capsule.onboarding

import androidx.compose.animation.core.Animatable
import androidx.compose.animation.core.FastOutLinearInEasing
import androidx.compose.animation.core.LinearOutSlowInEasing
import androidx.compose.animation.core.RepeatMode
import androidx.compose.animation.core.animateDpAsState
import androidx.compose.animation.core.animateFloat
import androidx.compose.animation.core.infiniteRepeatable
import androidx.compose.animation.core.keyframes
import androidx.compose.animation.core.rememberInfiniteTransition
import androidx.compose.animation.core.tween
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.lazy.grid.items
import androidx.compose.foundation.pager.HorizontalPager
import androidx.compose.foundation.pager.rememberPagerState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.Icon
import androidx.compose.material3.VerticalDivider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.compositionLocalOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.scale
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.screen.Screen
import com.slack.circuit.runtime.ui.Ui
import com.slack.circuit.runtime.ui.ui
import io.daio.pancake.components.buttons.ButtonDefaults
import io.daio.pancake.components.buttons.MediumButton
import io.daio.pancake.components.buttons.SmallButton
import io.daio.pancake.components.text.TextArea
import io.daio.pancake.components.text.TextAreaDefaults
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyTextLarge
import io.daio.pancake.foundations.text.TitleTextScreen
import io.daio.pancake.icons.Icons
import io.daio.pancake.icons.Notification
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.LazyStackGrid
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.Surface
import io.daio.pancake.layout.Tier
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flowOf
import studio.goodegg.capsule.navigation.OnboardingScreen
import studio.goodegg.capsule.ui.CapsuleIcon
import studio.goodegg.capsule.ui.Logos
import studio.goodegg.capsule.ui.components.ArtworkTile

class OnboardingScreenUiFactory : Ui.Factory {
    override fun create(
        screen: Screen,
        context: CircuitContext,
    ): Ui<*>? {
        return when (screen) {
            is OnboardingScreen -> onboarding()
            else -> null
        }
    }
}

private fun onboarding() =
    ui<OnboardingUiState> { state, modifier ->
        Onboarding(
            state,
            modifier,
        )
    }

private val pages = listOf(SignUpSection(), NotificationsSection(), SubscribeSection())

@Composable
fun Onboarding(
    state: OnboardingUiState,
    modifier: Modifier = Modifier,
) {
    Surface(modifier = modifier.fillMaxSize()) {
        Stack(
            spaceBetween = LayoutGap.Small,
            verticalAlignment = Alignment.CenterVertically,
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            val pagerState = rememberPagerState(0) { pages.size }
            Tier(horizontalAlignment = Alignment.End, modifier = Modifier.fillMaxWidth()) {
                SmallButton(
                    text = "Skip",
                    colors = ButtonDefaults.colors(
                        backgroundColor = Color.Transparent,
                        contentColor = Theme.colors.onSurfaceBackground,
                    ),
                    onClick = {
                        val next = pagerState.currentPage + 1
                        if (next < pagerState.pageCount)
                            pagerState.requestScrollToPage(next)
                        else
                            state.eventSink.invoke(OnboardingUiEvent.Skip)
                    },
                )
            }

            val bounceAnim = remember { Animatable(0f) }

            LaunchedEffect(pagerState.currentPage) {
                bounceAnim.animateTo(
                    targetValue = -20f,
                    animationSpec = tween(durationMillis = 300, easing = LinearOutSlowInEasing),
                )
                bounceAnim.animateTo(
                    targetValue = 0f,
                    animationSpec = tween(durationMillis = 300, easing = FastOutLinearInEasing),
                )
                bounceAnim.animateTo(
                    targetValue = -10f, // Second bounce (smaller)
                    animationSpec = tween(durationMillis = 200, easing = LinearOutSlowInEasing),
                )
                bounceAnim.animateTo(
                    targetValue = 0f,
                    animationSpec = tween(durationMillis = 200, easing = FastOutLinearInEasing),
                )
            }

            Icon(
                Logos.CapsuleIcon,
                contentDescription = "capsule logo",
                modifier = Modifier.height(42.dp)
                    .offset(y = bounceAnim.value.dp),
                tint = Theme.colors.accent,
            )

            Spacer(Modifier.height(Theme.dimens.size_s))
            DotIndicator(
                pagerState.currentPage,
                pagerState.pageCount,
            )
            Spacer(Modifier.height(pages[pagerState.currentPage].topPadding))
            TitleTextScreen(pages[pagerState.currentPage].title)
            BodyTextLarge(
                pages[pagerState.currentPage].body,
                textAlign = TextAlign.Center,
                modifier = Modifier.padding(horizontal = Theme.dimens.size_xl),
            )
            HorizontalPager(
                state = pagerState,
                modifier = Modifier.fillMaxWidth(),
                userScrollEnabled = false,
                beyondViewportPageCount = 1,
            ) { page ->
                pages[page].Content(
                    onNext = {
                        val next = pagerState.currentPage + 1
                        if (next < pagerState.pageCount)
                            pagerState.requestScrollToPage(next)
                        else
                            state.eventSink.invoke(OnboardingUiEvent.Skip)
                    },
                    state = state,
                )
            }
        }
    }
}

interface OnboardingSection {
    val title: String
    val body: String
    val topPadding: Dp

    @Composable
    fun Content(
        onNext: () -> Unit,
        state: OnboardingUiState,
    )
}

private class SubscribeSection : OnboardingSection {
    override val title: String
        get() = "Get Started"
    override val body: String
        get() = "Here are recommended trending podcasts to get you started"
    override val topPadding: Dp
        get() = 0.dp

    @Composable
    override fun Content(onNext: () -> Unit, state: OnboardingUiState) {
        Stack(
            modifier = Modifier.fillMaxSize(),
            verticalAlignment = Alignment.Top,
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            Spacer(Modifier.height(Theme.dimens.size_xxs))

            LazyStackGrid(2, modifier = Modifier.weight(1f)) {
                items(state.discover.trending, key = { it.slug }) {
                    ArtworkTile(
                        modifier = Modifier.animateItem(),
                        size = null,
                        subscribed = state.subscriptions.contains(it.slug),
                        onSubscribe = {
                            state.eventSink.invoke(OnboardingUiEvent.Subscribe(it))
                        },
                        backgroundColor = Color.Transparent,
                        imageUrl = it.imageUrl,
                        onClick = {
                            state.eventSink.invoke(OnboardingUiEvent.Subscribe(it))
                        },
                    )
                }
            }

            MediumButton(
                "Continue",
                modifier = Modifier.width(148.dp),
                colors = ButtonDefaults.colors(
                    backgroundColor = Theme.colors.accent,
                ),
                onClick = onNext,
            )
            Spacer(Modifier.height(Theme.dimens.size_s))
        }
    }
}

private class SignUpSection : OnboardingSection {
    override val title: String
        get() = "Sign Up or Sign In"

    override val body: String
        get() = "With a free account, you can easily subscribe and sync between multiple devices."
    override val topPadding: Dp = 56.dp

    @Composable
    override fun Content(onNext: () -> Unit, state: OnboardingUiState) {
        Stack(
            modifier = Modifier.fillMaxSize(),
            verticalAlignment = Alignment.Top,
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            Spacer(Modifier.height(Theme.dimens.size_xl))

            var input by remember { mutableStateOf("") }
            if (state.user == null) {
                TextArea(
                    modifier = Modifier.padding(horizontal = 48.dp),
                    textStyle = Theme.typography.titleDefault,
                    keyboardOptions = KeyboardOptions(
                        keyboardType = if (state.awaitingSignInCode) KeyboardType.Number else KeyboardType.Email,
                    ),
                    maxLines = 1,
                    textAlign = TextAlign.Center,
                    colors = TextAreaDefaults.colors(
                        backgroundColor = Theme.colors.surfaceInteractive,
                    ),
                    value = input,
                    onValueChange = {
                        input = it
                    },
                    placeholder = if (state.awaitingSignInCode) "Enter verification code" else "Enter your email",
                )
            } else {
                BodyTextLarge("You're all set")
            }
            Spacer(Modifier.height(26.dp))
            MediumButton(
                if (state.user != null) "Continue" else "Submit",
                enabled = input.isNotBlank() || state.user != null,
                colors = ButtonDefaults.colors(
                    backgroundColor = Theme.colors.accent,
                ),
                modifier = Modifier.width(148.dp),
                onClick = {
                    if (input.isBlank() && state.user == null)
                        return@MediumButton

                    if (state.user != null) {
                        onNext()
                    } else if (state.awaitingSignInCode) {
                        state.eventSink(
                            OnboardingUiEvent.VerifySignUp(input),
                        )
                    } else if (!state.awaitingVerifySignIn) {
                        state.eventSink(
                            OnboardingUiEvent.SignUp(input),
                        )
                    }

                    input = ""
                },
            )
        }
    }
}

private class NotificationsSection : OnboardingSection {
    override val title: String
        get() = "Don’t miss anything!"
    override val body: String
        get() = "Turn on notifications to always stay up to date with your favorite podcasts!"
    override val topPadding: Dp = 56.dp

    @Composable
    override fun Content(onNext: () -> Unit, state: OnboardingUiState) {
        Stack(
            modifier = Modifier.fillMaxSize(),
            verticalAlignment = Alignment.Top,
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            Spacer(Modifier.height(Theme.dimens.size_xxs))

            Box(
                modifier = Modifier.graphicsLayer {
                    scaleX = 0.8f
                    scaleY = 0.8f
                },
                contentAlignment = Alignment.TopCenter,
            ) {
                AnimatedNotification(Icons.Notification, "Your favorite podcast just dropped!")
            }
            VerticalDivider(color = Theme.colors.accent, modifier = Modifier.height(60.dp))
            MediumButton(
                modifier = Modifier.widthIn(min = 148.dp),
                text = if (state.notificationsEnabled) "Continue" else "Turn On Notifications",
                colors = ButtonDefaults.colors(
                    backgroundColor = Theme.colors.accent,
                ),
                onClick = {
                    if (state.notificationsEnabled) {
                        onNext()
                    } else {
                        state.eventSink.invoke(OnboardingUiEvent.EnableNotifications)
                    }
                },
            )
        }
    }
}

@Composable
private fun AnimatedNotification(
    icon: ImageVector,
    title: String,
    modifier: Modifier = Modifier,
    animationZRotationDegrees: Float = 3f,
    animationShakes: Int = 12,
) {
    val infiniteTransition = rememberInfiniteTransition()
    val shakeAnimation by infiniteTransition.animateFloat(
        initialValue = 0f,
        targetValue = 0f, // targetValue is not used in keyframes but required for animateFloat
        animationSpec = infiniteRepeatable(
            animation = keyframes {
                durationMillis = (animationShakes * 50) + 2000
                delayMillis = 500
                var current = 0
                repeat(animationShakes) {
                    current += 50
                    if (it % 2 != 0)
                        -animationZRotationDegrees at current
                    else
                        animationZRotationDegrees at current
                }
                current += 50
                0f at current
            },
            repeatMode = RepeatMode.Restart, // Restart the animation after each cycle
        ),
    )

    Box {
        Box(
            modifier = Modifier
                .offset(y = 30.dp)
                .graphicsLayer {
                    alpha = .6f
                }
                .scale(.85f)
                .background(Theme.colors.surfaceInteractive, shape = RoundedCornerShape(10.dp))
                .fillMaxWidth()
                .height(80.dp),
        )

        Box(
            modifier = Modifier
                .offset(y = 15.dp)
                .graphicsLayer {
                    alpha = .6f
                }
                .scale(.95f)
                .background(Theme.colors.surfaceInteractive, shape = RoundedCornerShape(10.dp))
                .fillMaxWidth()
                .height(80.dp),
        )

        Tier(
            horizontalAlignment = Alignment.Start,
            modifier = modifier
                .fillMaxWidth()
                .height(80.dp)
                .graphicsLayer {
                    rotationZ = shakeAnimation
                }
                .background(
                    Theme.colors.surfaceInteractive,
                    RoundedCornerShape(10.dp),
                )
                .padding(horizontal = Theme.dimens.size_m),
        ) {
            Icon(
                modifier = Modifier.size(32.dp),
                imageVector = icon,
                tint = Theme.colors.onSurfaceInteractive,
                contentDescription = "",
            )
            BodyTextLarge(title, textColor = Theme.colors.onSurfaceInteractive)
        }
    }
}

@Composable
fun DotIndicator(
    selectedIndex: Int,
    count: Int,
    selectedColor: Color = Theme.colors.accent,
    unselectedColor: Color = Theme.colors.onSurfaceBackground,
    modifier: Modifier = Modifier,
) {
    Row(
        horizontalArrangement = Arrangement.spacedBy(
            Theme.dimens.size_xxs,
        ),
        modifier = modifier,
    ) {
        repeat(count) { index ->
            val isSelected = selectedIndex == index
            val width by animateDpAsState(targetValue = if (isSelected) 18.dp else 6.dp)
            val color = if (isSelected) selectedColor else unselectedColor.copy(alpha = .2f)
            Box(
                modifier = Modifier.size(
                    width = width, height = 6.dp,
                ).background(color, shape = Theme.shapes.circle),
            )
        }
    }
}