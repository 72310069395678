package io.daio.pancake.foundations.text

import androidx.compose.runtime.Immutable
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight.Companion.W400
import androidx.compose.ui.text.font.FontWeight.Companion.W500
import androidx.compose.ui.text.font.FontWeight.Companion.W700
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp

@Immutable
data class Typography internal constructor(
    val titleScreen: TextStyle,
    val titleLarge: TextStyle,
    val titleDefault: TextStyle,
    val bodyLarge: TextStyle,
    val bodyDefault: TextStyle,
    val bodySmall: TextStyle,
    val link: TextStyle,
)

internal val mainTypography: Typography by lazy(LazyThreadSafetyMode.NONE) {
    Typography(
        titleScreen =
            TextStyle(
                fontSize = FontSize.fontTitleScreen,
                fontStyle = FontStyle.Normal,
                fontWeight = W700,
                lineHeight = 32.sp,
            ),
        titleLarge =
            TextStyle(
                fontSize = FontSize.fontTitleLarge,
                fontStyle = FontStyle.Normal,
                fontWeight = W700,
                lineHeight = 24.sp,
            ),
        titleDefault =
            TextStyle(
                fontSize = FontSize.fontTitleDefault,
                fontStyle = FontStyle.Normal,
                fontWeight = W700,
                lineHeight = 18.sp,
            ),
        bodyLarge =
            TextStyle(
                fontSize = FontSize.fontBodyLarge,
                fontFamily = FontFamily.SansSerif,
                fontStyle = FontStyle.Normal,
                fontWeight = W400,
                lineHeight = 20.sp,
            ),
        bodyDefault =
            TextStyle(
                fontSize = FontSize.fontBodyDefault,
                fontFamily = FontFamily.SansSerif,
                fontStyle = FontStyle.Normal,
                fontWeight = W400,
                lineHeight = 18.sp,
            ),
        bodySmall =
            TextStyle(
                fontSize = FontSize.fontBodySmall,
                fontFamily = FontFamily.SansSerif,
                fontStyle = FontStyle.Normal,
                fontWeight = W400,
                lineHeight = 18.sp,
            ),
        link =
            TextStyle(
                fontSize = FontSize.fontLink,
                fontFamily = FontFamily.SansSerif,
                fontStyle = FontStyle.Normal,
                lineHeight = 12.sp,
            ),
    )
}
