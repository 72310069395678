package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.ShareAndroid: ImageVector
    get() {
        if (_ShareAndroid != null) {
            return _ShareAndroid!!
        }
        _ShareAndroid = ImageVector.Builder(
            name = "ShareAndroid",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF))) {
                moveTo(16.5f, 21f)
                arcToRelative(2.387f, 2.387f, 0f, isMoreThanHalf = false, isPositiveArc = true, -1.77f, -0.744f)
                arcTo(2.485f, 2.485f, 0f, isMoreThanHalf = false, isPositiveArc = true, 14f, 18.45f)
                curveToRelative(0f, -0.085f, 0.02f, -0.283f, 0.063f, -0.595f)
                lineTo(8.207f, 14.37f)
                arcToRelative(2.465f, 2.465f, 0f, isMoreThanHalf = false, isPositiveArc = true, -1.708f, 0.68f)
                arcToRelative(2.387f, 2.387f, 0f, isMoreThanHalf = false, isPositiveArc = true, -1.77f, -0.744f)
                arcTo(2.485f, 2.485f, 0f, isMoreThanHalf = false, isPositiveArc = true, 4f, 12.5f)
                curveToRelative(0f, -0.708f, 0.243f, -1.31f, 0.73f, -1.806f)
                arcTo(2.387f, 2.387f, 0f, isMoreThanHalf = false, isPositiveArc = true, 6.5f, 9.95f)
                arcToRelative(2.445f, 2.445f, 0f, isMoreThanHalf = false, isPositiveArc = true, 1.708f, 0.68f)
                lineToRelative(5.854f, -3.485f)
                arcToRelative(1.478f, 1.478f, 0f, isMoreThanHalf = false, isPositiveArc = true, -0.052f, -0.287f)
                arcTo(4.152f, 4.152f, 0f, isMoreThanHalf = false, isPositiveArc = true, 14f, 6.55f)
                curveToRelative(0f, -0.708f, 0.243f, -1.31f, 0.73f, -1.806f)
                arcTo(2.387f, 2.387f, 0f, isMoreThanHalf = false, isPositiveArc = true, 16.5f, 4f)
                curveToRelative(0.694f, 0f, 1.285f, 0.248f, 1.77f, 0.744f)
                curveToRelative(0.487f, 0.496f, 0.73f, 1.098f, 0.73f, 1.806f)
                reflectiveCurveToRelative(-0.243f, 1.31f, -0.73f, 1.806f)
                arcToRelative(2.387f, 2.387f, 0f, isMoreThanHalf = false, isPositiveArc = true, -1.77f, 0.744f)
                curveToRelative(-0.333f, 0f, -0.646f, -0.06f, -0.938f, -0.18f)
                arcToRelative(2.465f, 2.465f, 0f, isMoreThanHalf = false, isPositiveArc = true, -0.77f, -0.5f)
                lineToRelative(-5.854f, 3.485f)
                curveToRelative(0.027f, 0.1f, 0.045f, 0.195f, 0.052f, 0.287f)
                arcToRelative(4.125f, 4.125f, 0f, isMoreThanHalf = false, isPositiveArc = true, 0f, 0.616f)
                arcToRelative(1.476f, 1.476f, 0f, isMoreThanHalf = false, isPositiveArc = true, -0.053f, 0.287f)
                lineToRelative(5.855f, 3.485f)
                arcToRelative(2.465f, 2.465f, 0f, isMoreThanHalf = false, isPositiveArc = true, 1.708f, -0.68f)
                curveToRelative(0.694f, 0f, 1.285f, 0.248f, 1.77f, 0.744f)
                curveToRelative(0.487f, 0.496f, 0.73f, 1.098f, 0.73f, 1.806f)
                reflectiveCurveToRelative(-0.243f, 1.31f, -0.73f, 1.806f)
                arcTo(2.387f, 2.387f, 0f, isMoreThanHalf = false, isPositiveArc = true, 16.5f, 21f)
                close()
                moveTo(16.5f, 19.3f)
                arcToRelative(0.799f, 0.799f, 0f, isMoreThanHalf = false, isPositiveArc = false, 0.594f, -0.244f)
                arcToRelative(0.831f, 0.831f, 0f, isMoreThanHalf = false, isPositiveArc = false, 0.24f, -0.606f)
                curveToRelative(0f, -0.24f, -0.08f, -0.443f, -0.24f, -0.606f)
                arcToRelative(0.799f, 0.799f, 0f, isMoreThanHalf = false, isPositiveArc = false, -0.594f, -0.244f)
                arcToRelative(0.799f, 0.799f, 0f, isMoreThanHalf = false, isPositiveArc = false, -0.594f, 0.244f)
                arcToRelative(0.831f, 0.831f, 0f, isMoreThanHalf = false, isPositiveArc = false, -0.24f, 0.606f)
                curveToRelative(0f, 0.24f, 0.08f, 0.443f, 0.24f, 0.606f)
                curveToRelative(0.16f, 0.162f, 0.358f, 0.244f, 0.594f, 0.244f)
                close()
                moveTo(6.5f, 13.35f)
                arcToRelative(0.798f, 0.798f, 0f, isMoreThanHalf = false, isPositiveArc = false, 0.594f, -0.244f)
                arcToRelative(0.831f, 0.831f, 0f, isMoreThanHalf = false, isPositiveArc = false, 0.24f, -0.606f)
                curveToRelative(0f, -0.24f, -0.08f, -0.443f, -0.24f, -0.606f)
                arcToRelative(0.798f, 0.798f, 0f, isMoreThanHalf = false, isPositiveArc = false, -0.594f, -0.244f)
                arcToRelative(0.798f, 0.798f, 0f, isMoreThanHalf = false, isPositiveArc = false, -0.594f, 0.244f)
                arcToRelative(0.831f, 0.831f, 0f, isMoreThanHalf = false, isPositiveArc = false, -0.24f, 0.606f)
                curveToRelative(0f, 0.24f, 0.08f, 0.443f, 0.24f, 0.606f)
                curveToRelative(0.16f, 0.162f, 0.358f, 0.244f, 0.594f, 0.244f)
                close()
                moveTo(16.5f, 7.4f)
                arcToRelative(0.798f, 0.798f, 0f, isMoreThanHalf = false, isPositiveArc = false, 0.594f, -0.244f)
                arcToRelative(0.831f, 0.831f, 0f, isMoreThanHalf = false, isPositiveArc = false, 0.24f, -0.606f)
                curveToRelative(0f, -0.24f, -0.08f, -0.443f, -0.24f, -0.606f)
                arcTo(0.798f, 0.798f, 0f, isMoreThanHalf = false, isPositiveArc = false, 16.5f, 5.7f)
                arcToRelative(0.798f, 0.798f, 0f, isMoreThanHalf = false, isPositiveArc = false, -0.594f, 0.244f)
                arcToRelative(0.831f, 0.831f, 0f, isMoreThanHalf = false, isPositiveArc = false, -0.24f, 0.606f)
                curveToRelative(0f, 0.24f, 0.08f, 0.443f, 0.24f, 0.606f)
                curveToRelative(0.16f, 0.163f, 0.358f, 0.244f, 0.594f, 0.244f)
                close()
            }
        }.build()

        return _ShareAndroid!!
    }

@Suppress("ObjectPropertyName")
private var _ShareAndroid: ImageVector? = null
