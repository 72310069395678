package studio.goodegg.capsule.details.podcast

import com.slack.circuit.runtime.CircuitUiEvent
import studio.goodegg.capsule.Episode
import studio.goodegg.capsule.EpisodeAndMetadata
import studio.goodegg.capsule.ui.components.Filter

sealed class PodcastDetailsUiEvent : CircuitUiEvent {
    data class PlayEpisode(val episode: Episode, val openPlayer: Boolean = false) :
        PodcastDetailsUiEvent()

    data class MarkAsPlayed(val episode: Episode) : PodcastDetailsUiEvent()

    data class DownloadEpisode(val data: EpisodeAndMetadata) : PodcastDetailsUiEvent()

    data class ShareEpisode(val episode: Episode) : PodcastDetailsUiEvent()

    data object SharePodcast : PodcastDetailsUiEvent()

    data class Search(val query: String) : PodcastDetailsUiEvent()

    data class SetFilter(val filter: Filter) : PodcastDetailsUiEvent()

    data class OpenEpisodeDetails(val episode: Episode) : PodcastDetailsUiEvent()

    data object ToggleNotifications : PodcastDetailsUiEvent()

    data object ToggleAutoDownload : PodcastDetailsUiEvent()

    data object Subscribe : PodcastDetailsUiEvent()

    data class AddToPlayQueue(val data: EpisodeAndMetadata) : PodcastDetailsUiEvent()

    data class RemoveFromPlayQueue(val data: EpisodeAndMetadata) : PodcastDetailsUiEvent()
}
