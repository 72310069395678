package studio.goodegg.capsule.settings

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.widthIn
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import com.slack.circuit.foundation.internal.BackHandler
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.screen.Screen
import com.slack.circuit.runtime.ui.Ui
import com.slack.circuit.runtime.ui.ui
import io.daio.pancake.components.buttons.ButtonDefaults
import io.daio.pancake.components.buttons.SmallButton
import io.daio.pancake.components.toggle.Toggle
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.foundations.text.TitleText
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.Tier
import io.daio.wild.style.StyleDefaults
import io.daio.wild.style.clickable
import studio.goodegg.capsule.navigation.SettingsScreen

class SettingsScreenUiFactory : Ui.Factory {
    override fun create(
        screen: Screen,
        context: CircuitContext,
    ): Ui<*>? {
        return when (screen) {
            is SettingsScreen -> settings()
            else -> null
        }
    }
}

private fun settings() =
    ui<SettingsUiState> { state, modifier ->
        Settings(
            state,
            modifier,
        )
    }

private val sections = listOf(
    MainSettingsSection(),
    ThemeSettingsSection(),
    PlaybackSettingsSection(),
    SupportSettingsSection(),
)

@Composable
fun Settings(
    state: SettingsUiState,
    modifier: Modifier = Modifier,
) {
    Box(modifier = modifier.fillMaxSize()) {
        var currentSection by rememberSaveable { mutableStateOf(sections.first().key) }
        val current = sections.find { currentSection == it.key }!!

        Stack(spaceBetween = LayoutGap.ExtraLarge) {
            Tier {
//                Icon(
//                    imageVector = Icons.ArrowBack,
//                    contentDescription = "",
//                    modifier = Modifier
//                        .size(24.dp)
//                        .clickable(
//                            style = {
//                                scale = if (pressed) .95f else 1f
//                            },
//                            onClick = {
//                                if (currentSection != sections.first().key) {
//                                    currentSection = sections.first().key
//                                } else {
//                                    state.eventSink(SettingsUiEvent.Back)
//                                }
//                            },
//                        ),
//                )
                TitleText(
                    current.title,
                    textColor = Theme.colors.accent,
                )
            }
            BackHandler(currentSection != sections.first().key) {
                currentSection = sections.first().key
            }

            current.Content(
                onNext = {
                    currentSection = it.key
                },
                state = state,
            )
        }
    }
}

interface SettingSection {
    val key: String
    val title: String

    @Composable
    fun Content(
        onNext: (SettingSection) -> Unit,
        state: SettingsUiState,
    )
}

private class MainSettingsSection : SettingSection {
    override val key: String
        get() = "main-setting"

    override val title: String
        get() = "Settings"

    @Composable
    override fun Content(
        onNext: (SettingSection) -> Unit,
        state: SettingsUiState,
    ) {
        SettingOption(
            "Theme",
            onClick = {
                onNext(ThemeSettingsSection())
            },
        )

        SettingOption(
            "Playback",
            onClick = {
                onNext(PlaybackSettingsSection())
            },
        )

        SettingOption(
            "Support",
            onClick = {
                onNext(SupportSettingsSection())
            },
        )

        Spacer(Modifier.height(Theme.dimens.size_xl))
        SmallButton(
            text = if (state.signedIn) "Sign Out" else "Sign in",
            colors = ButtonDefaults.colors(
                backgroundColor = Theme.colors.accent,
                contentColor = Theme.colors.onAccent,
            ),
            modifier = Modifier.widthIn(min = 148.dp),
            onClick = {
                state.eventSink.invoke(
                    if (state.signedIn)
                        SettingsUiEvent.SignOut
                    else SettingsUiEvent.SignIn,
                )
            },
        )
        state.user?.let {
            BodyText(it.email, textColor = Theme.colors.accent)
        }
    }

}

private class ThemeSettingsSection : SettingSection {
    override val key: String
        get() = "theme-setting"

    override val title: String
        get() = "Themes"

    @Composable
    override fun Content(
        onNext: (SettingSection) -> Unit,
        state: SettingsUiState,
    ) {
        SettingOption("Default", {})
    }
}

private class PlaybackSettingsSection : SettingSection {
    override val key: String
        get() = "player-setting"

    override val title: String
        get() = "Playback"

    @Composable
    override fun Content(
        onNext: (SettingSection) -> Unit,
        state: SettingsUiState,
    ) {

    }
}

private class SupportSettingsSection : SettingSection {
    override val key: String
        get() = "support-setting"

    override val title: String
        get() = "Support"

    @Composable
    override fun Content(
        onNext: (SettingSection) -> Unit,
        state: SettingsUiState,
    ) {
        SettingOption(
            "Do Not Track", {},
            trailing = {
                Toggle(
                    true,
                    modifier = Modifier.height(12.dp),
                    onCheckedChange = {

                    },
                )
            },
        )
        SettingOption("Privacy Policy", {})
        SettingOption("Send Bug Report", {})
    }
}

@Composable
private fun SettingOption(
    title: String,
    onClick: () -> Unit,
    trailing: @Composable (() -> Unit)? = null,
    modifier: Modifier = Modifier,
) {
    Tier(modifier = modifier.fillMaxWidth().height(24.dp)) {
        TitleText(
            text = title,
            modifier = modifier.weight(1f).clickable(
                style = StyleDefaults.style(
                    colors = StyleDefaults.colors(backgroundColor = Color.Transparent),
                    scale = StyleDefaults.scale(pressedScale = .98f),
                ),
                onClick = onClick,
            ),
        )

        trailing?.invoke()
    }
}