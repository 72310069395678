package studio.goodegg.capsule.inbox

import androidx.compose.runtime.Immutable
import com.slack.circuit.runtime.CircuitUiState
import studio.goodegg.capsule.EpisodeAndMetadata
import studio.goodegg.capsule.PodcastSubscription
import studio.goodegg.capsule.ui.components.Filter

@Immutable
data class InboxUiState(
    val cardStyle: CardStyle = CardStyle.Compact,
    val subscriptions: List<PodcastSubscription>,
    val playQueue: List<String>,
    val latestEpisodes: List<EpisodeAndMetadata>,
    val showNoMoreEpisodes: Boolean = false,
    val showNoSubscriptions: Boolean = false,
    val loading: Boolean = false,
    val filter: Filter = Filter.Latest,
    val availableFilters: List<Filter> = Filter.entries,
    val eventSink: (InboxUiEvent) -> Unit,
) : CircuitUiState
