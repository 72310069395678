package io.daio.pancake.components.toggle

import androidx.compose.material3.Switch
import androidx.compose.material3.SwitchColors
import androidx.compose.material3.SwitchDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Stable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import io.daio.pancake.foundations.Theme

@Composable
fun Toggle(
    checked: Boolean,
    onCheckedChange: ((Boolean) -> Unit),
    modifier: Modifier = Modifier,
    colors: ToggleColors = ToggleDefaults.colors(),
) {
    Switch(
        checked = checked,
        onCheckedChange = onCheckedChange,
        modifier = modifier,
        colors = colors.toMaterialSwitchColors(),
    )
}

object ToggleDefaults {
    @Composable
    fun colors(): ToggleColors =
        ToggleColors(
            checkedThumbColor = Theme.colors.surfaceInteractive,
            uncheckedThumbColor = Theme.colors.surfaceInteractive,
            checkedTrackColor = Theme.colors.accent,
            uncheckedTrackColor = Theme.colors.surfaceEmphasized,
        )
}

@Stable
data class ToggleColors(
    val checkedThumbColor: Color,
    val uncheckedThumbColor: Color,
    val checkedTrackColor: Color,
    val uncheckedTrackColor: Color,
)

@Composable
private fun ToggleColors.toMaterialSwitchColors(): SwitchColors =
    SwitchDefaults.colors(
        checkedThumbColor = this.checkedThumbColor,
        uncheckedThumbColor = this.uncheckedThumbColor,
        checkedTrackColor = this.checkedTrackColor,
        uncheckedTrackColor = this.uncheckedTrackColor,
    )
