package studio.goodegg.capsule.api.clients

import io.github.jan.supabase.auth.Auth
import io.github.jan.supabase.auth.OtpType
import io.github.jan.supabase.auth.auth
import io.github.jan.supabase.auth.providers.builtin.OTP
import io.github.jan.supabase.auth.status.SessionStatus
import io.github.jan.supabase.createSupabaseClient
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.User
import studio.goodegg.capsule.api.SUPABASE_BASE_URL

interface AuthClient {
    val isSignedIn: Boolean
    val accessToken: String?
    fun observeCurrentUser(): Flow<User?>
    suspend fun signInWithOTP(email: String)
    suspend fun verify(email: String, code: String)
    suspend fun signOut()
}

@Inject
internal class AuthClientImpl : AuthClient {
    private val supabase = createSupabaseClient(
        supabaseUrl = SUPABASE_BASE_URL,
        supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imt5Y3JwdnJvaXRndmR6bG96cmJqIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTI0NzkwNDMsImV4cCI6MjAwODA1NTA0M30.oeoISYw3G0wcmBqmaCjTQBOJQ3L3IBHadKKomchoJwA",
    ) {
        install(Auth)
    }

    override val isSignedIn: Boolean
        get() = supabase.auth.currentUserOrNull() != null

    override val accessToken: String?
        get() = supabase.auth.currentAccessTokenOrNull()

    override fun observeCurrentUser(): Flow<User?> {
        return supabase.auth.sessionStatus.map {
            when (it) {
                is SessionStatus.Authenticated -> it.session.user?.email?.let { email ->
                    User(email)
                }

                SessionStatus.Initializing -> null
                is SessionStatus.NotAuthenticated -> null
                is SessionStatus.RefreshFailure -> null
            }
        }
    }

    override suspend fun signInWithOTP(email: String) {
        supabase.auth.signInWith(OTP) {
            this.email = email
        }
    }

    override suspend fun verify(email: String, code: String) {
        supabase.auth.verifyEmailOtp(type = OtpType.Email.EMAIL, email = email, token = code)
    }

    override suspend fun signOut() {
        supabase.auth.signOut()
    }
}