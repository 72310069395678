package studio.goodegg.capsule.api.clients

import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.plugins.timeout
import io.ktor.client.request.get
import io.ktor.client.request.parameter
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.http.ContentType
import io.ktor.http.contentType
import kotlinx.serialization.Serializable
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.Episode
import studio.goodegg.capsule.Podcast
import studio.goodegg.capsule.api.FUNCTIONS_BASE_URL
import studio.goodegg.capsule.utils.Failed
import studio.goodegg.capsule.utils.Response
import studio.goodegg.capsule.utils.Success

interface PodcastClient {
    suspend fun getPodcast(
        slug: String,
        limit: Int? = null,
        before: Long? = null,
    ): Response<Podcast>

    suspend fun getEpisode(slug: String): Response<Episode>
    suspend fun getPodcastsBatch(podcastBatch: List<PodcastBatch>): Response<List<Podcast>>
}

@Serializable
data class PodcastBatch(
    val slug: String,
    val created: Long,
)

@Inject
internal class PodcastClientImpl(private val httpClient: HttpClient) : PodcastClient {

    override suspend fun getPodcast(slug: String, limit: Int?, before: Long?): Response<Podcast> {
        return try {
            val response = httpClient.get(urlString = "${FUNCTIONS_BASE_URL}podcast/$slug") {
                limit?.let { parameter("episodeLimit", it) }
                before?.let { parameter("episodesBefore", it) }
            }
            Success(response.body())
        } catch (error: Throwable) {
            error.printStackTrace()
            Failed(error)
        }
    }

    override suspend fun getEpisode(slug: String): Response<Episode> {
        return try {
            val response = httpClient.get(urlString = "${FUNCTIONS_BASE_URL}episode/$slug")
            Success(response.body())
        } catch (error: Throwable) {
            error.printStackTrace()
            Failed(error)
        }
    }

    override suspend fun getPodcastsBatch(
        podcastBatch: List<PodcastBatch>,
    ): Response<List<Podcast>> = try {

        val response = httpClient.post(urlString = "${FUNCTIONS_BASE_URL}podcast-batch") {
            timeout {
                // Batch requests should have longer time outs (2 mins)
                requestTimeoutMillis = 120000
                connectTimeoutMillis = 120000
                socketTimeoutMillis = 120000
            }
            contentType(ContentType.Application.Json)
            setBody(podcastBatch)
        }
        Success(response.body())
    } catch (error: Throwable) {
        error.printStackTrace()
        Failed(error)
    }
}
