package io.daio.bass

/**
 * A data class that represents a media item, such as a song, podcast, or video.
 *
 * @param url the URL of the media item
 * @param id the identifier of the media item
 * @param title the title of the media item
 * @param artist the artist of the media item
 * @param artworkUrl the URL of the artwork for the media item
 * @param length the length of the media item in milliseconds
 * @param extras additional metadata.
 * @param mimeType of the media item.
 */
data class MediaItem(
    val url: String,
    val id: String = "",
    val title: String? = null,
    val artist: String? = null,
    val artworkUrl: String? = null,
    val length: Long = 0L,
    val extras: Map<String, String?> = emptyMap(),
    val mimeType: String = "audio/mpeg",
)
