package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.Notif: ImageVector
    get() {
        if (_Notif != null) {
            return _Notif!!
        }
        _Notif = ImageVector.Builder(
            name = "Notif",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(fill = SolidColor(Color(0xFFE8EAED))) {
                moveTo(4f, 19f)
                verticalLineToRelative(-2f)
                horizontalLineToRelative(2f)
                verticalLineToRelative(-7f)
                curveToRelative(0f, -1.383f, 0.417f, -2.612f, 1.25f, -3.688f)
                curveToRelative(0.833f, -1.075f, 1.917f, -1.779f, 3.25f, -2.112f)
                verticalLineToRelative(-0.7f)
                curveToRelative(0f, -0.417f, 0.146f, -0.77f, 0.438f, -1.063f)
                arcTo(1.447f, 1.447f, 0f, isMoreThanHalf = false, isPositiveArc = true, 12f, 2f)
                curveToRelative(0.417f, 0f, 0.77f, 0.146f, 1.063f, 0.438f)
                curveToRelative(0.291f, 0.291f, 0.437f, 0.645f, 0.437f, 1.062f)
                verticalLineToRelative(0.7f)
                curveToRelative(1.333f, 0.333f, 2.417f, 1.037f, 3.25f, 2.112f)
                curveTo(17.583f, 7.388f, 18f, 8.617f, 18f, 10f)
                verticalLineToRelative(7f)
                horizontalLineToRelative(2f)
                verticalLineToRelative(2f)
                lineTo(4f, 19f)
                close()
                moveTo(12f, 22f)
                curveToRelative(-0.55f, 0f, -1.02f, -0.196f, -1.412f, -0.587f)
                arcTo(1.926f, 1.926f, 0f, isMoreThanHalf = false, isPositiveArc = true, 10f, 20f)
                horizontalLineToRelative(4f)
                curveToRelative(0f, 0.55f, -0.196f, 1.02f, -0.588f, 1.413f)
                arcTo(1.926f, 1.926f, 0f, isMoreThanHalf = false, isPositiveArc = true, 12f, 22f)
                close()
                moveTo(8f, 17f)
                horizontalLineToRelative(8f)
                verticalLineToRelative(-7f)
                curveToRelative(0f, -1.1f, -0.392f, -2.042f, -1.175f, -2.825f)
                curveTo(14.042f, 6.392f, 13.1f, 6f, 12f, 6f)
                reflectiveCurveToRelative(-2.042f, 0.392f, -2.825f, 1.175f)
                curveTo(8.392f, 7.958f, 8f, 8.9f, 8f, 10f)
                verticalLineToRelative(7f)
                close()
            }
        }.build()

        return _Notif!!
    }

@Suppress("ObjectPropertyName")
private var _Notif: ImageVector? = null
