package studio.goodegg.capsule.categories

import androidx.compose.runtime.Immutable
import com.slack.circuit.runtime.CircuitUiState
import studio.goodegg.capsule.Category

@Immutable
data class CategoriesUiState(
    val refreshing: Boolean,
    val items: List<Category>,
    val eventSink: (CategoriesUiEvent) -> Unit,
) : CircuitUiState
