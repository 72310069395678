package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.More: ImageVector
    get() {
        if (_More != null) {
            return _More!!
        }
        _More = ImageVector.Builder(
            name = "More",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF))) {
                moveTo(6f, 14f)
                curveToRelative(-0.55f, 0f, -1.02f, -0.196f, -1.412f, -0.588f)
                arcTo(1.926f, 1.926f, 0f, isMoreThanHalf = false, isPositiveArc = true, 4f, 12f)
                curveToRelative(0f, -0.55f, 0.196f, -1.02f, 0.588f, -1.412f)
                arcTo(1.926f, 1.926f, 0f, isMoreThanHalf = false, isPositiveArc = true, 6f, 10f)
                curveToRelative(0.55f, 0f, 1.02f, 0.196f, 1.412f, 0.588f)
                curveToRelative(0.392f, 0.391f, 0.588f, 0.862f, 0.588f, 1.412f)
                curveToRelative(0f, 0.55f, -0.196f, 1.02f, -0.588f, 1.412f)
                arcTo(1.926f, 1.926f, 0f, isMoreThanHalf = false, isPositiveArc = true, 6f, 14f)
                close()
                moveTo(12f, 14f)
                curveToRelative(-0.55f, 0f, -1.02f, -0.196f, -1.412f, -0.588f)
                arcTo(1.926f, 1.926f, 0f, isMoreThanHalf = false, isPositiveArc = true, 10f, 12f)
                curveToRelative(0f, -0.55f, 0.196f, -1.02f, 0.588f, -1.412f)
                arcTo(1.926f, 1.926f, 0f, isMoreThanHalf = false, isPositiveArc = true, 12f, 10f)
                curveToRelative(0.55f, 0f, 1.02f, 0.196f, 1.412f, 0.588f)
                curveToRelative(0.392f, 0.391f, 0.588f, 0.862f, 0.588f, 1.412f)
                curveToRelative(0f, 0.55f, -0.196f, 1.02f, -0.588f, 1.412f)
                arcTo(1.926f, 1.926f, 0f, isMoreThanHalf = false, isPositiveArc = true, 12f, 14f)
                close()
                moveTo(18f, 14f)
                curveToRelative(-0.55f, 0f, -1.02f, -0.196f, -1.413f, -0.588f)
                arcTo(1.926f, 1.926f, 0f, isMoreThanHalf = false, isPositiveArc = true, 16f, 12f)
                curveToRelative(0f, -0.55f, 0.196f, -1.02f, 0.587f, -1.412f)
                arcTo(1.926f, 1.926f, 0f, isMoreThanHalf = false, isPositiveArc = true, 18f, 10f)
                curveToRelative(0.55f, 0f, 1.02f, 0.196f, 1.413f, 0.588f)
                curveToRelative(0.391f, 0.391f, 0.587f, 0.862f, 0.587f, 1.412f)
                curveToRelative(0f, 0.55f, -0.196f, 1.02f, -0.587f, 1.412f)
                arcTo(1.926f, 1.926f, 0f, isMoreThanHalf = false, isPositiveArc = true, 18f, 14f)
                close()
            }
        }.build()

        return _More!!
    }

@Suppress("ObjectPropertyName")
private var _More: ImageVector? = null
