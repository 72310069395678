package studio.goodegg.capsule.onboarding

import com.slack.circuit.runtime.CircuitUiEvent
import studio.goodegg.capsule.PodcastResult

sealed class OnboardingUiEvent : CircuitUiEvent {
    data class Remove(val slug: String) : OnboardingUiEvent()
    data class Subscribe(val podcastResult: PodcastResult) : OnboardingUiEvent()
    data object EnableNotifications : OnboardingUiEvent()
    data object OpenDedicatedSignIn : OnboardingUiEvent()
    data class SignUp(val email: String) : OnboardingUiEvent()
    data class VerifySignUp(val code: String) : OnboardingUiEvent()
    data object Skip : OnboardingUiEvent()
}
