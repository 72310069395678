package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.ShareIos: ImageVector
    get() {
        if (_ShareIos != null) {
            return _ShareIos!!
        }
        _ShareIos = ImageVector.Builder(
            name = "ShareIos",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(fill = SolidColor(Color(0xFFE8EAED))) {
                moveTo(5.875f, 22f)
                curveToRelative(-0.516f, 0f, -0.957f, -0.178f, -1.324f, -0.534f)
                arcTo(1.725f, 1.725f, 0f, isMoreThanHalf = false, isPositiveArc = true, 4f, 20.182f)
                verticalLineToRelative(-10f)
                curveToRelative(0f, -0.5f, 0.184f, -0.928f, 0.55f, -1.284f)
                arcToRelative(1.834f, 1.834f, 0f, isMoreThanHalf = false, isPositiveArc = true, 1.325f, -0.534f)
                horizontalLineToRelative(2.813f)
                verticalLineToRelative(1.818f)
                lineTo(5.874f, 10.182f)
                verticalLineToRelative(10f)
                horizontalLineToRelative(11.25f)
                verticalLineToRelative(-10f)
                horizontalLineToRelative(-2.813f)
                lineTo(14.311f, 8.364f)
                horizontalLineToRelative(2.813f)
                curveToRelative(0.516f, 0f, 0.957f, 0.178f, 1.324f, 0.534f)
                curveToRelative(0.367f, 0.356f, 0.551f, 0.784f, 0.551f, 1.284f)
                verticalLineToRelative(10f)
                curveToRelative(0f, 0.5f, -0.184f, 0.928f, -0.55f, 1.284f)
                arcToRelative(1.834f, 1.834f, 0f, isMoreThanHalf = false, isPositiveArc = true, -1.325f, 0.534f)
                lineTo(5.875f, 22f)
                close()
                moveTo(10.563f, 15.636f)
                lineTo(10.563f, 5.477f)
                lineToRelative(-1.5f, 1.455f)
                lineTo(7.75f, 5.636f)
                lineTo(11.5f, 2f)
                lineToRelative(3.75f, 3.636f)
                lineToRelative(-1.313f, 1.296f)
                lineToRelative(-1.5f, -1.455f)
                verticalLineToRelative(10.16f)
                horizontalLineToRelative(-1.874f)
                close()
            }
        }.build()

        return _ShareIos!!
    }

@Suppress("ObjectPropertyName")
private var _ShareIos: ImageVector? = null
