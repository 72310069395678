package studio.goodegg.capsule.settings

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.Navigator
import com.slack.circuit.runtime.presenter.Presenter
import com.slack.circuit.runtime.screen.Screen
import kotlinx.coroutines.launch
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.domain.auth.ObserveSignedInInteractor
import studio.goodegg.capsule.domain.auth.SignOutInteractor
import studio.goodegg.capsule.domain.settings.PutBooleanPreferenceInteractor
import studio.goodegg.capsule.domain.settings.PutStringPreferenceInteractor
import studio.goodegg.capsule.navigation.SettingsScreen
import studio.goodegg.capsule.navigation.SignInScreen

@Inject
class SettingsPresenterFactory(
    private val putStringPreferenceInteractor: PutStringPreferenceInteractor,
    private val putBooleanPreferenceInteractor: PutBooleanPreferenceInteractor,
    private val signOutInteractor: SignOutInteractor,
    private val observeSignedInInteractor: ObserveSignedInInteractor,
) : Presenter.Factory {
    override fun create(
        screen: Screen,
        navigator: Navigator,
        context: CircuitContext,
    ): Presenter<*>? {
        return when (screen) {
            is SettingsScreen ->
                SettingsPresenter(
                    navigator,
                    putStringPreferenceInteractor,
                    putBooleanPreferenceInteractor,
                    signOutInteractor,
                    observeSignedInInteractor,
                )

            else -> null
        }
    }
}

class SettingsPresenter(
    private val navigator: Navigator,
    private val putStringPreferenceInteractor: PutStringPreferenceInteractor,
    private val putBooleanPreferenceInteractor: PutBooleanPreferenceInteractor,
    private val signOutInteractor: SignOutInteractor,
    private val observeSignedInInteractor: ObserveSignedInInteractor,
) : Presenter<SettingsUiState> {
    @Composable
    override fun present(): SettingsUiState {

        val scope = rememberCoroutineScope()

        val user by remember { observeSignedInInteractor.invoke()
        }.collectAsState(null)

        fun eventSink(event: SettingsUiEvent) {
            when (event) {
                is SettingsUiEvent.ToggleSetting -> Unit
                SettingsUiEvent.SignIn -> navigator.goTo(SignInScreen)
                SettingsUiEvent.SignOut -> scope.launch {
                    signOutInteractor.invoke()
                }

                SettingsUiEvent.Back -> navigator.pop()
            }
        }

        return SettingsUiState(
            signedIn = user != null,
            user = user,
            eventSink = ::eventSink,
        )
    }
}
