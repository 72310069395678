package studio.goodegg.capsule.work

import me.tatarka.inject.annotations.Provides
import studio.goodegg.capsule.common.di.ApplicationScope

actual interface WorkSchedulerComponent {

    @ApplicationScope
    @Provides
    fun providesScheduler(): WorkScheduler = object : WorkScheduler {
        override fun schedule(work: Work, hours: Int) {

        }

        override fun runNow(work: Work) {
        }

    }
}