package studio.goodegg.capsule.base.main

import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.rememberCoroutineScope
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.CircuitUiState
import com.slack.circuit.runtime.Navigator
import com.slack.circuit.runtime.presenter.Presenter
import com.slack.circuit.runtime.screen.Screen
import com.slack.circuit.runtime.ui.Ui
import com.slack.circuit.runtime.ui.ui
import io.daio.pancake.layout.Surface
import kotlinx.coroutines.launch
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.domain.settings.GetBooleanPreferenceInteractor
import studio.goodegg.capsule.navigation.DiscoverScreen
import studio.goodegg.capsule.navigation.InboxScreen
import studio.goodegg.capsule.navigation.OnboardingScreen
import studio.goodegg.capsule.navigation.SplashScreen

@Inject
class SplashPresenterFactory(
    private val getBooleanPreferenceInteractor: GetBooleanPreferenceInteractor,
) : Presenter.Factory {
    override fun create(
        screen: Screen,
        navigator: Navigator,
        context: CircuitContext,
    ): Presenter<*>? {
        return when (screen) {
            is SplashScreen ->
                SplashPresenter(
                    navigator,
                    getBooleanPreferenceInteractor,
                )

            else -> null
        }
    }
}

class SplashPresenter(
    private val navigator: Navigator,
    private val getBooleanPreferenceInteractor: GetBooleanPreferenceInteractor,

    ) : Presenter<SplashUiState> {
    @Composable
    override fun present(): SplashUiState {
        LaunchedEffect(Unit) {
            if (getBooleanPreferenceInteractor("onboarding-complete") == false)
                navigator.resetRoot(OnboardingScreen)
            else
                navigator.resetRoot(DiscoverScreen)
        }

        return SplashUiState
    }

}

data object SplashUiState : CircuitUiState

class SplashScreenUiFactory : Ui.Factory {
    override fun create(
        screen: Screen,
        context: CircuitContext,
    ): Ui<*>? {
        return when (screen) {
            is SplashScreen -> splash()
            else -> null
        }
    }
}

private fun splash() =
    ui<SplashUiState> { state, modifier ->
        Surface(modifier = modifier.fillMaxSize()) {

        }
    }
