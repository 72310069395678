package studio.goodegg.capsule

import me.tatarka.inject.annotations.Component
import me.tatarka.inject.annotations.Provides
import studio.goodegg.capsule.common.di.ApplicationScope
import kotlin.jvm.JvmInline

expect interface FileStorageLocationComponent

@JvmInline
value class FileStorageLocation(val url: String)

@Component
interface FileComponent : FileStorageLocationComponent
