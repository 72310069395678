package io.daio.bass

interface BassPlayer {
    val currentItem: MediaItem?
    val currentPosition: Long
    val playerType: PlayerType
    var trimSilence: Boolean
    var audioBoostGain: Int

    fun play(
        media: MediaItem,
        offset: Long = 0,
    )

    fun playlist(
        media: List<MediaItem>,
        startIndex: Int = 0,
        offset: Long = 0,
    )

    fun stop()

    fun pause()

    fun resume()

    fun seekByDelta(deltaMs: Long)

    fun setPlaybackSpeed(speed: Float)

    fun release()

    fun next()

    fun hasNext(): Boolean

    fun previous()

    fun hasPrevious(): Boolean

    fun interface Factory {
        /**
         * Create a new [BassPlayer]
         */
        fun create(context: BassContext): BassPlayer
    }

    class Builder(internal val context: BassContext) {
        private val playerOptions = BassPlayerOptions()

        internal fun withOption(
            key: String,
            value: Any,
        ): Builder =
            apply {
                playerOptions.put(key, value)
            }

        fun build(): BassPlayer {
            return createPlayer(context, playerOptions)
        }
    }
}

internal class DefaultPlayerFactory : BassPlayer.Factory {
    override fun create(context: BassContext): BassPlayer {
        return BassPlayer.Builder(context).build()
    }
}

val BassContext.bassPlayer: BassPlayer
    get() = SingletonBassPlayer.getPlayer(this)
