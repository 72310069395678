package studio.goodegg.capsule.domain.playback

import studio.goodegg.capsule.Episode

enum class PlayQueuePosition {
    Front, End
}

fun interface AddToPlayQueueInteractor {
    operator fun invoke(
        episode: Episode,
        playQueuePosition: PlayQueuePosition,
    )
}
