package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.ScrubForward: ImageVector
    get() {
        if (_ScrubForward != null) {
            return _ScrubForward!!
        }
        _ScrubForward = ImageVector.Builder(
            name = "ScrubForward",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(fill = SolidColor(Color(0xFFE8EAED))) {
                moveTo(9f, 16f)
                verticalLineToRelative(-4.5f)
                lineTo(7.5f, 11.5f)
                lineTo(7.5f, 10f)
                horizontalLineToRelative(3f)
                verticalLineToRelative(6f)
                lineTo(9f, 16f)
                close()
                moveTo(12.5f, 16f)
                arcToRelative(0.968f, 0.968f, 0f, isMoreThanHalf = false, isPositiveArc = true, -0.713f, -0.287f)
                arcTo(0.968f, 0.968f, 0f, isMoreThanHalf = false, isPositiveArc = true, 11.5f, 15f)
                verticalLineToRelative(-4f)
                curveToRelative(0f, -0.283f, 0.096f, -0.52f, 0.287f, -0.713f)
                arcTo(0.968f, 0.968f, 0f, isMoreThanHalf = false, isPositiveArc = true, 12.5f, 10f)
                horizontalLineToRelative(2f)
                curveToRelative(0.283f, 0f, 0.52f, 0.096f, 0.713f, 0.287f)
                curveToRelative(0.191f, 0.192f, 0.287f, 0.43f, 0.287f, 0.713f)
                verticalLineToRelative(4f)
                curveToRelative(0f, 0.283f, -0.096f, 0.52f, -0.287f, 0.713f)
                arcTo(0.968f, 0.968f, 0f, isMoreThanHalf = false, isPositiveArc = true, 14.5f, 16f)
                horizontalLineToRelative(-2f)
                close()
                moveTo(13f, 14.5f)
                horizontalLineToRelative(1f)
                verticalLineToRelative(-3f)
                horizontalLineToRelative(-1f)
                verticalLineToRelative(3f)
                close()
                moveTo(12f, 22f)
                curveToRelative(-1.25f, 0f, -2.42f, -0.238f, -3.512f, -0.712f)
                arcToRelative(9.149f, 9.149f, 0f, isMoreThanHalf = false, isPositiveArc = true, -2.85f, -1.925f)
                arcToRelative(9.147f, 9.147f, 0f, isMoreThanHalf = false, isPositiveArc = true, -1.925f, -2.85f)
                arcTo(8.707f, 8.707f, 0f, isMoreThanHalf = false, isPositiveArc = true, 3f, 13f)
                curveToRelative(0f, -1.25f, 0.237f, -2.42f, 0.712f, -3.512f)
                arcToRelative(9.148f, 9.148f, 0f, isMoreThanHalf = false, isPositiveArc = true, 1.925f, -2.85f)
                arcToRelative(9.148f, 9.148f, 0f, isMoreThanHalf = false, isPositiveArc = true, 2.85f, -1.925f)
                arcTo(8.707f, 8.707f, 0f, isMoreThanHalf = false, isPositiveArc = true, 12f, 4f)
                horizontalLineToRelative(0.15f)
                lineTo(10.6f, 2.45f)
                lineTo(12f, 1f)
                lineToRelative(4f, 4f)
                lineToRelative(-4f, 4f)
                lineToRelative(-1.4f, -1.45f)
                lineTo(12.15f, 6f)
                lineTo(12f, 6f)
                curveToRelative(-1.95f, 0f, -3.604f, 0.68f, -4.963f, 2.037f)
                curveTo(5.68f, 9.396f, 5f, 11.05f, 5f, 13f)
                curveToRelative(0f, 1.95f, 0.68f, 3.604f, 2.037f, 4.962f)
                curveTo(8.396f, 19.322f, 10.05f, 20f, 12f, 20f)
                curveToRelative(1.95f, 0f, 3.604f, -0.68f, 4.962f, -2.038f)
                curveTo(18.322f, 16.604f, 19f, 14.95f, 19f, 13f)
                horizontalLineToRelative(2f)
                curveToRelative(0f, 1.25f, -0.238f, 2.42f, -0.712f, 3.512f)
                arcToRelative(9.148f, 9.148f, 0f, isMoreThanHalf = false, isPositiveArc = true, -1.925f, 2.85f)
                arcToRelative(9.148f, 9.148f, 0f, isMoreThanHalf = false, isPositiveArc = true, -2.85f, 1.926f)
                arcTo(8.707f, 8.707f, 0f, isMoreThanHalf = false, isPositiveArc = true, 12f, 22f)
                close()
            }
        }.build()

        return _ScrubForward!!
    }

@Suppress("ObjectPropertyName")
private var _ScrubForward: ImageVector? = null
