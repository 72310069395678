package studio.goodegg.capsule.details.episode

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import com.slack.circuit.retained.collectAsRetainedState
import com.slack.circuit.retained.rememberRetained
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.Navigator
import com.slack.circuit.runtime.presenter.Presenter
import com.slack.circuit.runtime.screen.Screen
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.PlayState
import studio.goodegg.capsule.domain.download.DeleteDownloadInteractor
import studio.goodegg.capsule.domain.download.DownloadInteractor
import studio.goodegg.capsule.domain.playback.AddToPlayQueueInteractor
import studio.goodegg.capsule.domain.playback.MarkAsPlayedInteractor
import studio.goodegg.capsule.domain.playback.ObservePlayQueueInteractor
import studio.goodegg.capsule.domain.playback.ObservePlayedItem
import studio.goodegg.capsule.domain.playback.ObservePodcastNowPlayingInteractor
import studio.goodegg.capsule.domain.playback.PauseInteractor
import studio.goodegg.capsule.domain.playback.PlayInteractor
import studio.goodegg.capsule.domain.playback.PlayQueuePosition
import studio.goodegg.capsule.domain.playback.RemoveFromPlayQueueInteractor
import studio.goodegg.capsule.domain.playback.ResumePlaybackInteractor
import studio.goodegg.capsule.domain.playback.UnMarkAsPlayedInteractor
import studio.goodegg.capsule.domain.podcast.ObserveEpisodeInteractor
import studio.goodegg.capsule.domain.share.ShareEpisodeInteractor
import studio.goodegg.capsule.navigation.EpisodeDetailsScreen
import studio.goodegg.capsule.navigation.PodcastDetailsScreen

@Inject
class EpisodeDetailsPresenterFactory(
    private val observeEpisodeInteractor: ObserveEpisodeInteractor,
    private val observeNowPlaying: ObservePodcastNowPlayingInteractor,
    private val pauseInteractor: PauseInteractor,
    private val resumePlaybackInteractor: ResumePlaybackInteractor,
    private val playInteractor: PlayInteractor,
    private val downloadsInteractor: DownloadInteractor,
    private val deleteDownloadInteractor: DeleteDownloadInteractor,
    private val observePlayQueueInteractor: ObservePlayQueueInteractor,
    private val addToPlayQueueInteractor: AddToPlayQueueInteractor,
    private val removeFromPlayQueueInteractor: RemoveFromPlayQueueInteractor,
    private val shareEpisodeInteractor: ShareEpisodeInteractor,
    private val markPlayedInteractor: MarkAsPlayedInteractor,
    private val unmarkPlayedInteractor: UnMarkAsPlayedInteractor,
    private val observePlayedItem: ObservePlayedItem,
) : Presenter.Factory {
    override fun create(
        screen: Screen,
        navigator: Navigator,
        context: CircuitContext,
    ): Presenter<*>? {
        return when (screen) {
            is EpisodeDetailsScreen -> EpisodeDetailsPresenter(
                slug = screen.slug,
                navigator = navigator,
                observeEpisodeInteractor = observeEpisodeInteractor,
                observeNowPlaying = observeNowPlaying,
                pauseInteractor = pauseInteractor,
                resumePlaybackInteractor = resumePlaybackInteractor,
                playInteractor = playInteractor,
                downloadsInteractor = downloadsInteractor,
                deleteDownloadInteractor = deleteDownloadInteractor,
                observePlayQueueInteractor = observePlayQueueInteractor,
                addToPlayQueueInteractor = addToPlayQueueInteractor,
                removeFromPlayQueueInteractor = removeFromPlayQueueInteractor,
                shareEpisodeInteractor = shareEpisodeInteractor,
                markPlayedInteractor = markPlayedInteractor,
                unmarkPlayedInteractor = unmarkPlayedInteractor,
                observePlayedItem = observePlayedItem,
            )

            else -> null
        }
    }
}

// TODO this is all pretty in-efficient at the moment because the observe episode interactor
// is also listening to now playing etc. Tidy up.
class EpisodeDetailsPresenter(
    private val slug: String,
    private val navigator: Navigator,
    private val observeEpisodeInteractor: ObserveEpisodeInteractor,
    private val observeNowPlaying: ObservePodcastNowPlayingInteractor,
    private val pauseInteractor: PauseInteractor,
    private val resumePlaybackInteractor: ResumePlaybackInteractor,
    private val playInteractor: PlayInteractor,
    private val downloadsInteractor: DownloadInteractor,
    private val deleteDownloadInteractor: DeleteDownloadInteractor,
    private val observePlayQueueInteractor: ObservePlayQueueInteractor,
    private val addToPlayQueueInteractor: AddToPlayQueueInteractor,
    private val removeFromPlayQueueInteractor: RemoveFromPlayQueueInteractor,
    private val shareEpisodeInteractor: ShareEpisodeInteractor,
    private val markPlayedInteractor: MarkAsPlayedInteractor,
    private val unmarkPlayedInteractor: UnMarkAsPlayedInteractor,
    private val observePlayedItem: ObservePlayedItem,
) : Presenter<EpisodeDetailsUiState> {
    @Composable
    override fun present(): EpisodeDetailsUiState {
        val episode by remember { observeEpisodeInteractor(slug) }
            .collectAsState(null)

        val nowPlaying by remember { observeNowPlaying() }.collectAsState(null)

        val playQueue by rememberRetained {
            observePlayQueueInteractor.invoke().map { it.map { it.episodeSlug } }
        }.collectAsRetainedState(emptyList())

        val played by rememberRetained {
            observePlayedItem.invoke(slug).map { (it?.playedCount ?: 0) > 0 }
        }.collectAsRetainedState(false)

        val scope = rememberCoroutineScope()

        fun eventSink(event: EpisodeDetailsUiEvent) {
            when (event) {
                is EpisodeDetailsUiEvent.DownloadEpisode -> {
                    val download = event.data.download
                    if (download != null) {
                        scope.launch {
                            deleteDownloadInteractor(download.slug)
                        }
                    } else {
                        downloadsInteractor(
                            event.data.episode.episodeSlug,
                            event.data.episode.media,
                            event.data.episode.title,
                        )
                    }
                }

                is EpisodeDetailsUiEvent.PlayEpisode -> {
                    if (nowPlaying?.episode?.episodeSlug == event.data.episode.episodeSlug) {
                        when (nowPlaying?.playingState?.playState) {
                            PlayState.Buffering, PlayState.Playing -> pauseInteractor()
                            PlayState.Paused -> resumePlaybackInteractor()
                            else -> Unit
                        }
                    } else {
                        scope.launch {
                            playInteractor(event.data.episode, 0)
                        }
                    }
                }

                is EpisodeDetailsUiEvent.ShareEpisode -> {
                    shareEpisodeInteractor.invoke(event.episode)
                }

                is EpisodeDetailsUiEvent.OpenPodcast -> {
                    navigator.goTo(PodcastDetailsScreen(event.podcastSlug))
                }

                is EpisodeDetailsUiEvent.AddToPlayQueue -> {
                    addToPlayQueueInteractor.invoke(event.data.episode, PlayQueuePosition.End)
                }

                is EpisodeDetailsUiEvent.RemoveFromPlayQueue -> {
                    removeFromPlayQueueInteractor.invoke(event.data.episode)
                }

                is EpisodeDetailsUiEvent.MarkAsPlayed -> {
                    scope.launch {
                        markPlayedInteractor.invoke(event.data.episode)
                    }
                }

                is EpisodeDetailsUiEvent.UnMarkAsPlayed -> {
                    scope.launch {
                        unmarkPlayedInteractor.invoke(event.data.episode)
                    }
                }
            }
        }

        return EpisodeDetailsUiState(
            episode = episode,
            played = played,
            playQueue = playQueue,
            eventSink = ::eventSink,
        )
    }
}
