package studio.goodegg.capsule.details.episode

import androidx.compose.runtime.Immutable
import com.slack.circuit.runtime.CircuitUiState
import studio.goodegg.capsule.EpisodeAndMetadata

@Immutable
data class EpisodeDetailsUiState(
    val episode: EpisodeAndMetadata?,
    val played: Boolean,
    val playQueue: List<String>,
    val eventSink: (EpisodeDetailsUiEvent) -> Unit,
) : CircuitUiState
