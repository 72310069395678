package studio.goodegg.capsule.db

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import kotlin.Any
import kotlin.Long
import kotlin.String

public class DownloadQueries(
  driver: SqlDriver,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> selectAll(mapper: (
    slug: String,
    mediaurl: String,
    filepath: String,
    percentage: Long,
  ) -> T): Query<T> = Query(-1_453_153_454, arrayOf("download"), driver, "download.sq", "selectAll",
      "SELECT download.slug, download.mediaurl, download.filepath, download.percentage FROM download WHERE percentage = 100") {
      cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getLong(3)!!
    )
  }

  public fun selectAll(): Query<Download> = selectAll { slug, mediaurl, filepath, percentage ->
    Download(
      slug,
      mediaurl,
      filepath,
      percentage
    )
  }

  public fun <T : Any> selectAllInProgress(mapper: (
    slug: String,
    mediaurl: String,
    filepath: String,
    percentage: Long,
  ) -> T): Query<T> = Query(1_283_211_108, arrayOf("download"), driver, "download.sq",
      "selectAllInProgress",
      "SELECT download.slug, download.mediaurl, download.filepath, download.percentage FROM download WHERE percentage < 100") {
      cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getLong(3)!!
    )
  }

  public fun selectAllInProgress(): Query<Download> = selectAllInProgress { slug, mediaurl,
      filepath, percentage ->
    Download(
      slug,
      mediaurl,
      filepath,
      percentage
    )
  }

  public fun <T : Any> selectAllIncludingInProgress(mapper: (
    slug: String,
    mediaurl: String,
    filepath: String,
    percentage: Long,
  ) -> T): Query<T> = Query(-121_915_643, arrayOf("download"), driver, "download.sq",
      "selectAllIncludingInProgress",
      "SELECT download.slug, download.mediaurl, download.filepath, download.percentage FROM download") {
      cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getLong(3)!!
    )
  }

  public fun selectAllIncludingInProgress(): Query<Download> = selectAllIncludingInProgress { slug,
      mediaurl, filepath, percentage ->
    Download(
      slug,
      mediaurl,
      filepath,
      percentage
    )
  }

  public fun <T : Any> selectDownload(slug: String, mapper: (
    slug: String,
    mediaurl: String,
    filepath: String,
    percentage: Long,
  ) -> T): Query<T> = SelectDownloadQuery(slug) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getLong(3)!!
    )
  }

  public fun selectDownload(slug: String): Query<Download> = selectDownload(slug) { slug_, mediaurl,
      filepath, percentage ->
    Download(
      slug_,
      mediaurl,
      filepath,
      percentage
    )
  }

  public suspend fun insert(
    slug: String,
    mediaurl: String,
    filepath: String,
    percentage: Long,
  ) {
    driver.execute(1_933_747_596, """
        |INSERT INTO download (slug, mediaurl, filepath, percentage)
        |VALUES (?, ?, ?, ?)
        |ON CONFLICT(slug) DO UPDATE SET
        |  mediaurl = excluded.mediaurl,
        |  filepath = excluded.filepath,
        |  percentage = excluded.percentage
        """.trimMargin(), 4) {
          bindString(0, slug)
          bindString(1, mediaurl)
          bindString(2, filepath)
          bindLong(3, percentage)
        }.await()
    notifyQueries(1_933_747_596) { emit ->
      emit("download")
    }
  }

  public suspend fun delete(slug: String) {
    driver.execute(1_782_081_662, """DELETE FROM download WHERE slug = ?""", 1) {
          bindString(0, slug)
        }.await()
    notifyQueries(1_782_081_662) { emit ->
      emit("download")
    }
  }

  private inner class SelectDownloadQuery<out T : Any>(
    public val slug: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("download", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("download", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(-2_062_219_913,
        """SELECT download.slug, download.mediaurl, download.filepath, download.percentage FROM download WHERE slug = ?""",
        mapper, 1) {
      bindString(0, slug)
    }

    override fun toString(): String = "download.sq:selectDownload"
  }
}
