package io.daio.pancake.bottomsheet

import androidx.compose.animation.core.AnimationSpec
import androidx.compose.foundation.layout.LayoutScopeMarker
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.unit.Density
import io.daio.pancake.foundations.annotations.InternalPancakeApi
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Stack
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.launch
import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock

@Composable
fun rememberBottomSheetState(
    initialValue: ModalBottomSheetValue = ModalBottomSheetValue.Hidden,
    animationSpec: AnimationSpec<Float> = ModalBottomSheetDefaults.AnimationSpec,
    isSkipHalfExpanded: Boolean = false,
): BottomSheetState {
    val density = LocalDensity.current
    val scope = rememberCoroutineScope()
    return remember(density) {
        createBottomSheetState(density, initialValue, animationSpec, isSkipHalfExpanded, scope)
    }
}

fun createBottomSheetState(
    density: Density,
    initialValue: ModalBottomSheetValue = ModalBottomSheetValue.Hidden,
    animationSpec: AnimationSpec<Float> = ModalBottomSheetDefaults.AnimationSpec,
    isSkipHalfExpanded: Boolean = false,
    scope: CoroutineScope = CoroutineScope(Dispatchers.Main + SupervisorJob()),
): BottomSheetState {
    return BottomSheetState(
        ModalBottomSheetState(
            initialValue,
            isSkipHalfExpanded = isSkipHalfExpanded,
            density = density,
            animationSpec = animationSpec,
        ),
        scope = scope,
    )
}

@LayoutScopeMarker
@Immutable
interface BottomSheetScope {
    fun dismiss()

    fun setContent(
        title: String? = null,
        content: @Composable BottomSheetScope.() -> Unit,
    )
}

@OptIn(InternalPancakeApi::class)
class BottomSheetState internal constructor(
    @InternalPancakeApi
    val sheetState: ModalBottomSheetState,
    private val scope: CoroutineScope,
) {
    private val mutex = Mutex()

    @InternalPancakeApi
    var currentContent by mutableStateOf<(@Composable () -> Unit)?>(null)
        private set

    private val bottomSheetScope =
        object : BottomSheetScope {
            override fun dismiss() {
                this@BottomSheetState.dismiss()
            }

            override fun setContent(
                title: String?,
                content: @Composable BottomSheetScope.() -> Unit,
            ) {
                this@BottomSheetState.setContent(content)
            }
        }

    fun show(content: @Composable BottomSheetScope.() -> Unit) {
        setContent(content)
        open()
    }

    private fun open() {
        scope.launch {
            mutex.withLock {
                if (currentContent != null) {
                    sheetState.show()
                }
            }
        }
    }

    fun setContent(content: @Composable BottomSheetScope.() -> Unit) {
        currentContent = {
            LaunchedEffect(sheetState.isVisible) {
                if (sheetState.currentValue == ModalBottomSheetValue.Hidden) {
                    dismiss()
                }
            }

            Stack(spaceBetween = LayoutGap.None) {
                content.invoke(bottomSheetScope)
            }
        }
    }

    fun dismiss() {
        scope.launch {
            mutex.withLock {
                sheetState.hide()
            }
            currentContent = null
        }
    }
}
