package studio.goodegg.capsule.repositories

import app.cash.sqldelight.async.coroutines.awaitAsList
import app.cash.sqldelight.async.coroutines.awaitAsOneOrNull
import app.cash.sqldelight.coroutines.asFlow
import app.cash.sqldelight.coroutines.mapToList
import app.cash.sqldelight.coroutines.mapToOneOrNull
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.distinctUntilChanged
import kotlinx.coroutines.flow.flowOn
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.withContext
import kotlinx.datetime.Clock
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.Episode
import studio.goodegg.capsule.PlayedItem
import studio.goodegg.capsule.common.coroutines.IoDispatcher
import studio.goodegg.capsule.db.CapsuleDbCreator
import studio.goodegg.capsule.db.Played
import studio.goodegg.capsule.db.asFlow

fun Played.toPlayed(): PlayedItem = PlayedItem(
    slug = slug,
    duration = duration,
    currentPosition = current_position,
    lastPlayed = last_played,
    playedCount = played_count.toInt(),
)

fun PlayedItem.percentage(): Float {
    return currentPosition
        .times(100)
        .div(duration)
        .toFloat()
        .div(100)
        .coerceIn(minimumValue = 0f, maximumValue = 1f)
}

interface PlayedItemsRepository {
    suspend fun getMostRecentlyPlayed(): PlayedItem?
    suspend fun getBySlug(slug: String): PlayedItem?
    fun observeBySlug(slug: String): Flow<PlayedItem?>
    fun observeInProgress(): Flow<List<PlayedItem>>
    suspend fun markAsPlayed(episode: Episode)
    suspend fun unmarkAsPlayed(episode: Episode)
    suspend fun updatePlayedItem(
        slug: String,
        duration: Long,
        currentPosition: Long,
        playCount: Long? = null,
    )

    suspend fun getCompletePlayed(): List<PlayedItem>
    fun observeCompletePlayed(): Flow<List<PlayedItem>>
}

@Inject
internal class PlayedRepositoryImpl(
    private val capsuleDbCreator: CapsuleDbCreator,
    @IoDispatcher private val ioDispatcher: CoroutineDispatcher,
) :
    PlayedItemsRepository {

    override suspend fun getMostRecentlyPlayed(): PlayedItem? = withContext(ioDispatcher) {
        capsuleDbCreator.get().playedQueries.selectCurrentInProgress().awaitAsOneOrNull()
            ?.toPlayed()
    }

    override suspend fun getBySlug(slug: String): PlayedItem? = withContext(ioDispatcher) {
        capsuleDbCreator.get().playedQueries.selectBySlug(slug).awaitAsOneOrNull()
            ?.toPlayed()
    }

    override fun observeBySlug(slug: String): Flow<PlayedItem?> {
        return capsuleDbCreator.asFlow {
            playedQueries.selectBySlug(slug)
                .asFlow()
                .mapToOneOrNull(ioDispatcher)
                .map {
                    it?.toPlayed()
                }
                .distinctUntilChanged()
                .flowOn(ioDispatcher)
        }
    }

    override fun observeInProgress(): Flow<List<PlayedItem>> {
        return capsuleDbCreator.asFlow {
            playedQueries.selectAllInProgress()
                .asFlow()
                .mapToList(ioDispatcher)
                .map { results ->
                    results.map { it.toPlayed() }
                }
                .distinctUntilChanged()
                .flowOn(ioDispatcher)
        }

    }

    override suspend fun markAsPlayed(episode: Episode) = withContext(ioDispatcher) {
        capsuleDbCreator.get().playedQueries.insert(
            episode.episodeSlug,
            episode.duration,
            episode.duration,
            Clock.System.now().toEpochMilliseconds(),
            1,
        )
    }

    override suspend fun unmarkAsPlayed(episode: Episode) = withContext(ioDispatcher) {
        capsuleDbCreator.get().playedQueries.insert(
            episode.episodeSlug,
            episode.duration,
            current_position = 0,
            Clock.System.now().toEpochMilliseconds(),
            0,
        )
    }

    override suspend fun updatePlayedItem(
        slug: String,
        duration: Long,
        currentPosition: Long,
        playCount: Long?,
    ) = withContext(ioDispatcher) {
        val count = playCount ?: getBySlug(slug)?.playedCount?.toLong() ?: 0L

        capsuleDbCreator.get().playedQueries.insert(
            slug,
            duration,
            currentPosition,
            Clock.System.now().toEpochMilliseconds(),
            count,
        )
    }

    override suspend fun getCompletePlayed(): List<PlayedItem> = withContext(ioDispatcher) {
        capsuleDbCreator.get()
            .playedQueries
            .selectAllPlayed()
            .awaitAsList()
            .map { it.toPlayed() }
    }

    override fun observeCompletePlayed(): Flow<List<PlayedItem>> {
        return capsuleDbCreator.asFlow {
            playedQueries
                .selectAllPlayed()
                .asFlow()
                .mapToList(ioDispatcher)
                .map { played -> played.map { it.toPlayed() } }
        }
    }
}