package studio.goodegg.capsule.ui.components

import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.RowScope
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.sizeIn
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.scale
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import io.daio.pancake.components.progress.CircularProgress
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.foundations.text.BodyTextSmall
import io.daio.pancake.foundations.text.LinkText
import io.daio.pancake.foundations.text.TitleText
import io.daio.pancake.icons.ArrowBack
import io.daio.pancake.icons.Check
import io.daio.pancake.icons.Delete
import io.daio.pancake.icons.Download
import io.daio.pancake.icons.Downloaded
import io.daio.pancake.icons.Icons
import io.daio.pancake.icons.More
import io.daio.pancake.icons.Pause
import io.daio.pancake.icons.PlayFilled
import io.daio.pancake.icons.Played
import io.daio.pancake.icons.Replay
import io.daio.pancake.icons.Share
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.Surface
import io.daio.pancake.layout.Tier
import io.daio.wild.container.Container
import io.daio.wild.style.StyleDefaults
import studio.goodegg.capsule.Episode
import studio.goodegg.capsule.PlayState
import studio.goodegg.capsule.PlaybackState
import studio.goodegg.capsule.formatCreatedDate
import studio.goodegg.capsule.formatDuration
import studio.goodegg.capsule.formatTimeRemaining

@Composable
fun EpisodeItem(
    episode: Episode,
    played: Boolean = false,
    actions: @Composable RowScope.() -> Unit,
    modifier: Modifier = Modifier,
) {
    Stack(
        modifier =
            modifier
                .wrapContentHeight()
                .fillMaxWidth(),
        spaceBetween = LayoutGap.Small,
    ) {
        Tier(verticalAlignment = Alignment.Top) {
            ArtworkTile(
                size = 68.dp,
                imageUrl = episode.image,
                modifier =
                    Modifier.graphicsLayer {
                        alpha = if (played) .4f else 1f
                    },
            )
            Stack(
                spaceBetween = LayoutGap.Tiny,
                modifier =
                    Modifier.graphicsLayer {
                        alpha = if (played) .4f else 1f
                    },
            ) {
                BodyTextSmall(episode.title, maxLines = 3, overflow = TextOverflow.Ellipsis)
                LinkText(
                    text = episode.formatCreatedDate(),
                    textColor = Theme.colors.onSurfaceBackground.copy(alpha = .8f),
                )
            }
        }
        BodyTextSmall(
            episode.description.orEmpty(),
            maxLines = 3,
            overflow = TextOverflow.Ellipsis,
            modifier =
                Modifier.graphicsLayer {
                    alpha = if (played) .4f else 1f
                },
        )

        Tier(spaceBetween = LayoutGap.Medium) {
            actions()
        }

    }
}

@Composable
fun EpisodeItem(
    episode: Episode,
    nowPlaying: PlaybackState?,
    onPlay: () -> Unit,
    onDownload: () -> Unit,
    onShare: () -> Unit,
    modifier: Modifier = Modifier,
    downloadPercentage: Long = 0,
    played: Boolean = false,
) {
    EpisodeItem(
        episode = episode, played = played, modifier = modifier,
        actions = {
            PlayButton(nowPlaying, episode, onPlay)
            IconButton(
                icon = Icons.Share,
                onClick = onShare,
            )
            when (downloadPercentage) {
                0L -> {
                    IconButton(
                        icon = Icons.Download,
                        onClick = onDownload,
                    )
                }

                100L -> {
                    IconButton(
                        icon = Icons.Delete,
                        onClick = onDownload,
                    )
                }

                in 1L..99L -> {
                    IconButton(
                        icon = {
                            CircularProgress(
                                modifier = Modifier.scale(.5f),
                                progress = { downloadPercentage / 100f },
                            )
                        },
                        onClick = onDownload,
                    )
                }
            }
            if (played) {
                Icon(
                    modifier = Modifier.size(20.dp),
                    imageVector = Icons.Check,
                    contentDescription = "played indicator",
                    tint = Theme.colors.positive,
                )
            }
        },
    )
}

@Composable
fun PlayButton(
    nowPlaying: PlaybackState?,
    episode: Episode,
    onPlay: () -> Unit,
    modifier: Modifier = Modifier,
    playingIcon: ImageVector = Icons.Pause,
    playIcon: ImageVector = Icons.PlayFilled,
    contentPadding: PaddingValues = PaddingValues(6.dp),
) {
    val isEpisode = episode.episodeSlug == nowPlaying?.slug
    val isPaused = isEpisode && nowPlaying?.playState == PlayState.Paused
    val isPlaying = isEpisode &&
            (nowPlaying?.playState == PlayState.Playing || nowPlaying?.playState == PlayState.Buffering)

    IconButton(
        contentPadding = contentPadding,
        modifier = modifier,
        icon = if (isPaused || !isPlaying) playIcon else playingIcon,
        color = Theme.colors.accent,
        contentColor = Theme.colors.onAccent,
        onClick = onPlay,
    )
}

@Composable
fun EpisodeCard(
    episode: Episode,
    nowPlaying: PlaybackState?,
    onPlay: () -> Unit,
    onDownload: () -> Unit,
    onShare: () -> Unit,
    modifier: Modifier = Modifier,
    backgroundColor: Color = Theme.colors.surfaceContainer,
    onMoreOptions: (() -> Unit)? = null,
    onOpen: () -> Unit = { },
    downloadPercentage: Long = 0,
    playedProgress: Long = 0,
    played: Boolean = false,
) {
    Surface(
        color = backgroundColor,
        modifier = modifier.sizeIn(maxHeight = 348.dp),
        shape = Theme.shapes.large,
    ) {
        Stack(
            spaceBetween = LayoutGap.Small,
            modifier = Modifier.padding(Theme.dimens.size_m),
        ) {
            Tier(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceBetween,
            ) {
                Badge(episode.formatCreatedDate())

                onMoreOptions?.let {
                    IconButton(
                        icon = Icons.More,
                        onClick = onMoreOptions,
                    )
                }
            }

            Container(
                onClick = onOpen,
                style = StyleDefaults.style(
                    colors = StyleDefaults.colors(backgroundColor = Color.Transparent),
                    scale = StyleDefaults.scale(pressedScale = .9f),
                ),
            ) {
                Tier(verticalAlignment = Alignment.Top) {
                    ArtworkTile(
                        size = 128.dp,
                        imageUrl = episode.image,
                    )
                    Stack(
                        spaceBetween = LayoutGap.None,
                    ) {
                        TitleText(
                            episode.title, maxLines = 4, overflow = TextOverflow.Ellipsis,
                        )
                        Spacer(modifier = Modifier.height(Theme.dimens.size_xxs))
                        BodyTextSmall(
                            text = episode.formatDuration(),
                        )
                    }
                }
            }

            BodyText(
                episode.description.orEmpty(),
                maxLines = 4,
                overflow = TextOverflow.Ellipsis,
                modifier =
                    Modifier.graphicsLayer {
                        alpha = .6f
                    },
            )

            HorizontalDivider(
                color = Theme.colors.onSurfaceBackground,
                modifier = Modifier.graphicsLayer {
                    alpha = .2f
                },
            )

            Tier(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceBetween,
            ) {
                Tier {
                    IconButton(
                        icon = Icons.Share,
                        onClick = onShare,
                    )
                    when (downloadPercentage) {
                        0L -> {
                            IconButton(
                                icon = Icons.Download,
                                onClick = onDownload,
                            )
                        }

                        100L -> {
                            IconButton(
                                icon = Icons.Delete,
                                onClick = onDownload,
                            )
                        }

                        in 1L..99L -> {
                            val progress by animateFloatAsState(downloadPercentage / 100f)
                            IconButton(
                                icon = {
                                    CircularProgress(
                                        modifier = Modifier.scale(.5f),
                                        progress = { progress },
                                    )
                                },
                                onClick = onDownload,
                            )
                        }
                    }
                }
                Tier {
                    if (playedProgress > 0L && !played) {
                        BodyText(
                            text = "${episode.formatTimeRemaining(playedProgress)} left",
                            textColor = Theme.colors.accent,
                        )
                    }
                    PlayButton(
                        nowPlaying,
                        episode,
                        onPlay,
                        playIcon = if (played) Icons.Replay else Icons.PlayFilled,
                    )
                }
            }
        }
    }
}

@Composable
fun Badge(
    text: String,
    color: Color = Theme.colors.accent,
) {
    Surface(
        shape = Theme.shapes.medium,
        color = color,
    ) {
        BodyTextSmall(
            text,
            modifier = Modifier
                .padding(
                    vertical = Theme.dimens.size_xxs,
                    horizontal = Theme.dimens.size_s,
                ),
        )
    }
}

@Composable
fun EpisodeCompactCard(
    episode: Episode,
    nowPlaying: PlaybackState?,
    onPlay: () -> Unit,
    onDownload: () -> Unit,
    onShare: () -> Unit,
    modifier: Modifier = Modifier,
    backgroundColor: Color = Theme.colors.surfaceContainer,
    onMoreOptions: (() -> Unit)? = null,
    onOpen: () -> Unit = { },
    downloadPercentage: Long = 0,
    playedProgress: Long = 0,
    played: Boolean = false,
    showImage: Boolean = true,
    descriptionLines: Int = 1,
    showRelease: Boolean = true,
) {
    Surface(
        color = backgroundColor,
        modifier = modifier.sizeIn(maxHeight = 348.dp)
            .graphicsLayer {
                alpha = if (played) .8f else 1f
            },
        shape = Theme.shapes.large,
    ) {
        Stack(
            spaceBetween = LayoutGap.Small,
            modifier = Modifier.padding(Theme.dimens.size_m),
        ) {
            Tier(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.spacedBy(
                    Theme.dimens.size_s,
                    alignment = Alignment.End,
                ),
            ) {
                if (showRelease) {
                    Box(modifier = Modifier.weight(1f)) {
                        Surface(
                            shape = Theme.shapes.medium,
                            color = Theme.colors.accent,
                            modifier = Modifier,
                        ) {
                            BodyTextSmall(
                                episode.formatCreatedDate(),
                                modifier = Modifier
                                    .padding(
                                        vertical = Theme.dimens.size_xxs,
                                        horizontal = Theme.dimens.size_s,
                                    ),
                            )
                        }
                    }
                }

                if (played) {
                    Icon(
                        modifier = Modifier.size(20.dp),
                        imageVector = Icons.Played,
                        contentDescription = "played indicator",
                        tint = Theme.colors.positive,
                    )
                }
                onMoreOptions?.let {
                    IconButton(
                        icon = Icons.More,
                        onClick = onMoreOptions,
                    )
                }
            }

            Container(
                onClick = onOpen,
                style = StyleDefaults.style(
                    colors = StyleDefaults.colors(backgroundColor = Color.Transparent),
                    scale = StyleDefaults.scale(pressedScale = .9f),
                ),
            ) {
                Tier(verticalAlignment = Alignment.Top) {
                    if (showImage) {
                        ArtworkTile(
                            size = 64.dp,
                            imageUrl = episode.image,
                            onClick = onOpen,
                        )
                    }
                    Stack(
                        spaceBetween = LayoutGap.Tiny,
                    ) {
                        TitleText(
                            episode.title, maxLines = 4, overflow = TextOverflow.Ellipsis,
                        )
                        BodyText(
                            episode.description.orEmpty(),
                            maxLines = descriptionLines,
                            overflow = TextOverflow.Ellipsis,
                        )
                        BodyText(
                            text = episode.formatDuration(),
                        )
                    }
                }
            }

            HorizontalDivider(
                color = Theme.colors.onSurfaceBackground,
                modifier = Modifier.graphicsLayer {
                    alpha = .2f
                },
            )

            Tier(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceBetween,
            ) {
                Tier {
                    IconButton(
                        icon = Icons.Share,
                        onClick = onShare,
                    )
                    when (downloadPercentage) {
                        0L -> {
                            IconButton(
                                icon = Icons.Download,
                                onClick = onDownload,
                            )
                        }

                        100L -> {
                            IconButton(
                                icon = Icons.Delete,
                                onClick = onDownload,
                            )
                        }

                        in 1L..99L -> {
                            val progress by animateFloatAsState(downloadPercentage / 100f)
                            IconButton(
                                icon = {
                                    CircularProgress(
                                        modifier = Modifier.scale(.5f),
                                        progress = { progress },
                                    )
                                },
                                onClick = onDownload,
                            )
                        }
                    }
                }
                Tier {
                    if (playedProgress > 0L && !played) {
                        BodyText(
                            text = "${episode.formatTimeRemaining(playedProgress)} left",
                            textColor = Theme.colors.accent,
                        )
                    }
                    PlayButton(
                        nowPlaying,
                        episode,
                        onPlay,
                        playIcon = if (played) Icons.Replay else Icons.PlayFilled,
                    )
                }
            }
        }
    }
}

@Composable
fun EpisodePlaceholder(
    modifier: Modifier = Modifier,
    withImage: Boolean = true,
    withBadge: Boolean = false,
    withActions: Boolean = false,
    withBackground: Boolean = false,
    titleLines: Int = 1,
    descriptionLines: Int = 1,
) {
    val color = Theme.colors.surfaceContainer
    Surface(
        color = if (withBackground) color.copy(alpha = .4f) else Color.Transparent,
        modifier = if (withBackground) modifier.sizeIn(maxHeight = 348.dp)
            .shimmer() else modifier.shimmer(),
        shape = Theme.shapes.large,
    ) {
        val shimmerModifier = Modifier
            .wrapContentHeight()
            .fillMaxWidth()
        Stack(
            modifier = if (withBackground) shimmerModifier.padding(16.dp) else shimmerModifier,
            spaceBetween = LayoutGap.Medium,
        ) {
            if (withBadge) {
                Container(
                    modifier = Modifier.height(24.dp).width(80.dp),
                    shape = Theme.shapes.large,
                    color = Theme.colors.accent.copy(alpha = .4f),
                ) { }
            }
            Tier(
                spaceBetween = LayoutGap.Tiny,
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.fillMaxWidth(),
            ) {
                if (withImage) {
                    Container(
                        modifier = Modifier.size(68.dp),
                        shape = Theme.shapes.large,
                        color = color,
                    ) { }
                }

                Stack(
                    spaceBetween = LayoutGap.Tiny,
                ) {
                    repeat(titleLines) {
                        Container(
                            modifier = Modifier.height(24.dp).fillMaxWidth(.4f),
                            shape = Theme.shapes.large,
                            color = color,
                        ) { }
                    }
                    repeat(descriptionLines) {
                        Container(
                            modifier = Modifier.height(24.dp).fillMaxWidth(),
                            shape = Theme.shapes.large,
                            color = color,
                        ) { }
                    }

                    if (withActions) {
                        Spacer(Modifier.height(2.dp))
                        HorizontalDivider(
                            color = Theme.colors.onSurfaceBackground,
                            modifier = Modifier.graphicsLayer {
                                alpha = .2f
                            },
                        )
                        Spacer(Modifier.height(4.dp))
                        Tier(
                            modifier = Modifier.fillMaxWidth(),
                            horizontalArrangement = Arrangement.SpaceBetween,
                        ) {
                            Container(
                                modifier = Modifier.size(24.dp).fillMaxWidth(),
                                shape = Theme.shapes.circle,
                                color = color,
                            ) { }
                            Container(
                                modifier = Modifier.size(24.dp).fillMaxWidth(),
                                shape = Theme.shapes.circle,
                                color = color,
                            ) { }
                        }
                    }
                }
            }
        }
    }
}
