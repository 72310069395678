package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.Notification: ImageVector
    get() {
        if (_Notification != null) {
            return _Notification!!
        }
        _Notification = ImageVector.Builder(
            name = "Notification",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(fill = SolidColor(Color(0xFFE8EAED))) {
                moveTo(4f, 19f)
                verticalLineTo(17f)
                horizontalLineTo(6f)
                verticalLineTo(10f)
                curveTo(6f, 8.617f, 6.417f, 7.387f, 7.25f, 6.313f)
                curveTo(8.083f, 5.238f, 9.167f, 4.533f, 10.5f, 4.2f)
                verticalLineTo(3.5f)
                curveTo(10.5f, 3.083f, 10.646f, 2.729f, 10.938f, 2.438f)
                curveTo(11.229f, 2.146f, 11.583f, 2f, 12f, 2f)
                curveTo(12.417f, 2f, 12.771f, 2.146f, 13.063f, 2.438f)
                curveTo(13.354f, 2.729f, 13.5f, 3.083f, 13.5f, 3.5f)
                verticalLineTo(4.2f)
                curveTo(14.833f, 4.533f, 15.917f, 5.238f, 16.75f, 6.313f)
                curveTo(17.583f, 7.387f, 18f, 8.617f, 18f, 10f)
                verticalLineTo(17f)
                horizontalLineTo(20f)
                verticalLineTo(19f)
                horizontalLineTo(4f)
                close()
                moveTo(12f, 22f)
                curveTo(11.45f, 22f, 10.979f, 21.804f, 10.587f, 21.413f)
                curveTo(10.196f, 21.021f, 10f, 20.55f, 10f, 20f)
                horizontalLineTo(14f)
                curveTo(14f, 20.55f, 13.804f, 21.021f, 13.413f, 21.413f)
                curveTo(13.021f, 21.804f, 12.55f, 22f, 12f, 22f)
                close()
                moveTo(8f, 17f)
                horizontalLineTo(16f)
                verticalLineTo(10f)
                curveTo(16f, 8.9f, 15.608f, 7.958f, 14.825f, 7.175f)
                curveTo(14.042f, 6.392f, 13.1f, 6f, 12f, 6f)
                curveTo(10.9f, 6f, 9.958f, 6.392f, 9.175f, 7.175f)
                curveTo(8.392f, 7.958f, 8f, 8.9f, 8f, 10f)
                verticalLineTo(17f)
                close()
            }
        }.build()

        return _Notification!!
    }

@Suppress("ObjectPropertyName")
private var _Notification: ImageVector? = null
