package studio.goodegg.capsule.navigation

import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.ui.unit.dp
import com.slack.circuit.runtime.screen.Screen

@Parcelize
data object InboxScreen : CapsuleScreen("Inbox")

@Parcelize
data object SubscriptionsScreen : CapsuleScreen("Subscriptions")

@Parcelize
data object DiscoverScreen : CapsuleScreen("Discover")

@Parcelize
data object SearchScreen : CapsuleScreen("Search")

@Parcelize
data object SettingsScreen : CapsuleScreen("Settings")

@Parcelize
data object ListenScreen : CapsuleScreen("My Library")

@Parcelize
data object ResumeScreen : CapsuleScreen("Resume")

@Parcelize
data object DownloadsScreen : CapsuleScreen("Downloads")

@Parcelize
data object SignInScreen : CapsuleScreen("SignIn")

@Parcelize
data object OnboardingScreen : CapsuleScreen("Onboarding")

@Parcelize
data object SplashScreen : CapsuleScreen("Splash")

@Parcelize
data class PodcastDetailsScreen(val slug: String) : CapsuleScreen("Podcast Detail")

@Parcelize
data class EpisodeDetailsScreen(val slug: String) : CapsuleScreen("Episode Detail")

@Parcelize
data class GenrePodcastsGridScreen(val title: String, val genreId: String) :
    CapsuleScreen("Genre Podcasts Grid")

@Parcelize
data object CategoriesGrid : CapsuleScreen("Categories Grid")

abstract class CapsuleScreen(
    val name: String,
    val paddingOverride: ((PaddingValues) -> PaddingValues)? = null,
) : Screen

@Parcelize
data class DockPlayerScreen(val expand: Boolean = true) : CapsuleScreen("Dock Player")

@Parcelize
data object FullPlayerScreen :
    CapsuleScreen("Full Player", paddingOverride = { PaddingValues(0.dp) })

@Parcelize
data object PlayQueueScreen : CapsuleScreen("PlayQueue")