package io.daio.bass.compose

import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier

@Composable
actual fun CastButton(
    modifier: Modifier,
    castButtonState: CastButtonState,
    content: @Composable (CastButtonScope.() -> Unit)?,
) {
    // Do Nothing.
}

private object CastButtonStateImpl : CastButtonState {
    override fun selectDevice() {
    }
}

@Composable
actual fun rememberCastButtonState(setAlwaysVisible: Boolean): CastButtonState = CastButtonStateImpl
