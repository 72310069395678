package studio.goodegg.capsule.api.clients

import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.get
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.StationResponse
import studio.goodegg.capsule.TuneResponse
import studio.goodegg.capsule.api.FUNCTIONS_BASE_URL
import studio.goodegg.capsule.utils.Failed
import studio.goodegg.capsule.utils.Response
import studio.goodegg.capsule.utils.Success

interface RadioClient {
    suspend fun tune(id: String): Response<TuneResponse>
    suspend fun search(q: String): Response<StationResponse>
}

@Inject
internal class RadioClientImpl(private val httpClient: HttpClient) : RadioClient {

    override suspend fun tune(id: String): Response<TuneResponse> {
        return try {
            val response =
                httpClient.get(urlString = "${FUNCTIONS_BASE_URL}capsule-radio/tune?guid=$id")
            Success(response.body())
        } catch (error: Throwable) {
            error.printStackTrace()
            Failed(error)
        }
    }

    override suspend fun search(q: String): Response<StationResponse> {
        return try {
            val response =
                httpClient.get(urlString = "${FUNCTIONS_BASE_URL}capsule-radio/stations/search?q=$q")
            Success(response.body())
        } catch (error: Throwable) {
            error.printStackTrace()
            Failed(error)
        }
    }
}
