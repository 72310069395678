package studio.goodegg.capsule.categories

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.lazy.grid.itemsIndexed
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.screen.Screen
import com.slack.circuit.runtime.ui.Ui
import com.slack.circuit.runtime.ui.ui
import io.daio.pancake.components.progress.FullScreenLoadingIndicator
import io.daio.pancake.icons.ArrowBack
import io.daio.pancake.icons.Icons
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.LazyStackGrid
import io.daio.pancake.layout.Stack
import io.daio.wild.style.clickable
import studio.goodegg.capsule.navigation.CategoriesGrid
import studio.goodegg.capsule.ui.components.CategoryCard
import studio.goodegg.capsule.ui.components.getCategoryColor

class CategoriesScreenUiFactory : Ui.Factory {
    override fun create(
        screen: Screen,
        context: CircuitContext,
    ): Ui<*>? {
        return when (screen) {
            is CategoriesGrid -> categories()
            else -> null
        }
    }
}

private fun categories() =
    ui<CategoriesUiState> { state, modifier ->
        Categories(
            state,
            modifier,
        )
    }

@Composable
fun Categories(
    state: CategoriesUiState,
    modifier: Modifier = Modifier,
) {
    Box(modifier = modifier.fillMaxSize()) {
        if (state.refreshing) {
            FullScreenLoadingIndicator()
        }

        Stack(modifier = modifier) {
            Icon(
                imageVector = Icons.ArrowBack,
                contentDescription = "",
                modifier = Modifier
                    .size(24.dp)
                    .clickable(
                        style = {
                            scale = if (pressed) .95f else 1f
                        },
                        onClick = {
                            state.eventSink(CategoriesUiEvent.Back)
                        },
                    ),
            )

            LazyStackGrid(
                columns = 2,
                modifier = Modifier.fillMaxSize(),
                contentPadding = PaddingValues(
                    bottom = 66.dp, // todo player dynamic padding
                ),
                gap = LayoutGap.Medium,
            ) {
                itemsIndexed(
                    state.items,
                    key = { _, it -> it.genreId },
                ) { index, item ->
                    CategoryCard(
                        item.label,
                        backgroundColor = getCategoryColor(index),
                        onClick = {
                            state.eventSink.invoke(
                                CategoriesUiEvent.OpenCategory(
                                    item.label,
                                    item.genreId,
                                ),
                            )
                        },
                    )
                }
            }
        }
    }
}
