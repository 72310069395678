package studio.goodegg.capsule.onboarding

import androidx.compose.runtime.Immutable
import com.slack.circuit.runtime.CircuitUiState
import studio.goodegg.capsule.PodcastList
import studio.goodegg.capsule.User

@Immutable
data class OnboardingUiState(
    val user: User?,
    val loading: Boolean,
    /**
     * Signals we are waiting for the user to input the code emailed to them.
     */
    val awaitingSignInCode: Boolean,
    /**
     * Verifies we are waiting for the user to verify sign in (submitted code)
     */
    val awaitingVerifySignIn: Boolean,
    val notificationsEnabled: Boolean,
    val subscriptions: Set<String>,
    val discover: PodcastList,
    val eventSink: (OnboardingUiEvent) -> Unit,
) : CircuitUiState
