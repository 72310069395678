package studio.goodegg.capsule.core.playback

import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.Json
import studio.goodegg.capsule.Episode
import studio.goodegg.capsule.FileDataSerializer
import studio.goodegg.capsule.FileDataStore
import studio.goodegg.capsule.FileStorageLocation
import studio.goodegg.capsule.common.coroutines.IoDispatcher

class PlayerQueue internal constructor(
    private val playQueueDataStore: PlayQueueDataStore,
    @IoDispatcher private val ioDispatcher: CoroutineDispatcher,
) {
    private val queueFlow = MutableStateFlow<List<Episode>>(emptyList())
    private val queue = ArrayDeque<Episode>()
    private val scope = CoroutineScope(SupervisorJob() + ioDispatcher)

    init {
        scope.launch {
            queue.addAll(playQueueDataStore.load())
            queueFlow.value = queue.toList()
        }
    }

    val popNext: Episode?
        get() = queue.removeFirstOrNull()
            ?.also { updateQueue() }

    val upNext: Episode? get() = queue.firstOrNull()

    val all get() = queue.toList()

    val isEmpty get() = queue.isEmpty()

    fun contains(playerItem: Episode) = queue.contains(playerItem)

    fun addToEndQueue(playerItem: Episode) {
        if (contains(playerItem)) return

        queue.addLast(playerItem)
        updateQueue()
    }

    fun clear() {
        queue.clear()
        updateQueue()
    }

    fun addToFrontQueue(playerItem: Episode) {
        if (contains(playerItem)) return

        queue.addFirst(playerItem)
        updateQueue()
    }

    fun remove(playerItem: Episode) {
        queue.remove(playerItem)
        updateQueue()
    }

    fun update(items: List<Episode>) {
        queue.clear()
        queue.addAll(items)
        updateQueue()
    }

    private fun updateQueue() {
        queueFlow.value = queue.toList()
        scope.launch {
            playQueueDataStore.cache(queue)
        }
    }

    fun observeQueue(): Flow<List<Episode>> = queueFlow
}

class PlayQueueDataStore internal constructor(
    fileStorageLocation: FileStorageLocation,
    @IoDispatcher private val dispatcher: CoroutineDispatcher,
) {
    private val key = "playqueue"
    private val fileDataStore = FileDataStore(
        filename = "capsule-playqueue",
        fileStorageLocation = fileStorageLocation,
        folderName = key,
        fileSerializer = PlayQueueSerializer,
        dispatcher = dispatcher,
    )

    suspend fun cache(episodes: List<Episode>) = withContext(dispatcher) {
        fileDataStore.save(episodes, "playqueue")
    }

    suspend fun load(): List<Episode> =
        withContext(dispatcher) { fileDataStore.get("playqueue").orEmpty() }
}

private object PlayQueueSerializer : FileDataSerializer<List<Episode>> {
    override suspend fun write(data: List<Episode>): String {
        return Json.encodeToString(ListSerializer(Episode.serializer()), data)
    }

    override suspend fun read(text: String): List<Episode> {
        return Json.decodeFromString(ListSerializer(Episode.serializer()), text)
    }
}