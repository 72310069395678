package studio.goodegg.capsule.base.main

import androidx.compose.animation.AnimatedContent
import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.SizeTransform
import androidx.compose.animation.animateColorAsState
import androidx.compose.animation.core.animateDpAsState
import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.animation.core.spring
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.animation.slideInVertically
import androidx.compose.animation.slideOutVertically
import androidx.compose.animation.togetherWith
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.RowScope
import androidx.compose.foundation.layout.calculateEndPadding
import androidx.compose.foundation.layout.calculateStartPadding
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.platform.LocalLayoutDirection
import androidx.compose.ui.semantics.Role
import androidx.compose.ui.semantics.role
import androidx.compose.ui.semantics.semantics
import androidx.compose.ui.unit.dp
import com.slack.circuit.backstack.NavDecoration
import com.slack.circuit.backstack.SaveableBackStack
import com.slack.circuit.backstack.rememberSaveableBackStack
import com.slack.circuit.foundation.Circuit
import com.slack.circuit.foundation.CircuitCompositionLocals
import com.slack.circuit.foundation.CircuitContent
import com.slack.circuit.foundation.NavigableCircuitContent
import com.slack.circuit.foundation.rememberCircuitNavigator
import com.slack.circuit.runtime.Navigator
import io.daio.pancake.bottomsheet.rememberBottomSheetState
import io.daio.pancake.components.bottombar.BarItem
import io.daio.pancake.components.bottombar.BarItemColors
import io.daio.pancake.components.bottombar.BarItemDefaults
import io.daio.pancake.components.bottombar.BottomBar
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.color.darkColors
import io.daio.pancake.foundations.text.TitleText
import io.daio.pancake.icons.FeedFilled
import io.daio.pancake.icons.FeedOutline
import io.daio.pancake.icons.Icons
import io.daio.pancake.icons.LibraryFilled
import io.daio.pancake.icons.LibraryOutline
import io.daio.pancake.icons.SearchFilled
import io.daio.pancake.icons.SearchOutline
import io.daio.pancake.icons.Settings
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.Tier
import io.daio.pancake.scaffold.Scaffold
import io.daio.wild.container.Container
import io.daio.wild.modifier.thenIfNotNull
import io.daio.wild.style.StyleDefaults
import kotlinx.collections.immutable.ImmutableList
import me.tatarka.inject.annotations.Assisted
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.navigation.CapsuleScreen
import studio.goodegg.capsule.navigation.DiscoverScreen
import studio.goodegg.capsule.navigation.InboxScreen
import studio.goodegg.capsule.navigation.ListenScreen
import studio.goodegg.capsule.navigation.PlayQueueScreen
import studio.goodegg.capsule.navigation.DockPlayerScreen
import studio.goodegg.capsule.navigation.SettingsScreen
import studio.goodegg.capsule.navigation.SplashScreen
import studio.goodegg.capsule.player.LocalPlayerDock
import studio.goodegg.capsule.player.PLayerDefaults
import studio.goodegg.capsule.player.isExpanded
import studio.goodegg.capsule.ui.components.LocalBottomSheet

@Composable
@Inject
fun MainApp(
    circuit: Circuit,
    @Assisted modifier: Modifier = Modifier,
    @Assisted backStack: SaveableBackStack = rememberSaveableBackStack(root = SplashScreen),
    @Assisted navigator: Navigator = rememberCircuitNavigator(backStack) {},
) {
    val current by remember(backStack) {
        derivedStateOf { backStack.first().screen as CapsuleScreen }
    }

    val isMainScreen = current == InboxScreen ||
            current == DiscoverScreen ||
            current == ListenScreen

    val bottomBar: (@Composable () -> Unit) =
        {
            BottomBar {
                BarItem(
                    icon = Icons.FeedOutline,
                    selectedIcon = Icons.FeedFilled,
                    selected = current == InboxScreen,
                    modifier = Modifier.weight(1f),
                    onClick = {
                        navigator.resetRoot(InboxScreen)
                    },
                )
                BarItem(
                    icon = Icons.SearchOutline,
                    selectedIcon = Icons.SearchFilled,
                    selected = current == DiscoverScreen,
                    modifier = Modifier.weight(1f),
                    onClick = {
                        navigator.resetRoot(DiscoverScreen)
                    },
                )
                BarItem(
                    icon = Icons.LibraryOutline,
                    selectedIcon = Icons.LibraryFilled,
                    selected = current == ListenScreen,
                    modifier = Modifier.weight(1f),
                    onClick = {
                        navigator.resetRoot(ListenScreen)
                    },
                )
            }
        }

    val bottomSheetState = rememberBottomSheetState(
        isSkipHalfExpanded = true,
    )

    CompositionLocalProvider(LocalBottomSheet provides bottomSheetState) {
        CircuitCompositionLocals(circuit) {
            Scaffold(
                modifier = modifier,
                bottomSheetState = bottomSheetState,
                themeColors = darkColors.copy(accent = Color(0xFFE3C07C)),
                bottomBar = if (isMainScreen) bottomBar else null,
                dock = { paddingValues ->
                    val expanded = LocalPlayerDock.current.isExpanded
                    val padding by animateDpAsState(
                        if (expanded) 0.dp else paddingValues.calculateBottomPadding(),
                        animationSpec = PLayerDefaults.expandAnimation,
                    )

                    val fillWidthFraction by animateFloatAsState(
                        if (expanded) 1f else .9f,
                        animationSpec = spring(
                            stiffness = 300f,
                            visibilityThreshold = .1f,
                        ),
                    )

                    // TODO this is just working around navigating to the PlayQueue,
                    // need to restructure to bring UI to spec.
                    AnimatedVisibility(
                        current != PlayQueueScreen,
                        enter = slideInVertically { 1000 },
                        exit = slideOutVertically { 1000 },
                    ) {
                        Box(
                            modifier =
                                Modifier.fillMaxWidth(fraction = fillWidthFraction)
                                    .padding(bottom = padding),
                        ) {
                            CircuitContent(DockPlayerScreen(), navigator = navigator)
                        }
                    }
                },
            ) { padding ->
                Stack(
                    spaceBetween = LayoutGap.None,
                    modifier =
                        Modifier.fillMaxSize()
                            .thenIfNotNull(
                                value = current.paddingOverride,
                                ifNullModifier = Modifier.padding(
                                    PaddingValues(
                                        top = padding.calculateTopPadding(),
                                        start = Theme.dimens.size_m,
                                        end = Theme.dimens.size_m,
                                        bottom = padding.calculateBottomPadding(),
                                    ),
                                ),
                                ifNotNullModifier = { Modifier.padding(it(padding)) },
                            ),
                ) {
                    AnimatedVisibility(isMainScreen) {
                        Tier(
                            modifier = Modifier.fillMaxWidth(),
                            horizontalAlignment = Alignment.End,
                        ) {
                            (current as? ListenScreen)?.let { screen ->
                                TitleText(
                                    screen.name,
                                    textColor = Theme.colors.accent,
                                    modifier = Modifier.weight(1f),
                                )
                            }
                            TopBarItem(
                                icon = Icons.Settings,
                                selected = false,
                                onClick = {
                                    navigator.goTo(SettingsScreen)
                                },
                            )
                        }
                    }

                    NavigableCircuitContent(
                        modifier = Modifier.weight(1f),
                        navigator = navigator,
                        backStack = backStack,
                        decoration = CapsuleDecor,
                    )
                }
            }
        }
    }
}

private object CapsuleDecor : NavDecoration {
    @Composable
    override fun <T> DecoratedContent(
        args: ImmutableList<T>,
        backStackDepth: Int,
        modifier: Modifier,
        content: @Composable (T) -> Unit,
    ) {
        AnimatedContent(
            targetState = args,
            modifier = modifier,
            transitionSpec = {
                (fadeIn() togetherWith fadeOut()).using(
                    // Disable clipping since the faded slide-in/out should
                    // be displayed out of bounds.
                    SizeTransform(clip = false),
                )
            },
        ) {
            content.invoke(it.first())
        }
    }
}

@Composable
private fun RowScope.TopBarItem(
    icon: ImageVector,
    selected: Boolean,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    selectedIcon: ImageVector = icon,
    title: String? = null,
    barItemColors: BarItemColors = BarItemDefaults.colors(),
) {
    val contentColor =
        if (selected) barItemColors.selectedTextColor else barItemColors.textColor
    val color by animateColorAsState(contentColor)

    Container(
        onClick = onClick,
        modifier = modifier.semantics {
            role = Role.Button
        },
        style =
            StyleDefaults.style(
                colors =
                    StyleDefaults.colors(
                        contentColor = color,
                        backgroundColor = Color.Transparent,
                    ),
                scale = StyleDefaults.scale(pressedScale = .90f),
            ),
    ) {
        Stack(
            spaceBetween = LayoutGap.Tiny,
            verticalAlignment = Alignment.CenterVertically,
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            Icon(
                imageVector = if (selected) selectedIcon else icon,
                contentDescription = "Top Bar Item $title",
                modifier = Modifier.size(BarItemDefaults.iconSize),
            )
        }
    }
}