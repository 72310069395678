package studio.goodegg.capsule.work

interface WorkScheduler {
    /**
     * Schedule work to run every supplied number of [hours]
     *
     * @param work [Work] to run on a schedule.
     * @param hours How often in hours the work should run.
     */
    fun schedule(work: Work, hours: Int)

    /**
     * Schedule work to run immediately.
     *
     * @param work [Work] to run.
     */
    fun runNow(work: Work)
}

sealed interface Work

data class UpdateEpisodes(
    val name: String = "update-episodes",
    val notify: Boolean = true,
    val slugs: List<String> = emptyList(),
) : Work

data class DownloadEpisode(
    val notify: Boolean = true,
    val slug: String,
    val name: String = "download-$slug",
    val mediaUrl: String,
    val title: String,
) : Work

