package studio.goodegg.capsule.domain.download

import me.tatarka.inject.annotations.Component
import me.tatarka.inject.annotations.Provides
import studio.goodegg.capsule.repositories.DownloadsRepository
import studio.goodegg.capsule.work.DownloadEpisode
import studio.goodegg.capsule.work.WorkScheduler

@Component
interface DownloadDomainComponent {
    @Provides
    fun providesDownloadInteractor(scheduler: WorkScheduler): DownloadInteractor =
        DownloadInteractor { slug, url, title ->
            scheduler.runNow(DownloadEpisode(slug = slug, mediaUrl = url, title = title))
        }

    @Provides
    fun providesObserveDownloadsInteractor(repository: DownloadsRepository): ObserveDownloadsInteractor =
        ObserveDownloadsInteractor { repository.observeInProgress() }

    @Provides
    fun providesObserveDownloadedInteractor(repository: DownloadsRepository): ObserveDownloadedInteractor =
        ObserveDownloadedInteractor { repository.observe() }

    @Provides
    fun providesDeleteDownloadedInteractor(repository: DownloadsRepository): DeleteDownloadInteractor =
        DeleteDownloadInteractor { slug -> repository.delete(slug) }
}
