package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.NotifOff: ImageVector
    get() {
        if (_NotifOff != null) {
            return _NotifOff!!
        }
        _NotifOff = ImageVector.Builder(
            name = "NotifOff",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(
                fill = SolidColor(Color(0xFFFFFFFF)),
                pathFillType = PathFillType.EvenOdd
            ) {
                moveTo(6f, 10f)
                curveToRelative(0f, -0.623f, 0.084f, -1.214f, 0.253f, -1.774f)
                lineTo(8f, 9.97f)
                lineTo(8f, 17f)
                horizontalLineToRelative(7.041f)
                lineToRelative(2.003f, 2f)
                lineTo(4f, 19f)
                verticalLineToRelative(-2f)
                horizontalLineToRelative(2f)
                verticalLineToRelative(-7f)
                close()
                moveTo(16f, 10f)
                verticalLineToRelative(2.011f)
                lineToRelative(2f, 1.997f)
                lineTo(18f, 10f)
                curveToRelative(0f, -1.383f, -0.417f, -2.612f, -1.25f, -3.688f)
                curveToRelative(-0.833f, -1.075f, -1.917f, -1.779f, -3.25f, -2.112f)
                verticalLineToRelative(-0.7f)
                curveToRelative(0f, -0.417f, -0.146f, -0.77f, -0.438f, -1.063f)
                arcTo(1.447f, 1.447f, 0f, isMoreThanHalf = false, isPositiveArc = false, 12f, 2f)
                curveToRelative(-0.417f, 0f, -0.77f, 0.146f, -1.063f, 0.438f)
                arcTo(1.446f, 1.446f, 0f, isMoreThanHalf = false, isPositiveArc = false, 10.5f, 3.5f)
                verticalLineToRelative(0.7f)
                curveToRelative(-0.6f, 0.15f, -1.15f, 0.375f, -1.648f, 0.675f)
                lineToRelative(1.481f, 1.478f)
                arcTo(3.914f, 3.914f, 0f, isMoreThanHalf = false, isPositiveArc = true, 12f, 6f)
                curveToRelative(1.1f, 0f, 2.042f, 0.392f, 2.825f, 1.175f)
                curveTo(15.608f, 7.958f, 16f, 8.9f, 16f, 10f)
                close()
                moveTo(12f, 22f)
                curveToRelative(-0.55f, 0f, -1.02f, -0.196f, -1.412f, -0.587f)
                arcTo(1.926f, 1.926f, 0f, isMoreThanHalf = false, isPositiveArc = true, 10f, 20f)
                horizontalLineToRelative(4f)
                curveToRelative(0f, 0.55f, -0.196f, 1.02f, -0.588f, 1.413f)
                arcTo(1.926f, 1.926f, 0f, isMoreThanHalf = false, isPositiveArc = true, 12f, 22f)
                close()
                moveTo(5.835f, 3f)
                lineTo(4f, 4.837f)
                lineToRelative(14.78f, 14.757f)
                lineToRelative(1.834f, -1.837f)
                lineTo(5.834f, 3f)
                close()
            }
        }.build()

        return _NotifOff!!
    }

@Suppress("ObjectPropertyName")
private var _NotifOff: ImageVector? = null
