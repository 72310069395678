package studio.goodegg.capsule.domain.playback

import kotlinx.coroutines.flow.Flow
import studio.goodegg.capsule.EpisodeAndMetadata
import studio.goodegg.capsule.PlaybackState
import studio.goodegg.capsule.Station

fun interface ObserveNowPlayingItemInteractor {
    operator fun invoke(): Flow<NowPlayingItem?>
}

data class StationAndMetadata(
    val station: Station,
    val playbackState: PlaybackState?,
)

sealed interface NowPlayingItem {
    data class Radio(val station: StationAndMetadata) : NowPlayingItem
    data class Podcast(
        val episode: EpisodeAndMetadata,
        val podcastName: String? = null,
    ) : NowPlayingItem
}

val NowPlayingItem.title: String
    get() = when (this) {
        is NowPlayingItem.Podcast -> episode.episode.title
        is NowPlayingItem.Radio -> station.station.title
    }

val NowPlayingItem.parentTitle: String
    get() = when (this) {
        is NowPlayingItem.Podcast -> podcastName ?: episode.episode.title
        is NowPlayingItem.Radio -> station.station.title
    }

val NowPlayingItem.subtitle: String
    get() = when (this) {
        is NowPlayingItem.Podcast -> episode.episode.author
        is NowPlayingItem.Radio -> station.station.subtext
    }

val NowPlayingItem.image: String?
    get() = when (this) {
        is NowPlayingItem.Podcast -> episode.episode.image
        is NowPlayingItem.Radio -> station.station.image
    }

val NowPlayingItem.playbackState: PlaybackState?
    get() = when (this) {
        is NowPlayingItem.Podcast -> episode.playingState
        is NowPlayingItem.Radio -> station.playbackState
    }

