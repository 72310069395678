package io.daio.bass

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.channels.awaitClose
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.callbackFlow
import kotlinx.coroutines.flow.emptyFlow
import kotlinx.coroutines.flow.flatMapLatest
import kotlinx.coroutines.flow.launchIn
import org.w3c.dom.Audio
import org.w3c.dom.events.EventListener

@OptIn(ExperimentalCoroutinesApi::class)
class AudioJsBassPlayer(
    private val playlist: BassDefaultPlaylist = BassDefaultPlaylist(),
) : BassPlayer {
    internal val player: MutableStateFlow<Audio?> = MutableStateFlow(null)
    private val scope = CoroutineScope(Dispatchers.Main + SupervisorJob())

    override val currentItem: MediaItem?
        get() = playlist.current()

    override val currentPosition: Long
        get() = player.value?.currentTime?.toLong() ?: 0L

    override val playerType: PlayerType
        get() = PlayerType.Local

    override var trimSilence: Boolean
        get() = false
        set(value) {}

    override var audioBoostGain: Int
        get() = 0
        set(value) {}

    override fun play(
        media: MediaItem,
        offset: Long,
    ) {
        stop()
        playlist.setItem(media)
        playInternal(media)
        if (offset > 0) {
            seekByDelta(offset)
        }
    }

    override fun playlist(
        media: List<MediaItem>,
        startIndex: Int,
        offset: Long,
    ) {
        stop()
        playlist.setItems(media)
        playlist.moveToPosition(startIndex)
        playInternal(playlist.current()!!)
        if (offset > 0) {
            seekByDelta(offset)
        }
    }

    override fun stop() {
        reset()
        playlist.clear()
    }

    override fun pause() {
        player.value?.pause()
    }

    override fun resume() {
        player.value?.play()
    }

    override fun seekByDelta(deltaMs: Long) {
        val player = player.value ?: return
        val seekSeconds = deltaMs / 1000
        player.currentTime += seekSeconds.toDouble()
    }

    override fun setPlaybackSpeed(speed: Float) {
        player.value?.playbackRate = speed.toDouble()
    }

    override fun release() {
        stop()
    }

    override fun next() {
        val next = playlist.next()
        if (next != null) {
            playInternal(next)
        }
    }

    override fun hasNext(): Boolean = playlist.hasNext()

    override fun previous() {
        val previous = playlist.previous()
        if (previous != null) {
            playInternal(previous)
        }
    }

    override fun hasPrevious(): Boolean = playlist.hasPrevious()

    private fun playInternal(media: MediaItem) {
        reset()
        player.value =
            runCatching {
                Audio(media.url).apply {
                    play()
                }
            }.getOrNull()
    }

    private fun reset() {
        player.value?.pause()
        player.value?.currentTime = 0.0
        player.value?.remove()
        player.value = null
    }

    init {
        player.flatMapLatest {
            val audio = it ?: return@flatMapLatest emptyFlow()

            callbackFlow<Unit> {
                val endListener =
                    EventListener {
                        next()
                    }

                val loadListener =
                    EventListener {
                        playlist.moveToItem(MediaItem(audio.currentSrc))
                    }

                audio.addEventListener("ended", endListener)
                audio.addEventListener("playing", loadListener)

                awaitClose {
                    audio.removeEventListener("ended", endListener)
                    audio.removeEventListener("playing", loadListener)
                }
            }
        }.launchIn(scope)
    }
}

internal val BassPlayer.audioPlayer: MutableStateFlow<Audio?>
    get() = (this as AudioJsBassPlayer).player
