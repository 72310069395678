package studio.goodegg.capsule.downloads

import androidx.compose.runtime.Immutable
import com.slack.circuit.runtime.CircuitUiState

@Immutable
data class DownloadsUiState(
    val items: List<DownloadGroup>,
    val eventSink: (DownloadsUiEvent) -> Unit,
) : CircuitUiState
