package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.SearchOutline: ImageVector
    get() {
        if (_SearchOutline != null) {
            return _SearchOutline!!
        }
        _SearchOutline = ImageVector.Builder(
            name = "SearchOutline",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF))) {
                moveToRelative(19.6f, 21f)
                lineToRelative(-6.3f, -6.3f)
                arcTo(6.096f, 6.096f, 0f, isMoreThanHalf = false, isPositiveArc = true, 9.5f, 16f)
                curveToRelative(-1.817f, 0f, -3.354f, -0.63f, -4.612f, -1.887f)
                curveTo(3.629f, 12.854f, 3f, 11.317f, 3f, 9.5f)
                curveToRelative(0f, -1.817f, 0.63f, -3.354f, 1.888f, -4.612f)
                curveTo(6.146f, 3.629f, 7.683f, 3f, 9.5f, 3f)
                curveToRelative(1.817f, 0f, 3.354f, 0.63f, 4.613f, 1.888f)
                curveTo(15.37f, 6.146f, 16f, 7.683f, 16f, 9.5f)
                arcToRelative(6.096f, 6.096f, 0f, isMoreThanHalf = false, isPositiveArc = true, -1.3f, 3.8f)
                lineToRelative(6.3f, 6.3f)
                lineToRelative(-1.4f, 1.4f)
                close()
                moveTo(9.5f, 14f)
                curveToRelative(1.25f, 0f, 2.313f, -0.438f, 3.188f, -1.313f)
                curveTo(13.562f, 11.813f, 14f, 10.75f, 14f, 9.5f)
                curveToRelative(0f, -1.25f, -0.438f, -2.313f, -1.313f, -3.188f)
                curveTo(11.813f, 5.438f, 10.75f, 5f, 9.5f, 5f)
                curveToRelative(-1.25f, 0f, -2.313f, 0.438f, -3.188f, 1.313f)
                reflectiveCurveTo(5f, 8.25f, 5f, 9.5f)
                curveToRelative(0f, 1.25f, 0.438f, 2.313f, 1.313f, 3.188f)
                curveTo(7.188f, 13.562f, 8.25f, 14f, 9.5f, 14f)
                close()
            }
        }.build()

        return _SearchOutline!!
    }

@Suppress("ObjectPropertyName")
private var _SearchOutline: ImageVector? = null
