package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.DensitySmall: ImageVector
    get() {
        if (_DensitySmall != null) {
            return _DensitySmall!!
        }
        _DensitySmall = ImageVector.Builder(
            name = "DensitySmall",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(
                fill = SolidColor(Color(0xFFD9D9D9)),
                pathFillType = PathFillType.EvenOdd
            ) {
                moveTo(21f, 5f)
                lineTo(3f, 5f)
                verticalLineToRelative(2f)
                horizontalLineToRelative(18f)
                lineTo(21f, 5f)
                close()
                moveTo(21f, 9f)
                lineTo(3f, 9f)
                verticalLineToRelative(2f)
                horizontalLineToRelative(18f)
                lineTo(21f, 9f)
                close()
                moveTo(3f, 13f)
                horizontalLineToRelative(18f)
                verticalLineToRelative(2f)
                lineTo(3f, 15f)
                verticalLineToRelative(-2f)
                close()
                moveTo(21f, 17f)
                lineTo(3f, 17f)
                verticalLineToRelative(2f)
                horizontalLineToRelative(18f)
                verticalLineToRelative(-2f)
                close()
            }
        }.build()

        return _DensitySmall!!
    }

@Suppress("ObjectPropertyName")
private var _DensitySmall: ImageVector? = null
