package io.daio.pancake.components.progress

import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.LinearProgressIndicator
import androidx.compose.runtime.Composable
import androidx.compose.runtime.NonRestartableComposable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.StrokeCap
import io.daio.pancake.foundations.Theme

@Composable
@NonRestartableComposable
fun ProgressBar(
    progress: () -> Float,
    modifier: Modifier = Modifier,
    color: Color = Theme.colors.onSurfaceBackground,
) {
    LinearProgressIndicator(
        modifier = modifier,
        progress = progress,
        strokeCap = StrokeCap.Round,
        color = color,
        trackColor = color.copy(alpha = .6f),
    )
}

@Composable
@NonRestartableComposable
fun CircularProgress(
    progress: () -> Float,
    modifier: Modifier = Modifier,
    color: Color = Theme.colors.onSurfaceBackground,
) {
    CircularProgressIndicator(
        modifier = modifier,
        progress = progress,
        strokeCap = StrokeCap.Round,
        color = color,
        trackColor = color.copy(alpha = .6f),
    )
}
