package studio.goodegg.capsule.trending

import com.slack.circuit.runtime.CircuitUiEvent
import studio.goodegg.capsule.PodcastResult
import studio.goodegg.capsule.domain.playback.EpisodeAndPosition

sealed class TrendingUiEvent : CircuitUiEvent {
    data class OpenPodcast(val slug: String) : TrendingUiEvent()

    data class Play(val episode: EpisodeAndPosition, val openPlayer: Boolean = false) :
        TrendingUiEvent()

    data class OpenCategory(val genreId: String, val label: String) : TrendingUiEvent()

    data object OpenCategories : TrendingUiEvent()

    data class AddToSubscriptions(val podcast: PodcastResult) : TrendingUiEvent()

    data object OpenSearch : TrendingUiEvent()
}
