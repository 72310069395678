package io.daio.pancake.components.bottombar

import androidx.compose.animation.animateColorAsState
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.RowScope
import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.windowInsetsPadding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.runtime.ReadOnlyComposable
import androidx.compose.runtime.Stable
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.semantics.Role
import androidx.compose.ui.semantics.role
import androidx.compose.ui.semantics.semantics
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.color.contentColorFor
import io.daio.pancake.foundations.text.LinkText
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.Tier
import io.daio.wild.container.Container
import io.daio.wild.content.LocalContentColor
import io.daio.wild.style.StyleDefaults

@Composable
fun BottomBar(
    modifier: Modifier = Modifier,
    containerColor: Color = Theme.colors.surfaceContainer,
    contentColor: Color = contentColorFor(containerColor),
    shape: RoundedCornerShape = Theme.shapes.circle,
    contentPadding: PaddingValues = BottomBarDefaults.contentPadding,
    windowInsets: WindowInsets = BottomBarDefaults.windowInsets,
    actions: @Composable RowScope.() -> Unit,
) {
    BottomAppBar(
        containerColor = containerColor,
        contentColor = contentColor,
        shape = shape,
        contentPadding = contentPadding,
        windowInsets = windowInsets,
    ) {
        Tier(
            modifier = modifier.fillMaxSize(),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalAlignment = Alignment.CenterVertically,
            content = actions,
        )
    }
}

@Composable
fun RowScope.BarItem(
    icon: ImageVector,
    selected: Boolean,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    selectedIcon: ImageVector = icon,
    title: String? = null,
    barItemColors: BarItemColors = BarItemDefaults.colors(),
) {
    val contentColor =
        if (selected) barItemColors.selectedTextColor else barItemColors.textColor
    val color by animateColorAsState(contentColor)

    Container(
        onClick = onClick,
        modifier =
            modifier.weight(1f).semantics {
                role = Role.Button
            },
        style =
            StyleDefaults.style(
                colors =
                    StyleDefaults.colors(
                        contentColor = color,
                        backgroundColor = Color.Transparent,
                    ),
                scale = StyleDefaults.scale(pressedScale = .90f),
            ),
    ) {
        Stack(
            spaceBetween = LayoutGap.Tiny,
            verticalAlignment = Alignment.CenterVertically,
            horizontalAlignment = Alignment.CenterHorizontally,
            modifier = modifier.size(BarItemDefaults.barItemHeight),
        ) {
            Icon(
                imageVector = if (selected) selectedIcon else icon,
                contentDescription = "Bottom Bar Item $title",
                modifier = Modifier.size(BarItemDefaults.iconSize),
            )
            title?.let {
                LinkText(
                    text = title,
                    textColor = LocalContentColor.current,
                )
            }
        }
    }
}

@Immutable
data class BarItemColors(
    val textColor: Color,
    val selectedTextColor: Color,
)

object BarItemDefaults {
    val barItemHeight: Dp = 56.dp
    val iconSize: Dp = 24.dp

    @Stable
    @Composable
    @ReadOnlyComposable
    fun colors(
        textColor: Color = Theme.colors.onSurfaceEmphasized,
        selectedTextColor: Color = Theme.colors.accent,
    ): BarItemColors =
        BarItemColors(
            textColor = textColor,
            selectedTextColor = selectedTextColor,
        )
}

object BottomBarDefaults {
    val contentPadding: PaddingValues =
        PaddingValues(
            start = BottomAppBarHorizontalPadding,
            top = BottomAppBarVerticalPadding,
            end = BottomAppBarHorizontalPadding,
        )

    internal val windowInsets: WindowInsets = WindowInsets(0, 0, 0, 0)
}

private val BottomAppBarHorizontalPadding = 16.dp - 12.dp
private val BottomAppBarVerticalPadding = 16.dp - 12.dp

@Composable
fun BottomAppBar(
    modifier: Modifier = Modifier,
    containerColor: Color = Theme.colors.surfaceContainer,
    contentColor: Color = contentColorFor(containerColor),
    shape: RoundedCornerShape = Theme.shapes.circle,
    contentPadding: PaddingValues = BottomBarDefaults.contentPadding,
    windowInsets: WindowInsets = BottomBarDefaults.windowInsets,
    content: @Composable RowScope.() -> Unit,
) {
    Container(
        color = containerColor,
        contentColor = contentColor,
        shape = shape,
        modifier = modifier,
    ) {
        Row(
            Modifier.fillMaxWidth()
                .windowInsetsPadding(windowInsets)
                .height(56.dp)
                .padding(contentPadding),
            horizontalArrangement = Arrangement.Start,
            verticalAlignment = Alignment.CenterVertically,
            content = content,
        )
    }
}
