package studio.goodegg.capsule.settings

import com.slack.circuit.runtime.CircuitUiEvent

sealed class SettingsUiEvent : CircuitUiEvent {
    data class ToggleSetting(val key: String, val value: String) : SettingsUiEvent()
    data object SignIn : SettingsUiEvent()
    data object SignOut : SettingsUiEvent()
    data object Back : SettingsUiEvent()
}
