package studio.goodegg.capsule.ui.components

import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.lazy.LazyListScope
import androidx.compose.foundation.lazy.LazyListState
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.NonRestartableComposable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.LazyStack

@Composable
@NonRestartableComposable
fun PlayerAwareLazyStack(
    modifier: Modifier = Modifier,
    horizontalAlignment: Alignment.Horizontal = Alignment.Start,
    spaceBetween: LayoutGap = LayoutGap.Medium,
    alignment: Alignment.Vertical = Alignment.Top,
    contentPadding: PaddingValues = PaddingValues(
        bottom = 66.dp, // playerheight plus 8dp padding
    ),
    lazyListState: LazyListState = rememberLazyListState(),
    content: LazyListScope.() -> Unit,
) {
    LazyStack(
        modifier = modifier,
        horizontalAlignment = horizontalAlignment,
        spaceBetween = spaceBetween,
        alignment = alignment,
        contentPadding = contentPadding,
        lazyListState = lazyListState,
        content = content,
    )
}