package io.daio.pancake.icons

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

val Icons.Settings: ImageVector
    get() {
        if (_Settings != null) {
            return _Settings!!
        }
        _Settings = ImageVector.Builder(
            name = "Settings",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 24f,
            viewportHeight = 24f
        ).apply {
            path(fill = SolidColor(Color(0xFFFFFFFF))) {
                moveToRelative(9.25f, 22f)
                lineToRelative(-0.4f, -3.2f)
                arcToRelative(3.79f, 3.79f, 0f, isMoreThanHalf = false, isPositiveArc = true, -0.612f, -0.3f)
                arcToRelative(8.27f, 8.27f, 0f, isMoreThanHalf = false, isPositiveArc = true, -0.563f, -0.375f)
                lineTo(4.7f, 19.375f)
                lineToRelative(-2.75f, -4.75f)
                lineToRelative(2.575f, -1.95f)
                arcToRelative(2.389f, 2.389f, 0f, isMoreThanHalf = false, isPositiveArc = true, -0.025f, -0.338f)
                verticalLineToRelative(-0.675f)
                curveToRelative(0f, -0.108f, 0.008f, -0.22f, 0.025f, -0.337f)
                lineTo(1.95f, 9.375f)
                lineToRelative(2.75f, -4.75f)
                lineToRelative(2.975f, 1.25f)
                curveToRelative(0.183f, -0.133f, 0.375f, -0.258f, 0.575f, -0.375f)
                curveToRelative(0.2f, -0.117f, 0.4f, -0.217f, 0.6f, -0.3f)
                lineToRelative(0.4f, -3.2f)
                horizontalLineToRelative(5.5f)
                lineToRelative(0.4f, 3.2f)
                curveToRelative(0.217f, 0.083f, 0.42f, 0.183f, 0.612f, 0.3f)
                curveToRelative(0.192f, 0.117f, 0.38f, 0.242f, 0.563f, 0.375f)
                lineToRelative(2.975f, -1.25f)
                lineToRelative(2.75f, 4.75f)
                lineToRelative(-2.575f, 1.95f)
                curveToRelative(0.017f, 0.117f, 0.025f, 0.23f, 0.025f, 0.338f)
                verticalLineToRelative(0.675f)
                curveToRelative(0f, 0.108f, -0.017f, 0.22f, -0.05f, 0.337f)
                lineToRelative(2.575f, 1.95f)
                lineToRelative(-2.75f, 4.75f)
                lineToRelative(-2.95f, -1.25f)
                arcToRelative(6.826f, 6.826f, 0f, isMoreThanHalf = false, isPositiveArc = true, -0.575f, 0.375f)
                curveToRelative(-0.2f, 0.117f, -0.4f, 0.217f, -0.6f, 0.3f)
                lineToRelative(-0.4f, 3.2f)
                horizontalLineToRelative(-5.5f)
                close()
                moveTo(11f, 20f)
                horizontalLineToRelative(1.975f)
                lineToRelative(0.35f, -2.65f)
                arcToRelative(5.61f, 5.61f, 0f, isMoreThanHalf = false, isPositiveArc = false, 1.438f, -0.588f)
                curveToRelative(0.441f, -0.258f, 0.845f, -0.57f, 1.212f, -0.937f)
                lineToRelative(2.475f, 1.025f)
                lineToRelative(0.975f, -1.7f)
                lineToRelative(-2.15f, -1.625f)
                curveToRelative(0.083f, -0.233f, 0.142f, -0.48f, 0.175f, -0.738f)
                arcToRelative(6.153f, 6.153f, 0f, isMoreThanHalf = false, isPositiveArc = false, 0f, -1.575f)
                arcToRelative(3.529f, 3.529f, 0f, isMoreThanHalf = false, isPositiveArc = false, -0.175f, -0.737f)
                lineToRelative(2.15f, -1.625f)
                lineToRelative(-0.975f, -1.7f)
                lineToRelative(-2.475f, 1.05f)
                arcToRelative(5.554f, 5.554f, 0f, isMoreThanHalf = false, isPositiveArc = false, -1.213f, -0.962f)
                arcToRelative(5.605f, 5.605f, 0f, isMoreThanHalf = false, isPositiveArc = false, -1.437f, -0.588f)
                lineTo(13f, 4f)
                horizontalLineToRelative(-1.975f)
                lineToRelative(-0.35f, 2.65f)
                curveToRelative(-0.517f, 0.133f, -0.996f, 0.33f, -1.437f, 0.587f)
                arcToRelative(5.993f, 5.993f, 0f, isMoreThanHalf = false, isPositiveArc = false, -1.213f, 0.938f)
                lineTo(5.55f, 7.15f)
                lineToRelative(-0.975f, 1.7f)
                lineToRelative(2.15f, 1.6f)
                curveToRelative(-0.083f, 0.25f, -0.142f, 0.5f, -0.175f, 0.75f)
                arcToRelative(6.122f, 6.122f, 0f, isMoreThanHalf = false, isPositiveArc = false, 0f, 1.575f)
                curveToRelative(0.033f, 0.25f, 0.092f, 0.5f, 0.175f, 0.75f)
                lineToRelative(-2.15f, 1.625f)
                lineToRelative(0.975f, 1.7f)
                lineToRelative(2.475f, -1.05f)
                arcToRelative(5.53f, 5.53f, 0f, isMoreThanHalf = false, isPositiveArc = false, 2.65f, 1.55f)
                lineTo(11f, 20f)
                close()
                moveTo(12.05f, 15.5f)
                curveToRelative(0.967f, 0f, 1.792f, -0.342f, 2.475f, -1.025f)
                arcTo(3.372f, 3.372f, 0f, isMoreThanHalf = false, isPositiveArc = false, 15.55f, 12f)
                curveToRelative(0f, -0.967f, -0.342f, -1.792f, -1.025f, -2.475f)
                arcTo(3.372f, 3.372f, 0f, isMoreThanHalf = false, isPositiveArc = false, 12.05f, 8.5f)
                curveToRelative(-0.983f, 0f, -1.813f, 0.342f, -2.487f, 1.025f)
                arcTo(3.393f, 3.393f, 0f, isMoreThanHalf = false, isPositiveArc = false, 8.55f, 12f)
                curveToRelative(0f, 0.967f, 0.338f, 1.792f, 1.013f, 2.475f)
                curveToRelative(0.675f, 0.683f, 1.504f, 1.025f, 2.487f, 1.025f)
                close()
            }
        }.build()

        return _Settings!!
    }

@Suppress("ObjectPropertyName")
private var _Settings: ImageVector? = null
