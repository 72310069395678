

// Do not edit directly
// Generated on Thu, 06 Feb 2025 22:47:57 GMT



package io.daio.pancake.foundations.size;

import androidx.compose.ui.unit.dp

object Spacing {
  val spaceL = 24.00.dp
  val spaceM = 16.00.dp
  val spaceS = 12.00.dp
  val spaceXl = 32.00.dp
  val spaceXs = 8.00.dp
  val spaceXxl = 48.00.dp
  val spaceXxs = 4.00.dp
  val spaceXxxl = 64.00.dp
  val spaceXxxs = 2.00.dp
}
