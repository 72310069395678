package studio.goodegg.capsule.api.clients

import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.get
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.Episode
import studio.goodegg.capsule.PodcastList
import studio.goodegg.capsule.PodcastResult
import studio.goodegg.capsule.SearchResult
import studio.goodegg.capsule.TrendingResult
import studio.goodegg.capsule.api.FUNCTIONS_BASE_URL
import studio.goodegg.capsule.utils.Failed
import studio.goodegg.capsule.utils.Response
import studio.goodegg.capsule.utils.Success

interface DiscoveryClient {
    suspend fun getDiscovery(locale: String): Response<PodcastList>

    suspend fun search(q: String): Response<SearchResult>

    suspend fun genreSearch(genreId: String, locale: String): Response<SearchResult>

    suspend fun episodeSearch(q: String): Response<List<Episode>>

    suspend fun podcastSearch(q: String): Response<List<PodcastResult>>

    suspend fun getTrending(locale: String): Response<TrendingResult>
}

@Inject
internal class DiscoveryClientImpl(private val httpClient: HttpClient) : DiscoveryClient {

    override suspend fun getDiscovery(locale: String): Response<PodcastList> {
        return try {
            val response =
                httpClient.get(urlString = "${FUNCTIONS_BASE_URL}discovery?country=$locale")
            Success(response.body())
        } catch (error: Throwable) {
            Failed(error)
        }
    }

    override suspend fun search(q: String): Response<SearchResult> {
        return try {
            val response =
                httpClient.get(urlString = "${FUNCTIONS_BASE_URL}search?q=$q&includeEpisodes=1")
            Success(response.body())
        } catch (error: Throwable) {
            error.printStackTrace()
            Failed(error)
        }
    }

    override suspend fun genreSearch(genreId: String, locale: String): Response<SearchResult> {
        return try {
            val response =
                httpClient.get(urlString = "${FUNCTIONS_BASE_URL}search/genres?genreId=$genreId&country=$locale")
            Success(response.body())
        } catch (error: Throwable) {
            error.printStackTrace()
            Failed(error)
        }
    }

    override suspend fun episodeSearch(q: String): Response<List<Episode>> {
        return try {
            val response =
                httpClient.get(urlString = "${FUNCTIONS_BASE_URL}search/episodes?q=$q")
            val body: SearchResult = response.body()
            Success(body.episodes)
        } catch (error: Throwable) {
            error.printStackTrace()
            Failed(error)
        }
    }

    override suspend fun podcastSearch(q: String): Response<List<PodcastResult>> {
        return try {
            val response = httpClient.get(urlString = "${FUNCTIONS_BASE_URL}search/podcasts?q=$q")
            val body: SearchResult = response.body()
            Success(body.podcasts)
        } catch (error: Throwable) {
            error.printStackTrace()
            Failed(error)
        }
    }

    override suspend fun getTrending(locale: String): Response<TrendingResult> {
        return try {
            val response =
                httpClient.get(urlString = "${FUNCTIONS_BASE_URL}trending?country=$locale")
            Success(response.body())
        } catch (error: Throwable) {
            error.printStackTrace()
            Failed(error)
        }
    }
}
